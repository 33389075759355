import React from "react"
import { useTranslation } from "react-i18next"
import { convertToSimpleDate } from '../../utils/filters/date'
import "./firmware-release-notes.scss"

const ReleaseNotes = (props) => {
  const { t } = useTranslation()
  const { selectedDevice } = props

  const isValidNote = (note) => {
    return note?.length > 0 && note[0]?.length > 0
  }

  return (
    <div className="firmware-release-notes-wrapper">
      <div className="product-name-latest">{selectedDevice.versions[0].name}</div>
      <div className="label-date">
        <span>{t("uploaded")}: </span>
        {convertToSimpleDate(selectedDevice.versions[0].created_at)}
      </div>
      <div className="release-notes-list">
        {selectedDevice.versions[0].release_notes?.filter((n) => isValidNote(n)).map((note, id) =>
          <div 
            key={`note-${id}`}
            className="note-item"
          >
            <div className="bullet"></div>
            {note}
          </div>)
        }
      </div>
    </div>
  )
}

export default ReleaseNotes
