import React, { Fragment, useEffect, useState} from 'react'
import { useTranslation } from "react-i18next";
import {connect} from "react-redux";
import { toast } from "../common/Toast"
import LoadingSpinner from "../common/LoadingSpinner";
import { ArrowLeftIcon, TrashCan } from './../../icons/index'
import { useWindowSize } from "../../libs/hooks";
import {
  deleteNotification,
  getNotifications,
  postNotification,
  updateNotification
} from "../../utils/requests/notifications";
import { checkPermission, userNotificationWritePermission, userNotificationDeletePermission } from '../../utils/permissionValidation';
import './notification-settings.scss'

const NotificaitonSettings = (props) => {
  const serialNumber = props?.serialNumber
  const model = props?.model
  const {t} = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [sort, setSort] = useState(false)
  const [usersNotifications, setUsersNotifications] = useState([])
  const [newUserNotification, setNewUserNotification] = useState({})
  const [showSpinner, setShowSpinner] = useState(false)
  const notificationOptions = {
    faultAndSuccess: 1,
    fault: 2,
    success: 3,
    none: 4,
  }

  useEffect(() => {
    if(!usersNotifications.length) {
      setShowSpinner(true)
      getAllNotifications()
    }
    if (usersNotifications && sort) {
      arrangeArr(usersNotifications)
    }
  }, [sort])

  const fieldsHandler = (e) => {
    if (e.target.name === 'fault') {
      return setNewUserNotification({
        ...newUserNotification,
        [e.target.name]: !newUserNotification?.fault
      })
    }
    if (e.target.name === 'success') {
      return setNewUserNotification({
        ...newUserNotification,
        [e.target.name]: !newUserNotification?.success
      })
    }

    setNewUserNotification({
      ...newUserNotification,
      [e.target.name]: e.target.value.trim()
    })
  }

  const arrangeArr = (arr) => {
    if(arr.length) {
      const arrangedArr = arr.sort((a, b) => a && a.email && b && b.email ? a.email.localeCompare(b.email) : false)
      let sortedArray = sort ? arrangedArr : arrangedArr.reverse()

      if (props.currentUserBranchAdmin) {
        sortedArray = sortedArray.filter(item => item.email !== props.currentUserBranchAdmin)

        const adminItem = arr.find(item => item.email === props.currentUserBranchAdmin)

        if (adminItem) {
          sortedArray.unshift(adminItem)
        }
      }

      return sortedArray
    }

    return []
  }

  const changeSort = () => {
    setSort(!sort)
  }
  
  const isValidConfigurationEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const isValidFormat =  emailRegex.test(String(email).toLowerCase())
    if(!isValidFormat) {
      toast.error(t("error.email"))
      return 
    }

    const existingEmail = usersNotifications.find((config) => config.email.toLowerCase() === email.toLowerCase())

    if (existingEmail?.email?.toLowerCase() === email.toLowerCase()) {
      toast.error(existingEmail.branches_id === props.currentUserBranch ? t("notifications.error_member_email") : t("error.email_exists"))
      return false
    }

    return true
  }

  const addUser = () => {
    if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
      return
    }
    
    if(!isValidConfigurationEmail(newUserNotification.email)) {
      return
    }

    setShowSpinner(true)

    let body = {
      user: props.authedUser.email,
      recipient: newUserNotification && newUserNotification.email ? newUserNotification.email : '',
      sn: serialNumber,
      model: model,
      cycle_notification_option: notificationOptions.none
    }

    if(newUserNotification.fault || newUserNotification.success) {
      body = {
        ...body,
        cycle_notification_option: newUserNotification.fault && newUserNotification.success ? notificationOptions.faultAndSuccess : newUserNotification.fault && !newUserNotification.success ? notificationOptions.fault : notificationOptions.success
      }
    }
    
    postNotification(body)
      .then(()=>{
        getAllNotifications()
        toast.success(t("notifications.success-add-notification-settings"))
      })
      .catch((error) => {
        const alreadyMemberCode = "alreadyMember"
        const errorCode = error?.response?.data?.code
        const errorMessage = errorCode === alreadyMemberCode ? t("notifications.error_member_email") : t([`error.${errorCode}`, "error.something_wrong"])
        toast.error(errorMessage)

        setShowSpinner(false)
      })

    setNewUserNotification({})
  }

  const getAllNotifications = () => {
    getNotifications(serialNumber)
      .then(r => {
        setUsersNotifications(r.data)
        setShowSpinner(false)
      })
  }

  const updateUser = (notification, key) => {
    setShowSpinner(true)
    
    let body = {}

    if (notification.cycle_notification_option === notificationOptions.faultAndSuccess && key === 'fault') {
      body = {
        cycle_notification_option: notificationOptions.success
      }
    }

    if (notification.cycle_notification_option === notificationOptions.faultAndSuccess && key === 'success') {
      body = {
        cycle_notification_option: notificationOptions.fault
      }
    }

    if (notification.cycle_notification_option === notificationOptions.fault && key === 'fault') {
      body = {
        cycle_notification_option: notificationOptions.none
      }
    }

    if (notification.cycle_notification_option === notificationOptions.fault && key === 'success') {
      body = {
        cycle_notification_option: notificationOptions.faultAndSuccess
      }
    }

    if (notification.cycle_notification_option === notificationOptions.success && key === 'fault') {
      body = {
        cycle_notification_option: notificationOptions.faultAndSuccess
      }
    }

    if (notification.cycle_notification_option === notificationOptions.success && key === 'success') {
      body = {
        cycle_notification_option: notificationOptions.none
      }
    }

    if (notification.cycle_notification_option === notificationOptions.none && key === 'fault') {
      body = {
        cycle_notification_option: notificationOptions.fault
      }
    }

    if (notification.cycle_notification_option === notificationOptions.none && key === 'success') {
      body = {
        cycle_notification_option: notificationOptions.success
      }
    }

    updateNotification(notification.id, body)
      .then(()=> {
        toast.success(t("notifications.success-edit-notification-settings"))
        getAllNotifications()
      })
      .catch (() => {
        setShowSpinner(false)
        toast.error(t("error.something_wrong"))
      })
  }

  const deleteUser = (id) => {
    if (!checkPermission(props.userPermissions, userNotificationDeletePermission)) {
      return
    }

    setShowSpinner(true)

    deleteNotification(id)
      .then(() => {
        getAllNotifications()
        toast.success(t("notifications.success-delete-notification-settings"))
      })
      .catch(() => {
        setShowSpinner(false)
      })
  }
  
  return (
    <div className="notification-wrapper d-flex flex-column">
      <h1>{t('nav.notifications')}</h1>
      {isMobile ?
        <div className="mobile d-flex flex-column">
          <div className="send-to">
            {t('notifications.send-to')}
          </div>
          <input type="email" name="email" placeholder={t('notifications.enter_email')} onChange={fieldsHandler}
                 value={newUserNotification?.email || ''}/>
          <div className="d-flex flex-justify-between cycle">
            <div>
              {t('notifications.cycle-summary')}
            </div>
            <div className="checkbox d-flex flex-align-center">
              <input type="checkbox" name="fault" onChange={fieldsHandler} checked={newUserNotification?.fault}/>
              <div>{t('fault')}</div>
              <input type="checkbox" name="success" onChange={fieldsHandler} checked={newUserNotification?.success}/>
              <div>{t('notifications.success')}</div>
            </div>
          </div>
          <button className="add" onClick={addUser}>{t('add')}</button>
          {usersNotifications && arrangeArr(usersNotifications).map((user, index) =>
            <div key={index} className="users-wrapper">
              <div className="send-to">
                {user.email}
              </div>
              <div className="d-flex flex-justify-between cycle">
                <div>
                  {t('notifications.cycle-summary')}
                </div>
                <div className="checkbox d-flex flex-align-center">
                  <input type="checkbox" name="fault" onChange={fieldsHandler} checked={user?.cycle_notification_option === 1 || user?.cycle_notification_option === 2}/>
                  <div>{t('fault')}</div>
                  <input type="checkbox" name="success" onChange={fieldsHandler} checked={user?.cycle_notification_option === 1 || user?.cycle_notification_option === 3}/>
                  <div>{t('notifications.success')}</div>
                </div>
              </div>
              <div className="delete">
                {user.email !== props.authedUser?.email && (!props.currentUserBranch || props.currentUserBranch !== user.branches_id) && (
                  <TrashCan onClick={(e) => {
                      if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
                        return
                      }

                      e.stopPropagation()
                      deleteUser(user.id)
                    }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      :
        <Fragment>
          <div className="table">
            <div className="send-to d-flex flex-column">
              <div
                onClick={() => changeSort()}
                className="cursor-pointer"
              >
                {t('notifications.send-to')}
                <ArrowLeftIcon
                  className="down"/>
                <ArrowLeftIcon className="up"/>
              </div>
              <div className="table-header">
                <input type="email" name="email" placeholder={t('notifications.enter_email')} onChange={fieldsHandler}
                       value={newUserNotification?.email || ''}/>
              </div>
            </div>
            <div className="cycle-summary-notification d-flex flex-column">
              <div>{t('notifications.cycle-summary')}</div>
              <div className="table-header d-flex">
                <input type="checkbox" name="fault" onChange={fieldsHandler} checked={newUserNotification?.fault}/>
                <div>{t('fault')}</div>
                <input type="checkbox" name="success" onChange={fieldsHandler} checked={newUserNotification?.success}/>
                <div>{t('notifications.success')}</div>
              </div>
            </div>
            <div className="actions">
              <div>{t('actions')}</div>
              <div className="table-header">
                <button onClick={addUser} disabled={newUserNotification.email ? false : true}>{t('add')}</button>
              </div>
            </div>
          </div>
          {usersNotifications && arrangeArr(usersNotifications).map((user, index) => (
            <div className="table" key={index}>
              <div className="table-body">{user.email}</div>
              <div className="table-body d-flex flex-align-center">
                <input type="checkbox" name="fault" checked={user.cycle_notification_option === 1 || user.cycle_notification_option === 2}
                       onChange={() => updateUser(user, 'fault')}/>
                <div>{t('fault')}</div>
                <input type="checkbox" name="success" checked={user.cycle_notification_option === 1 || user.cycle_notification_option === 3} onChange={() => updateUser(user, 'success')}/>
                <div>{t('notifications.success')}</div>
              </div>
              <div className="table-body d-flex">
                {user.email !== props.authedUser?.email && (!props.currentUserBranch || props.currentUserBranch !== user.branches_id) && (
                  <TrashCan
                    className="delete-icon"
                    onClick={(e) => {
                      if (!checkPermission(props.userPermissions, userNotificationWritePermission)) {
                        return
                      }

                      e.stopPropagation()
                      deleteUser(user.id)
                    }}
                  />
                )}
              </div>
            </div>
          ))}
        </Fragment>
      }
      {showSpinner &&
        <div className={"spinner-wrapper"}>
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}
function mapStateToProps({authedUser, userPermissions, userCompany }) {
  return {
    authedUser,
    userPermissions,
    currentUserBranch: userCompany?.branchId,
    currentUserBranchAdmin: userCompany?.companyAdmin
  }
}

export default connect(mapStateToProps)(NotificaitonSettings)
