import {
  LOAD_SERVICES_FULFILLED,
  LOAD_SERVICES_PENDING,
  LOAD_SERVICE_BY_ID_PENDING,
  LOAD_SERVICE_BY_ID_FULFILLED,
  LOAD_NEW_SERVICES_PENDING,
  LOAD_NEW_SERVICES_FULFILLED,
  LOAD_SERVICES_REJECTED,
  LOAD_NEW_SERVICES_REJECTED,
  LOAD_SERVICE_BY_ID_REJECTED,
  PUT_SERVICE_STATUS_PENDING,
  PUT_SERVICE_STATUS_REJECTED,
  CLEAR_SERVICES_MESSAGES,
  PUT_SERVICE_STATUS_FULFILLED,
  DELETE_SERVICE_PENDING,
  DELETE_SERVICE_REJECTED,
  PUT_SERVICE_PENDING,
  PUT_SERVICE_FULFILLED,
  PUT_SERVICE_REJECTED,
  POST_SERVICE_PENDING,
  POST_SERVICE_FULFILLED,
  POST_SERVICE_REJECTED,
  DELETE_SERVICE_FULFILLED,
} from "../actions/services"
import i18n from "i18next"

export default function services(state = {}, action) {
  switch (action.type) {
    case LOAD_SERVICES_PENDING:
    case LOAD_NEW_SERVICES_PENDING:
      return {
        ...state,
        servicesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_SERVICE_BY_ID_PENDING:
      return {
        ...state,
        serviceLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case PUT_SERVICE_PENDING:
      return {
        ...state,
        serviceLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case POST_SERVICE_PENDING:
      return {
        ...state,
        serviceLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case DELETE_SERVICE_PENDING:
      return {
        ...state,
        servicesLoading: true,
        errorMessage: null,
        successMessage: null,
      }
    case PUT_SERVICE_STATUS_PENDING:
      return {
        ...state,
        ...(!action.meta.showToast && { serviceLoading: true }),
        ...(action.meta.showToast && { servicesLoading: true }),
        errorMessage: null,
        successMessage: null,
      }
    case LOAD_SERVICES_FULFILLED:
      const services = state?.services || []
      const newServicesIds = action.payload.map((item) => item.id)
      const difference = services
        .filter(x => !newServicesIds.includes(x.id))
        .concat(action.payload)

      return {
        ...state,
        services: difference,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE) ? true : false,
        servicesLoading: false,
      }
    case LOAD_NEW_SERVICES_FULFILLED:
      return {
        ...state,
        services: action.payload,
        canLoadMore: action.payload.length === Number(process.env.REACT_APP_PAGINATION_SIZE) ? true : false,
        servicesLoading: false,
      }
    case LOAD_SERVICE_BY_ID_FULFILLED:
      return {
        ...state,
        service: action.payload,
        serviceLoading: false,
      }
    case PUT_SERVICE_FULFILLED:
      return {
        ...state,
        services: state?.services.map((item) => (item.id === action.payload.id ? { ...action.payload } : item)),
        serviceLoading: false,
        ...(action.meta.showToast && { successMessage: i18n.t("services_context.service_updated") }),
      }
    case POST_SERVICE_FULFILLED:
      return {
        ...state,
        services: [action.payload, ...(state?.services || [])],
        serviceLoading: false,
        ...(action.meta.showToast && { successMessage: i18n.t("services_context.service_created") }),
      }
    case PUT_SERVICE_STATUS_FULFILLED:
      return {
        ...state,
        services: state?.services.map((item) => (item.id === action.payload.id ? { ...action.payload } : item)),
        ...(action.meta.showToast && { servicesLoading: false }),
        ...(!action.meta.showToast && { serviceLoading: false }),
        successMessage: action.meta.showToast
          ? action.meta.operation === "enable"
            ? i18n.t("services_context.service_activated")
            : i18n.t("services_context.service_deactivated")
          : i18n.t("services_context.service_created"),
      }
    case DELETE_SERVICE_FULFILLED:
      return {
        ...state,
        services: state?.services.filter((service) => service.id !== action.meta.id),
        servicesLoading: false,
        successMessage: i18n.t("services_context.service_deleted"),
      }
    case LOAD_SERVICES_REJECTED:
    case LOAD_NEW_SERVICES_REJECTED:
      return {
        ...state,
        servicesLoading: false,
      }
    case LOAD_SERVICE_BY_ID_REJECTED:
      return {
        ...state,
        serviceLoading: false,
      }
    case POST_SERVICE_REJECTED:
      return {
        ...state,
        serviceLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_SERVICE_REJECTED:
      console.log("CODE:", action.payload.response?.data?.code)
      return {
        ...state,
        serviceLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case DELETE_SERVICE_REJECTED:
      return {
        ...state,
        servicesLoading: false,
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case PUT_SERVICE_STATUS_REJECTED:
      return {
        ...state,
        ...(action.meta.showToast && { servicesLoading: false }),
        ...(!action.meta.showToast && { serviceLoading: false }),
        errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"]),
      }
    case CLEAR_SERVICES_MESSAGES:
      return {
        ...state,
        errorMessage: null,
        successMessage: null,
      }
    default:
      return state
  }
}
