import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Storage } from "aws-amplify"
import { toast } from "../../Toast"
import { TrashCanFull, UploadCloudFull } from "../../../../icons"
import './image-uploader.scss'

const ImageUploader = ({ templateId, sectionId, fileSrc, fileKey, handleImage }) => {
  const { t } = useTranslation()
  const [imageSource, setImageSource] = useState(fileSrc)
  const [imageKey, setImageKey] = useState(fileKey)
  const [showDeleteButton, setShowDeleteButton] = useState(false)
  const fileInput = useRef(null)
  const acceptedFileTypes = ["image/svg", "image/svg+xml", "image/png", "image/jpg", "image/jpeg"]
  const maxFileSize = 5000000
  const s3ColteneImagesFolder = "coltene-landing/images"

  useEffect(() => {
    const getS3Image = async (key) => {
      var img = await Storage.get(key, {
        level: "public",
      })

      setImageSource(img)
    }

    if (imageKey?.length > 0 && !imageSource) {
      getS3Image(imageKey)
      return
    }

    setImageSource(fileSrc)
  }, [fileSrc])

  useEffect(() => {
    setImageKey(fileKey)
  }, [fileKey])

  const handleAdd = () => {
    if (imageSource) {
      return
    }

    fileInput.current.click()
  }

  const handleRemove = async () => {
    if (imageKey && !imageKey.includes("default")) {
      try {
        await Storage.remove(imageKey, { level: "public"})
      } catch (error) {
        console.log("Remove image failed", error)
      }
    }

    handleImage('', null)
  }

  const uploadSingleFile = async (e) => {
    const file = e.target.files[0]

    if (!acceptedFileTypes.includes(file.type)) {
      toast.error(t("error.file_type_not_supported"))

      return
    }

    if (file.size > maxFileSize) {
      toast.error(t("error.file_too_lager"))

      return
    }

    try {
      const ext = file.name.split(".")[1]
      const storagePath = `${s3ColteneImagesFolder}/${templateId}-${sectionId}.${ext}`

      const isSVG = ext === 'svg'

      var { key } = await Storage.put(storagePath, file, {
        level: "public",
        contentType: isSVG ? "image/svg+xml" : "image/*",
      })

      var img = await Storage.get(key, {
        level: "public",
      })

      handleImage(img, key)
    } catch (error) {
      console.log("error", error)

      toast.error("An error occured while updating the image")
    }
  }

  return (
    <div
      className="image-uploader"
      onMouseEnter={() => setShowDeleteButton(true)}
      onMouseLeave={() => setShowDeleteButton(false)}
      onClick={() => handleAdd()}
    >
      <input
        ref={fileInput}
        type="file" 
        accept="image/svg, image/png, image/jpg, image/jpeg, image/svg+xml" 
        style={{ display: "none" }} 
        onChange={uploadSingleFile} 
      />
      {imageSource &&
        <img 
          className={"image" + (showDeleteButton ? " with-transparency" : "")}
          src={imageSource}
          alt="background"
        />
      }
      {!imageSource &&
        <div className="add-image-wrapper">
          <UploadCloudFull className="upload-svg" />
          {t("upload_image")}
        </div>
      }
      {imageSource && showDeleteButton && (
        <div className="remove-image" onClick={() => handleRemove()}>
          <TrashCanFull />
        </div>
      )}
    </div>
  )
}

export default ImageUploader
