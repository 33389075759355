import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "../../libs/hooks"
import { getImageByModel } from "../../utils/functions"
import { Pages } from "../../utils/pages"

function NewProductCard(props) {
  const { t } = useTranslation()
  const [machines, setMachines] = useState([])
  const maxDisplayedMachines = 2;

  useEffect(() => {
    if (props.machines?.length > 0) {
      const sortedMachines = props.machines?.sort((a, b) => {
        return b.association_active - a.association_active
      })

      setMachines(sortedMachines)
    }    
  }, [props.machines])

  return (
    <div className={`new-product-card`}>
      <div className={`left`}>
        <div className="product-image">
          <img 
            src={getImageByModel(props.model)} 
            className="img" 
            alt={props.model}
          />
        </div>
      </div>
      <div className="right">
        <div className="product-name">
          <div>{props.isICTrackProduct ? t([`products.${props.model}`, props.model]) : props.model}</div>
        </div>
        {machines.slice(0, maxDisplayedMachines).map((machine, index) =>
          <div className="products" key={`${machine.serial_number}-${index}`}>
            {machine.association_active ? (
              <Link
                to={{
                  pathname: "/my-products",
                  state: {
                    model: props.model,
                    serialNumber: machine.serial_number,
                  }
                }}
                className="product-route"
              >
                {machine.unit_nickname ? machine.unit_nickname : `${t("sn")}: ${machine.serial_number}`}
              </Link>
            ) : (
              <div className="product">
                {machine.unit_nickname ? machine.unit_nickname : `${t("sn")}: ${machine.serial_number}`}
              </div>
            )}
          </div>)
        }
        {machines.length > maxDisplayedMachines && 
          <Link
            to={{
              pathname: "/my-products",
              state: {
                model: props.model
              }
            }}
            className="more-products"
          >
            {`+${machines.length - maxDisplayedMachines} ${t("more")}`}
          </Link>
        }
      </div>
    </div>
  )
}

export default NewProductCard
