import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectMenu } from "../../SelectMenu"
import { ArrowLeftIcon } from "../../../../icons"
import './flex-container-builder-toolbox.scss'

const FlexContainerBuilderToolbox = ({ id, style, children, onUpdate }) => {
  const { t } = useTranslation()
  const [flexDirection, setFlexDirection] = useState('row')
  //const [gap, setGap] = useState('0rem')
  //const [childElements, setChildElements] = useState([])
  const directionOptions = [{ key: 'row', value: 'Row' }, { key:'column', value: 'Column' }]

  useEffect(() => {
    setFlexDirection(style?.flexDirection || 'row')
    //setGap(style?.gap || '0rem')
  
    //setChildElements(children || [])
  },[style, children])

  // const handleShiftChild = (index, direction) => {
  //   const newChildren = [...childElements]

  //   if (direction === 'up' && index < newChildren.length - 1) {
  //     [newChildren[index + 1], newChildren[index]] = [newChildren[index], newChildren[index + 1]]
  //   } else if (direction === 'down' && index > 0) {
  //     [newChildren[index - 1], newChildren[index]] = [newChildren[index], newChildren[index - 1]]
  //   }

  //   setChildElements(newChildren)
  //   onUpdate(id, { children: newChildren })
  // }

  return (
    <div className="flex-container-toolbox">
      <div className="input-wrapper">
        <p className="input-option">{t("flex_direction")}</p>
        <SelectMenu
          options={directionOptions}
          initialSelectedKey={flexDirection}
          onChange={(option) => {
            setFlexDirection(option.key)
            onUpdate(id, { 
              style: {  
                ...style,
                flexDirection: option.key,
                //gap,
              }
            })
          }}
        />
      </div>

      {/* <div className="input-wrapper">
        <p className="input-option">{t("gap")}</p>
        <input 
            type="number"
            className="input-item"
            value={parseFloat(gap)}
            onChange={(e) => {
              setGap(`${e.target.value}rem`)
              onUpdate(id, { 
                style: { 
                  ...style,
                  flexDirection,
                  gap: `${e.target.value}rem`,
                }
              })
            }}
          />
      </div> */}

      {/* <div className="title">{t("layout")}</div>
      {childElements.map((child, index) =>
        <div key={child.id} className="child-item">
          <ArrowLeftIcon 
            className={"arrow-down" + (index === childElements.length - 1 ? " disabled-shift" : "")}
            onClick={() => handleShiftChild(index, "up")}
          />
          <ArrowLeftIcon 
            className={"arrow-up" + (index === 0 ? " disabled-shift" : "")}
            onClick={() => handleShiftChild(index, "down")}
          />
          <span>{child.id}</span>
        </div>
      )} */}
    </div>
  )
}

export default FlexContainerBuilderToolbox