import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { LineChart, CartesianGrid, Tooltip, XAxis, Line } from "recharts"
import { formatTime } from "../../../utils/functions"

function getColorMap() {
  return {
    airGapSwitchLow: "#3f28dc",
    airGapSwitchFull: "#E31A1C",
    airGapSwitchOverflow: "#33A02C",
    airFilterHepa: "#FF7F00",
    chamberOverflow: "#6A3D9A",
    chamberFull: "#62e6c0",
    chamberLow: "#048b69",
    chemicalReservoirSwitch: "##8305b2",
    dryerHeater: "#0665ee",
    doorPosition: "#339375",
    dosingBellowsPump: "#2c352e",
    dosingValidation: "#3bb905",
    dosingFillingPump: "#e33d7b",
    evacuationPump: "#eacd54",
    lcsAirPressureSwitch: "#0cdeef",
    lcsPresent: "#838ac3",
    rinseAidSwitch: "#a33616",
    saltReservoirSwitch: "#ac2a4f",
    valveLcs: "#c78975",
    valveCondenser: "#e5cbb4",
    valveColdWater: "#ed3f2c",
    valveHotWater: "#56ee20",
    valveAirGap: "#e17bc2",
    valveRinseAid: "#a9f4c7",
    valveRoWater: "#268481",
    valveSalt: "#391a18",
    waterHeater: "#2c0f47",
    waterLeak: "#fac44e",
  }
}

export default function OutputChart(props) {
  const { t } = useTranslation()
  const [timeFormat, setTimeFormat] = useState("24hr")
  const [visibleKeys, setVisibleKeys] = useState([])
  const [data, setData] = useState([])
  const colorMap = getColorMap()

  useEffect(() => {
    const updatedData = props.data.map((item) => {
      const transformedItem = { time: item.time }
      Object.keys(item).forEach((key) => {
        if (key !== "time" && item[key].value !== undefined) {
          transformedItem[key] = item[key].value
          transformedItem[`${key}_status`] = item[key].key
        }
      })
      return transformedItem
    })
  
    setData(updatedData)
  }, [props.data])

  useEffect(() => {
    if (data && data.length > 0) {
      const keys = Object.keys(data[0]).filter((key) => key !== "time")
      setVisibleKeys((prev) => (prev.length > 0 ? prev : keys))
    }
  }, [data])

  const validData = data && data.length > 0

  const handleToggleTimeFormat = () => {
    setTimeFormat((prev) => (prev === "24hr" ? "12hr" : "24hr"))
  }

  const handleCheckboxChange = (key) => {
    setVisibleKeys((prev) => {
      if (prev.includes(key)) {
        if (prev.length === 1) {
          return prev
        }
        return prev.filter((item) => item !== key)
      } else {
        return [...prev, key]
      }
    })
  }

  const renderLegend = ({ onCheckboxChange, onToggleTimeFormat, timeFormat }) => {
    const legendItems = [
      { key: "airGapSwitchLow", label: t("advance_troubleshooting_context.airGapSwitchLow") },
      { key: "airGapSwitchFull", label: t("advance_troubleshooting_context.airGapSwitchFull") },
      { key: "airGapSwitchOverflow", label: t("advance_troubleshooting_context.airGapSwitchOverflow") },
      { key: "airFilterHepa", label: t("advance_troubleshooting_context.airFilterHepa") },
      { key: "chamberOverflow", label: t("advance_troubleshooting_context.chamberOverflow") },
      { key: "chamberFull", label: t("advance_troubleshooting_context.chamberFull") },
      { key: "chamberLow", label: t("advance_troubleshooting_context.chamberLow") },
      { key: "chemicalReservoirSwitch", label: t("advance_troubleshooting_context.chemicalReservoirSwitch") },
      { key: "dryerHeater", label: t("advance_troubleshooting_context.dryerHeater") },
      { key: "doorPosition", label: t("advance_troubleshooting_context.doorPosition") },
      { key: "dosingBellowsPump", label: t("advance_troubleshooting_context.dosingBellowsPump") },
      { key: "dosingValidation", label: t("advance_troubleshooting_context.dosingValidation") },
      { key: "dosingFillingPump", label: t("advance_troubleshooting_context.dosingFillingPump") },
      { key: "evacuationPump", label: t("advance_troubleshooting_context.evacuationPump") },
      { key: "lcsAirPressureSwitch", label: t("advance_troubleshooting_context.lcsAirPressureSwitch") },
      { key: "lcsPresent", label: t("advance_troubleshooting_context.lcsPresent") },
      { key: "rinseAidSwitch", label: t("advance_troubleshooting_context.rinseAidSwitch") },
      { key: "saltReservoirSwitch", label: t("advance_troubleshooting_context.saltReservoirSwitch") },
      { key: "valveLcs", label: t("advance_troubleshooting_context.valveLcs") },
      { key: "valveCondenser", label: t("advance_troubleshooting_context.valveCondenser") },
      { key: "valveColdWater", label: t("advance_troubleshooting_context.valveColdWater") },
      { key: "valveHotWater", label: t("advance_troubleshooting_context.valveHotWater") },
      { key: "valveAirGap", label: t("advance_troubleshooting_context.valveAirGap") },
      { key: "valveRinseAid", label: t("advance_troubleshooting_context.valveRinseAid") },
      { key: "valveRoWater", label: t("advance_troubleshooting_context.valveRoWater") },
      { key: "valveSalt", label: t("advance_troubleshooting_context.valveSalt") },
      { key: "waterHeater", label: t("advance_troubleshooting_context.waterHeater") },
      { key: "waterLeak", label: t("advance_troubleshooting_context.waterLeak") },
    ]

    return (
      <>
        <div className="button-wrapper">
          <button className="toggle-button" onClick={onToggleTimeFormat}>
            {timeFormat === "24hr" ? t("advance_troubleshooting_context.switch_12_hr") : t("advance_troubleshooting_context.switch_24_hr")}
          </button>
        </div>

        <div className="legend-items">
          {legendItems.map((item) => (
            <div className="legend-item" key={item.key}>
              <input
                type="checkbox"
                id={`checkbox-${item.key}`}
                checked={visibleKeys.includes(item.key)}
                onChange={() => onCheckboxChange(item.key)}
                className="legend-checkbox"
              />
              <label htmlFor={`checkbox-${item.key}`} style={{ color: colorMap[item.key] }}>{item.label}</label>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload?.length) {
      const filteredPayload = payload.filter((entry) => !entry.dataKey.endsWith("_status"))
      return (
        <div className="tooltip">
          {filteredPayload.map((entry, index) => {
            const dataKey = entry.dataKey
            const status = entry.payload[`${dataKey}_status`]
            return (
              <div key={index} style={{ color: entry.color }} className="tooltip-item">
                <p>{`${t(`advance_troubleshooting_context.${dataKey}`)}: ${status}`}</p>
              </div>
            )
          })}
          <div className="legend">
            <p>{t("time")}</p>
            <p className="item">{formatTime(timeFormat, label)}</p>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <LineChart width={1136} height={300} style={{ textAlign: "right", width: "100%" }} data={data}>
        <CartesianGrid />
        <XAxis tickFormatter={(time) => formatTime(timeFormat, time)} dataKey="time" style={{ fontSize: "0.8em" }} />
        {validData &&
          Object.keys(data[0])
            .filter((key) => key !== "time" && visibleKeys.includes(key))
            .map((key, index) => <Line key={key} yAxisId="left" type="step" dataKey={key} stroke={colorMap[key]} strokeWidth="2" dot={false} />)}
        <Tooltip content={renderTooltip} />
      </LineChart>

      <div className="legend-container">
        {renderLegend({
          onToggleTimeFormat: handleToggleTimeFormat,
          timeFormat,
          onCheckboxChange: handleCheckboxChange,
        })}
      </div>
    </div>
  )
}
