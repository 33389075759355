import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import Graph from "./Graph"
import PageModal from '../common/PageModal'
import GenericTable from "../common/GenericTable"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { toast } from "../common/Toast"
import { getAnalyticsFaultsCycleDetails } from "../../utils/requests/cyclesAPI"
import './analytics-details-modal.scss'

const AnalyticsDetailsModal = (props) => {
  const { t } = useTranslation()
  const { selectedProduct, cf, isSerialNumberSearch, closeModal } = props
  const [showSpinner, setShowSpinner] = useState(null)
  const [cycleDetailsTableData, setCycleDetailsTableData] = useState([])
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    setShowSpinner(true)
    let queryParams = {
      cycle_fault: cf,
    }

    if (isSerialNumberSearch) {
      queryParams = { ...queryParams, sn: selectedProduct }
    } else {
      queryParams = { ...queryParams, product_type: selectedProduct }
    }

    getAnalyticsFaultsCycleDetails(queryParams)
      .then((response) => {
        const cycleDetailsData = response.data

        const tableData = []

        cycleDetailsData.forEach((model) => {
          model.trend.forEach((trendItem) => {
            const formattedMonth = `${trendItem.year}-${trendItem.month.toString().padStart(2, '0')}`

            tableData.push({
              model: model.model,
              month: formattedMonth,
              year: trendItem.year,
              total_faults: trendItem.total_faults,
              unique_sn: trendItem.unique_sn,
              total_connected: trendItem.total_connected,
            })
          })
        })

        setCycleDetailsTableData(tableData)

        setShowSpinner(false)
        setIsFirstLoad(false)
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))

        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }, [])

  const handleCloseModal = () => {
    if (closeModal) {
        closeModal()
    }
  }

  return (
    <div className="analytics-details-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        title={t("monitoring.cycle-details")}
      >
        <div className="analytics-details-content">
          {cycleDetailsTableData.length > 0 &&
            <>
              <GenericTable
                id="cycle-details-table"
                className="cycle-details-table"
                data={cycleDetailsTableData}
                headers={[
                  {
                    title: t("month")
                  },
                  {
                    title: t("monitoring.total_cf")
                  },
                  {
                    title: t("monitoring.unique_sn_cf")
                  },
                  {
                    title: t("monitoring.total_connected")
                  },
                ]}
                keys={[
                  'month',
                  'total_faults',
                  'unique_sn',
                  'total_connected'
                ]}
                keyRenderer={{
                  code: (item) => {
                    return (
                      <div className={`product-type-wrapper`}>
                        {item.code}
                      </div>
                    )
                  }
                }}
              />
              <div className="graph-wrapper">
                <Graph data={cycleDetailsTableData} />
              </div>
            </>
          }
          {cycleDetailsTableData.length === 0 && !isFirstLoad &&
            <div className="no-product-wrapper">
              <EmptyPageResults
                title={t("tracking.no_data")}
              />
            </div>
          }
        </div>
      </PageModal>
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

export default AnalyticsDetailsModal