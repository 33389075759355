import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ImageUploader from '../ImageUploader'
import { getLanguageFromRegionalLanguage } from '../../../../utils/languages'
import './image-builder-toolbox.scss'

const ImageBuilderToolbox = ({ templateId, id, imageKey, src, translation, onUpdate }) => {
  const { t } = useTranslation()
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundImageKey, setBackgroundImageKey] = useState(null)
  const [translatedImageKeys, setTranslatedImageKeys] = useState({})
  const emptyTranslation = {
    fr_CA: {
      src: '',
      imageKey: ''
    },
    de_DE: {
      src: '',
      imageKey: ''
    },
    fr_FR: {
      src: '',
      imageKey: ''
    },
    it_IT: {
      src: '',
      imageKey: ''
    },
    es_ES: {
      src: '',
      imageKey: ''
    },
    es_MX: {
      src: '',
      imageKey: ''
    },
    pt_BR: {
      src: '',
      imageKey: ''
    },
  }

  useEffect(() => {
    setBackgroundImage(src || '')
    setBackgroundImageKey(imageKey)

    const mappedTranslations = Object.fromEntries(
      Object.entries(translation || {}).map(([key, imageKey]) => [
        key,
        { src: '', imageKey },
      ])
    )
    setTranslatedImageKeys({...emptyTranslation, ...mappedTranslations})
  },[imageKey, src, translation])

  const handleImage = (img, imageKey) => {
    setBackgroundImage(img)
    setBackgroundImageKey(imageKey)

    onUpdate(id, { imageKey: imageKey, src: img })
  }

  const handleImageTranslation = (img, imageKey, language) => {
    const updatedTranslation = {
      ...translatedImageKeys, 
      [language]: {
        src: img,
        imageKey,
      } 
    }
    setTranslatedImageKeys(updatedTranslation)

    onUpdate(id, { translation: updatedTranslation })
  }

  return (
    <div className="image-toolbox">
      <div className="input-wrapper">
        <p className="input-option">{t("image")}</p>
        <ImageUploader
          templateId={templateId}
          sectionId={id}
          fileSrc={backgroundImage}
          fileKey={backgroundImageKey}
          handleImage={handleImage}
        />
      </div>
      <div className="title">{t("translations")}</div>
      {Object.keys(translatedImageKeys).map((language) => 
        <div key={language} className="input-wrapper">
          <p className="input-option">{t(getLanguageFromRegionalLanguage(language))}</p>
          <ImageUploader
            templateId={templateId}
            sectionId={`${id}-${language}`}
            fileSrc={translatedImageKeys[language]?.src}
            fileKey={translatedImageKeys[language]?.imageKey}
            handleImage={(img, imageKey) => handleImageTranslation(img, imageKey, language)}
          />
        </div>
      )}
    </div>
  )
}

export default ImageBuilderToolbox