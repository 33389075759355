import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { ColtenePlaceholderProduct, CheckCircleFilledIcon, PendingIcon, CanceledIcon } from "../../icons"
import { convertToSimpleDateWithTime } from "../../utils/filters/date"
import { formatStoreProductPrice } from "../../utils/filters/filters"
import { useWindowSize } from "../../libs/hooks"
import "./admin-order-details.scss"

const AdminOrderDetails = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { order, showSpinner, onCloseModal, handleDeclineRefundRequested, handleApproveRefund, handleDeclineRefundInProgress, handleCompleteRefund } = props
  const [shippingAddress, setShippingAddress] = useState([])
  const [taxPercent, setTaxPercent] = useState(0)
  const [isPromotion, setIsPromotion] = useState(false)
  const [totalItemsPrice, setTotalItemsPrice] = useState(0)
  
  useEffect(() => {
    if (order) {
      const addressData = [
        order.shippingAddress1 || "",
        order.shippingAddress2 || "",
        order.shippingPostcode || "",
        order.shippingCity || "",
        order.shippingState || "",
        order.shippingCountry || "",
      ]

      setShippingAddress(addressData.filter((item) => item !== ""))

      if (order.taxAmount && order.paymentAmount) {
        const tax = Number(order.taxAmount)
        const payment = Number(order.paymentAmount)
        setTaxPercent(tax / (payment - tax))

        if (Number(order.paymentAmount) === 0) {
          setIsPromotion(true)
    
          const itemsPrice = order?.items?.reduce((acc, item) => {
            const itemTotal = (Number(item.product?.price) || 0) * (item.quantity || 0)
            return acc + itemTotal
          }, 0) || 0
    
          setTotalItemsPrice(itemsPrice)
        }
      }
    }
  }, [order])

  const detailsFooter = () => {
    return (
      <>
        {handleDeclineRefundRequested && handleApproveRefund && order.status === "Refund Requested" && (
          <>
            <SimpleButton 
              className="cancel-button" 
              onClick={() => handleDeclineRefundRequested(order.id)}
              disabled={showSpinner}
            >
              {t("decline")}
            </SimpleButton>
            <SimpleButton 
              className="submit-button" 
              onClick={() => handleApproveRefund(order.id)}
              disabled={showSpinner}
            >
              {t("approve")}
            </SimpleButton>
          </>
        )}
        {handleDeclineRefundInProgress && handleCompleteRefund && order.status === "Refund In Progress" && (
          <>
            <SimpleButton 
              className="cancel-button" 
              onClick={() => handleDeclineRefundInProgress(order.id)}
              disabled={showSpinner}
            >
              {t("reactivate")}
            </SimpleButton>
            <SimpleButton 
              className="submit-button" 
              onClick={() => handleCompleteRefund(order.id)}
              disabled={showSpinner}
            >
              {t("complete")}
            </SimpleButton>
          </>
        )}
      </>
    )
  }

  return (
    <PageModal
      onToggle={onCloseModal}
      toggle
      footerContent={detailsFooter()}
      className={"admin-order-details-modal" + ((order.status !== "Refund Requested" && order.status !== "Refund In Progress") || !handleDeclineRefundRequested || !handleApproveRefund || !handleDeclineRefundInProgress || !handleCompleteRefund ? " no-footer" : "")} 
      title={`${t("coltene_store.admin_order_history.order-no")} ${order.id}`}
    >
      <div className="admin-order-details-wrapper">
        {handleApproveRefund && order.status === "Refund Requested" && (
          <div className="cancelation-requested-banner d-flex flex-justify-center flex-align-center">{t("coltene_store.admin_order_history.cancelation-requested")}</div>
        )}

        <div className="admin-ordered-products">
          <div className="title">{t("store_context.ordered_products")}</div>
          {!isMobile ? (
            <div className="table">
              <div className="table-content">
                <div className="table-header">
                  <div className="header">
                    <div className="header-text">{t("product")}</div>
                  </div>
                  <div className="header">
                    <div className="header-text">{t("category")}</div>
                  </div>
                  <div className="header">
                    <div className="header-text">{t("quantity")}</div>
                  </div>
                  <div className="header">
                    <div className="header-text">{t("price")}</div>
                  </div>
                </div>
                {order?.items.map((item, index) => (
                  <div className="row-container" key={`item-${index}`}>
                    <div className="body">
                      <div className="product-details d-flex">
                        <div className="image-wrapper d-flex flex-justify-center">
                          {item.product?.images[0]?.url ? <img src={item.product?.images[0].url} alt="product" /> : <ColtenePlaceholderProduct />}
                        </div>
                        <div className="details d-flex flex-column">
                          <div>{item?.product.title}</div>
                          <div>{item?.product.subtitle}</div>
                        </div>
                      </div>
                    </div>
                    <div className="body d-flex">{item?.product.isDigital ? "Subscription": "Physical Product"}</div>
                    <div className="body d-flex">{item?.quantity}</div>
                    <div className="body d-flex">{formatStoreProductPrice((Number(item.paidAmount) / (1 + taxPercent)) || 0, item.paidCurrency)}</div>
                  </div>
                ))}
                <div className="table-footer">
                  {isPromotion && (
                    <>
                      <div className="footer" />
                      <div className="footer" />
                      <div className="footer">
                        <div className="footer-text">{t("promotion")}</div>
                      </div>
                      <div className="footer promotion-footer">
                        <div className="footer-text">{`- ${formatStoreProductPrice(totalItemsPrice, order.paymentCurrency)}`}</div>
                      </div>
                    </>
                  )}
                  <div className="footer"/>
                  <div className="footer"/>
                  <div className="footer">
                    <div className="footer-text">{t("subtotal")}</div>
                  </div>
                  <div className="footer">
                    <div className="footer-text">{formatStoreProductPrice((Number(order.paymentAmount) || 0) - (Number(order.taxAmount) || 0), order.paymentCurrency)}</div>
                  </div>
                  <div className="footer"/>
                  <div className="footer"/>
                  <div className="footer">
                    <div className="footer-text">{t("tax")}</div>
                  </div>
                  <div className="footer">
                    <div className="footer-text">{formatStoreProductPrice(order.taxAmount, order.paymentCurrency)}</div>
                  </div>
                  <div className="footer"/>
                  <div className="footer"/>
                  <div className="footer footer-bolded">
                    <div className="footer-text">{t("total_amount_paid")}</div>
                  </div>
                  <div className="footer footer-bolded">
                    <div className="footer-text">{formatStoreProductPrice(order.paymentAmount, order.paymentCurrency)}</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="mobile-view-products">
              {order.items?.map((item, index) => (
                <div className="item-container" key={`item-${index}`}>
                  <div className="product-details d-flex">
                    <div className="image-wrapper d-flex flex-justify-center flex-align-center">
                      {item.product?.images[0]?.url ? <img src={item.product?.images[0].url} alt="product" /> : <ColtenePlaceholderProduct />}
                    </div>
                    <div className="details d-flex flex-column">
                      <div>{item.product?.title}</div>
                      <div>
                        {item.product?.subtitle}
                      </div>
                      <div className="price-info d-flex">
                        <div className="price-info-body d-flex flex-column">
                          <div className="title">{t("quantity")}</div>
                          <div className="value">{item?.quantity}</div>
                        </div>
                        <div className="price-info-body d-flex flex-column">
                          <div className="title">{t("category")}</div>
                          <div className="value">{item.product?.isDigital ? "Subscription" : "Physcical Product"}</div>
                        </div>
                        <div className="price-info-body d-flex flex-column">
                          <div className="title">{t("price")}</div>
                          <div className="value">{formatStoreProductPrice((Number(item.paidAmount) / (1 + taxPercent)) || 0, item.paidCurrency)}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="price-overview">
                {isPromotion && (
                  <>
                    <div className="price">
                      <div className="label">{t("promotion")}</div>
                      <div className="value">{`- ${formatStoreProductPrice(totalItemsPrice, order.paymentCurrency)}`}</div>
                    </div>
                  </>
                )}
                <div className="price">
                  <div className="label">{t("subtotal")}</div>
                  <div className="value">{formatStoreProductPrice((Number(order.paymentAmount) || 0) - (Number(order.taxAmount) || 0), order.paymentCurrency)}</div>
                </div>
                <div className="price">
                  <div className="label">{t("tax")}</div>
                  <div className="value">{formatStoreProductPrice(order.taxAmount, order.paymentCurrency)}</div>
                </div>
                <div className="price">
                  <div className="label bolded">{t("total_amount_paid")}</div>
                  <div className="value bolded">{formatStoreProductPrice(order.paymentAmount, order.paymentCurrency)}</div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="admin-payment-details">
          <div className="title">{t("coltene_store.admin_order_history.payment-details")}</div>
          <div className="details d-flex">
            <div className="section">
              <div className="section-title">{t("coltene_store.admin_order_history.order-status")}</div>
              <div className="section-value d-flex">
                {order.status === "Paid" || order.status === "Complete" || order.status === "Shipped" ? (
                  <CheckCircleFilledIcon className="success-svg" />
                ) : order.status === "Refund In Progress" || order.status === "Refund Requested" ? (
                  <PendingIcon className="pending-svg" />
                ) : (
                  <CanceledIcon className="cancel-svg" />
                )}
                <div
                  className={`details-order-status ${
                    order.status === "Paid" || order.status === "Complete" || order.status === "Shipped"
                      ? "processed"
                      : order.status === "Refund In Progress" || order.status === "Refund Requested"
                      ? "processing"
                      : "cancelled"
                  } d-flex flex-align-center`}
                >
                  {order.status === "Refunded"
                    ? t("canceled")
                    : order.status === "Refund Requested"
                    ? t("coltene_store.admin_order_history.cancelation-requested")
                    : order.status === "Refund In Progress"
                    ? t("coltene_store.admin_order_history.cancelation-processing")
                    : order.status}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="section-title">{t("coltene_store.admin_order_history.payment-date")}</div>
              <div className="section-value">{convertToSimpleDateWithTime(order.purchasedAt)}</div>
            </div>
          </div>
        </div>

        <div className="admin-customer-details">
          <div className="title">{t("coltene_store.admin_order_history.customer-details")}</div>
          <div className="details d-flex customer-details">
            <div className="section">
              <div className="section-title">{t("email")}</div>
              <div className="section-value with-word-wrap">{order.customerEmail}</div>
            </div>
            <div className="section">
              <div className="section-title">{t("phone")}</div>
              <div className="section-value">{order.shippingPhone}</div>
            </div>
            <div className="section">
              <div className="section-title">{t("coltene_store.admin_order_history.shipping-address")}</div>
              <div className="section-value">{shippingAddress.join(", ")}</div>
            </div>
          </div>
        </div>
      </div>
    </PageModal>
  )
}

export default AdminOrderDetails
