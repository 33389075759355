import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import DOMPurify from "dompurify"
import { snakeCase, camelCase } from 'lodash'
import SimpleButton from '../../common/SimpleButton'
import LoadingSpinner from "../../common/LoadingSpinner"
import ToggleSwitchShort from '../../common/ToggleSwitchShort'
import { markAllNotificationsRead, markNotificationRead, loadCurrentUserNotifications } from '../../../actions/notifications'
import { formatDateDayMonthYear, convertToSimpleDate, convertToSimpleDateWithTime} from '../../../utils/filters/date'
import { checkPermission, firmwarePermission } from '../../../utils/permissionValidation'
import './notifications-timeline.scss'

const isCycleStatus = (status) => ["cycle_complete", "cycle_aborted", "cycle_interrupted", "cycle_fault"].includes(status)

const NotificationsTimeline = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const {
    notifications,
    products,
    isUnreadNotifications,
    closeTimeline,
    handleIsUnreadNotificationsChange,
    notificationsLoading,
  } = props
  const [timeline, setTimeline] = useState({})
  const [isLoadingMarkAll, setIsLoadingMarkAll] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)

  useEffect(() => {
    const groupedNotifications = {}

    notifications.forEach((notification) => {
      const formattedDate = formatDateDayMonthYear(notification.notification_date)

      if (!groupedNotifications[formattedDate]) {
        groupedNotifications[formattedDate] = []
      }

      if(camelCase(notification.type) === 'firmwareUpdate' && notification.models?.length > 0) {
        const splittedItems = notification.models.map(model => ({
          ...notification,
          models: model
        }))
        groupedNotifications[formattedDate].push(...splittedItems)
      } else {
        groupedNotifications[formattedDate].push(notification)
      }
    })

    setTimeline(groupedNotifications)
  }, [notifications])

  const handleMarkAllAsRead = () => {
    setIsLoadingMarkAll(true)

    if (!isLoadingMarkAll && notifications.filter((item) => !item.is_read).length > 0) {
      setShowSpinner(true)

      props.actions.markAllNotificationsRead(props.cognitoSub)
        .then(() => {
          props.actions.loadCurrentUserNotifications(props.cognitoSub, isUnreadNotifications)
        })
        .finally(() => {
          setIsLoadingMarkAll(false)
          setShowSpinner(false)
        })
    }
  }

  const handleMarkNotificationAsRead = (notificationId, type) => {
    setShowSpinner(true)

    props.actions.markNotificationRead(props.cognitoSub, notificationId, type)
      .then(() => {
        props.actions.loadCurrentUserNotifications(props.cognitoSub, isUnreadNotifications)
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  const getMessageTitle = (item) => {
    switch (snakeCase(item.type)) {
      case 'device':
        return (
          <>
            <p className="notification-title">{products[item.device_sn]?.model || ''}</p>
            {item.device_sn && 
              <p className="notification-sn">
                &nbsp;&nbsp;&nbsp;&nbsp;{`${t("sn")}: ${item.device_sn}`}
              </p>
            }
          </>
        )
      case 'firmware_update':
        return <p className="notification-title">{item.models}</p>
      default:
        return <p className="notification-title">{`${t([`filter_context.${snakeCase(item.type)}`, snakeCase(item.type)])}`}</p>
    }
  }

  const getSoftwareVersionForUpdateAvailable = (eventBody) => {
    if (!eventBody) {
      return ''
    }

    const words = eventBody.split(' ')
    if (words.length === 1) {
      return ''
    }

    const index = words.findIndex(e => e.includes('available'))
    if (!index) {
      return ''
    }

    return words[index-1]
  }

  const getInfoForMaintenanceAvailable = (message) => {
    if (!message) {
      return ''
    }

    const words = message.split(' ')
    return words[words.length - 1].replace('.', '')
  }

  const getSoftwareUpdateBody = (version, model) => {   
    return (
      <div className="notification-body">
        <div className='one-line-format'>
          <span className="firmware-update">
            {t("notifications.firmware-update-part1")} {version}&nbsp;
          </span>
          {t("notifications.firmware-update-part2")}&nbsp;{model}.&nbsp;{t("notifications.firmware-update-part3")}.
        </div>
      </div>
    )
  }

  const getAccountNotificationBody = (item) => {
    const eventData = item.event_data

    switch (eventData?.event_type) {
      case 'invite':
        return (
          <div 
            className="notification-body" 
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${t(`notifications.organization-${eventData.event_type}-${eventData.status}-message`, {
              email: eventData.email,
              organization: eventData.company_name,
              role: eventData.user_role,
            })}</p>`)}}>
          </div>
        )
      case 'request':
        return (
          <div 
            className="notification-body" 
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${t(`notifications.organization-${eventData.event_type}-${eventData.status}-message`, {
              email: eventData.email,
              organization: eventData.company_name,
            })}</p>`)}}>
          </div>
        )
      case 'company':
        return (
          <div 
            className="notification-body" 
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${t(`notifications.organization-${eventData.event_type}-${eventData.status}-message`, {
              organization: eventData.company_name,
            })}</p>`)}}>
          </div>
        )
      case 'member':
        return (
          <div
            className="notification-body"
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${t(`notifications.organization-${eventData.event_type}-${eventData.status}-message`, {
              email: eventData.email,
            })}</p>`)}}>
          </div>
        )
      default:
        return <div className="notification-body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${item.message || item.title}</p>`)}} />
    }
  }

  const getMessageBody = (item) => {
    if (item.type === 'device') {
      const eventType = item.event_id?.split('-')[1]

      let message = ''

      switch (eventType) {
        case 'cycle_fault':
          const cycleNumber = item.event_data?.cycle_number ? `${t("cycle")} ${item.event_data?.cycle_number || ""}` : ""

          message = (
            <div className="cf-notification-body">
              {`${cycleNumber} CF-${item.event_data?.cycle_fault}`}
            </div>
          )
          break
        case 'cycle_complete':
        case 'cycle_aborted':
        case 'cycle_interrupted':
          message = (
            <div className="notification-body">
              {item.event_body || eventType.split('_').join(' ').toUpperCase()}
              {item.device_sn &&
                <SimpleButton className="submit-button">
                  {t("view_details")}
                </SimpleButton>
              }
            </div>
          )
          break
        case 'maintenance_notification_trigger_cycle':
          message = (
            <div className="notification-body reminder-space">
              {t("notifications.maintenance-available-message-part0")}&nbsp;{t("notifications.maintenance-available-message-part1")}&nbsp;{t("notifications.maintenance-available-message-part2")}&nbsp;{getInfoForMaintenanceAvailable(item.event_body)}.
            </div>
          )
          break
        case 'maintenance_notification_trigger_date':
          message = (
            <div className="notification-body reminder-space">
              {t("notifications.maintenance-available-message-part0")}&nbsp;{t("notifications.maintenance-available-message-part1")}&nbsp;{t("notifications.maintenance-available-message-part3")}&nbsp;{getInfoForMaintenanceAvailable(item.event_body)}.
            </div>
          )
          break
        case 'software_update_available':
          message = (getSoftwareUpdateBody(
            getSoftwareVersionForUpdateAvailable(item.event_body),
            products[item.device_sn]?.model || ''
          ))
          break
        default:
          message = (
            <div className="notification-body">
              {item.event_body || eventType?.split('_')?.join(' ')?.toUpperCase()}
            </div>
          )
      }

      return message
    }

    switch (snakeCase(item.type)) {
      case 'firmware_update':
        return getSoftwareUpdateBody(item.firmware_version, item.models)
      case 'account':
        return getAccountNotificationBody(item)
      default:
        return <div className="notification-body" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(`<p>${item.message || item.title}</p>`)}} />
    }
  }
  
  const handleDeviceNotificationNavigation = (item) => {
    const status = item.event_id?.split('-')[1]

    if (isCycleStatus(status)) {
      history.push({
        pathname: `/products/${item.device_sn}`,
        state: {
          cycleFaultDate: convertToSimpleDate(item.notification_date),
          cycleFault: item.event_data?.cycle_fault,
          cycleNumber: item.event_data?.cycle_number
        },
      })

      closeTimeline()
    }
  }

  const handleFirmwareUpdateNotificationNavigation = (item) => {
    if (checkPermission(props.userPermissions, firmwarePermission)) {
      history.push({
        pathname: "/firmware",
        state: { modelInNotification: item.models },
      })

      closeTimeline()
    }
  }

  const handleAccountNotificationNavigation = (item) => {
    const navigateToMyOrganization = () => {
      history.push({
        pathname: `/my-account/my-organization`,
      })

      closeTimeline()
    }

    const naviagateToMembers = () => {
      history.push({
        pathname: `/members`,
      })

      closeTimeline()
    }

    const navigateToRequests = () => {
      history.push({
        pathname: `/members`,
        state: { activeTab: 1 },
      })

      closeTimeline()
    }

    const navigateToOrganizationsManagement = () => {
      history.push({
        pathname: `/organizations-management`,
      })

      closeTimeline()
    }

    const eventData = item.event_data
    switch (eventData?.event_type) {
      case 'invite':
        switch (eventData?.status) {
          case 'invited':
          case 'resent':
            navigateToMyOrganization()
            break
          case 'accepted':
            naviagateToMembers()
            break
          case 'expired':
            navigateToRequests()
            break
          default:
            break
        }
        break
      case 'request':
        switch (eventData?.status) {
          case 'accepted':
          case 'expired':
            navigateToMyOrganization()
            break
          case 'requested':
          case 'resent':
            navigateToRequests()
            break
          default:
            break
        }
        break
      case 'company':
        switch (eventData?.status) {
          case 'validated':
            naviagateToMembers()
            break
          case 'pending':
            navigateToOrganizationsManagement()
            break
          default:
            break
        }
        break
      case 'member':
        switch (eventData?.status) {
          case 'left':
            naviagateToMembers()
            break
          default:
            break
        }
        break
      default:
        break
    }
  }

  const handleItemClick = (item) => {
    if (item.is_read === 0) {
      handleMarkNotificationAsRead(item.id, item.type)
    }

    switch (snakeCase(item.type)) {
      case 'device':
        handleDeviceNotificationNavigation(item)
        break
      case 'firmware_update':
        handleFirmwareUpdateNotificationNavigation(item)
        break
      case 'account':
        handleAccountNotificationNavigation(item)
        break
      default:
        break
    }
  }

  return (
    <div className="notifications-timeline-page">
      <div className="notifications-overflow" onClick={()=> closeTimeline()}/>
      <div className="notifications-timeline-wrapper">
        <div className="notifications-header">
          <div className="notifications-back-button" onClick={() => closeTimeline()}>
            <p className="title">{t("nav.notifications")}</p>
          </div>
          <div className="notifications-mark-as-read" onClick={() => handleMarkAllAsRead()}>{t("notifications.mark-all-read")}</div>
          <div className="notifications-show-unread">
            <p className="input-option">{t("notifications.only-show-unread")}</p>
            <div 
              className="status-option"
              onClick = {() => handleIsUnreadNotificationsChange()}
            >
              <ToggleSwitchShort checked={isUnreadNotifications} />
            </div>
          </div>
        </div>
        <div className="notifications-body">
          {Object.keys(timeline).map((date) => 
            <div 
              key={date} 
              className="notifications-date-wrapper"
            >
              {timeline[date].map((item, index) =>
                <div
                  key={index}
                  className={"notification-item" + (item.is_read ? "" : " unread")}
                  onClick={() => handleItemClick(item)}
                >
                  <div className="title-wrapper">
                    {getMessageTitle(item)}
                  </div>
                  {getMessageBody(item)}
                  {!item.is_read && (
                    <div className="is-unread"/>
                  )}
                  <p className='notification-diff'>
                    {convertToSimpleDateWithTime(new Date(item.notification_date))}
                  </p>
                </div>)
              }
            </div>)
          }
          {Object.keys(timeline).length === 0 && (
            <div className="no-unread-notifications">
              <p>{t("notifications.no-unread-notifications")}</p>
            </div>
          )}
        </div>
        {(showSpinner || notificationsLoading) && (
          <div className={"spinner-wrapper"}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </div>
  )
}

function stateToProps({ authedUser, userPermissions }) {
  return {
    cognitoSub: authedUser?.cognitoSub,
    userPermissions,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        markAllNotificationsRead,
        markNotificationRead,
        loadCurrentUserNotifications
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(NotificationsTimeline)