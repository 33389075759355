import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import CheckboxInput from "../common/CheckboxInput"
import { loadReviewOptions, postReviewItems } from "../../actions/priceLists"
import { priceListWritePermission } from "../../utils/permissionValidation"
import "./review-item-modal.scss"

const RequestReviewItemModal = (props) => {
  const { t } = useTranslation()
  const { selectedParts, reviewOptions, isEdit, handleClose, isLoading } = props
  const [parts, setParts] = useState([])
  const [canSubmit, setCanSubmit] = useState(false)

  useEffect(() => {
    if (reviewOptions.length === 0) {
      props.actions.loadReviewOptions()
    }
  }, [props.actions])

  useEffect(() => {
    if (selectedParts.length > 0) {
      const partsToReview = selectedParts.map(part => {
        return {
          partNo: part.partNo,
          description: part.description || "",
          options: part.options || [],
          comments: part.comments || "",
        }
      })

      setParts(partsToReview)
    }
  }, [selectedParts])

  useEffect(() => {
    const hasAllPartsFilled = parts.length > 0 && parts.every((part) => part.options.length > 0 || part.comments.length > 0)
    setCanSubmit(hasAllPartsFilled)
  }, [parts])

  const footerContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button"
          onClick={handleClose}
        >
          {t("cancel")}
        </SimpleButton>
        <SimpleButton
          className="submit-button" 
          onClick={handleSubmit}
          disabled={isLoading || !canSubmit}
          requiredPermission={priceListWritePermission}
        >
          {t("submit")}
        </SimpleButton>
      </>
    )
  }

  const handlePartSelection = (partNo) => {
    const selectedPart = selectedParts.find((part) => part.partNo === partNo)

    const partExists = parts.find((part) => part.partNo === selectedPart.partNo)

    if (partExists) {
      const partsWithSelectionRemoved = parts.filter((part) => part.partNo !== selectedPart.partNo)
      setParts(partsWithSelectionRemoved)

      return
    }

    setParts([...parts, { partNo: selectedPart.partNo, description: selectedPart. description, options: [], comments: "" }])
  }

  const handleSelectAllOptions = (partNo) => {
    const allOptions = reviewOptions.map((option) => option.id)

    const updatedParts = parts.map((part) => {
      if (part.partNo === partNo) {
        return { ...part, options: allOptions }
      }

      return part
    })

    setParts(updatedParts)
  }

  const handleClearAllOptions = (partNo) => {
    const updatedParts = parts.map((part) => {
      if (part.partNo === partNo) {
        return { ...part, options: [] }
      }

      return part
    })

    setParts(updatedParts)
  }

  const handleSelectOption = (partNo, optionId) => {
    const updatedParts = parts.map((part) => {
      if (part.partNo === partNo) {
        const options = part.options.includes(optionId)
          ? part.options.filter((id) => id !== optionId)
          : [...part.options, optionId]

        return { ...part, options }
      }

      return part
    })

    setParts(updatedParts)
  }
  
  const handleChangeField = (field, value, partNo) => {
    const updatedParts = parts.map((item) => {
      if (item.partNo === partNo) {
        return { ...item, [field]: value }
      }

      return item
    })

    setParts(updatedParts)
  }

  const handleSubmit = () => {
    props.actions.postReviewItems(parts)
      .then(() => handleClose())
  }

  return (
    <PageModal
      toggle
      className={"review-item-modal" + (!isEdit ? " hide-footer" : "")}
      onToggle={handleClose}
      title={t("dealer-portal.request-review")}
      footerContent={footerContent()}
    >
      <div className="review-item-modal-content">
        <div className="row-wrapper">
          <div className="input-wrapper">
            <div className="modal-section-disclaimer">
              {!isEdit ? (
                <>
                  <p>
                    Hello, <br /> Thank you for contacting us. We’ve received your
                    query and our team is looking into it.
                  </p>
                  <p>
                    We do our best to respond to every email within 24 hours, so
                    you should be hearing back from one of our customer service
                    team members soon.
                  </p>
                </>
              ) : (
                <p>
                  {t("dealer-portal.request_review_item_disclaimer")}
                </p>
              )}
            </div>
          </div>
        </div>
        {isEdit && (
          <div className="row-wrapper">
            <div className="input-wrapper">
              <p className="input-option">{t("dealer-portal.selected-part-number")}</p>
              <div className="options-wrapper">
                {selectedParts.map(({ partNo }) =>
                  <CheckboxInput 
                    key={partNo}
                    checked={parts.find((p) => p.partNo === partNo)}
                    text={partNo}
                    onChange={() => handlePartSelection(partNo)} 
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {parts.map((item) =>
          <>
            {isEdit && (
              <div className="separator" />
            )}
            <div className="row-wrapper">
              <div className="column-wrapper">
                <div className="identifier-label">
                  {item.partNo}
                </div>
                <div className="input-option">
                  {item.description}
                </div>
              </div>
              {isEdit && (
                <div className="action-buttons-wrapper">
                  <button
                    className="action-button"
                    onClick={() => handleSelectAllOptions(item.partNo)}
                  >
                    {t("dealer-portal.select-all")}
                  </button>
                  <button
                    className="action-button"
                    onClick={() => handleClearAllOptions(item.partNo)}
                  >
                    {t("clear_all")}
                  </button>
                </div>
              )}
            </div>
            <div className="row-wrapper">
              <div className="input-wrapper">
                <div className="options-wrapper">
                  {reviewOptions.map(({ id, reviewOptions }) =>
                    <CheckboxInput 
                      key={id}
                      checked={parts.find((p) => p.partNo === item.partNo)?.options?.includes(id)}
                      text={reviewOptions}
                      onChange={() => handleSelectOption(item.partNo, id)}
                      disabled={!isEdit}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="row-wrapper">
              <div className="input-wrapper">
                <p className="input-option">{t("dealer-portal.comments")}</p>
                <textarea
                  className={"textarea-item"}
                  value={item.comments}
                  onChange={(e) => handleChangeField("comments", e.target.value, item.partNo)}
                  readOnly={!isEdit}
                />
              </div>
            </div>
          </>)
        }
      </div>
    </PageModal>
  )
}

function stateToProps({ priceLists }) {
  return {
    isLoading: priceLists?.isLoading,
    reviewOptions: priceLists?.reviewOptions || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadReviewOptions,
        postReviewItems,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(RequestReviewItemModal)