import i18n from 'i18next';

const iso8601Regex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d+)?(Z|[+-]\d{2}:\d{2})$/

const t = (value) => {
  return i18n.t(value)
}

export const months = () => {
  return [
    t('calendar.months.january'),
    t('calendar.months.february'),
    t('calendar.months.march'),
    t('calendar.months.april'),
    t('calendar.months.may'),
    t('calendar.months.june'),
    t('calendar.months.july'),
    t('calendar.months.august'),
    t('calendar.months.september'),
    t('calendar.months.october'),
    t('calendar.months.november'),
    t('calendar.months.december')
  ]
}

export const daysOfWeek = () => {
  return [
  t('calendar.days.sun'),
  t('calendar.days.mon'),
  t('calendar.days.tue'),
  t('calendar.days.wed'),
  t('calendar.days.thu'),
  t('calendar.days.fri'),
  t('calendar.days.sat')
]
}

export const extDaysOfWeek = () => {
  return [
    t('calendar.days.sunday'),
    t('calendar.days.monday'),
    t('calendar.days.tuesday'),
    t('calendar.days.wednesday'),
    t('calendar.days.thursday'),
    t('calendar.days.friday'),
    t('calendar.days.saturday')
  ]
}

export const formatVal = (value) => {
  return value < 10 ? `0${value}` : value
}

export const formatDate = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }

  const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  const month = (date.getMonth() + 1) < 10 ? `0${(date.getMonth() + 1)}` : (date.getMonth() + 1)
  return `${date.getFullYear()}-${month}-${day}`
}

export const formatHoursWithMinutes = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }
  
  const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()

  return `${hours}:${minutes}`
}

export const formatTime = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }
  const hoursWithMinutes = formatHoursWithMinutes(date)
  const seconds = date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()
  return `${hoursWithMinutes}:${seconds}`
}

export const formatCurrentTime = () => {
  const date = new Date()
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let ampm = hours >= 12 ? 'pm' : 'am'
  hours = hours % 12
  hours = hours < 10 ? `0${hours}` : 12
  minutes = minutes < 10 ? `0${minutes}` : minutes
  return `${hours}:${minutes} ${ampm}`
}

export const formatDateMonthYear = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }

  return `${months()[date.getMonth()]} ${date.getFullYear()}`
}

export const formatDateMonthDayYear = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date)
  }

  return `${months()[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`
}

export const formatDateDayMonthYear = (date) => {
  if (typeof date !== Date()) {
    date = new Date(date);
  }

  const today = new Date();

  if (
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
  ) {
    return  t('today');
  }

  return `${date.getDate()} ${months()[date.getMonth()]}, ${date.getFullYear()}`;
};

export const currentDay = () => {
  const today = new Date()
  return `${extDaysOfWeek()[today.getDay()]}, ${months()[today.getMonth()]} ${today.getDate()}, ${today.getFullYear()}`
}

export const convertToSimpleDate = (date) => {
  if (!date || isNaN(Date.parse(date))) {
    return ""
  }

  const isoDate = iso8601Regex.test(date.toString()) ? date : toLocalISOString(new Date(date))

  return isoDate.split("T")[0]
}

export const convertToSimpleDateWithTime = (date) => {
  if (!date || isNaN(Date.parse(date))) {
    return ""
  }

  const isoDate = iso8601Regex.test(date.toString()) ? date : toLocalISOString(new Date(date))

  const splited = isoDate.split("T") || []
  return splited[0] + " " + splited[1]?.substring(0,5)
}

export const convertToSimpleDateWithFullTime = (date) => {
  if (!date || isNaN(Date.parse(date))) {
    return ""
  }

  const isoDate = iso8601Regex.test(date.toString()) ? date : toLocalISOString(new Date(date))

  const splited = isoDate.split("T") || []
  return splited[0] + " " + splited[1]?.substring(0,8)
}

const toLocalISOString = (date) => {
  if (!date) {
    return ""
  }

  const addLeadingZero  = (n) => n < 10 ? '0' + n : n

  const yyyy = date.getFullYear()
  const mm = addLeadingZero(date.getMonth() + 1)
  const dd = addLeadingZero(date.getDate())
  const hh = addLeadingZero(date.getHours())
  const mi = addLeadingZero(date.getMinutes())
  const ss = addLeadingZero(date.getSeconds())

  return `${yyyy}-${mm}-${dd}T${hh}:${mi}:${ss}`
}

export const convertTimeWithTAndZ = (date) => {
  const inputDate = new Date(date)

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  }
  
  const localDateTime = new Intl.DateTimeFormat(localStorage.i18nextLng, options).format(inputDate).replaceAll("/", "-").replaceAll(",", "").replace("AM", "").replace("PM", "")




  return localDateTime
}
