import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import GeneralInformation from "./GeneralInformation"
import Configuration from "./Configuration"
import CycleCalendar from "./CycleCalendar"
import SoftwareVersion from "./SoftwareVersion"
import Troubleshooting from "./Troubleshooting"
import Videos from "./Videos"
import Documents from "./Documents"
import NotesHistory from "./NotesHistory"
import OnlineAccess from './OnlineAccess'
import Legend from "../Dashboard/Legend"
import LoadingSpinner from "../common/LoadingSpinner"
import Breadcrumb from "../common/Breadcrumb"
import EmptyPageResults from "../common/EmptyPageResults"
import WrappedTabs from "../common/WrappedTabs"
import SimpleButton from '../common/SimpleButton'
import TooltipedIcon from '../common/TooltipedIcon'
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import { toast } from "../common/Toast"
import { getEndUserProductSummary, getProductSummary } from "../../utils/requests/productsAPI"
import { capitalizeFirstLetter, getImageByModel } from "../../utils/functions"
import { hasDealerSiteAccess, hasEndUserSiteAccess, hasInstrumentTrackingPermission } from "../../utils/permissionValidation"
import { UpdateIcon, CheckDoubleIcon, VerifiedIcon, ApertureIcon } from "../../icons"
import { setBackNavigationState } from '../../actions/backNavigationState'
import "./products-modern.scss"

const ProductsModern = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const { id } = useParams()
  const canAccessInstrumentTracking = hasInstrumentTrackingPermission(props.userPermissions)
  const lastCycleDate = location.state?.lastCycleDate
  const navigationTab = location.state?.activeTab
  const [isValidProduct, setIsValidProduct] = useState(false)
  const [isDentist, setIsDentist] = useState(null)
  const [generalInformation, setGeneralInformation] = useState({})
  const [configuration, setConfiguration] = useState({})
  const [softwareVersion, setSoftwareVersion] = useState({})
  const [model, setModel] = useState(null)
  const [modelId, setModelId] = useState(null)
  const [productNickname, setProductNickname] = useState(null)
  const [superPassword, setSuperPassword] = useState([])
  const [hasOnlineAccess, setHasOnlineAccess] = useState(false)
  const [hasUpdateAvailable, setHasUpdateAvailable] = useState(false)
  const [hasPrivacyPolicyChecked, setHasPrivacyPolicyChecked] = useState(false)
  const [productIsAperture, setProductIsAperture] = useState(false)
  const [productIsG4Plus, setProductIsG4Plus] = useState(false)
  const [activeTab, setActiveTab] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [tabs, setTabs] = useState([])
  const [hasShownToast, setHasShownToast] = useState(false)
  const [lastPingDate, setLastPingDate] = useState("")
  const dealerTechSections = ["cycleHistory", "softwareVersion", "troubleshoot", "videos", "documents"]
  const isEndUser = hasEndUserSiteAccess(props.userPermissions)
  
  useEffect(() => {
    const hasEndUserSitePermissions = hasEndUserSiteAccess(props.userPermissions)
    setIsDentist(hasEndUserSitePermissions)
  }, [props.userPermissions])

  useEffect(() => {
    if (!props.userPermissions || (Array.isArray(props.userPermissions) && props.userPermissions.length === 0)) {
      return
    }

    if (isEndUser && (!props.userProducts?.products || Object.keys(props.userProducts.products).length === 0)) {
      return
    }

    let modelId = location.state?.model_id
    let hasAssociationActive = false

    setShowSpinner(true)

    if (isEndUser) {
      const userProduct = Object.entries(props.userProducts.products)
        .map(([key, value]) => value)
        .find((product) => product.serial_number === id)

      modelId = userProduct?.model_id || modelId
      hasAssociationActive = userProduct?.association_active || hasAssociationActive
      modelId && setModelId(modelId)
      setModel(userProduct?.model)

      if (!props.backNavigationState) {
        const state = location.state
        history.replace(`${history.location.pathname}`, { ...state, withBackNavigation: true})

        props.actions.setBackNavigationState({
          backNavPage: '/my-products',
          model: userProduct?.model,
          serialNumber: id
        })
      }

      setHasOnlineAccess(userProduct?.association_active || hasAssociationActive)

      if (!hasAssociationActive) {
        if (!hasShownToast) {
          toast.error(t("device_context.no_online_access"))
  
          setHasShownToast(true)
        }
        
        setShowSpinner(false)
        return
      }

      getEndUserProductData(modelId)
    } else {
      modelId && setModelId(modelId)

      getProductData(modelId)
    }
  }, [props.userProducts, props.userPermissions])

  useEffect(() => {
    let tabData = [
      {
        key: 0,
        title: t("general_information"),
        content: <GeneralInformation generalInformation={generalInformation} />,
        section: "generalInformation"
      },
      {
        key: 1,
        title: t("configuration"),
        content: <Configuration configuration={configuration} />,
        section: "configuration"
      },
      {
        key: 2,
        title: t("device_context.cycle_history"),
        content: <CycleCalendar sn={id} isDentist={isDentist} modelId={modelId} model={model} isG4Plus={productIsG4Plus} />,
        section: "cycleHistory"
      },
      {
        key: 3,
        title: t("device_context.online_access"),
        content: <OnlineAccess serialNumber={id} email={props.authedUser?.email} />,
        section: "onlineAccess"
      },
      {
        key: 4,
        title: t("device_context.software_version"),
        content: <SoftwareVersion softwareVersion={softwareVersion} />,
        section: "softwareVersion"
      },
      {
        key: 5,
        title: t("nav.troubleshoot"),
        content: <Troubleshooting model={model} />,
        section: "troubleshoot"
      },
      {
        key: 6,
        title: t("nav.videos"),
        content: <Videos model={model} />,
        section: "videos"
      },
      {
        key: 7,
        title: t("nav.documents"),
        content: <Documents model={model} />,
        section: "documents"
      },
      {
        key: 8,
        title: t("device_context.notes_history"),
        content: <NotesHistory serialNumber={id} />,
        section: "notesHistory"
      },
    ]

    if (hasDealerSiteAccess(props.userPermissions)) {
      tabData = tabData.filter((tab) => dealerTechSections.includes(tab.section))
    }
    
    setTabs(tabData)
    setActiveTab(tabData[navigationTab || 0])
  }, [generalInformation, configuration, model, modelId, isDentist, id, softwareVersion, productIsG4Plus, props.authedUser?.email])

  const getEndUserProductData = (modelId) => {
    getEndUserProductSummary(props.authedUser?.cognitoSub, id, { model_id: modelId ?? "" })
      .then((resp) => {
        const data = resp.data
        if (data) {
          setModel(model || data.model)
          setProductNickname(data.product_nickname)
          setHasUpdateAvailable(data.update_available)
          setHasPrivacyPolicyChecked(data.privace_policy_checked)
          setProductIsAperture(data.isAperture)
          setProductIsG4Plus(data.is_g4_plus)

          setIsValidProduct(Object.keys(data).length > 0)

          if (Object.keys(data).length === 0) {
            toast.error(t("device_context.product_not_found"))
          }
        }
      })
      .catch((error) => {
        toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))
      })
      .finally(() => {
        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const getProductData = (modelId) => {
  
    getProductSummary(id, { model_id: modelId ?? "" })
      .then((resp) => {
        const data = resp.data
        if (data) {
          const superPass = data.super_password ? data.super_password.split(" ") : []
          data.model_id && setModelId(data.model_id)
          setGeneralInformation({
            registration_contact: data.registration_contact,
            iot_contact: data.iot_contact,
            warranty_id: data.warranty_id,
            geo_data: data.geo_data,
          })

          setConfiguration(data)

          setSoftwareVersion({
            softwareVersionData: data.software_version,
          })

          setModel(model || data.model)
          setSuperPassword(superPass)
          setProductNickname(data.product_nickname)
          setHasUpdateAvailable(data.update_available)
          setHasPrivacyPolicyChecked(data.privace_policy_checked)
          setProductIsAperture(data.isAperture)
          setProductIsG4Plus(data.is_g4_plus)
          setHasOnlineAccess(data.association_active)
          setLastPingDate(data.last_ping_date)

          setIsValidProduct(Object.keys(data).length > 0)
        }
      })
      .catch((error) => {
        toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))
      })
      .finally(() => {
        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setActiveTab(tab)
  }

  const navigateToVideos = () => {
    history.push({
      pathname: `/videos`,
      state: {
        model: model,
        withBackNavigation: true
      },
    })
  }

  const navigateToDocuments = () => {
    history.push({
      pathname: `/documents`,
      state: {
        model: model,
        withBackNavigation: true
      },
    })
  }

  return (
    <div className="products-modern-wrapper d-flex flex-column">
      <Breadcrumb path={window.location.pathname} withBackNavigation />
      <DashboardHeader headerText={id} />
      <div className="separator"/>
      {isValidProduct &&
        <Legend
          canAccessInstrumentTracking={canAccessInstrumentTracking}
          isDentist={isDentist}
        />
      }
      {!isValidProduct && !isFirstLoad && !isDentist &&
        <EmptyPageResults
          className="with-margin-top"
          title={t("device_context.product_not_found")}
          subtitle={t("device_context.product_not_found_subtitle")}
        />
      }
      <div className={"content" + (!isDentist && !isValidProduct ? " hidden-content" : "")}>
        <div className="product-header-wrapper">
          <div className="product-wrapper">
            <div className="image-wrapper d-flex flex-align-center">
              <img src={getImageByModel(model)} className="img" alt={model} />
            </div>
            <div className="product-data-wrapper">
              <div className="product-identification-wrapper">
                <div className="product-model">{model}</div>
                <div className="product-serial">{`${t("sn")}: ${id}`}</div>
                {productNickname && 
                  <div className="product-nickname">
                    {`${t("device_context.nickname")}: ${productNickname}`}
                  </div>
                }
                {isValidProduct && superPassword.length > 0 && !isDentist && (
                  <div className="product-super-password-wrapper">
                    <div className="section-title">{t("device_context.super_password")}</div>
                    <div className="d-flex flex-row super-password">
                      <div className="d-flex flex-column flex-align-center">
                        <span>{superPassword[0]}</span>
                        <span className="tooltip">{t("device_context.tech")}</span>
                      </div>
                      <div className="d-flex flex-column flex-align-center">
                        <span>{superPassword[1]}</span>
                        <span className="tooltip">{t("device_context.spw")}</span>
                      </div>
                      <div className="d-flex flex-column flex-align-center">
                        <span>{superPassword[2]}</span>
                        <span className="tooltip">{t("device_context.web")}</span>
                      </div>
                    </div>
                  </div>
                )}
                {isValidProduct && !isEndUser && 
                  <div className="last-ping-date">
                    <div className="section-title">{`${t("device_context.unit.last_ping_date")}`}</div> 
                    <span>{lastPingDate ? lastPingDate : t("n_a")}</span>
                  </div>
                }
              </div>
              {isValidProduct &&
                <div className="product-status">
                  {(canAccessInstrumentTracking || !isDentist) && 
                    <TooltipedIcon
                      icon={<ApertureIcon className={!productIsAperture ? "unavailable" : ""}/>}
                      tooltipText={productIsAperture ? t("products.aperture_associated") : t("products.aperture_not_associated")}
                    />
                  }
                  <TooltipedIcon
                    icon={<UpdateIcon className={!hasUpdateAvailable ? "unavailable" : ""}/>}
                    tooltipText={hasUpdateAvailable ? t("products.update_avilable") : t("products.update_not_avilable")}
                  />
                  <TooltipedIcon
                    icon={<VerifiedIcon className={!hasOnlineAccess ? "unavailable" : ""}/>}
                    tooltipText={hasOnlineAccess ? capitalizeFirstLetter(t("device_context.online_access_active")) : capitalizeFirstLetter(t("device_context.online_access_inactive"))}
                  />
                  <TooltipedIcon
                    icon={<CheckDoubleIcon className={!hasPrivacyPolicyChecked ? "unavailable" : ""}/>}
                    tooltipText={hasPrivacyPolicyChecked ? capitalizeFirstLetter(t("device_context.privacy_policy_checked")) : t("products.privacy_policy_not_accepted")}
                  />
                </div>
              }
            </div>
          </div>
          {isDentist && isValidProduct &&
            <div className="navigation-buttons-wrapper">
              <SimpleButton className="action-button" onClick={() => navigateToVideos()}>
                {t("nav.videos")}
              </SimpleButton>
              <SimpleButton className="action-button" onClick={() => navigateToDocuments()}>
                {t("nav.documents")}
              </SimpleButton>
            </div>
          }
        </div>
        {!isDentist &&
          <>
            <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))} activeTab={activeTab?.key} changeTab={handleChangeTab} />
            {activeTab?.content && activeTab.content}
          </>
        }
        
        {isDentist &&
          <>
            <div className="calendar-separator" />
            <CycleCalendar
              isDentist
              sn={id} 
              modelId={modelId}
              model={model}
              lastCycleDate={lastCycleDate}
              isG4Plus={productIsG4Plus}
              hasOnlineAccess={hasOnlineAccess}
            />
          </>
        }
      </div>
      
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ userPermissions, userProducts, authedUser, backNavigationState }) {
  return {
    userPermissions,
    userProducts,
    authedUser,
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        setBackNavigationState
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(ProductsModern)
