import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux';
import { useTranslation } from "react-i18next";
import { useWindowSize } from '../../../libs/hooks'
import languages from '../../../utils/languages'
import {
  CaretDownIcon,
  CaretUpIcon
  } from '../../../icons'
import { useOnClickOutside } from '../../../hooks/useOnClickOutside'
import "./language.scss"
import { keyboardEvents } from '../../../utils/keyboardEvents';

const Language = (props) => {
  const { t, i18n } = useTranslation()
  const [width] = useWindowSize()
  const [ showDropdown, setShowDropdown ] = useState(false)
  const [langCode, setLangCode] = useState(languages[props.authedUser?.language] ? props.authedUser?.language : 'en-CA')
  const [focusedOption, setFocusedOption] = useState(Object.keys(languages).indexOf(langCode) || 0)
  const ref = useRef(null)
  
  useEffect(() => { 
    const nextLangCode = languages[props.authedUser?.language] ? props.authedUser?.language : 'en-CA'
    i18n.changeLanguage(nextLangCode)
    setLangCode(nextLangCode)
  }, [i18n, props.authedUser?.language])

  useOnClickOutside(ref, () => {
    setShowDropdown(false)
  })

  const updatePreferredLanguage = (e) => {
    e.stopPropagation();
    const value = e.target.getAttribute('data-code');
    i18n.changeLanguage(value)

    setLangCode(value)

    setFocusedOption(Object.keys(languages).indexOf(value))

    setShowDropdown(false)
  }

  const updatePreferredLanguageOnEnter = () => {
    const value = Object.keys(languages)[focusedOption]
    i18n.changeLanguage(value)
    setLangCode(value)
  }

  return (
    <div 
      className="language-container"
      tabIndex={0}
      role="button"
      ref={ref}
      onClick={() => {
        setShowDropdown((prevState) => {
          return !prevState
        })
      }}
      onKeyDown={(event) => {
        if (event.key === keyboardEvents.ENTER) {
          const currentLanguageIndex = Object.keys(languages).indexOf(langCode)
          if (focusedOption !== currentLanguageIndex) {
            updatePreferredLanguageOnEnter()
          }

          setShowDropdown((prevState) => {
            return !prevState
          })
        }

        if (event.key === keyboardEvents.ESC) {
          setShowDropdown(false)
          setFocusedOption(Object.keys(languages).indexOf(langCode) || 0)
        }

        if (event.key === keyboardEvents.ARROW_DOWN) {
          setFocusedOption((prev) => {
            return prev < Object.keys(languages).length - 1 ? prev + 1 : 0
          })
        }

        if (event.key === keyboardEvents.ARROW_UP) {
          setFocusedOption((prev) => {
            return prev > 0 ? prev - 1 : Object.keys(languages).length - 1
          })
        }
      }}
    >
      <div className="language-wrapper">
        <span className="lang-name">
          {t(`${languages[langCode]}`)}
        </span>{" "}
        &nbsp;
        {showDropdown ? (
          width < 760 ? (
            <CaretUpIcon className="icon" />
          ) : (
            <CaretDownIcon className="icon" />
          )
        ) : width < 760 ? (
          <CaretDownIcon className="icon" />
        ) : (
          <CaretDownIcon className="icon" />
        )}
      </div>
      {showDropdown && (
        <div className="language-options">
          {Object.keys(languages).map((k, index) => (
            <div
              key={k}
              className={"lang-option" + (focusedOption === index ? " focused" : "")}
              onClick={updatePreferredLanguage}
              data-code={k}
            >
              {t(`${languages[k]}`)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser
  };
}

export default connect(mapStateToProps)(Language);

