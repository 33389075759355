import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { LineChart, CartesianGrid, Tooltip, XAxis, YAxis, Line } from "recharts"
import { formatTime } from "../../../utils/functions"

const celsius = "\u2103"
const temperatureUnit = "C"

export default function TemperatureChart(props) {
  const { data } = props
  const { t } = useTranslation()
  const [timeFormat, setTimeFormat] = useState("24hr")
  const [visibleKeys, setVisibleKeys] = useState(["temperatureChamber"])

  const handleToggleTimeFormat = () => {
    setTimeFormat((prev) => (prev === "24hr" ? "12hr" : "24hr"))
  }

  const handleCheckboxChange = (key) => {
    setVisibleKeys((prev) => {
      if (prev.includes(key)) {
        if (prev.length === 1) {
          return prev
        }
        return prev.filter((item) => item !== key)
      } else {
        return [...prev, key]
      }
    })
  }

  const celsiusFormatter = (value) => {
    return value !== null && value !== "undefined" ? `${value}°${temperatureUnit}` : "N/A"
  }

  const renderLegend = ({ onCheckboxChange, onToggleTimeFormat, timeFormat }) => {
    const legendItems = [{ key: "temperatureChamber", label: t("advance_troubleshooting_context.temperatureChamber") }]
    
    return (
      <>
        <div className="button-wrapper">
          <button className="toggle-button" onClick={onToggleTimeFormat}>
            {timeFormat === "24hr" ? t("advance_troubleshooting_context.switch_12_hr") : t("advance_troubleshooting_context.switch_24_hr")}
          </button>
        </div>
  
        <div className="legend-items">
          {legendItems.map((item) => (
            <div className="legend-item" key={item.key}>
              <input type="checkbox" checked={visibleKeys?.includes(item.key)} onChange={() => onCheckboxChange(item.key)} className="legend-checkbox" />
              <label htmlFor={`checkbox-${item.key}`}>{item.label}</label>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length > 0) {
      const dataPoint = payload[0].payload
      return (
        <div className="tooltip">
          <div className="tooltip-item">
            <div className="legend">
              <p>{t("advance_troubleshooting_context.temperatureChamber")}</p>
              <p className="item">{celsiusFormatter(dataPoint.temperatureChamber)}</p>
            </div>
            <div className="legend">
              <p>{t("time")}</p>
              <p className="item">{formatTime(timeFormat, label)}</p>
            </div>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <LineChart width={1176} height={300} data={data}>
        <CartesianGrid />
        <XAxis tickFormatter={(time) => formatTime(timeFormat, time)} dataKey="time" style={{ fontSize: "0.8em" }} />
        <YAxis yAxisId="left" unit={celsius} style={{ fontSize: "0.8em" }} type="number" domain={["auto", "auto"]} />
        <Line
          name="Temperature (T1)"
          yAxisId="left"
          type="monotone"
          dataKey="temperatureChamber"
          stroke="#3D89BC"
          strokeWidth="2"
          activeDot={{ r: 8 }}
          dot={false}
        />
        <Tooltip content={renderTooltip} />
      </LineChart>

      <div className="legend-container">
        {renderLegend({
          onToggleTimeFormat: handleToggleTimeFormat,
          timeFormat,
          onCheckboxChange: handleCheckboxChange,
        })}
      </div>
    </div>
  )
}
