import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import { Auth } from "aws-amplify"
import { Trans, useTranslation } from "react-i18next"
import Button from "../common/Button"
import PublicPageHeading from "../common/PublicPageHeading"
import { handleSetAuthedUser } from "../../actions/authedUser"
import { handleSetWarranty } from "../../actions/warranty"
import { handleClearBasket } from "../../actions/colteneStore"
import { handleSetCurrentUserPermissions } from "../../actions/userPermissions"
import { EmailIcon, LockIcon, EyeOffIcon, EyeIcon, ColteneModernLogo } from "../../icons"
import { useAppContext } from "../../libs/contextLib"
import { useFormFields } from "../../libs/hooks"
import { getEmailValidationShema, validateForm } from "../../utils/formValidation"
import { updateLastLogin, getCurrentUserPermissions } from "../../utils/requests/usersAPI"
import { COLTENE_STORE } from "../../utils/local-store-resolvers"
import { keyboardEvents } from "../../utils/keyboardEvents"
import "./login.scss"

function Login(props) {
  const { t } = useTranslation()
  const { setAuthenticated } = useAppContext()
  const { dispatch } = props
  const [isWarrantyRegistration, setIsWarrantyRegistration] = useState(props.warranty?.action === "warranty")
  const [isLoading, setLoading] = useState(false)
  const [hidePassword, setHidePassword] = useState(true)
  const [pError, setAuthenticationError] = useState("")
  const [fields, handleFieldChange] = useFormFields({
    email: props.querystring?.email || "",
    password: "",
    remember: localStorage.remember_me ? true : false,
  })
  const action = new URL(window.location.href)
  const userErrorMsg = {
    email: t("error.login_error"),
  }
  
  if (action.searchParams.get("action") === "onlineaccess") {
    localStorage.removeItem("remember_me")
  }

  useEffect(() => {
    const controller = new AbortController()
    return () => {
      setLoading(false)
      controller.abort()
    }
  }, [])

  //set email value from storage and trigger change event
  useEffect(() => {
    const email = fields.email !== "" ? fields.email : localStorage.remember_me

    if (props.warranty?.action !== "warranty" && email != null) {
      const emailElem = document.getElementById("email")
      const checkboxElem = document.getElementById("remember-me")

      const event = new Event("input", { bubbles: true })
      const ev = new Event("change", { bubbles: true })

      const setValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set
      setValue.call(emailElem, email)

      const setChecked = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "checked").set
      setChecked.call(checkboxElem, true)

      document.getElementById("password").value = ""

      emailElem.dispatchEvent(event)
      checkboxElem.dispatchEvent(ev)
    }
  }, [props.warranty?.action])

  const handleLogin = async (e) => {
    e.preventDefault()
    setLoading(true)

    try {
      const loginSchema = getEmailValidationShema(userErrorMsg)
      const validateData = await validateForm(loginSchema, fields)

      setAuthenticationError(validateData.email)

      if (Object.keys(validateData).length > 0) {
        return
      }

      const sess = await Auth.signIn(fields.email, fields.password)
      const userAttr = sess.attributes
      const cognitoSub = userAttr['sub']

      const permissions = await getCurrentUserPermissions()
      dispatch(handleSetCurrentUserPermissions(permissions))

      const store = JSON.parse(localStorage.getItem(COLTENE_STORE))
      if (store) {
        if (sess["username"] !== store.authedUser) {
          dispatch(handleClearBasket())
          localStorage.removeItem(COLTENE_STORE)
        }
      }

      dispatch(
        handleSetAuthedUser({
          email: userAttr["email"],
          encryptedEmail: userAttr["email"] ? btoa(userAttr["email"]) : "",
          firstname: userAttr["given_name"] || "",
          lastname: userAttr["family_name"] || "",
          language: userAttr["locale"] || "",
          company: userAttr["custom:company"] || "",
          phone: userAttr["custom:phone_number"] || "",
          city: userAttr["custom:city"] || "",
          stateprovince: userAttr["custom:state"] || "",
          country: userAttr["custom:country"] || "",
          postalcode: userAttr["custom:postal_code"] || "",
          userGroup: userAttr["custom:user_group"] || "dentist",
          //privacyAccepted: userAttr['custom:privacy'] || 'true',
          newsletter: userAttr['custom:newsletter'] || '',
          cognitoUserName: sess['username'],
          cognitoSub: userAttr['sub'],
          features: userAttr['custom:user_features'] || '',
          companyAccountType: userAttr["custom:company_account_type"] || "individual",
          address: userAttr["custom:address"] || "",
        })
      )

      const dbLastLogin = updateLastLogin(cognitoSub, {
        user_group: userAttr['custom:user_group'] || ''
      })
        .catch((e) => {
          console.log('Error Login Update -- ', e.message || e)
        })

      setAuthenticated(true)
      handleRememberMe()
    } catch (e) {
      setAuthenticationError(e.message)
    } finally {
      setLoading(false)
    }
  }

  const validateLoginForm = () => {
    return fields?.email?.length > 0 && fields?.password?.length > 0
  }

  const handleRememberMe = () => {
    if (!storageAvailable()) {
      return false
    }

    if (fields.remember) {
      localStorage.setItem("remember_me", fields.email)
      return
    }

    localStorage.removeItem("remember_me")
  }

  const storageAvailable = () => {
    try {
      localStorage.setItem("t", "test")
      localStorage.removeItem("t")
      return true
    } catch (e) {
      console.log("Storage not available", e.message)
      return false
    }
  }

  const toggleShowHidePassword = (e) => {
    document.getElementById("password").type = hidePassword ? "text" : "password"
    setHidePassword(!hidePassword)
  }

  return (
    <div className="center login-container public-page">
      {isWarrantyRegistration && !props.warranty?.accountCreated ? (
        <div className="center registration-container">
          <ColteneModernLogo className="coltene-icon" />
          <div>
            <div className="title">{t("warranty.registering_warranty")}</div>
            <p>
              <Link
                to="/register/create-account/dentist"
                className="link"
                onClick={() => {
                  dispatch(
                    handleSetWarranty({
                      action: "warranty",
                      accountCreated: true,
                    })
                  )
                }}
              >
                {t("create")}
              </Link>{" "}
              {t("warranty.account_to_register")}
            </p>
            <p>
              {t("register.already_have_account")} ?{" "}
              <Link
                to="/login"
                className="link"
                onClick={() => {
                  setIsWarrantyRegistration(false)
                }}
              >
                {t("account_context.login")}
              </Link>
            </p>
          </div>
        </div>
      ) : (
        <div className="center form-container">
          <PublicPageHeading heading={t("account_context.login_in_to_account")} />
          <div className="login-form">
            <form onSubmit={handleLogin} noValidate>
              {pError && <p className="error-message">{pError}</p>}
              <div className="input-fields">
                <div className="input-box">
                  <EmailIcon className="at-icon" />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={fields.email}
                    autoFocus
                    placeholder={t("email")}
                    onChange={handleFieldChange}
                    autoComplete="off"
                  />
                </div>
                <div className="input-box">
                  <LockIcon className="lock-icon" />
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={fields.password}
                    placeholder={t("account_context.password")}
                    onChange={handleFieldChange}
                    autoComplete="off"
                  />
                  {hidePassword ? (
                    <EyeIcon 
                      tabIndex={0} 
                      role="button" 
                      className="eye-icon"
                      onClick={toggleShowHidePassword}
                      onKeyDown={(event) => {
                        if (event.key === keyboardEvents.ENTER) {
                          toggleShowHidePassword()
                        }
                      }}
                    />
                  ) : (
                    <EyeOffIcon 
                      tabIndex={0}
                      role="button"
                      className="eye-icon"
                      onClick={toggleShowHidePassword}
                      onKeyDown={(event) => {
                        if (event.key === keyboardEvents.ENTER) {
                          toggleShowHidePassword()
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="remember-wrapper">
                <div className="remember-me">
                  <input 
                    type="checkbox" 
                    id="remember-me" 
                    name="remember" 
                    className="checkbox"
                    onChange={handleFieldChange}
                    checked={fields?.remember || false}
                    onKeyDown={(event) => {
                      if (event.key === keyboardEvents.ENTER) {
                        event.preventDefault()
                        handleFieldChange({
                          target: {
                            name: 'remember',
                            type: 'checkbox',
                            checked: !fields.remember,
                          },
                        })
                      }
                    }}
                  />
                  <label htmlFor="remember-me">{t("account_context.remember_me")}</label>
                </div>
                <div>
                  <Link to="/forgot-password" className="forgot-password">
                    {t("account_context.forgot_password")}
                  </Link>
                </div>
              </div>
              <Button type="submit" isLoading={isLoading} disabled={!validateLoginForm()} className="large">
                {t("account_context.login")}
              </Button>
            </form>
            <div className="updates-account-message">
              <Trans i18nKey="account_context.updates_account_msg">
                If you had an <strong>updates.scican.com</strong> account and this is your first time on this site, Please reset your password by clicking
                ‘Forgot Password'.
              </Trans>
            </div>
          </div>
          <div className="signup-link">
            {t("account_context.dont_have_account")}?&nbsp;
            <Link to="/register" className="sign-up">
              {t("account_context.register_account")}
            </Link>
          </div>
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ onlineAccess, warranty }) {
  return {
    querystring: onlineAccess ? onlineAccess : null,
    warranty,
  }
}

export default connect(mapStateToProps)(Login)
