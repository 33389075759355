import {_baseGetFetch, _basePostFetch, _basePutFetch} from './baseFetch'
import { Storage } from "aws-amplify"
const fileAPI = process.env.REACT_APP_CONTAINER_API_GATEWAY

export const getFirmwareList = () => {
  return _baseGetFetch(`/products/firmware-downloads`)
}

export const getUserProductFirmware = (userUuid, serialNumber) => {
  return _baseGetFetch(`/users/${userUuid}/products/${serialNumber}/firmware-downloads`)
}

export const downloadFirmware = async (fid, filename, filepath, body) => {
  if (filename?.length > 0 && filepath?.length > 0) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filepath}/${filename}`)
    window.open(result, "_blank")
  } else {
    window.open(`${fileAPI}/firmware-download/files?fid=${fid}`).focus()
  }

  downloadCounter(fid)
    .then((res) => {
      //
    })
  return trackDownload(body)
}

export const trackDownload = (body) => {
  return _basePostFetch(`/products/firmware-downloads/tracking`, body)
}

const downloadCounter = (fid) => {
  return _basePutFetch(`/products/firmware-downloads/files/${fid}`)
}
