import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import WrappedTabs from '../common/WrappedTabs'
import Breadcrumb from "../common/Breadcrumb"
import { capitalizeWords } from "../../utils/functions"
import "./uploads.scss"

const Uploads = (props) => {
  const { t } = useTranslation()
  const [availablePages, setAvailablePages] = useState([])
  const [selectedPage, setSelectedPage] = useState(null)
  const uploadPages = [
    {
      key: 0,
      title: "nav.documents",
      content: null,
      permission: "page:document-upload",
    },
    {
      key: 1,
      title: "device_context.firmware",
      content:  null,
      permission: "page:firmware-upload",
    },
  ]
  
  useEffect(() => {
    let accessAllowedPages = []

    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    accessAllowedPages = uploadPages.filter((page) => permissionArr.includes(page.permission?.toLowerCase()))

    setAvailablePages(accessAllowedPages)
    setSelectedPage(accessAllowedPages[0] || null)
  }, [props.userPermissions])

  const handleTabClick = (index) => {
    const page = availablePages.find(({key}) => key === index)
    setSelectedPage(page)
  }

  return (
    <div className="uploads-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.uploads")} />
      <WrappedTabs 
        tabs={availablePages.map((page) => ({ id: page.key, name: capitalizeWords(t(page.title)) }))} 
        activeTab={selectedPage?.key} 
        changeTab={handleTabClick} 
      />
      {selectedPage?.content && selectedPage.content}
    </div>
  )
}

function mapStateToProps({ userPermissions }) {
  return {
    userPermissions,
  }
}

export default connect(mapStateToProps)(Uploads)
