import React, { useEffect } from 'react'
import { Storage } from 'aws-amplify'
import { ImagePlaceholder } from '../../../../icons'
import './image-builder.scss'

const ImageBuilder = ({ id, style, imageKey, src, handleClick, onUpdate }) => {
  useEffect(() => {
    const getS3Image = async (key) => {
      var img = await Storage.get(key, {
        level: "public",
      })

      onUpdate(id, { src: img })
    }

    if (imageKey && !src) {
      getS3Image(imageKey)
    }
  }, [src])

  if (!src) {
    return (
      <div
        className="image-builder-section"
        style={style}
        onClick={(e) => {
          e.stopPropagation();
          handleClick()
        }}
      >
        <ImagePlaceholder className="image-placeholder"/>
      </div>
    )
  }

  return (
    <div
      className="image-builder-section"
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleClick()
      }}
    >
      <img
        src={src}
        alt={imageKey}
        className="image"
      />
    </div>
  )
}

export default ImageBuilder