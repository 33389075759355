import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import SimpleButton from "../common/SimpleButton"
import ToggleSwitch from "../common/ToggleSwitch"
import ConditionalWrapper from "../common/ConditionalWrapper"
import PageModal from "../common/PageModal"
import { toast } from "../common/Toast"
import { createAccount, updateAccount } from "../../actions/accountsManagement"
import { getAccountValidationSchema, validateForm } from "../../utils/formValidation"
import { accountsDeletePermission, accountsWritePermission } from "../../utils/permissionValidation"
import "./account-modal.scss"

const AccountModal = (props) => {
  const { t } = useTranslation()
  const { type, toggle, onToggle, account, roles, openConfirmation, isLoading } = props
  const [canDeleteAccount, setCanDeleteAccount] = useState(false)
  const [initialFormData, setInitialFormData] = useState({ description: "", isEnabled: true })
  const [formData, updateFormData] = useState({ description: "", isEnabled: true })
  const [errors, setErrors] = useState({})

  const handleInputChange = (e) => {
    updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const validateFormFields = async () => {
    setErrors({})
    const validateData = await validateForm(getAccountValidationSchema(), formData)

    return new Promise(function (resolve, reject) {
      if (Object.keys(validateData).length > 0) {
        reject({ type: "validationError", data: validateData })
      } else {
        resolve()
      }
    })
  }

  useEffect(() => {
    if (type === "edit" && roles.length === 0) {
      setCanDeleteAccount(true)
    } else {
      setCanDeleteAccount(false)
    }
  }, [type, formData, roles])

  useEffect(() => {
    if (type === "edit") {
      updateFormData({
        name: account?.name,
        description: account?.description,
        isEnabled: account?.isEnabled,
        userCount: account?.userCount,
      })

      setInitialFormData({
        name: account?.name,
        description: account?.description,
        isEnabled: account?.isEnabled,
        userCount: account?.userCount,
      })
    }
  }, [account])

  const handleCreateAccount = async () => {
    try {
      await validateFormFields()

      const bodyParams = {
        name: formData.name.trim(),
        description: formData.description.trim(),
        isEnabled: formData.isEnabled,
      }

      await props.actions.createAccount(bodyParams)

      onToggle()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)
        toast.error(t("error.fill_all_fields"))
      } else {
        onToggle()
      }
    }
  }

  const handleEditAccount = async () => {
    try {
      await validateFormFields()

      const bodyParams = {
        name: formData.name.trim(),
        description: formData.description.trim(),
        ...(roles.length === 0 && { isEnabled: formData.isEnabled }),
      }

      await props.actions.updateAccount(account.id, bodyParams)
      onToggle()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)
        toast.error(t("error.fill_all_fields"))
      } else {
        onToggle()
      }
    }
  }

  const accountHeader = () => {
    return (
      <React.Fragment>
        {type === "create" && (
          <div className="status-wrapper d-flex flex-align-center">
            <div className="title">{t("status")}</div>
            <div className="d-flex" onClick={() => updateFormData({ ...formData, isEnabled: !formData.isEnabled })}>
              <ToggleSwitch checked={formData.isEnabled} onChange={() => {}} />
              <div className="status">{formData.isEnabled ? t("enabled") : t("disabled")}</div>
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }

  const accountFooter = () => {
    return (
      <>
        {type === "edit" && (
          <SimpleButton 
            className="action-delete-button" 
            onClick={openConfirmation} 
            disabled={!canDeleteAccount}
            requiredPermission={accountsDeletePermission}
          >
            {t("delete")}
          </SimpleButton>
        )}
        <SimpleButton
          className="cancel-button"
          onClick={() => {
            onToggle()
          }}
        >
          {t("cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button"
          disabled={isDisabledSaveButton()}
          onClick={() => (type === "edit" ? handleEditAccount() : handleCreateAccount())}
          requiredPermission={accountsWritePermission}
        >
          {t("save")}
        </SimpleButton>
      </>
    )
  }

  const isDisabledSaveButton = () => {
    return (type === "edit" && JSON.stringify(initialFormData) === JSON.stringify(formData)) || isLoading
  }

  return (
    <PageModal
      onToggle={onToggle}
      toggle={toggle}
      className={type === "edit" ? "edit-account-modal" : "create-account-modal"}
      title={type === "edit" ? t("account_management.account.edit_account") : t("account_management.account.create_new_account")}
      footerContent={accountFooter()}
      headerContent={accountHeader()}
    >
      <div className="account-details-wrapper">
        <div className="detail-wrapper">
          <div className="title d-flex flex-justify-between">
            <div>{t("name")} *</div>
            <div className="character-limit">{`${formData?.name?.length || 0}/24`}</div>
          </div>
          <textarea
            className={`${errors.name ? "input-error" : ""}`}
            placeholder={t("account_management.account.account_name")}
            onChange={handleInputChange}
            value={formData.name}
            name="name"
            maxLength={24}
            disabled={type === "edit"}
          />
        </div>

        <div className="detail-wrapper">
          <div className="title">{t("description")}</div>
          <textarea placeholder={t("description")} onChange={handleInputChange} value={formData.description} name="description" />
        </div>

        {type === "edit" && (
          <ConditionalWrapper condition={roles.length > 0} wrapper={(children) => <div className="uneditable-toggle">{children}</div>}>
            <div className="status-wrapper d-flex flex-align-center">
              <div className="title">{t("status")}</div>
              <div
                className="d-flex"
                onClick={() => {
                  if (roles.length === 0) {
                    updateFormData({ ...formData, isEnabled: !formData.isEnabled })
                  }
                }}
              >
                <ToggleSwitch checked={formData.isEnabled} onChange={() => {}} />
                <div className="status">{formData.isEnabled ? t("enabled") : t("disabled")}</div>
              </div>
            </div>
          </ConditionalWrapper>
        )}
      </div>
    </PageModal>
  )
}

function stateToProps({ accounts }) {
  return {
    roles: accounts?.roles || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ 
      createAccount, 
      updateAccount 
    }, 
    dispatch),
  }
}

export default connect(stateToProps, dispatchToProps)(AccountModal)
