import { useState } from 'react'
import { FullLeftArrow } from '../../../../icons'
import './carousel-builder.scss'

const CarouselBuilder = ({ id, style, handleClick, children }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  return (
    <div
      className="carousel-builder-section"
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleClick()
      }}
    >
      <FullLeftArrow 
        className="arrow arrow-left"
        onClick={() => setCurrentIndex(prev => prev - 1 < 0 ? children.length -  1 : prev - 1)}
      />
      {children && children.length > 0 && children[currentIndex]}
      <FullLeftArrow 
        className="arrow arrow-right"
        onClick={() => setCurrentIndex(prev => prev + 1 > children.length - 1 ? 0 : prev + 1)}
      />
    </div>
  )
}

export default CarouselBuilder