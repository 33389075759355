import React, { useEffect, useState } from "react"
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import ToggleSwitch from "../common/ToggleSwitch"
import DropdownSearch from "../common/DropdownSearchField"
import LoadingSpinner from "../common/LoadingSpinner"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import { toast } from "../common/Toast"
import { createService, updateService, changeServiceStatus } from "../../actions/services"
import { getServiceValidationSchema, validateForm } from "../../utils/formValidation"
import { servicesWritePermission } from "../../utils/permissionValidation"
import "./service-modal.scss"

const defaultFormState = {
  name: "",
  description: "",
  isEnabled: false,
}

const ServiceModal = (props) => {
  const { t } = useTranslation()
  const { onToggle, service, policies, serviceLoading } = props
  const [formData, updateFormData] = useState(defaultFormState)
  const [errors, setErrors] = useState({})

  useEffect(() => {
    if (service) {
      updateFormData({
        id: service?.id || 0,
        name: service?.name || "",
        description: service?.description || "",
        policyId: service?.policyId || "",
        isEnabled: !!service?.isEnabled || false,
      })
    }
  }, [service])

  const handleInputChange = (e) => {
    return updateFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  const handleSelectPolicy = (policyId) => {
    updateFormData({
      ...formData,
      policyId: Number(policyId),
    })
  }

  const validateFormFields = async () => {
    setErrors({})
    const schema = getServiceValidationSchema()
    const validateData = await validateForm(schema, formData)

    return new Promise(function (resolve, reject) {
      if (Object.keys(validateData).length > 0) {
        reject({ type: "validationError", data: validateData })
      } else {
        resolve()
      }
    })
  }

  const handleCreateService = async () => {
    try {
      await validateFormFields()

      let bodyParams = {
        name: formData.name.trim(),
        description: formData.description.trim(),
        policyId: formData.policyId,
      }

      const service = await props.actions.createService(
        bodyParams, 
        formData.isEnabled ? false : true
      )

      if (formData.isEnabled) {
        await props.actions.changeServiceStatus(service.value.id, "enable", false)
      }
      onToggle()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)
        toast.error(t("error.fill_all_fields"))
      } else {
        onToggle()
      }
    }
  }

  const handleEditService = async () => {
    try {
      await validateFormFields()

      let bodyParams = {
        name: formData.name.trim(),
        description: formData.description.trim(),
        policyId: formData.policyId,
      }

      const updatedService = await props.actions.updateService(
        service.id,
        bodyParams,
        !!service.isEnabled !== formData.isEnabled ? false : true
      )

      if (!!service.isEnabled !== formData.isEnabled) {
        await props.actions.changeServiceStatus(updatedService.value.id, formData.isEnabled ? "enable" : "disable", false)
      }
      onToggle()
    } catch (error) {
      if (error.type === "validationError") {
        setErrors(error.data)
        toast.error(t("error.fill_all_fields"))
      } else {
        onToggle()
      }
    }
  }

  const serviceFooter = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => onToggle()}
        >
          {t("cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={service ? handleEditService : handleCreateService}
          disabled={serviceLoading}
          requiredPermission={servicesWritePermission}
        >
          {t("save")}
        </SimpleButton>
      </>
    )
  }

  const serviceModalHeaderContent = () => {
    return (
      <div className="status-wrapper">
        <div className="title">{t("status")}</div>
        <ToggleSwitch 
          checked={formData.isEnabled} 
          onChange={() => updateFormData({ ...formData, isEnabled: !formData.isEnabled })} 
        />
        <div className="status">{formData.isEnabled ? t("active") : t("inactive")}</div>
      </div>
    )
  }

  return (
    <PageModal
      toggle
      onToggle={onToggle}
      className="service-modal"
      title={service ? t("services_context.edit_service") : t("services_context.add_new_service")}
      headerContent={serviceModalHeaderContent()}
      footerContent={serviceFooter()}
    >
      <div className="input-wrapper">
        <div className="input-option d-flex">
          {t("name")} <div className="required">*</div>
        </div>
        <input
          type="text"
          className={`name-input ${errors.name ? "input-error" : ""}`}
          name="name"
          value={formData.name}
          onChange={handleInputChange}
          disabled={service}
        ></input>
      </div>
      <div className="input-wrapper">
        <div className="input-option d-flex">{t("description")}</div>
        <textarea className="description-input" name="description" value={formData.description} onChange={handleInputChange}></textarea>
      </div>
      <div className="policy-wrapper">
        <div className="input-option d-flex">
          {t("nav.policies")} <div className="required">*</div>
        </div>
        <DropdownSearch
          selectLbl={t("policies.select_policy")}
          onChange={handleSelectPolicy}
          options={policies.map((policy) => {
            return {
              id: policy.id,
              value: policy.name,
            }
          })}
          defaultValue={service ? policies.find((policy) => policy.id === service.policyId) : null}
          error={errors.policyId}
        />
      </div>
    </PageModal>
  )
}

function mapStateToProps({ services }) {
  return {
    serviceLoading: services?.serviceLoading,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        createService,
        updateService,
        changeServiceStatus,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(ServiceModal)

ServiceModal.propTypes = {
  service: PropTypes.object,
  toggle: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  policies: PropTypes.array.isRequired,
}
