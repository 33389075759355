import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Auth } from "aws-amplify"
import { useTranslation } from 'react-i18next'
import './forgotpassword.scss'
import Button from '../common/Button'
import PublicPageHeading from "../common/PublicPageHeading"
import ResetPassword from "./ResetPassword"
import TextInputField from "../common/TextInputField"
import { processError } from "../../libs/processError"
import { useFormFields } from "../../libs/hooks"
import { getEmailValidationShema, validateForm } from '../../utils/formValidation'
import {
  EmailIcon
} from '../../icons'

const ForgotPassword = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [requestSent, setRequestSent] = useState(false)
  const [authError, setAuthError] = useState("")
  const [error, setError] = useState({})
  const [isSubmitting, setSubmitting] = useState(false)
  const { t } = useTranslation()
  const [fields, handleFieldChange] = useFormFields({
    email: ""
  })
  const userErrorMsg = {
    email: t("error.email")
  }
  
  //set email value if any in querystring
  useEffect(() => {
    const urlPart = new URL(window.location.href)
    const email = urlPart.searchParams.get('email') || ""
    const action = urlPart.searchParams.get('action') || ""
    
    if(email) {
      let event = new CustomEvent('event', {
        bubbles: true,
        detail: {
            name: "email",
            value: email
        }
      })
      handleFieldChange(event)
    }

    if (email?.length > 0 && action === "reset") {
      setRequestSent(true)
    }
  }, [])

  const handleRequestForgotPassword = async (e) => {
    e.preventDefault()

    try {
      setSubmitting(true)

      const emailSchema = getEmailValidationShema(userErrorMsg)
      const validateData = await validateForm(emailSchema, fields)
      
      setError(validateData)

      if(Object.keys(validateData).length > 0) {
        setLoading(false)
        return false
      }
      setLoading(true)

      await Auth.forgotPassword(fields.email)

      setRequestSent(true)
      setLoading(false)
      setSubmitting(false)
    } catch(e) {
      // setAuthError(e.message)
      processError(e)
      setLoading(false)
      setSubmitting(false)
    }
    
  }

  const validateLoginForm = () => {
    return fields.email.length > 0
  }
  
  return (
    requestSent === true
    ?  <ResetPassword 
        username={fields.email} 
        isLoading={isLoading}
      />
    :
    <div className="forgot-password-wrapper public-page">
      <div className="center form-container">
        <PublicPageHeading heading={t('account_context.forgot_password')} />
        {
          authError &&
          <div className="error-message">{authError}</div>
        }
        <div className="signup-form">
          <form onSubmit={ handleRequestForgotPassword } noValidate>
            <div className="forgot-password-msg">{t("account_context.forgot_password_msg")}</div>
            <div className="input-fields row">
              <TextInputField
                  type="email"
                  name="email"
                  value={fields.email}
                  placeholder={t("email")}
                  onChange = {handleFieldChange}
                  error= {error.email ? error.email : null}
                  isRequired={true}
                  isSubmitting={isSubmitting}
                  inputIcon={<EmailIcon className="at-icon" />}
                />
            </div>
            <Button
              type="submit"
              isLoading={isLoading}
              disabled={!validateLoginForm()}
              className="large"
            >
              {t("continue")}
            </Button>
          </form>
        </div>
        <div className="login-link">
          <Link to="/" className="log-in">
            {t("account_context.login")}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword