import React, { useState, useEffect, useLayoutEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import CycleFaults from './CycleFaults'
import CycleAnalytics from './CycleAnalytics'
import Breadcrumb from "../common/Breadcrumb"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import WrappedTabs from '../common/WrappedTabs'
import { useAppContext } from "../../libs/contextLib"
import { clearBackNavigationState } from "../../actions/backNavigationState"

const MonitoringModern = (props) => {
  const { t } = useTranslation()
  const { showFeatureDev } = useAppContext()
  const [tabs, setTabs] = useState([])
  const [selectedPage, setSelectedPage] = useState(null)
 
  useLayoutEffect(() => {
    const tabs = [
      {
        key: 0,
        label: t('cycle_faults'),
        component: <CycleFaults />
      }
    ]

    if (showFeatureDev) {
      tabs.push({
        key: 1,
        label: t('monitoring.analytics'),
        component: <CycleAnalytics />
      })
    }

    setTabs(tabs)
  }, [showFeatureDev])

  useLayoutEffect(() => {
    if (tabs.length > 0) {
      const activeIndex = props.backNavigationState?.activeIndex
      const currentPage = tabs.find((tab) => tab.key === activeIndex) || tabs[0]
      setSelectedPage(currentPage)

      if (activeIndex) {
        props.actions.clearBackNavigationState()
      }
    }
  },[tabs])

  const handleTabChange = (tabIndex) => {
    setSelectedPage(tabs.find((tab) => tab.key === tabIndex))
  }

  return (
    <div className="monitoring-modern-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.monitoring")} />
      <WrappedTabs tabs={tabs.map((tab) => ({id: tab.key, name: tab.label}))} activeTab={selectedPage?.key} changeTab={handleTabChange} />
      {selectedPage?.component}
    </div>
  )
}

function mapStateToProps({ backNavigationState }) {
  return {
    backNavigationState
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearBackNavigationState
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(MonitoringModern)

