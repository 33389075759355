import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { SelectMenu } from "../../SelectMenu"
import { getLanguageFromRegionalLanguage } from '../../../../utils/languages'
import './text-builder-toolbox.scss'

const TextBuilderToolbox = ({ id, style, value, href, translation, onUpdate }) => {
  const { t } = useTranslation()
  const [text, setText] = useState('')
  const [link, setLink] = useState('')
  //const [fontSize, setFontSize] = useState('1rem')
  const [color, setColor] = useState('#FFFFFF')
  const [fontWeight, setFontWeight] = useState(400)
  //const [marginTop, setMarginTop] = useState('0rem')
  //const [marginLeft, setMarginLeft] = useState('0rem')
  //const [marginBottom, setMarginBottom] = useState('0rem')
  //const [marginRight, setMarginRight] = useState('0rem')
  const [translatedText, setTranslatedText] = useState({})
  const emptyTranslation = {
    fr_CA: '',
    de_DE: '',
    fr_FR: '',
    it_IT: '',
    es_ES: '',
    es_MX: '',
    pt_BR: '',
  }
  const fontWeightOptions = [{ key: 400, value: "Normal" }, { key: 500, value: "Medium" }, { key: 700, value: "Bold" }]

  useEffect(() => {
    setText(value || '')
    setLink(href || '')
    //setFontSize(style?.fontSize || '1rem')
    setColor(style?.color || '#FFFFFF')
    setFontWeight(Number(style?.fontWeight) || 400)
    //setMarginTop(style?.marginTop || '0rem')
    //setMarginLeft(style?.marginLeft || '0rem')
    //setMarginBottom(style?.marginBottom || '0rem')
    //setMarginRight(style?.marginRight || '0rem')
    setTranslatedText({ ...emptyTranslation, ...translation })
  },[value, href, style, translation])

  return (
    <div className="text-toolbox">
      <div className="input-wrapper">
        <p className="input-option">{t("text")}</p>
        <textarea
          className={"text-area-item"}
          value={text}
          onChange={(e) => {
            setText(e.target.value)
            onUpdate(id, { value: e.target.value })
          }}
        />
      </div>
      <div className="input-wrapper">
        <p className="input-option">{t("link")}</p>
        <input
          className={"input-item"}
          value={link}
          onChange={(e) => {
            setLink(e.target.value)
            onUpdate(id, { href: e.target.value })
          }}
        />
      </div>
      {/* <div className="input-wrapper">
        <p className="input-option">{t("font_size_rem")}</p>
        <input 
          type="number"
          className="input-item"
          value={parseFloat(fontSize)}
          onChange={(e) => {
            setFontSize(`${e.target.value}rem`)
            onUpdate(id, { 
              style: {  
                fontSize: `${e.target.value}rem`,
                color,
                fontWeight,
                marginTop,
                marginLeft,
                marginBottom,
                marginRight,
              }
            })
          }}
        />
      </div> */}
      <div className="input-wrapper">
        <p className="input-option">{t("color")}</p>
        <input 
          type="color"
          className="input-item"
          value={color}
          onChange={(e) => {
            setColor(e.target.value)
            onUpdate(id, { 
              style: {
                ...style,
                //fontSize,
                color: e.target.value,
                //fontWeight,
                //marginTop,
                //marginLeft,
                //marginBottom,
                //marginRight,
              }
            })
          }}
        />
      </div>
      <div className="input-wrapper">
        <p className="input-option">{t("font_weight")}</p>
        <SelectMenu
          options={fontWeightOptions}
          initialSelectedKey={fontWeight}
          onChange={(option) => {
            setFontWeight(option.key)
            onUpdate(id, { 
              style: {
                ...style,
                //fontSize,
                //color,
                fontWeight: option.key,
                //marginTop,
                //marginLeft,
                //marginBottom,
                //marginRight,
              }
            })
          }}
        />
      </div>
      {/* <div className="input-wrapper">
        <p className="input-option">{t("margin_rem")}</p>
        <div className="row-wrapper">
          <div className="option">
            <div className="subtitle">{t("top")}</div>
            <input 
              type="number"
              className="input-item"
              value={parseFloat(marginTop)}
              onChange={(e) => {
                setMarginTop(`${e.target.value}rem`)
                onUpdate(id, { 
                  style: {  
                    fontSize,
                    color,
                    fontWeight,
                    marginTop: `${e.target.value}rem`,
                    marginLeft,
                    marginBottom,
                    marginRight,
                  }
                })
              }}
            />
          </div>
          <div className="option">
            <div className="subtitle">{t("bottom")}</div>
            <input 
              type="number"
              className="input-item"
              value={parseFloat(marginBottom)}
              onChange={(e) => {
                setMarginBottom(`${e.target.value}rem`)
                onUpdate(id, { 
                  style: {  
                    fontSize,
                    color,
                    fontWeight,
                    marginTop,
                    marginLeft,
                    marginBottom: `${e.target.value}rem`,
                    marginRight,
                  }
                })
              }}
            />
          </div>
        </div>
        <div className="row-wrapper with-margin-top with-margin-bottom">
          <div className="option">
            <div className="subtitle">{t("left")}</div>
            <input 
              type="number"
              className="input-item"
              value={parseFloat(marginLeft)}
              onChange={(e) => {
                setMarginLeft(`${e.target.value}rem`)
                onUpdate(id, { 
                  style: {  
                    fontSize,
                    color,
                    fontWeight,
                    marginTop,
                    marginLeft: `${e.target.value}rem`,
                    marginBottom,
                    marginRight,
                  }
                })
              }}
            />
          </div>
          <div className="option">
            <div className="subtitle">{t("right")}</div>
            <input 
              type="number"
              className="input-item"
              value={parseFloat(marginRight)}
              onChange={(e) => {
                setMarginRight(`${e.target.value}rem`)
                onUpdate(id, { 
                  style: {  
                    fontSize,
                    color,
                    fontWeight,
                    marginTop,
                    marginLeft,
                    marginBottom,
                    marginRight: `${e.target.value}rem`,
                  }
                })
              }}
            />
          </div>
        </div>
      </div> */}
      <div className="title">{t("translations")}</div>
      {Object.keys(translatedText).map((language) => 
        <div key={language} className="input-wrapper">
          <p className="input-option">{t(getLanguageFromRegionalLanguage(language))}</p>
          <textarea
            className={"text-area-item"}
            value={translatedText[language]}
            onChange={(e) => {
              setTranslatedText({ ...translatedText, [language]: e.target.value})
              onUpdate(id, { translation: { ...translatedText, [language]: e.target.value }})
            }}
          />
        </div>
      )}
    </div>
  )
}

export default TextBuilderToolbox