import {
    LOAD_BRANCHES_PENDING,
    LOAD_BRANCHES_FULFILLED,
    LOAD_BRANCHES_REJECTED,
    LOAD_NEW_BRANCHES_PENDING,
    LOAD_NEW_BRANCHES_FULFILLED,
    LOAD_NEW_BRANCHES_REJECTED,
    POST_BRANCH_PENDING,
    POST_BRANCH_FULFILLED,
    POST_BRANCH_REJECTED,
    PUT_BRANCH_PENDING,
    PUT_BRANCH_FULFILLED,
    PUT_BRANCH_REJECTED,
    DELETE_BRANCH_PENDING,
    DELETE_BRANCH_FULFILLED,
    DELETE_BRANCH_REJECTED,
    LOAD_BRANCHES_COUNTRY_OPTIONS_PENDING,
    LOAD_BRANCHES_COUNTRY_OPTIONS_FULFILLED,
    LOAD_BRANCHES_COUNTRY_OPTIONS_REJECTED,
    CLEAR_BRANCHES_MESSAGES,
  } from "../actions/branches"
  import i18n from 'i18next'
  
  export default function branches(state = {}, action) {
    switch (action.type) {
      case LOAD_BRANCHES_PENDING:
      case LOAD_NEW_BRANCHES_PENDING:
      case POST_BRANCH_PENDING:
      case PUT_BRANCH_PENDING:
      case DELETE_BRANCH_PENDING:
      case LOAD_BRANCHES_COUNTRY_OPTIONS_PENDING:
        return {
          ...state,
          isLoading: true,
          errorMessage: null,
          successMessage: null,
        }
      case LOAD_BRANCHES_REJECTED:
      case LOAD_NEW_BRANCHES_REJECTED:
      case POST_BRANCH_REJECTED:
      case PUT_BRANCH_REJECTED:
      case DELETE_BRANCH_REJECTED:
      case LOAD_BRANCHES_COUNTRY_OPTIONS_REJECTED:
        return {
          ...state,
          isLoading: false,
          errorMessage: i18n.t([`error.${action.payload.response?.data?.code}`, "error.something_wrong"])
        }
      case LOAD_BRANCHES_FULFILLED:
        const branches = state?.branches || []
        const newBranchesIds = action.payload.data?.map(item => item.id)
        const difference = branches
          .filter(x => !newBranchesIds.includes(x.id))
          .concat(action.payload.data)
  
        return {
          ...state,
          branches: difference,
          canLoadMore: action.payload.currentPage < action.payload.totalPages,
          isLoading: false,
        }
      case LOAD_NEW_BRANCHES_FULFILLED:
        return {
          ...state,
          branches: action.payload.data,
          canLoadMore: action.payload.currentPage < action.payload.totalPages,
          isLoading: false,
        }
      case POST_BRANCH_FULFILLED:
        const initialBranches = state?.branches || [];
  
        return {
          ...state,
          branches: [...initialBranches, action.payload],
          isLoading: false,
          successMessage: i18n.t("organization_context.success_add_branch")
        }
      case PUT_BRANCH_FULFILLED:
        const updatedBranches = state?.branches?.map((item) => item.id === action.payload.id ? {...action.payload} : item)
  
        return {
          ...state,
          branches: updatedBranches,
          isLoading: false,
          successMessage: i18n.t("organization_context.success_edit_branch")
        }
      case DELETE_BRANCH_FULFILLED:
        const filteredBranches = state.branches?.filter(branch => branch.id !== action.meta.branchId) || []
        return {
          ...state,
          branches: filteredBranches,
          isLoading: false,
          successMessage: i18n.t("organization_context.branch_deleted"),
        }
      case LOAD_BRANCHES_COUNTRY_OPTIONS_FULFILLED:
        return {
          ...state,
          countryOptions: action.payload,
          isLoading: false,
        }
      case CLEAR_BRANCHES_MESSAGES: {
        return {
          ...state,
          errorMessage: null,
          successMessage: null,
        }
      }
      default:
        return state
    }
  }
    