import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ExpandableText from "../common/ExpandableText"
import { toast } from "../common/Toast"
import { CaretDownIcon } from "../../icons"
import { clearPriceListsMessages, loadPriceListRequests } from "../../actions/priceLists"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { convertTimeWithTAndZ } from "../../utils/filters/date"

const orderingFields = [
  {
    field: "requestType",
    order: "asc",
    translationKey: "request_type_asc",
  },
  {
    field: "requestType",
    order: "desc",
    translationKey: "request_type_desc",
  },
  {
    field: "partNo",
    order: "asc",
    translationKey: "part_no_asc",
  },
  {
    field: "partNo",
    order: "desc",
    translationKey: "part_no_desc",
  },
  {
    field: "description",
    order: "asc",
    translationKey: "description_asc",
  },
  {
    field: "description",
    order: "desc",
    translationKey: "description_desc",
  },
  {
    field: "comments",
    order: "asc",
    translationKey: "comments_asc",
  },
  {
    field: "comments",
    order: "desc",
    translationKey: "comments_desc",
  },
  {
    field: "requestDate",
    order: "asc",
    translationKey: "date_asc",
  },
  {
    field: "requestDate",
    order: "desc",
    translationKey: "date_desc",
  },
]

const Requests = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [itemsTableData, setItemsTableData] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [selectedRequestTypeFilter, setSelectedRequestTypeFilter] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [expandedItem, setExpandedItem] = useState(0)
  const requestTypeFilters = ["review", "missingItem"]

  useEffect(() => {
    const tableData = props.requests.map((item) => {
      return {
        identifier: item.id,
        type: item.requestType,
        partNo: item.partNo,
        itemName: item.itemName || "",
        options: item.options || [],
        description: item.description,
        comments: item.comments,
        date: convertTimeWithTAndZ(item.requestDate),
      }
    })

    setItemsTableData(tableData)
  }, [props.requests])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearPriceListsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadRequests(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadRequests(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParam, selectedRequestTypeFilter, orderBy])

  const handleLoadRequests = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    props.actions.loadPriceListRequests(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingFields.find(o => o.field === orderingField.field && o.order === orderingField.order))
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setSelectedRequestTypeFilter(null)
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (selectedRequestTypeFilter) {
      queryParams = { ...queryParams, requestType: selectedRequestTypeFilter }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`sortable_context.${orderBy.translationKey}`) : t("select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`sortable_context.${orderingField.translationKey}`)}
        </div>
      ))}
    </div>
  )

  return (
    <Fragment>
      <div className="updated-items-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={selectedRequestTypeFilter}
            counterBadge={(selectedRequestTypeFilter && 1)}
          />
        </div>
        {isMobile && itemsTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && itemsTableData.length > 0 && 
          <GenericTable
            data={itemsTableData}
            headers={[
              {
                title: t("request_type"),
                orderKey: "requestType",
              },
              {
                title: t("dealer-portal.partNo"),
                orderKey: "partNo",
              },
              {
                title: t("description"),
                orderKey: "description",
              },
              {
                title: t("dealer-portal.comments"),
                orderKey: "comments",
              },
              {
                title: t("date"),
                orderKey: "requestDate",
              },
            ]}
            keys={[
              "type", 
              "partNo", 
              "description", 
              "comments", 
              "date"
            ]}
            keyRenderer={{
              type: (item) => {
                return (
                  <>{t([`dealer-portal.${item.type}`, item.type])} </>
                )
              },
              description: (item) => {
                return (
                  <div className="description-wrapper">
                    <ExpandableText text={item.description} />
                  </div>
                )
              },
              comments: (item) => {
                return (
                  <div className="comments-wrapper">
                    <ExpandableText text={item.comments} />
                  </div>
                )
              },
              date: (item) => {
                return (
                  <ExpandableText text={item.date} />
                )
              },
            }}
            onRowClick={(item) => item.type === "missingItem" ? props.setSelectedMissingItem(item) : props.setSelectedReviewItem(item)}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && itemsTableData.map((item, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedItem(index)}
          >
            <div className={"card-item" + (expandedItem !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("dealer-portal.partNo")}</div>
              <div className={"card-item-body" + (expandedItem !== index ? " align-center" : "")}>
                <div>{item.partNo}</div>
              </div>
            </div>
            {expandedItem === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("request_type")}</div>
                  <div className="card-item-body">{t([`dealer-portal.${item.type}`, item.type])}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("description")}</div>
                  <div className="card-item-body">{item.description}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("dealer-portal.comments")}</div>
                  <div className="card-item-body">{item.comments}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("date")}</div>
                  <div className="card-item-body">{item.date}</div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && itemsTableData.length === 0 && (
          <EmptyPageResults
            title={t("dealer-portal.no-requests-found")}
            subtitle={t("cannot_find_matching_search")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("request_type"),
              type: FilterType.singleSelect,
              dataset: requestTypeFilters,
              input: selectedRequestTypeFilter,
              output: (selectedRequestType) => {
                setSelectedRequestTypeFilter(selectedRequestType)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
    </Fragment>
  )
}

function stateToProps({ priceLists }) {
  return {
    requests: priceLists?.priceListRequests || [],
    isLoading: priceLists?.isLoading,
    canLoadMore: priceLists?.canLoadMoreRequests,
    errorMessage: priceLists?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadPriceListRequests,
        clearPriceListsMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Requests)
