import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import SimpleButton from "../common/SimpleButton"
import LoadingSpinner from "../common/LoadingSpinner"
import VideoModal from "../common/VideoModal"
import { uniqBy } from "../../utils/filters/filters"
import { DownloadModernIcon, VideoPlayIcon, InformationIcon, PdfModernIcon } from "../../icons"
import { getVideosList } from "../../utils/requests/videosAPI"
import { downloadFirmware } from "../../utils/requests/firmwareAPI"
import { getFileIcon } from "../../utils/functions"
import "./firmware-latest-version.scss"

const LatestVersion = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { selectedDevice } = props
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  
  const [instructionSoftwares, setInstructionSoftwares] = useState([])
  const [uiSoftwares, setUiSoftwares] = useState([]) 
  const [controlSoftwares, setControlSoftwares] = useState([])
  const [uiControlSoftwares, setUiControlSoftwares] = useState([]) 

  useEffect(() => {
    let files = []

    selectedDevice.versions.forEach((version) => {
      files.push(
        version.files.map((file) => {
          return {
            ...file,
            version_id: version.id,
          }
        })
      )
    })

    const versions = uniqBy(selectedDevice.versions, "id")

    const mappedVersionsWithFiles = versions.map((version) => {
      version.files = []

      files = [].concat(...files)
      files.forEach((file) => {
        if (file.version_id === version.id) {
          version.files.push(file)
        }
      })
      return version
    })
    const instructionSoftwares = mappedVersionsWithFiles[0]?.files?.filter((file) => file.software_type === "Instruction")
    const uiSoftwares = mappedVersionsWithFiles[0]?.files?.filter((file) => file.software_type === "UI")
    const controlSoftwares = mappedVersionsWithFiles[0]?.files?.filter((file) => file.software_type === "Control")
    const uiControlSoftwares = mappedVersionsWithFiles[0]?.files?.filter((file) => file.software_type === "UI+Control")
    
    setInstructionSoftwares(instructionSoftwares)
    setUiSoftwares(uiSoftwares)
    setControlSoftwares(controlSoftwares)
    setUiControlSoftwares(uiControlSoftwares)
  }, [selectedDevice])

  useEffect(() => {
    setShowSpinner(true)

    const getVideos = async () => {
      const queryParams = {
        model: selectedDevice.model,
        category: "Firmware-download",
        page: 1,
        limit: 3,
      }

      const { data } = await getVideosList(queryParams)

      setVideos(data || [])
      setShowSpinner(false)
    }

    getVideos()
  }, [selectedDevice])

  const downloadFile = (file) => {
    const body = {
      filename: file.filename || file.label,
      reference_id: file.reference_id,
      prefix: selectedDevice.prefix,
      prefix_number: selectedDevice.prefix_number,
      email: props.authedUser.email,
      country: props.authedUser.country,
    }

    downloadFirmware(file.id, file.filename, file.filepath, body)
  }

  const openVideoModal = (item) => {
    setSelectedVideo(item)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  const handleNavigateToVideos = () => {
    history.push({
      pathname: `/videos`,
      state: {
        model: videos.length !== 0 ? selectedDevice.model : null,
        withBackNavigation: true
      },
    })
  }
  
  const renderFirmwareSection = (softwares, titleKey) => {
    if (softwares.length > 0) {
      return (
        <>
          <div className="inner-separator" />
          <div className="section-title">
            {t(`firmware_context.${titleKey}`)}
          </div>
          <div className="firmware-executables-wrapper">
            {softwares.map((file, index) => (
            
              <div className="firmware-executable" key={index}>
                <button
                  className="download-document"
                  onClick={() => downloadFile(file)}
                  disabled={showSpinner}
                >
                  <DownloadModernIcon className="download-icon" />
                </button>
                {getFileIcon(file.filename)}
                <div className="file-name">
                  {file.label?.split("_").join(" ").replace(/\.[^/.]+$/, "")}
                </div>
              </div>
            ))}
          </div>
        </>
      );
    }
    return null;
  };
  
  return (
    <div className="firmware-latest-version-wrapper">
      <div className="instruction-box-text">
        <div className="icon-wrapper">
          <InformationIcon />
        </div>
        <div className="instruction-wrapper">
          <div className="title">{t("firmware_context.update_instructions")}</div>
          <div className="instruction">
            <span className="title">{`${t("step_1")}. `}</span>
            <span>{t("firmware_context.step_1_instructions")}</span>
          </div>
          <div className="instruction">
              <span className="title">{`${t("step_2")}. `}</span>
              <span>{t("firmware_context.step_2_instructions")}</span>
          </div>
          <div className="instruction">
              <span className="title">{`${t("step_3")}. `}</span>
              <span>{t("firmware_context.step_3_instructions")}</span>
          </div>
        </div>        
      </div>
      <div className="firmware-videos-wrapper">
        <div className="section-title">{t("nav.videos")}</div>
        <div className="videos-wrapper">
          {videos.map((video, index) => (
            <div key={index} className="video-item-wrapper" onClick={() => openVideoModal(video)}>
              <div className="thumbnail-wrapper">
                <div className="thumbnail-icon">
                  <VideoPlayIcon />
                </div>
                <img src={video.thumbnail_url} alt="video" />
              </div>
              <p className="title">{video.title}</p>
            </div>
          ))}
          {videos.length === 0 && (
            <div className="no-videos-wrapper">
              <p className="disclaimer">{t("video_context.no_videos_yet")}</p>
            </div>
          )}
        </div>
        <SimpleButton 
          className="action-button" 
          onClick={() => handleNavigateToVideos()}
          disabled={videos.length === 0}
        >
          {t("video_context.view_all_videos")}
        </SimpleButton>
      </div>
      {instructionSoftwares.length > 0 && (
        <>
          <div className="inner-separator" />
          <div className="section-title">{t("firmware_context.instructions_other_documents")}</div>
          <div className="firmware-documents-wrapper">
            {instructionSoftwares.map((file, index) => (
              <div className="firmware-documents d-flex" key={index}>
                <div className="name-wrapper d-flex flex-align-center">
                  {getFileIcon(file.label)}
                  <div className="file-name">
                    {file.label
                      ?.split("_")
                      .join(" ")
                      .replace(/\.[^/.]+$/, "")}
                  </div>
                </div>
                <SimpleButton className="action-button" onClick={() => downloadFile(file)} disabled={showSpinner}>
                  {t("download")}
                </SimpleButton>
              </div>
            ))}
          </div>
        </>
      )}

      {renderFirmwareSection(uiSoftwares, "ui_software")}
      {renderFirmwareSection(controlSoftwares, "control_software")}
      {renderFirmwareSection(uiControlSoftwares, "ui_control_software")}
      
      {selectedVideo && 
        <VideoModal 
          title={selectedVideo.title}
          videoId={selectedVideo.video_id} 
          onCloseVideo={closeVideoModal} 
        />
      }
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ authedUser, userPermissions }) {
  return {
    authedUser,
    userPermissions
  }
}
export default connect(mapStateToProps)(LatestVersion)
