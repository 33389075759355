import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { LineChart, CartesianGrid, Tooltip, XAxis, YAxis, Line } from "recharts"
import { formatTime } from "../../../utils/functions"

function getColor(index) {
  const colors = ["#FF7F00", "#33A02C", "#E31A1C", "#6A3D9A", "#E82582", "#3700de", "#007F5F"]
  return colors[index % colors.length]
}

export default function InputChart(props) {
  const { data } = props
  const { t } = useTranslation()
  const [timeFormat, setTimeFormat] = useState("24hr")
  const [visibleKeys, setVisibleKeys] = useState([])

  useEffect(() => {
    if (data?.length > 0) {
      const keys = Object.keys(data[0]).filter((key) => key !== "time")
      setVisibleKeys((prev) => (prev.length > 0 ? prev : keys))
    }
  }, [data])

  const handleToggleTimeFormat = () => {
    setTimeFormat((prev) => (prev === "24hr" ? "12hr" : "24hr"))
  }

  const handleCheckboxChange = (key) => {
    setVisibleKeys((prev) => {
      if (prev.includes(key)) {
        if (prev.length === 1) {
          return prev
        }
        return prev.filter((item) => item !== key)
      } else {
        return [...prev, key]
      }
    })
  }

  const validData = data?.length > 0

  const renderLegend = ({ onCheckboxChange, onToggleTimeFormat, timeFormat }) => {
    const legendItems = [
      { key: "airGapPump", label: t("advance_troubleshooting_context.airGapPump") },
      { key: "countRpmSignalsTopArm", label: t("advance_troubleshooting_context.countRpmSignalsTopArm") },
      { key: "countRpmSignalsBottomArm", label: t("advance_troubleshooting_context.countRpmSignalsBottomArm") },
      { key: "circulationPump", label: t("advance_troubleshooting_context.circulationPump") },
      { key: "dryerMotor", label: t("advance_troubleshooting_context.dryerMotor") },
      { key: "fanLcd", label: t("advance_troubleshooting_context.fanLcd") },
      { key: "fanPcb", label: t("advance_troubleshooting_context.fanPcb") },
    ]
  
    return (
      <>
        <div className="button-wrapper">
          <button className="toggle-button" onClick={onToggleTimeFormat}>
          {timeFormat === "24hr" ? t("advance_troubleshooting_context.switch_12_hr") : t("advance_troubleshooting_context.switch_24_hr")}
          </button>
        </div>
        
        <div className="legend-items">
          {legendItems.map((item) => (
            <div className="legend-item" key={item.key}>
              <input
                type="checkbox"
                id={`checkbox-${item.key}`}
                checked={visibleKeys.includes(item.key)}
                onChange={() => onCheckboxChange(item.key)}
                className="legend-checkbox"
              />
             <label htmlFor={`checkbox-${item.key}`}>{item.label}</label>
            </div>
          ))}
        </div>
      </>
    )
  }

  const renderTooltip = ({ active, payload, label }) => {
    if (active && payload?.length > 0) {
      return (
        <div className="tooltip">
          {payload.map((entry, index) => (
            <div key={index} className="tooltip-item">
              <div className="legend">
                <p>{t(`advance_troubleshooting_context.${entry.name}`)}</p>
                <p className="item">{entry.value !== null ? `${entry.value}` : "N/A"}</p>
              </div>
            </div>
          ))}
          <div className="legend">
            <p>{t("time")}</p>
            <p className="item">{formatTime(timeFormat,label)}</p>
          </div>
        </div>
      )
    }
    return null
  }

  return (
    <div>
      <LineChart width={1176} height={300} data={data}>
        <CartesianGrid />
        <XAxis tickFormatter={(time) => formatTime(timeFormat,time)} dataKey="time" style={{ fontSize: "0.8em" }} />
        <YAxis yAxisId="left" style={{ fontSize: "0.8em" }} type="number" domain={["auto", "auto"]} />
        {validData &&
          Object.keys(data[0])
            .filter((key) => key !== "time" && visibleKeys.includes(key))
            .map((key, index) => (
              <Line key={key} yAxisId="left" type="monotone" dataKey={key} stroke={getColor(index)} strokeWidth="2" activeDot={{ r: 8 }} dot={false} />
            ))}
        <Tooltip content={renderTooltip} />
      </LineChart>

      <div className="legend-container">
        {renderLegend({
          onToggleTimeFormat: handleToggleTimeFormat,
          timeFormat,
          onCheckboxChange: handleCheckboxChange,
        })}
      </div>
    </div>
  )
}
