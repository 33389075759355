import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Storage } from "aws-amplify"
import { connect } from "react-redux"
import TemplateEditor from "./TemplateEditor"
import LoadingSpinner from "../common/LoadingSpinner"
import Breadcrumb from "../common/Breadcrumb"
import SimpleButton from "../common/SimpleButton"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ExpandableText from "../common/ExpandableText"
import EmptyPageResults from "../common/EmptyPageResults"
import ConfirmationModal from "../common/ConfirmationModal"
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { convertTimeWithTAndZ } from '../../utils/filters/date'
import { colteneTemplatesDeletePermission, colteneTemplatesWritePermission } from "../../utils/permissionValidation"
import { MenuKebabVerticalIcon, MenuKebabVerticalBackgroundIcon } from "../../icons"
import "./my-coltene-templates.scss"

const MyColteneTemplates = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [templates, setTemplates] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState(null)
  const [templateKeyToDelete, setTemplateKeyToDelete] = useState(null)
  const [isCloneTemplate, setIsCloneTemplate] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isEditorMode, setIsEditorMode] = useState(false)
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [hasPreviewTemplate, setHasPreviewTemplate] = useState(false)
  const s3ColteneTemplatesFolder = "coltene-landing/templates"
  const s3ColteneDeployedFolder = "coltene-landing/published"
  const s3ColteneImagesFolder = "coltene-landing/images"
  const s3PreviewTemplateKey = "coltene-landing/templates/preview.json"
  const s3DeployedTemplateKey = "coltene-landing/published/deployed.json"
  const previewLandingPageUrl = `${process.env.REACT_APP_LANDING_URL}/preview/index.html`
  const landingPageUrl = process.env.REACT_APP_LANDING_URL
  const templateStatus = {
    available: "available",
    deployed: "deployed",
    preview: "preview"
  }

  useEffect(() => {
    setShowSpinner(true)

    const getTemplates = async () => {
      try {
        const tableTemplates = []
  
        const templateKeys = await Storage.list(s3ColteneTemplatesFolder, { level: "public" , pageSize: 1000 })

        await Promise.all(
          templateKeys?.results?.map(async (file) => {
            try {
              const fileData = await Storage.get(file.key, { level: 'public', download: true })
              const jsonData = await fileData.Body.text()
              const templateData = JSON.parse(jsonData)

              if (templateData.status !== templateStatus.preview) {
                const newTemplate = {
                  id: templateData.id,
                  templateKey: file.key,
                  name: templateData.name,
                  createdAt: templateData.createdAt,
                  createdBy: templateData.createdBy,
                  uiTree: templateData.uiTree,
                  status: templateData.status,
                }
  
                tableTemplates.push(newTemplate)
              } else {
                setHasPreviewTemplate(true)
              }
            } catch (error) {
              console.error(`Error fetching template from S3: ${file.key}`, error)
            }
          })
        )

        const sortedTemplates = tableTemplates.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        setTemplates(sortedTemplates)

        //A.S: To be removed in the next iteration
        if (sortedTemplates.length > 0) {
          setSelectedTemplate(sortedTemplates[0])
        }
      } finally {
        setShowSpinner(false)
        setIsFirstLoad(false)

        //A.S: To be removed in the next iteration
        setIsEditorMode(true)
      }
    }

    getTemplates()
  }, [])

  useEffect(() => {
    if (templates.length === 0 || templates[0].actions?.length > 0) {
      return
    }

    const templatesWithActions = templates.map((template) => ({
      ...template,
      actions: [
        {
          label: "edit",
          onActionClick: (e) => { e.stopPropagation(); handleEditTemplate(template.id)},
          requiredPermission: colteneTemplatesWritePermission
        },
        {
          label: "preview",
          onActionClick: (e) => { e.stopPropagation(); handlePreview(template.uiTree) },
          requiredPermission: colteneTemplatesWritePermission
        },
        {
          label: "clone",
          onActionClick: (e) => { e.stopPropagation(); handleEditTemplate(template.id, true) },
          requiredPermission: colteneTemplatesWritePermission
        },
        {
          label: "delete",
          onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(template.templateKey)},
          isDisabled: template.status !== templateStatus.available,
          requiredPermission: colteneTemplatesDeletePermission
        },
      ]
    }))

    setTemplates(templatesWithActions)
  },[templates])

  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  const handleEditTemplate = (templateId, shouldCloneTemplate) => {
    const template = templates.find(t => t.id === templateId)

    if (!template) {
      return
    }

    setSelectedTemplate(template)
    setIsCloneTemplate(shouldCloneTemplate)
    setIsEditorMode(true)
  }

  const showConfirmationModal = (templateKey) => {
    setTemplateKeyToDelete(templateKey)
    setShowDeleteConfirmationModal(true)
  }

  const handleDeleteTemplate = async (templateKey) => {
    try {
      setShowDeleteConfirmationModal(false)

      setShowSpinner(true)

      const imagesKeys = await Storage.list(s3ColteneImagesFolder, { level: "public" , pageSize: 1000 })
      const templateId = templateKey?.split("/")[2]?.split(".")[0] || ''

      await Promise.all(
        imagesKeys?.results?.map(async (file) => {
          try {
            if (file.key?.includes(templateId)) {
              await Storage.remove(file.key, { level: "public" })
            }
          } catch (error) {
            console.error(`Error deleting images from S3: ${file.key}`, error)
          }
        })
      )

      await Storage.remove(templateKey, { level: "public" })

      const filteredTemplates = templates.filter(t => t.templateKey !== templateKey)
      setTemplates(filteredTemplates)

      toast.success(t("template_context.delete_success"))
    } catch {
      toast.error(t("error.something_wrong"))
    } finally {
      setShowSpinner(false)
    }
  }

  const handleAddNewTemplate = async (templateKey, template) => {
    let previousDeployedTemplateKey = null

    if (template.status === templateStatus.deployed) {
      const previousDeployedTemplate = templates.find((t) => t.status === templateStatus.deployed)

      if (previousDeployedTemplate) {
        previousDeployedTemplateKey = previousDeployedTemplate.templateKey

        const previousDeployedWithNewStatus = {
          ...previousDeployedTemplate,
          status: templateStatus.available
        }
  
        const jsonTemplate = JSON.stringify(previousDeployedWithNewStatus, null, 2)
  
        await Storage.put(`${s3ColteneTemplatesFolder}/${previousDeployedTemplate.id}.json`, jsonTemplate, {
          level: "public",
          contentType: "application/json",
        })
      }
    }

    const filteredTemplatesWithoutActions = templates
      .filter(t => t.templateKey !== templateKey)
      .map(({actions, ...rest}) => {
        if (rest.templateKey === previousDeployedTemplateKey) {
          return { ...rest, status: templateStatus.available }
        }
        return rest
      })
    
    setTemplates([
      {
        ...template,
        templateKey,
      },
      ...filteredTemplatesWithoutActions
    ])
  }

  const handlePreview = async (uiTree) => {

    if (!uiTree || uiTree.length === 0) {
      return
    }

    setShowSpinner(true)

    try {
      if (hasPreviewTemplate) {
        await Storage.remove(s3PreviewTemplateKey, { level: "public" })
      }

      const previewTemplate = {
        id: "preview",
        name: "Preview Template",
        createdAt: new Date().toISOString(),
        createdBy: props.email,
        status: templateStatus.preview,
        uiTree: uiTree
      }

      const jsonTemplate = JSON.stringify(previewTemplate, null, 2)

      await Storage.put(`${s3ColteneTemplatesFolder}/preview.json`, jsonTemplate, {
        level: "public",
        contentType: "application/json",
      })

      window.open(previewLandingPageUrl).focus()
    } catch (error) {
      console.log("Something went wrong on previewing the template", error)
    } finally {
      setShowSpinner(false)
    }
  }

  const handlePublish = async (uiTree) => {
    if (!uiTree || uiTree.length === 0) {
      return
    }

    setShowSpinner(true)

    try {
      try {
        const deployedFileData = await Storage.get(s3DeployedTemplateKey, { level: 'public', download: true })
        const jsonData = await deployedFileData.Body.text()

        if (jsonData) {
          await Storage.remove(s3DeployedTemplateKey, { level: "public" })
        }
      } catch {
        console.log("There's no deployed template yet")
      }

      const deployedTemplate = {
        id: "deployed",
        name: "Deployed Template",
        createdAt: new Date().toUTCString(),
        createdBy: props.email,
        status: templateStatus.deployed,
        uiTree: uiTree
      }

      const jsonTemplate = JSON.stringify(deployedTemplate, null, 2)

      await Storage.put(`${s3ColteneDeployedFolder}/deployed.json`, jsonTemplate, {
        level: "public",
        contentType: "application/json",
      })

      window.open(landingPageUrl).focus()
    } catch (error) {
      console.log("Something went wrong on publish the template", error)
    } finally {
      setShowSpinner(false)
    }
  }

  return (
    <div className="mycoltene-templates-wrapper">
      <Breadcrumb path={window.location.pathname} />
      {!isEditorMode && (
        <>
          <DashboardHeader
            headerText={t("templates")}
          >
            {/*A.S: Hidden until further iteration */}
            {/* {!isMobile &&
              <SimpleButton
                className="submit-button"
                onClick={() => setIsEditorMode(true)}
                requiredPermission={colteneTemplatesWritePermission}
              >
                {t("template_context.add_template")}
              </SimpleButton>
            } */}
          </DashboardHeader>
          {!isMobile && templates.length > 0 &&
            <GenericTable
              data={templates}
              headers={[
                {
                  title: t("name"),
                },
                {
                  title: t("created_by")
                },
                {
                  title: t("created_at")
                },
                {
                  title: t("status")
                },
                {
                  title: ''
                },
              ]}
              keys={[
                'name',
                'createdBy',
                'createdAt',
                'status',
                'action',
              ]}
              keyRenderer={{
                name: (item) => {
                  return (
                    <div className="name-wrapper">
                      <ExpandableText text={item.name} />
                    </div>
                  )
                },
                createdAt: (item) => convertTimeWithTAndZ(item.createdAt),
                status: (item) => t([`${item.status}`, item.status]),
                action: (item) => {
                  return (
                    <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["id"]) }}>
                      {showActionsMenu === item["id"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                      {showActionsMenu === item["id"] &&
                        <ActionsMenu
                          setHideMenu={() => setShowActionsMenu(null)}
                          actions={item.actions}
                          isLoading={showSpinner}
                        />
                      }
                    </div>
                  )
                }
              }}
              onRowClick={(template) => handleEditTemplate(template.id)}
            />
          }
          {isMobile && 
            <EmptyPageResults
              title={t("error.not_supported")}
              subtitle={t("error.only_desktop_supported")}
            />
          }
          {!showSpinner && !isFirstLoad && templates.length === 0 && (
            <EmptyPageResults
              title={t("template_context.no_templates")}
              subtitle={t("template_context.no_template_subtitle")}
            />
          )}
        </>
      )}
      {!isMobile && isEditorMode && (
        <TemplateEditor
          selectedTemplate={selectedTemplate}
          isCloneTemplate={isCloneTemplate}
          onNewTemplate={(templateKey, template) => handleAddNewTemplate(templateKey, template)}
          onPreview={(uiTree) => handlePreview(uiTree)}
          onPublish={(uiTree) => handlePublish(uiTree)}
          onToggle={() => {
            setIsEditorMode(false)
            setIsCloneTemplate(false)
            setSelectedTemplate(null)
          }}
        />
      )}
      {showDeleteConfirmationModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteConfirmationModal(false)}
          message={t("template_context.delete_confirmation")}
          acceptButtonText={t("delete")}
          onCancel={() => setShowDeleteConfirmationModal(false)}
          onAccept={() => handleDeleteTemplate(templateKeyToDelete)}
          isWarning
          requiredPermission={colteneTemplatesDeletePermission}
        />
      )}
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    email: authedUser?.email,
  }
}

export default connect(mapStateToProps)(MyColteneTemplates)
