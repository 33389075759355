import React, { useEffect, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import Language from "../Language"
import RBAC from "../RBAC"
import { Roles } from "../../../utils/roles"
import { Modules, Pages } from "../../../utils/pages"
import {
  ReportsIcon,
  ProductsModernIcon,
  HomeIcon,
  VideoIcon,
  FileDownloadIcon,
  MonitoringIcon,
  FwDownloadIcon,
  RemoteAccessIcon,
  TroubleshootIcon,
  SparepartsIcon,
  ReportProblemIcon,
  WarrantyIcon,
  BackIcon,
  UsersIcon,
  PoliciesIcon,
  AccountsIcon,
  SettingsWheelFullIcon,
  StoreIcon,
  RecycleBinModern,
  BusinessRulesModern,
  ServicesModern,
  OrderHistoryModern,
  CartModern,
  EventsModern,
  DealerBulletinsSmallIcon,
  // DigitalAssetsSmallIcon,
  PriceListSmallIcon,
  InstrumentTrackingIcon,
  MembersIcon,
  CompaniesIcon,
  TemplateBuilderIcon,
  UploadCloudFull
} from "../../../icons"
import { useAppContext } from "../../../libs/contextLib"
import { useWindowSize } from "../../../libs/hooks"
import { manageAdminRoutes } from "../../../utils/manageAdminRoutes"
import { hasEndUserSiteAccess, hasColteneSiteAccess, checkPermission } from "../../../utils/permissionValidation"
import "./nav.scss"

const navSections = {
  MAIN: "main",
  ADMIN: "admin",
}

const Nav = (props) => {
  const location = useLocation()
  const { isAuthenticated, showFeatureDev, supportsColteneStore, hasOrganizationAccess } = useAppContext()
  const { t } = useTranslation()
  const [width] = useWindowSize()
  const [isDentist, setIsDentist] = useState(true)
  const [isColteneUser, setIsColteneUser] = useState(true)
  const [navigation, setActiveNavigation] = useState(navSections.MAIN)

  useEffect(() => {
    const hasEndUserSitePermission = hasEndUserSiteAccess(props.userPermissions)
    const hasColteneSitePermission = hasColteneSiteAccess(props.userPermissions)

    setIsDentist(hasEndUserSitePermission)
    setIsColteneUser(hasColteneSitePermission)
  }, [props.userPermissions])

  useEffect(() => {
    const currentPath = location.pathname

    const adminRoutes = manageAdminRoutes.map((route) => route.route)
    if (adminRoutes.includes(currentPath)) {
      setActiveNavigation(navSections.ADMIN)
      return
    }

    setActiveNavigation(navSections.MAIN)
  }, [location.pathname])

  const closeMenu = () => {
    if (width < 1024) {
      props.closeNavDisplay(false)
    }
  }

  const getAvailablePage = (sectionRoutes) => {
    const firstAvailableRoute = sectionRoutes?.find((route) => checkPermission(props.userPermissions, route.permission))
    
    if (firstAvailableRoute) {
      return firstAvailableRoute.route
    }

    return Pages.dashbord.route
  }
  
  return (
    isAuthenticated && (
      <div
        id="main-menu"
        className={"menu-wrapper drawer-menu" + (props.disabledBanner ? " disabled-banner" : "") + (props.expandNavMenu ? " nav-expand" : "")}
      >
        <div className="menu-container">
          <div className="menu-language-wrapper menu-item">
            <Language />
          </div>
          {navigation === "main" && (
            <nav className="nav">
              <ul>
                <li>
                  <NavLink to={Pages.dashbord.route} exact activeClassName="active" onClick={closeMenu}>
                    <div className="nav-icon">
                      <HomeIcon />
                      <span className="tooltip">{t("nav.dashboard")}</span>
                    </div>
                    <span className="item-name">{t("nav.dashboard")}</span>
                  </NavLink>
                </li>
                
                {hasOrganizationAccess && (
                  <RBAC roles={Roles.priceLists.roles} permission={Pages.priceLists.permission}>
                    <li>
                      <NavLink to={Pages.priceLists.route} exact activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <PriceListSmallIcon />
                          <span className="tooltip">{t("nav.price_list")}</span>
                        </div>
                        <span className="item-name">{t("nav.price_list")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )}
                {hasOrganizationAccess && (
                  <RBAC roles={Roles.dealerBulletins.roles} permission={Pages.dealerBulletins.permission}>
                    <li>
                      <NavLink to={Pages.dealerBulletins.route} exact activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <DealerBulletinsSmallIcon />
                          <span className="tooltip">{t("nav.dealer_bulletins")}</span>
                        </div>
                        <span className="item-name">{t("nav.dealer_bulletins")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )}

                <RBAC roles={Roles.monitoring.roles} permission={Pages.monitoring.permission}>
                  <li>
                    <NavLink to={Pages.monitoring.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <MonitoringIcon />
                        <span className="tooltip">{t("nav.monitoring")}</span>
                      </div>
                      <span className="item-name">{t("nav.monitoring")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                {isDentist && (
                  <RBAC roles={Roles.myProducts.roles} permission={Pages.myProducts.permission}>
                    <li>
                      <NavLink to={Pages.myProducts.route} exact activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <ProductsModernIcon />
                          <span className="tooltip">{t("nav.my_products")}</span>
                        </div>
                        <span className="item-name">{t("nav.my_products")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )}
                <RBAC roles={Roles.instrumentTracking.roles} permission={Pages.instrumentTracking.permission}>
                  <li>
                    <NavLink to={Pages.instrumentTracking.route} activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <InstrumentTrackingIcon />
                        <span className="tooltip">{t("nav.instrument_tracking")}</span>
                      </div>
                      <span className="item-name">{t("nav.instrument_tracking")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.reports.roles} permission={Pages.reports.permission}>
                  <li>
                    <NavLink to={Pages.reports.route} activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <ReportsIcon />
                        <span className="tooltip">{t("nav.reports")}</span>
                      </div>
                      <span className="item-name">{t("nav.reports")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.documents.roles} permission={Pages.documents.permission}>
                  <li>
                    <NavLink to={Pages.documents.route} activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <FileDownloadIcon />
                        <span className="tooltip">{t("nav.documents")}</span>
                      </div>
                      <span className="item-name">{t("nav.documents")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.firmware.roles} permission={Pages.firmware.permission}>
                  <li>
                    <NavLink to={Pages.firmware.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <FwDownloadIcon />
                        <span className="tooltip">{t("nav.firmware")}</span>
                      </div>
                      <span className="item-name">{t("nav.firmware")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.videos.roles} permission={Pages.videos.permission}>
                  <li>
                    <NavLink to={Pages.videos.route} activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <VideoIcon />
                        <span className="tooltip">{t("nav.videos")}</span>
                      </div>
                      <span className="item-name">{t("nav.videos")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.remoteAccess.roles} permission={Pages.remoteAccess.permission}>
                  <li>
                    <NavLink to={Pages.remoteAccess.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <RemoteAccessIcon />
                        <span className="tooltip">{t("nav.remote_access")}</span>
                      </div>
                      <span className="item-name">{t("nav.remote_access")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.troubleshoot.roles} permission={Pages.troubleshoot.permission}>
                  <li>
                    <NavLink to={Pages.troubleshoot.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <TroubleshootIcon />
                        <span className="tooltip">{t("nav.troubleshoot")}</span>
                      </div>
                      <span className="item-name">{t("nav.troubleshoot")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.spareParts.roles} permission={Pages.spareParts.permission}>
                  <li>
                    <NavLink to={Pages.spareParts.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <SparepartsIcon />
                        <span className="tooltip">{t("nav.spareparts")}</span>
                      </div>
                      <span className="item-name">{t("nav.spareparts")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                {/* A.S: Hidden until further notice */}
                {/* <RBAC roles={Roles.warranty.roles} permission={Pages.warranty.permission}>
                  <li>
                    <NavLink to="{Pages.warranty.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <WarrantyIcon />
                        <span className="tooltip">{t("nav.warranty")}</span>
                      </div>
                      <span className="item-name">{t("nav.warranty")}</span>
                    </NavLink>
                  </li>
                </RBAC> */}
                <RBAC roles={Roles.reportProblem.roles} permission={Pages.reportProblem.permission}>
                  <li>
                    <NavLink to={Pages.reportProblem.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <ReportProblemIcon />
                        <span className="tooltip">{t("nav.report_problem")}</span>
                      </div>
                      <span className="item-name">{t("nav.report_problem")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                {/* {showFeatureDev && (
                  <RBAC roles={Roles.digitalAssets.roles} permission={Pages.digitalAssets.permission}>
                    <li>
                      <NavLink to={Pages.digitalAssets.route} exact activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <DigitalAssetsSmallIcon />
                          <span className="tooltip">{t("nav.digital_assets")}</span>
                        </div>
                        <span className="item-name">{t("nav.digital_assets")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )} */}
                {supportsColteneStore && (
                  <RBAC roles={Roles.colteneStore.roles} permission={Pages.colteneStore.permission}>
                    <li>
                      <NavLink to={Pages.colteneStore.route} activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <StoreIcon />
                          <span className="tooltip">{t("nav.coltene_store")}</span>
                        </div>
                        <span className="item-name">{t("nav.coltene_store")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )}
                {showFeatureDev && (
                  <RBAC roles={Roles.uploads.roles} permission={Pages.uploads.permission}>
                    <li>
                      <NavLink to={Pages.uploads.route} activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <UploadCloudFull />
                          <span className="tooltip">{t("nav.uploads")}</span>
                        </div>
                        <span className="item-name">{t("nav.uploads")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )}
                <RBAC 
                  roles={[
                    Modules.administration.permission,
                    Modules.organizationAdmin.permission
                  ]} 
                  permission={[
                    Modules.administration.permission,
                    Modules.organizationAdmin.permission
                  ]}
                >
                  <li>
                    <NavLink 
                      to={getAvailablePage(manageAdminRoutes)} 
                      exact 
                      activeClassName="active" 
                      onClick={() => setActiveNavigation(navSections.ADMIN)}
                    >
                      <div className="nav-icon">
                        <SettingsWheelFullIcon />
                        <span className="tooltip">{t("nav.admin_settings")}</span>
                      </div>
                      <span className="item-name">{t("nav.admin_settings")}</span>
                    </NavLink>
                  </li>
                </RBAC>
              </ul>
            </nav>
          )}
          {navigation === navSections.ADMIN && (
            <nav className="nav">
              <ul>
                <RBAC
                  roles={[
                    Modules.administration.permission,
                    Modules.organizationAdmin.permission
                  ]} 
                  permission={[
                    Modules.administration.permission,
                    Modules.organizationAdmin.permission
                  ]}
                >
                  <li>
                    <NavLink to={Pages.dashbord.route} exact activeClassName="active" onClick={() => setActiveNavigation(navSections.MAIN)}>
                      <div className="nav-icon">
                        <BackIcon />
                        <span className="tooltip">{t("nav.back_to_dashboard")}</span>
                      </div>
                      <span className="item-name">{t("nav.back_to_dashboard")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <li>
                  <p className="nav-delimiter">
                    {props.expandNavMenu ? 
                      isColteneUser ?
                        t("nav.account_management").toUpperCase() 
                        : t("nav.organization_management").toUpperCase()
                      : ""
                    }
                  </p>
                </li>
                <RBAC roles={Roles.users.roles} permission={Pages.users.permission}>
                  <li>
                    <NavLink to={Pages.users.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <UsersIcon />
                        <span className="tooltip">{t("nav.users_management")}</span>
                      </div>
                      <span className="item-name">{t("nav.users_management")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.accountsManagement.roles} permission={Pages.accountsManagement.permission}>
                  <li>
                    <NavLink to={Pages.accountsManagement.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <AccountsIcon />
                        <span className="tooltip">{t("nav.accounts")}</span>
                      </div>
                      <span className="item-name">{t("nav.accounts")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.companies.roles} permission={Pages.companies.permission}>
                  <li>
                    <NavLink to={Pages.companies.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <CompaniesIcon />
                        <span className="tooltip">{t("nav.organizations_management")}</span>
                      </div>
                      <span className="item-name">{t("nav.organizations_management")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.members.roles} permission={Pages.members.permission}>
                  <li>
                    <NavLink to={Pages.members.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <MembersIcon />
                        <span className="tooltip">{t("nav.members")}</span>
                      </div>
                      <span className="item-name">{t("nav.members")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.policies.roles} permission={Pages.policies.permission}>
                  <li>
                    <NavLink to={Pages.policies.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <PoliciesIcon />
                        <span className="tooltip">{t("nav.policies")}</span>
                      </div>
                      <span className="item-name">{t("nav.policies")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                {isColteneUser && (
                  <li>
                    <p className="nav-delimiter">{t("nav.general_settings").toUpperCase()}</p>
                  </li>
                )}
                <RBAC roles={Roles.events.roles} permission={Pages.events.permission}>
                  <li>
                    <NavLink to={Pages.events.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <EventsModern />
                        <span className="tooltip">{t("nav.events")}</span>
                      </div>
                      <span className="item-name">{t("nav.events")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.adminStore.roles} permission={Pages.adminStore.permission}>
                  <li>
                    <NavLink to={Pages.adminStore.route} activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <CartModern />
                        <span className="tooltip">{t("nav.admin_store")}</span>
                      </div>
                      <span className="item-name">{t("nav.admin_store")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.adminOrderHistory.roles} permission={Pages.adminOrderHistory.permission}>
                  <li>
                    <NavLink to={Pages.adminOrderHistory.route} activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <OrderHistoryModern />
                        <span className="tooltip">{t("nav.admin_order_history")}</span>
                      </div>
                      <span className="item-name">{t("nav.admin_order_history")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.services.roles} permission={Pages.services.permission}>
                  <li>
                    <NavLink to={Pages.services.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <ServicesModern />
                        <span className="tooltip">{t("nav.services")}</span>
                      </div>
                      <span className="item-name">{t("nav.services")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                <RBAC roles={Roles.businessRules.roles} permission={Pages.businessRules.permission}>
                  <li>
                    <NavLink to={Pages.businessRules.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <BusinessRulesModern />
                        <span className="tooltip">{t("nav.business_rules")}</span>
                      </div>
                      <span className="item-name">{t("nav.business_rules")}</span>
                    </NavLink>
                  </li>
                </RBAC>
                {showFeatureDev && (
                  <RBAC roles={Roles.accountsManagement.roles} permission={Pages.recycleBin.permission}>
                    <li>
                      <NavLink to={Pages.recycleBin.route} exact activeClassName="active" onClick={closeMenu}>
                        <div className="nav-icon">
                          <RecycleBinModern />
                          <span className="tooltip">{t("nav.recycle_bin")}</span>
                        </div>
                        <span className="item-name">{t("nav.recycle_bin")}</span>
                      </NavLink>
                    </li>
                  </RBAC>
                )}
                <RBAC roles={Roles.myColteneTemplates.roles} permission={Pages.myColteneTemplates.permission}>
                  <li>
                    <NavLink to={Pages.myColteneTemplates.route} exact activeClassName="active" onClick={closeMenu}>
                      <div className="nav-icon">
                        <TemplateBuilderIcon />
                        <span className="tooltip">{t("nav.mycoltene_templates")}</span>
                      </div>
                      <span className="item-name">{t("nav.mycoltene_templates")}</span>
                    </NavLink>
                  </li>
                </RBAC>
              </ul>
            </nav>
          )}
        </div>
      </div>
    )
  )
}

function mapStateToProps({ authedUser, userProducts, userPermissions }) {
  return {
    authedUser,
    userProducts: userProducts?.products || {},
    userPermissions: userPermissions || {},
  }
}

export default connect(mapStateToProps)(Nav)
