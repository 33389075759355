const languages = {
  'en-CA': 'english',
  'fr-CA': 'french',
  'de-DE': 'german',
  'it-IT': 'italian',
  'zh-CN': 'chinese',
  'ja-JP': 'japanese',
  'es-MX': 'spanish',
  'pl-PL': 'polish'
}

const iso639_2LocaleMapping =  {
  'en-CA': 'ENG',
  'fr-CA': 'FRA',
  'de-DE': 'DEU',
  'it-IT': 'ITA',
  'zh-CN': 'ZHO',
  'ja-JP': 'JPN',
  'es-MX': 'SPA',
  'pl-PL': 'POL'
}

const regionalLanguages = {
  'fr_CA': 'french_canada',
  'de_DE' : 'german_germany',
  'fr_FR' : 'french_france',
  'it_IT' : 'italian_italy',
  'es_ES' : 'spanish_spain',
  'es_MX' : 'spanish_latin_america',
  'pt_BR' : 'portuguese_latin_america',
}

export const getIso639_2FromLocale = (locale) => {
  return iso639_2LocaleMapping[locale]
}

export const getLocaleFromIso639_2 = (iso639_2) => {
  return Object.keys(iso639_2LocaleMapping).find(key => iso639_2LocaleMapping[key] === iso639_2)
}

export const getLanguageFromLocale = (locale) => {
  return languages[locale]
}

export const getLanguageFromRegionalLanguage = (locale) => {
  return regionalLanguages[locale]
}

export default languages;