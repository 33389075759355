import { ICDocIcon, ICExeIcon, ICIsoIcon, ICTxtIcon, ICZipIcon, PdfModernIcon } from "../icons"
import * as images from "../images"

const fileIcons = {
  doc: <ICDocIcon className="doc-icon" />,
  pdf: <PdfModernIcon className="doc-icon" />,
  txt: <ICTxtIcon className="doc-icon" />,
  iso: <ICIsoIcon className="doc-icon" />,
  zip: <ICZipIcon className="doc-icon" />,
  exe: <ICExeIcon className="doc-icon" />,
  uc3: <ICExeIcon className="doc-icon" />
}

export const getFileIcon = (fileName) => {
  const extension = fileName?.split('.').pop().toLowerCase()
  return fileIcons[extension] || <PdfModernIcon className="doc-icon" />
}

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return ''
  }

  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const capitalizeWords = (sentence) => {
  if (!sentence) {
    return ''
  }

  return sentence
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const JSONdeepClone = (object) => {
  return JSON.parse(JSON.stringify(object))
}

export const downloadFile = ({ data, fileName, fileType }) => {
  const blob = new Blob([data], { type: fileType })

  const anchor = document.createElement('a')
  anchor.download = fileName
  anchor.href = window.URL.createObjectURL(blob)

  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: true,
  })

  anchor.dispatchEvent(clickEvent)
  anchor.remove()
}

export function getImageByModel(model) {
  if(images[model?.toLowerCase().replace(/ |-/g,"_").replace("+","plus") + "_200x200"]) {
    return images[model?.toLowerCase().replace(/ |-/g,"_").replace("+","plus") + "_200x200"]
  } else  {
    return images.no_image_200x200
  }
}

export const disableScrolling = () => {
  const x = window.scrollX
  const y = window.scrollY

  window.onscroll = function () {
    window.scrollTo(x, y)
  }
}

export const enableScrolling = () => {
  window.onscroll = function () {}
}

export const formatPhone = (phone) => {
  let sanitizedPhone = phone.replace(/[^\d]/g, "");

  // returns: "x", "xx", "xxx"
  if (sanitizedPhone.length < 4) return sanitizedPhone; 

  // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
  if (sanitizedPhone.length < 7) return `${sanitizedPhone.slice(0, 3)}-${sanitizedPhone.slice(3)}`; 

  // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
  if (sanitizedPhone.length < 11) return `${sanitizedPhone.slice(0, 3)}-${sanitizedPhone.slice(3, 6)}-${sanitizedPhone.slice(6, 10)}`;
  
  return `${sanitizedPhone.slice(0, 3)}-${sanitizedPhone.slice(3, 6)}-${sanitizedPhone.slice(6, 10)}-${sanitizedPhone.slice(10)}`
}

//convert seconds to minutes
export const convertSeconds = (seconds) => {
  var d = new Date(seconds * 1000)
  var h = d.getHours() < 10 ? "0" + d.getHours() : d.getHours()
  var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes()
  var s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds()
  return m + ":" + s
}

//convert seconds to full date
export const convertSecondsFullTime = (seconds) => {
  // var d = new Date(seconds * 1000);
  // var h = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  // var m = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  // var s = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
  // return h + ":" + m + ":" + s;
  var date = new Date(seconds * 1000)
  var year = date.getUTCFullYear()
  var month = ("0" + (date.getUTCMonth() + 1)).slice(-2)
  var day = ("0" + date.getUTCDate()).slice(-2)
  var hours = ("0" + date.getUTCHours()).slice(-2)
  var minutes = ("0" + date.getUTCMinutes()).slice(-2)
  var second = ("0" + date.getUTCSeconds()).slice(-2)
  return `${year}-${month}-${day} ${hours}:${minutes}:${second}`
}

//Format date string
export const convertDate = (date) => {
  var year = date?.substring(0, 4)
  var month = date?.substring(4, 6)
  var day = date?.substring(6, 8)
  var hours = date?.substring(8, 10)
  var minutes = date?.substring(10, 12)
  var seconds = date?.substring(12, 14)

  if (hours && minutes && seconds) {
    return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  } else {
    return year + "-" + month + "-" + day
  }
}

// export const convertDateWithTandZ = (dateString) => {
//   const date = new Date(dateString);
//   const isoString = date.toISOString();
//   const formattedDate = new Date(isoString);
//   const year = formattedDate.getUTCFullYear();
//   const month = String(formattedDate.getUTCMonth() + 1).padStart(2, '0');
//   const day = String(formattedDate.getUTCDate()).padStart(2, '0');
//   const hours = String(formattedDate.getUTCHours()).padStart(2, '0');
//   const minutes = String(formattedDate.getUTCMinutes()).padStart(2, '0');
//   const seconds = String(formattedDate.getUTCSeconds()).padStart(2, '0');
//   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
// }

export const convertDateWithTandZ = (dateString) => {
  const date = new Date(dateString);
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false, // Ensure 24-hour format
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone // Use browser's time zone
  };
  
  const localDateString = date.toLocaleString("en-US", options);
  
  const year = localDateString.slice(6, 10);
  const month = localDateString.slice(0, 2);
  const day = localDateString.slice(3, 5);
  const time = localDateString.slice(11);

  return `${year}-${month}-${day} ${time}`;
}

export const formatTime = (timeFormat, time) => {
  if (!time) return ""
  
  if (timeFormat === "24hr") return time

  const [hours, minutes, seconds] = time.split(":")
  const hr = parseInt(hours, 10)
  const isPM = hr >= 12
  const formattedHour = hr % 12 === 0 ? 12 : hr % 12
  const amPm = isPM ? "PM" : "AM"
  return `${formattedHour}:${minutes}:${seconds} ${amPm}`
}

