import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import ImageUploader from '../ImageUploader'
import { SelectMenu } from "../../SelectMenu"
import { ArrowLeftIcon } from "../../../../icons"
import './section-builder-toolbox.scss'

const SectionBuilderToolbox = ({ templateId, id, imageKey, style, children, onUpdate }) => {
  const { t } = useTranslation()
  const [selectedBackgroundType, setSelectedBackgroundType] = useState(null)
  const [backgroundColor, setBackgroundColor] = useState('#FFFFFF')
  const [backgroundImage, setBackgroundImage] = useState('')
  const [backgroundImageKey, setBackgroundImageKey] = useState(null)
  const [gradientColors, setGradientColors] = useState({ color1: '#FFFFFF', color2: '#000000' })
  const [gradientAngle, setGradientAngle] = useState(90)
  //const [childElements, setChildElements] = useState([])
  const backgroundOptions = [{ key: "image", value: "Image" }, { key: "color", value: "Color" }, { key: "gradient", value: "Gradient" }]

  useEffect(() => {
    setSelectedBackgroundType(style?.backgroundImage ? 'image' : style?.background ? 'gradient' : 'color')

    setBackgroundColor(style?.backgroundColor || '#FFFFFF')
  
    const backgroundImg = extractImageUrl(style?.backgroundImage)
    setBackgroundImage(backgroundImg || '')
    setBackgroundImageKey(imageKey)

    const gradient = extractGradientData(style?.background)
    setGradientColors({
      color1: gradient?.color1 || '#FFFFFF',
      color2: gradient?.color2 || '#000000',
    })
    setGradientAngle(gradient?.angle || 90)
  
    //setChildElements(children || [])
  },[imageKey, style, children])

  const extractImageUrl = (backgroundImg) => {
    const match = backgroundImg?.match(/url\(['"]?(.*?)['"]?\)/)

    return match ? match[1] : null
  }

  const extractGradientData = (background) => {
    const gradientRegex = /linear-gradient\((\d+deg),\s*(#[0-9A-Fa-f]{6}),\s*(#[0-9A-Fa-f]{6})\)/
    const match = background?.match(gradientRegex)

    if (!match) {
      return null
    }
  
    return {
      angle: parseInt(match[1], 10),
      color1: match[2],
      color2: match[3],
    }
  }

  const handleBackgroundChange = (type) => {
    setSelectedBackgroundType(type)

    if (type === 'color') {
      onUpdate(id, { imageKey: null, style: { backgroundColor } })
    } else if (type === 'image') {
      onUpdate(id, { imageKey: backgroundImageKey, style: { backgroundImage: `url(${backgroundImage})` } })
    } else if (type === 'gradient') {
      onUpdate(id, { imageKey: null, style: { background: `linear-gradient(${gradientAngle}deg, ${gradientColors.color1}, ${gradientColors.color2})` } })
    }
  }

  const handleImage = (img, imageKey) => {
    setBackgroundImage(img)
    setBackgroundImageKey(imageKey)

    onUpdate(id, { imageKey: imageKey, style: { backgroundImage: `url(${img})` } })
  }

  // const handleShiftChild = (index, direction) => {
  //   const newChildren = [...childElements]

  //   if (direction === 'up' && index < newChildren.length - 1) {
  //     [newChildren[index + 1], newChildren[index]] = [newChildren[index], newChildren[index + 1]]
  //   } else if (direction === 'down' && index > 0) {
  //     [newChildren[index - 1], newChildren[index]] = [newChildren[index], newChildren[index - 1]]
  //   }

  //   setChildElements(newChildren)
  //   onUpdate(id, { children: newChildren })
  // }

  return (
    <div className="section-toolbox">
      <div className="input-wrapper">
        <p className="input-option">{t("background")}</p>
        <SelectMenu
          options={backgroundOptions}
          initialSelectedKey={selectedBackgroundType}
          onChange={(option) => {
            handleBackgroundChange(option.key)
          }}
        />
      </div>
      
      {selectedBackgroundType === 'color' && (
        <div className="input-wrapper">
          <p className="input-option">{t("background_color")}</p>
          <input 
            type="color"
            className="input-item"
            value={backgroundColor}
            onChange={(e) => {
              setBackgroundColor(e.target.value);
              onUpdate(id, { style: { backgroundColor: e.target.value } });
            }}
          />
        </div>
      )}

      {selectedBackgroundType === 'image' && (
        <div className="input-wrapper">
          <p className="input-option">{t("background_image")}</p>
          <ImageUploader
            templateId={templateId}
            sectionId={id}
            fileSrc={backgroundImage}
            fileKey={backgroundImageKey}
            handleImage={handleImage}
          />
        </div>
      )}

      {selectedBackgroundType === 'gradient' && (
        <div className="input-wrapper">
          <p className="input-option">{t("gradient_colors")}</p>
          <div className="gradient-color-picker">
            <input
              type="color"
              className="input-item"
              value={gradientColors.color1}
              onChange={(e) => {
                const newColors = { ...gradientColors, color1: e.target.value }
                setGradientColors(newColors)
                onUpdate(id, { style: { background: `linear-gradient(${gradientAngle}deg, ${newColors.color1}, ${newColors.color2})` } })
              }}
            />
            <input
              type="color"
              className="input-item"
              value={gradientColors.color2}
              onChange={(e) => {
                const newColors = { ...gradientColors, color2: e.target.value }
                setGradientColors(newColors)
                onUpdate(id, { style: { background: `linear-gradient(${gradientAngle}deg, ${newColors.color1}, ${newColors.color2})` } })
              }}
            />
          </div>
          <div className="input-wrapper with-margin-top">
            <p className="input-option">{t("gradient_angle_degrees")}</p>
            <input
              type="number"
              className="input-item"
              value={gradientAngle}
              onChange={(e) => {
                setGradientAngle(e.target.value)
                onUpdate(id, { style: { background: `linear-gradient(${e.target.value}deg, ${gradientColors.color1}, ${gradientColors.color2})` } })
              }}
            />
          </div>
        </div>
      )}

      {/* <div className="title">{t("layout")}</div>
      {childElements.map((child, index) =>
        <div key={child.id} className="child-item">
          <ArrowLeftIcon 
            className={"arrow-down" + (index === childElements.length - 1 ? " disabled-shift" : "")}
            onClick={() => handleShiftChild(index, "up")}
          />
          <ArrowLeftIcon 
            className={"arrow-up" + (index === 0 ? " disabled-shift" : "")}
            onClick={() => handleShiftChild(index, "down")}
          />
          <span>{child.id}</span>
        </div>
      )} */}
    </div>
  )
}

export default SectionBuilderToolbox