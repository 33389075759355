import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from 'react-router-dom'
import { connect } from "react-redux"
import MainDetails from './MainDetails'
import CycleHistory from './CycleHistory'
import Troubleshooting from './Troubleshooting'
import Notes from './Notes'
import CycleFaultPredictionsModal from "./CycleFaultPredictionsModal"
import PageModal from "../common/PageModal"
import SimpleButton from '../common/SimpleButton'
import LoadingSpinner from '../common/LoadingSpinner'
import RadioInput from '../common/RadioInput'
import WrappedTabs from '../common/WrappedTabs'
import TooltipedIcon from '../common/TooltipedIcon'
import { toast } from "../common/Toast"
import { InProgressGreenIcon, ApertureIcon, UpdateIcon, CheckDoubleIcon, VerifiedIcon, FlagAUIcon, FlagCAIcon, FlagCNIcon, FlagDEIcon, FlagJPIcon, FlagUSIcon, RobotIcon } from "../../icons"
import { capitalizeFirstLetter, getImageByModel } from '../../utils/functions'
import { getProductSummary } from '../../utils/requests/productsAPI'
import { convertToSimpleDate } from '../../utils/filters/date'
import { getCycleFaultProducts, putCyclesFaultsStatus } from "../../utils/requests/cyclesAPI"
import { getCFPredictions, getCFPredictionFeedback, updateProductCyclesFaultsNotes } from "../../utils/requests/productsAPI"
import { formatDate, formatTime } from "../../utils/filters/date"
import { cycleWritePermission } from "../../utils/permissionValidation"
import { useAppContext } from "../../libs/contextLib"
import './view-fault-details.scss'

const ViewFaultDetails = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { showFeatureDev } = useAppContext()
  const { selectedCycleFault, closeModal, handleUpdateCycleFault, handleNavigationWithState } = props
  const [newStatus, setNewStatus] = useState(null)
  const [cycleFaults, setCycleFaults] = useState([])
  const [hasOnlineAccess, setHasOnlineAccess] = useState(false)
  const [hasUpdateAvailable, setHasUpdateAvailable] = useState(false)
  const [hasPrivacyPolicyChecked, setHasPrivacyPolicyChecked] = useState(false)
  const [productIsAperture, setProductIsAperture] = useState(false)
  const [productIsG4Plus, setProductIsG4Plus] = useState(false)
  const [generalInformation, setGeneralInformation] = useState(null)
  const [newNote, setNewNote] = useState("")
  const [predictions, setPredictions] = useState([])
  const [selectedPrediction, setSelectedPrediction] = useState(null)
  const [activeTab, setActiveTab] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [tabs, setTabs] = useState([])
  const aiAvailableCF = ["45"]

  useEffect(() => {
    if (selectedCycleFault) {
      setNewStatus({
        pending_for_action: selectedCycleFault.pending_for_action, 
        action_in_progress: selectedCycleFault.action_in_progress
      })

      setShowSpinner(true)

      const queryParams = {
        sn: selectedCycleFault.serial_number,
        cycle_fault: selectedCycleFault.cycle_fault,
        all_records: true,
      }

      getCycleFaultProducts(queryParams)
        .then((data) => {
          if (data?.data) {
            setCycleFaults(data.data)
            setIsFirstLoad(false)

            const cycleFaultsAvailableForPredictions = data.data?.filter(c => aiAvailableCF.includes(c.cycle_fault)) || []
            if (showFeatureDev && cycleFaultsAvailableForPredictions.length > 0) {
              const getCyclesWithPredictins = async () => {
                const cyclePromises = cycleFaultsAvailableForPredictions.map(async (c) => {
                  let cycle = {
                    serialNumber: selectedCycleFault.serial_number,
                    cycleFault: selectedCycleFault.cycle_fault,
                    cycleNumber: c.cycle_number,
                    printout: c.printout_file_name,
                    predictions: [],
                    hasPredictions: false,
                    feedback: {},
                    hasFeedback: false,
                  }
              
                  const [predictionsResult, feedbackResult] = await Promise.allSettled([
                    getCFPredictions({ printout: c.printout_file_name, cycleFault: selectedCycleFault.cycle_fault }),
                    getCFPredictionFeedback(selectedCycleFault.serial_number, c.cycle_number, {
                      cycleFault: selectedCycleFault.cycle_fault
                    })
                  ])

                  if (predictionsResult?.status === "fulfilled" && predictionsResult?.value?.length > 0) {
                    const orderedPredictions = predictionsResult.value.sort((a, b) => Number(b.probability) - Number(a.probability));
                    cycle.predictions = orderedPredictions
                    cycle.hasPredictions = true
                  }
              
                  if (feedbackResult?.status === "fulfilled" && Object.keys(feedbackResult?.value || {}).length) {
                    cycle.feedback = {
                      ...feedbackResult.value
                    }
                    cycle.hasFeedback = true
                  }
              
                  return cycle
                })
              
                const cycles = await Promise.all(cyclePromises)
                setPredictions(cycles)
              }

              getCyclesWithPredictins()
            }
          }
        })
        .catch((error) => {
          toast.error(t('error.error_loading_data'))

          setIsFirstLoad(false)
        })

      getProductSummary(selectedCycleFault.serial_number, {
        model_id: selectedCycleFault.model_id
      })
        .then((data) => {
          setShowSpinner(false)

          if (data?.data) {
            setHasOnlineAccess(data.data.association_active)
            setHasUpdateAvailable(data.data.update_available)
            setHasPrivacyPolicyChecked(data.data.privace_policy_checked)
            setProductIsAperture(data.isAperture === 1)
            setGeneralInformation(data.data)
            setProductIsG4Plus(data.data.is_g4_plus)
          }
        })
        .catch(() => {
          setShowSpinner(false)
          toast.error(t("error.failure_msg"))
        })
    }
  },[selectedCycleFault])

  useEffect(() => {
    const tabData = [
      {
        key: 0,
        title: t("monitoring.main_details"),
        content: <MainDetails generalInformation={generalInformation} />,
      },
      {
        key: 1,
        title: t("device_context.cycle_history"),
        content: <CycleHistory serialNumber={selectedCycleFault?.serial_number} model={selectedCycleFault?.model} modelId={selectedCycleFault?.model_id} isG4Plus={productIsG4Plus} />,
      },
      {
        key: 2,
        title: t("notes"),
        content: <Notes serialNumber={selectedCycleFault?.serial_number} faultId={selectedCycleFault?.id} handleNavigationWithState={handleNavigationWithState} />,
      },
      {
        key: 3,
        title: t("nav.troubleshoot"),
        content: <Troubleshooting model={selectedCycleFault?.model} />,
      },    
    ]

    setTabs(tabData)
    setActiveTab(tabData[0])
  },[selectedCycleFault?.serial_number, selectedCycleFault?.id, selectedCycleFault?.model, generalInformation, productIsG4Plus])

  const handleChangeTab = (tabKey) => {
    const tab = tabs.find((tab) => tab.key === tabKey)
    setActiveTab(tab)
  }

  const handleOnClose = () => {
    if (closeModal) {
      closeModal()
    }
  }

  const renderCycleFaultCountry = (country) => {
    switch (country) {
      case "AU":
        return (
          <div className="country-wrapper">
            <FlagAUIcon />
            <p>{t("filter_context.AU")}</p>
          </div>
        )
      case "CA":
        return (
          <div className="country-wrapper">
            <FlagCAIcon />
            <p>{t("filter_context.CA")}</p>
          </div>
        )
      case "CN":
        return (
          <div className="country-wrapper">
            <FlagCNIcon />
            <p>{t("filter_context.CN")}</p>
          </div>
        )
      case "DE":
        return (
          <div className="country-wrapper">
            <FlagDEIcon />
            <p>{t("filter_context.DE")}</p>
          </div>
        )
      case "JP":
        return (
          <div className="country-wrapper">
            <FlagJPIcon />
            <p>{t("filter_context.JP")}</p>
          </div>
        )
      case "US":
        return (
          <div className="country-wrapper">
            <FlagUSIcon />
            <p>{t("filter_context.US")}</p>
          </div>
        )
      default:
        return null
    }
  }

  const handleNavigateToReportProblem = () => {
    handleNavigationWithState()

    history.push({
      pathname: "/report-problem",
      state: {
        model: selectedCycleFault?.model,
        serial_number: selectedCycleFault?.serial_number,
        withBackNavigation: true,
      },
    })
  }

  const extractMinutesAndSecondsWithDayPeriod = (date) => {
    if (!date) {
      return ""
    }

    const time = date.split("T")[1]
    const hour = time.split(":")[0]
    const minutes = time.split(":")[1]
    const dayPeriod = Number(hour) >= 12 ? 'pm' : 'am'
    const hours12 = Number(hour) % 12 || 12

    return `${hours12 < 10 ? "0": ""}${hours12}:${minutes}${dayPeriod}`
  }

  const handleUpdateStatus = () => {
    const date = new Date()
    const currentDateFormatted = `${formatDate(date)} ${formatTime(date)}`

    let withRequestFeedback = false

    if (!newStatus.pending_for_action && !newStatus.action_in_progress && cycleFaults.length && predictions.length) {
      const sortedCycles = cycleFaults.sort((a, b) => {
        const dateComparison = new Date(b.date_originated) - new Date(a.date_originated)
        
        if (dateComparison !== 0) {
          return dateComparison
        }

        return Number(b.cycle_number) - Number(a.cycle_number)
      })
      const latestCycle = sortedCycles[0]

      const predictionForFeedback = predictions.find(p => p.cycleNumber === latestCycle.cycle_number)
      if (!predictionForFeedback?.hasFeedback) {
        setSelectedPrediction({...predictionForFeedback, withRequestFeedback: true })
        withRequestFeedback = true
      }
    }

    setShowSpinner(true)

    putCyclesFaultsStatus(selectedCycleFault?.id, {
      ...newStatus,
      handeled_by: props.email,
      handeled_by_date: currentDateFormatted,
    })
      .then(() => {
        setShowSpinner(false)

        if (!withRequestFeedback) {
          handleUpdateCycleFault({ 
            ...selectedCycleFault, 
            ...newStatus,
            handeled_by_date: newStatus.action_in_progress ? currentDateFormatted : null,
            handeled_by: {
              avatar_url: null,
              email: newStatus.action_in_progress ? props.email : null,
              first_name: newStatus.action_in_progress ? props.firstName : null,
              last_name: newStatus.action_in_progress ? props.lastName : null,
            }
          })
        }

        toast.success(t("monitoring.success_update_status"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  const handlePostNewNote = () => {
    const date = new Date()

    const bodyParams = {
      note: newNote,
      date: `${formatDate(date)} ${formatTime(date)}`,
      user: props.email
    }

    setShowSpinner(true)

    updateProductCyclesFaultsNotes(selectedCycleFault?.id, bodyParams)
      .then(() => {
        setShowSpinner(false)

        handleUpdateCycleFault({ ...selectedCycleFault, notes: [...selectedCycleFault?.notes, bodyParams] })

        setNewNote("")

        toast.success(t("monitoring.success_add_note"))
      })
      .catch(() => {
        setShowSpinner(false)

        toast.error(t("error.failure_msg"))
      })
  }

  const handleOnUpdateFeedback = (cycleNumber, feedback) => {
    const updatedPredictions = predictions.map(p => {
      if (p.cycleNumber === cycleNumber) {
        return {
          ...p,
          feedback: { ...feedback},
          hasFeedback: true,
        }
      }

      return p
    })

    setPredictions(updatedPredictions)
  }

  const handleClosePredictionsModal = () => {
    if (selectedPrediction.withRequestFeedback) {
      const date = new Date()
      const currentDateFormatted = `${formatDate(date)} ${formatTime(date)}`

      handleUpdateCycleFault({ 
        ...selectedCycleFault, 
        ...newStatus,
        handeled_by_date: newStatus.action_in_progress ? currentDateFormatted : null,
        handeled_by: {
          avatar_url: null,
          email: newStatus.action_in_progress ? props.email : null,
          first_name: newStatus.action_in_progress ? props.firstName : null,
          last_name: newStatus.action_in_progress ? props.lastName : null,
        }
      })
    }

    setSelectedPrediction(null)
  }

  return (
    <div className="cycle-fault-details-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleOnClose()}
        title={t("monitoring.cycle-details")}
      >
        <div className="cycle-fault-modal-content">
          {selectedCycleFault?.action_in_progress && selectedCycleFault?.handeled_by?.first_name && selectedCycleFault?.handeled_by?.last_name &&
            <div className="fault-worked-on">
              <InProgressGreenIcon />
              <p>{`${t("monitoring.working_in_progress")} ${selectedCycleFault?.handeled_by?.first_name} ${selectedCycleFault?.handeled_by?.last_name}`}</p>
            </div>
          }
          <div className="fault-status">
            <div className="status-options">
              <RadioInput
                onChange={() => setNewStatus({pending_for_action: true, action_in_progress: false})}
                checked={newStatus?.pending_for_action}
                text={t("pending")}
              />
              <RadioInput
                onChange={() => setNewStatus({pending_for_action: false, action_in_progress: true})}
                checked={newStatus?.action_in_progress}
                text={t("in_progress")}
              />
              <RadioInput
                onChange={() => setNewStatus({pending_for_action: false, action_in_progress: false})}
                checked={!newStatus?.action_in_progress && !newStatus?.pending_for_action}
                text={t("closed")}
              />
            </div>
            <SimpleButton 
              className="submit-button"
              onClick={() => handleUpdateStatus()}
              disabled={(selectedCycleFault?.pending_for_action === newStatus?.pending_for_action && selectedCycleFault?.action_in_progress === newStatus?.action_in_progress) || showSpinner}
              requiredPermission={cycleWritePermission}
            >
              {t("monitoring.update_status")}
            </SimpleButton>
          </div>
          <div className="product-fault-details-wrapper">
            <div className="image-wrapper">
              <img src={getImageByModel(selectedCycleFault?.model)} className="img" alt={selectedCycleFault?.model}/>
            </div>
            <div className="data-wrapper">
              <div className="identification-wrapper">
                <div className="identification">
                  <div className="product-model">{selectedCycleFault?.model}</div>
                  <div className="product-serial">{`${t("sn")}: ${selectedCycleFault?.serial_number}`}</div>
                </div>
                <div className="cycle-data">
                  <div className="cycle-data-item">
                    <div className="cycle-data-item-title">{t("monitoring.cf_date")}</div>
                    <div className="cycle-data-item-value">{convertToSimpleDate(selectedCycleFault?.date_originated)}</div>
                  </div>
                  <div className="cycle-data-item">
                    <div className="cycle-data-item-title">{t("country")}</div>
                    <div className="cycle-data-item-value">{renderCycleFaultCountry(selectedCycleFault?.country)}</div>
                  </div>
                </div>
              </div>
              <div className="product-state">
                <div className="status-wrapper">
                  <TooltipedIcon
                    icon={<ApertureIcon className={!productIsAperture ? "unavailable" : ""}/>}
                    tooltipText={productIsAperture ? t("products.aperture_associated") : t("products.aperture_not_associated")}
                  />
                  <TooltipedIcon
                    icon={<UpdateIcon className={!hasUpdateAvailable ? "unavailable" : ""}/>}
                    tooltipText={hasUpdateAvailable ? t("products.update_avilable") : t("products.update_not_avilable")}
                  />
                  <TooltipedIcon
                    icon={<VerifiedIcon className={!hasOnlineAccess ? "unavailable" : ""}/>}
                    tooltipText={hasOnlineAccess ? capitalizeFirstLetter(t("device_context.online_access_active")) : capitalizeFirstLetter(t("device_context.online_access_inactive"))}
                  />
                  <TooltipedIcon
                    icon={<CheckDoubleIcon className={!hasPrivacyPolicyChecked ? "unavailable" : ""}/>}
                    tooltipText={hasPrivacyPolicyChecked ? capitalizeFirstLetter(t("device_context.privacy_policy_checked")) : t("products.privacy_policy_not_accepted")}
                  />
                </div>
                <SimpleButton 
                  className="action-button"
                  onClick={() => handleNavigateToReportProblem()}
                >
                  {t("nav.report_problem")}
                </SimpleButton>
              </div>              
            </div>
          </div>
          <div className="cycle-faults-wrapper">
            <div className="title-wrapper">
              <div className="faults-title">
                {!isFirstLoad && `${t("cycle_faults")} (${cycleFaults.length})`}
              </div>
            </div>
            <div className="faults-wrapper">
              {cycleFaults.map((fault, index) =>
                <div
                  key={index}
                  className="fault-item-wrapper" 
                >
                  <div className="title">{`CF-${fault.cycle_fault}`}</div>
                  <div className="content-wrapper">
                    <div className="time">{`${extractMinutesAndSecondsWithDayPeriod(fault.date_originated)} | C#${fault.cycle_number}`}</div>
                    {predictions.find(p => p.cycleNumber === fault.cycle_number)?.hasPredictions && (
                      <div onClick={() => setSelectedPrediction(predictions.find(p => p.cycleNumber === fault.cycle_number))}>
                        <TooltipedIcon
                          icon={
                            <RobotIcon 
                              className={"predictions-svg" + (predictions.find(p => p.cycleNumber === fault.cycle_number)?.hasFeedback ? " prediction-with-feedback" : "") } 
                            />
                          }
                          tooltipText={t("monitoring.coltene_ai")}
                        />
                      </div>
                    )}
                  </div>
                </div>)
              }
              {!isFirstLoad && cycleFaults.length === 0 && 
                <div className="no-faults-wrapper">
                  <p className="disclaimer">{t("monitoring.no_cycle_faults_available")}</p>
                </div>
              }
            </div>
          </div>
          <div className="new-note-wrapper">
            <div className="title">
              {t("monitoring.note")}
            </div>
            <div className="note-wrapper">
              <textarea
                className="note-input"
                value={newNote ?? ""}
                onChange={(e) => setNewNote(e.target.value)}
              />
              <SimpleButton
                className="submit-button"
                onClick={() => handlePostNewNote()}
                disabled={newNote.length === 0 || showSpinner}
                requiredPermission={cycleWritePermission}
              >
                {t("submit")}
              </SimpleButton>
            </div>
          </div>
          <WrappedTabs tabs={tabs.map((tab) => ({ id: tab.key, name: tab.title }))} activeTab={activeTab?.key} changeTab={handleChangeTab} />
          {activeTab?.content && activeTab.content}
        </div>
      </PageModal>
      {selectedPrediction &&
        <CycleFaultPredictionsModal
          selectedPrediction={selectedPrediction}
          onUpdateFeedback={handleOnUpdateFeedback}
          closeModal={handleClosePredictionsModal}
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function stateToProps({ authedUser, userPermissions }) {
  return {
    email: authedUser?.email,
    firstName: authedUser?.firstname,
    lastName: authedUser?.lastname,
    userPermissions
  }
}

export default connect(stateToProps)(ViewFaultDetails)
