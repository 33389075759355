import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { Storage } from "aws-amplify"
import Breadcrumb from "../common/Breadcrumb"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import SearchWithFilter from "../common/SearchWithFilter"
import SimpleButton from "../common/SimpleButton"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import EmptyPageResults from "../common/EmptyPageResults"
import GenericTable from "../common/GenericTable"
import ExpandableText from "../common/ExpandableText"
import ClickOutside from "../common/ClickOutside"
import { toast } from "../common/Toast"
import LoadingSpinner from "../common/LoadingSpinner"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"
import { convertTimeWithTAndZ } from "../../utils/filters/date"
import { CaretDownIcon, DownloadModernIcon, PdfModernIcon } from "../../icons"
import { clearBulletinsMessages, loadBulletins, loadBulletinsFilters } from "../../actions/bulletins"
import "./dealer-bulletins.scss"

const orderingFields = [
  {
    field: "documentTitle",
    order: "asc",
    translationKey: "title_asc",
  },
  {
    field: "documentTitle",
    order: "desc",
    translationKey: "title_desc",
  },
  {
    field: "bulletinType",
    order: "asc",
    translationKey: "bulletin_type_asc",
  },
  {
    field: "bulletinType",
    order: "desc",
    translationKey: "bulletin_type_desc",
  },
  {
    field: "createdAt",
    order: "asc",
    translationKey: "date_asc",
  },
  {
    field: "createdAt",
    order: "desc",
    translationKey: "date_desc",
  }
]

function DealerBulletins(props) {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [showSpinner, setShowSpinner] = useState(true)
  const [bulletins, setBulletins] = useState([])
  const [bulletinsModels, setBulletinsModels] = useState([])
  const [bulletinsTypes, setBulletinsTypes] = useState([])
  const [bulletinsProductTypes, setBulletinsProductTypes] = useState([])
  const [bulletinsLanguages, setBulletinsLanguages] = useState([])
  const [expandedBulletin, setExpandedBulletin] = useState(0)
  const [typeFilter, setTypeFilter] = useState(null)
  const [modelFilter, setModelFilter] = useState(null)
  const [productFilter, setProductFilter] = useState(null)
  const [languageFilter, setLanguageFilter] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [filterQuery, setFilterQuery] = useState(null)
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [orderBy, setOrderBy] = useState(null)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [canLoadMore, setCanLoadMore] = useState(false)
  
  useEffect(() => {
    setBulletins(props.bulletins)
  }, [props.bulletins])
  
  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])
  
  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])
  
  useEffect(() => {
    if (props.bulletinsFilters.length === 0) {
      props.actions.loadBulletinsFilters()
    }
  }, [props.actions])
  
  useEffect(() => { 
    if(props.bulletinsFilters) {
      setBulletinsModels(props.bulletinsFilters.model || [])
      setBulletinsProductTypes(props.bulletinsFilters.productType || [])
      setBulletinsTypes(props.bulletinsFilters.bulletinType || [])
      setBulletinsLanguages(props.bulletinsFilters.documentLanguage || [])
    }
  }, [props.bulletinsFilters])

   useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearBulletinsMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadBulletins(false)
    }
  }, [selectedPage])
  
  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadBulletins(true)
        }

        setShouldApplyFilters(true)
      },
      filterQuery ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }    
  },[filterQuery, orderBy, modelFilter, typeFilter, productFilter, languageFilter])
  
  const handleResetFilters = () => {
    setModelFilter(null)
    setTypeFilter(null)
    setProductFilter(null)
    setLanguageFilter(null)
  }

  async function handleDownload(filePath, fileName) {
    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(`${filePath}${fileName}`)
    window.open(result, "_blank")
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingFields.find(o => o.field === orderingField.field && o.order === orderingField.order))
  }
  
  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }
  
  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    setOrderBy(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }
  
  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy !== null ? t(`sortable_context.${orderBy?.translationKey}`) : t("select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )
  
  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`sortable_context.${orderingField.translationKey}`)}
        </div>
      ))}
    </div>
  )

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }
  
  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(null)
    }
  }
  
  const handleLoadBulletins = (withReset) => {
    let queryParams = {
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    if (orderBy) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }
    
    if (filterQuery) {
      queryParams = { ...queryParams, query: filterQuery }
    }
    
    if (modelFilter) {
      queryParams = { ...queryParams, model: modelFilter}
    }
    
    if (typeFilter) {
      queryParams = { ...queryParams, bulletinType: typeFilter}
    }
    
    if (productFilter) {
      queryParams = { ...queryParams, productType: productFilter}
    }
    
    if (languageFilter) {
      queryParams = { ...queryParams, documentLanguage: languageFilter}
    }
    
    props.actions.loadBulletins(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }
  
  return (
    <div className={`dealer-bulletins-wrapper`}>
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.dealer_bulletins")} />
      <div className="separator" />
      <div className="actions-wrapper">
        <SearchWithFilter
          onSearch={(e) => handleSearch(e)}
          showFiltersModal={() => setShowFiltersModal(true)}
          onResetFilters={() => handleResetFilters()}
          showCounterBadge={modelFilter || typeFilter || productFilter || languageFilter}
          counterBadge={(modelFilter && 1 ) + (typeFilter && 1) + (productFilter && 1) + (languageFilter && 1)}
        />
        {isMobile && bulletins.length > 0 && (
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        )}
      </div>

      <div>
        {!isMobile && bulletins.length > 0 && (
          <>
            <GenericTable
              data={bulletins}
              headers={[
                {
                  title: "",
                },
                {
                  title: t("title"),
                  orderKey: "documentTitle",
                },
                {
                  title: t("bulletin_type"),
                  orderKey: "bulletinType",
                },
                {
                  title: t("dealer-portal.updated"),
                  orderKey: "createdAt",
                },
              ]}
              keys={["download", "documentTitle", "bulletinType", "createdAt"]}
              keyRenderer={{
                download: (item) => {
                  return (
                    <div className="image-wrapper">
                      <DownloadModernIcon />
                    </div>
                  )
                },
                documentTitle: (item) => {
                  return (
                    <div className="title-wrapper">
                      <div className="pdf-icon"><PdfModernIcon /></div>
                      <div>{item.documentTitle}</div>
                    </div>
                  )
                },
                bulletinType: (item) => {
                  return (
                    <div className="description-wrapper">
                      <ExpandableText text={item.bulletinType} />
                    </div>
                  )
                },
                createdAt: (item) => {
                  return (
                    <div className="updated-wrapper">
                      <ExpandableText text={convertTimeWithTAndZ(item.createdAt)} />
                    </div>
                  )
                },
              }}
              activeSort={orderBy}
              onSort={(orderingField) => handleSort(orderingField)}
              onRowClick={(bulletin) => {handleDownload(bulletin.filePath, bulletin.fileName)}}
            />
          </>
        )}

        {isMobile && bulletins.length > 0 && (
          <>
            <div className="cards-container">
              {bulletins.map((row, index) => {
                return (
                  <div
                    className={"card" + (expandedBulletin === index ? " with-padding-bottom" : "")}
                  >
                    <div 
                      className="card-item full-row" 
                      onClick={(e) => {
                        e.stopPropagation()
                        handleDownload(row.filePath, row.fileName)
                      }}>
                      <div className="card-item-title">
                        <button className="download">
                          <DownloadModernIcon />
                        </button>
                      </div>
                      <div className={"card-item-body"}>
                        <div className="pdf-icon">
                          <PdfModernIcon />
                        </div>
                        <div className="value">{row.documentTitle}</div>
                      </div>
                    </div>
                    {expandedBulletin === index && (
                      <>
                        <div className="card-item">
                          <div className="card-item-title">{t("bulletin_type")}</div>
                          <div className="card-item-body">{row.bulletinType}</div>
                        </div>
                        <div className="card-item">
                          <div className="card-item-title">{t("dealer-portal.updated")}</div>
                          <div className="card-item-body">{convertTimeWithTAndZ(row.createdAt)}</div>
                        </div>
                      </>
                    )}
                    {expandedBulletin !== index && (
                      <div className="expander-wrapper" onClick={() => setExpandedBulletin(index)} >
                        <CaretDownIcon />
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
      
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("model"),
              type: FilterType.singleSelect,
              dataset: bulletinsModels,
              input: modelFilter,
              output: (selectedModelFilter) => {
                setModelFilter(selectedModelFilter)
              },
            },
            {
              name: t("bulletin_type"),
              type: FilterType.singleSelect,
              dataset: bulletinsTypes,
              input: typeFilter,
              output: (selectedTypeFilter) => {
                setTypeFilter(selectedTypeFilter)
              },
            },
            {
              name: t("product_type"),
              type: FilterType.singleSelect,
              dataset: bulletinsProductTypes,
              input: productFilter,
              output: (selectedProductFilter) => {
                setProductFilter(selectedProductFilter)
              },
            },
            {
              name: t("language"),
              type: FilterType.singleSelect,
              dataset: bulletinsLanguages?.map((lang) => `${lang}-lang`),
              input: languageFilter ? `${languageFilter}-lang` : null,
              output: (selectedLanguageFilter) => {
                const language = selectedLanguageFilter?.split("-")[0] || null
                setLanguageFilter(language)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}

      {!showSpinner && !isFirstLoad && bulletins.length === 0 && (
        <EmptyPageResults className="with-margin-top" title={t("no_data_found")} subtitle={t("cannot_find_matching_search")} />
      )}
      
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      
      {canLoadMore && bulletins.length > 0 && (
        <div className="buttons-wrapper">
          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
            {t('load_more')}
          </SimpleButton>
        </div>)
      }
    </div>
  )
}

function stateToProps({ bulletins }) {
  return {
    bulletins: bulletins?.bulletins || [],
    bulletinsFilters: bulletins?.bulletinsFilters || [],
    isLoading: bulletins?.isLoading,
    canLoadMore: bulletins?.canLoadMore,
    errorMessage: bulletins?.errorMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadBulletins,
        loadBulletinsFilters,
        clearBulletinsMessages
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(DealerBulletins)