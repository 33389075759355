import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { getLanguageFromRegionalLanguage } from '../../../../utils/languages'
import './video-builder-toolbox.scss'

const VideoBuilderToolbox = ({ id, href, translation, onUpdate }) => {
  const { t } = useTranslation()
  const [link, setLink] = useState('')
  const [translatedLink, setTranslatedLink] = useState({})
  const emptyTranslation = {
    fr_CA: '',
    de_DE: '',
    fr_FR: '',
    it_IT: '',
    es_ES: '',
    es_MX: '',
    pt_BR: '',
  }

  useEffect(() => {
    setLink(href || '')
    setTranslatedLink({ ...emptyTranslation, ...translation })
  },[href, translation])

  return (
    <div className="link-toolbox">
      <div className="input-wrapper">
        <p className="input-option">{t("link")}</p>
        <input
          className={"input-item"}
          value={link}
          onChange={(e) => {
            setLink(e.target.value)
            onUpdate(id, { href: e.target.value })
          }}
        />
      </div>
      <div className="title">{t("translations")}</div>
      {Object.keys(translatedLink).map((language) => 
        <div key={language} className="input-wrapper">
          <p className="input-option">{t(getLanguageFromRegionalLanguage(language))}</p>
          <input
            className={"input-item"}
            value={translatedLink[language]}
            onChange={(e) => {
              setTranslatedLink({ ...translatedLink, [language]: e.target.value})
              onUpdate(id, { translation: { ...translatedLink, [language]: e.target.value }})
            }}
          />
        </div>
      )}
    </div>
  )
}

export default VideoBuilderToolbox