const Roles = {
  dashbord: {
    route: "/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dentist",
      "dealer_technician",
      "marketing",
    ],
  },
  documents: {
    route: "/documents/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dentist",
      "dealer_technician",
      "marketing",
    ],
  },
  priceLists: {
    route: "/dealers/price-list/",
    roles: [
      "dealer_technician",
      "dealer_admin",
    ],
  },
  members: {
    route: "/members/",
    roles: [
      "dealer_admin",
    ],
  },
  // digitalAssets: {
  //   route: "/dealers/digital-assets/",
  //   roles: [
  //     "dealer_technician",
  //     "dealer_admin",
  //   ],
  // },
  dealerBulletins: {
    route: "/dealers/bulletins/",
    roles: [
      "dealer_technician",
      "dealer_admin",
    ],
  },
  videos: {
    route: "/videos/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dentist",
      "dealer_technician",
      "marketing",
    ],
  },
  reports: {
    route: "/reports/",
    roles: ["dentist"],
  },
  instrumentTracking: {
    route: "/instrument-tracking/",
    roles: ["dentist"],
  },
  remoteAccess: {
    route: "/remote-access/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
  productNotes: {
    route: "/products/:id/notes",
    roles: ["admin", "administrator", "scican_technician", "scican_customer_service", "embedded_software_group", "it", "sales", "business_unit","marketing"],
  },
  product: {
    route: "/products/:id",
    roles: ["admin", "administrator", "scican_technician", "scican_customer_service", "embedded_software_group", "it", "sales", "business_unit", "dentist","marketing"],
  },
  myProducts: {
    route: "/my-products/",
    roles: ["dentist"],
  },
  colteneStore: {
    route: "/coltene-store/",
    roles: ["dentist"],
  },
  productDetails: {
    route: "/coltene-store/:id",
    roles: ["dentist"],
  },
  previewProductDetails: {
    route: "/preview/:id",
    roles: ["admin"],
  },
  adminStore: {
    route: "/admin-store/",
    roles: ["admin", "administrator"],
  },
  adminOrderHistory: {
    route: "/admin-order-history/",
    roles: ["admin", "administrator"],
  },
  adminOrderDetails: {
    route: "/admin-order-history/:id",
    roles: ["admin", "administrator"],
  },
  accountDetails: {
    route: "/my-account/profile",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dentist",
      "dealer_technician",
      "marketing",
    ],
  },
  notifications: {
    route: "/my-account/notifications",
    roles: ["dentist"],
  },
  paymentMethods: {
    route: "/my-account/payment-methods",
    roles: ["dentist"],
  },
  userSubscriptions: {
    route: "/my-account/my-subscriptions",
    roles: ["dentist"],
  },
  userOrders: {
    route: "/my-account/my-orders",
    roles: ["dentist"],
  },
  userCompany: {
    route: "/my-account/my-organization",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dentist",
      "dealer_technician",
      "marketing",
    ],
  },
  monitoring: {
    route: "/monitoring/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      // 'sales',
      "business_unit",
      "marketing",
    ],
  },
  accountsManagement: {
    route: "/account-management/",
    roles: ["admin", "administrator"],
  },
  reportProblem: {
    route: "/report-problem/",
    roles: ["admin", "administrator", "scican_technician", "scican_customer_service", "embedded_software_group", "it", "sales", "business_unit","marketing"],
  },
  reportProblemForm: {
    route: "/report-problem/report-form",
    roles: ["admin", "administrator", "scican_technician", "scican_customer_service", "embedded_software_group", "it", "sales", "business_unit","marketing"],
  },
  spareParts: {
    route: "/spareparts",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
  firmware: {
    route: "/firmware",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },

  troubleshoot: {
    route: "/troubleshoot/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
  remoteAccessSession: {
    route: "/remote-access-session/",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
  warranty: {
    route: "/warranty/",
    roles: ["admin", "administrator", "scican_customer_service", "sales", "business_unit"],
  },
  events: {
    route: "/events/",
    roles: ["admin", "administrator"],
  },
  policies: {
    route: "/policies/",
    roles: ["admin", "administrator"],
  },
  businessRules: {
    route: "/business-rules",
    roles: ["admin", "administrator"],
  },
  services: {
    route: "/services",
    roles: ["admin", "administrator"],
  },
  users: {
    route: "/users-management",
    roles: ["admin", "administrator"],
  },
  companies: {
    route: "/organizations-management",
    roles: ["admin", "administrator"],
  },
  myColteneTemplates: {
    route: "/mycoltene-templates",
    roles: ["admin", "administrator"],
  },
  uploads: {
    route: "/uploads",
    roles: ["admin", "administrator"],
  },
  pageNotFound: {
    route: "/page-not-found",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dentist",
      "dealer_technician",
      "marketing",
    ],
  },
}

const ModuleRoles = {
  troubleshoot: {
    module: "Troubleshoot",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
  tsb: {
    module: "tTechnicalServiceBulletin",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
  remoteAccess: {
    module: "RemoteAccess",
    roles: [
      "admin",
      "administrator",
      "scican_technician",
      "scican_customer_service",
      "embedded_software_group",
      "it",
      "sales",
      "business_unit",
      "dealer_technician",
      "marketing",
    ],
  },
}

export { Roles, ModuleRoles }
