import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json?v=' + new Date().getTime()
    },
    //lng: 'en',
    nonExplicitSupportedLngs: true,
    fallbackLng: 'en-CA',
    debug: process.env.REACT_APP_STAGE === "dev",
    //supportedLngs: ["en-CA", "fr-CA", "de-DE", "zh-CN", "ja-JP", "es-MX"],

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })


export default i18n;