import React, { useState, createRef, useLayoutEffect, useEffect } from "react"
import { useTranslation } from 'react-i18next'
import "./expandable-text.scss"

const ExpandableText = ({ text }) => {
  const { t } = useTranslation()
  const [isExpanded, setIsExpanded] = useState(false)
  const [isExpandable, setIsExpandable] = useState(false)
  const ref = createRef()

  useLayoutEffect(() => {
    if (ref?.current) {
      setIsExpandable(ref.current.clientWidth < ref.current.scrollWidth)
    }
  }, [ref])

  useEffect(() => {
    function handleResize() {
      setIsExpandable(false)
      setIsExpanded(false)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    setIsExpandable(false)
  },[text])

  return (
    <div className={"expandable-text-wrapper" + (isExpanded ? " wrapper-expanded" : "") }>
      <div ref={ref} className={"expandable-text" + (isExpanded ? " text-expanded" : "")}>
        <span className="text">{text}</span>
        {isExpanded &&
          <span className="expand-action" onClick={(e) => { e.stopPropagation(); setIsExpanded(false)} }>
            {t("less")}
          </span>
        }
      </div>
      {isExpandable && !isExpanded && (
        <span className="expand-action" onClick={(e) => { e.stopPropagation(); setIsExpanded(true)} }>
          {t("more")}
        </span>
      )}
    </div>
  )
}

export default ExpandableText