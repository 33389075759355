import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { NavigationArrowLeft } from "../../../icons";
import { keyboardEvents } from "../../../utils/keyboardEvents";
import "./breadcrumb.scss";

const Breadcrumb = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [path, setPath] = useState(props.path ? props.path.split("/").filter((it) => it) : null);

  useEffect(() => {
    setPath(props.path ? props.path.split("/").filter((it) => it) : null);
  }, [props.path]);

  const redirect = (item = null) => {
    if (item && props.path && props.path.includes(item)) {
      history.push(`${props.path.split(item)[0]}${item}`);
    } else {
      history.push("/");
    }
  };

  return (
    <div className="breadcrumb d-flex cursor-pointer">
      {props.withBackNavigation && 
        <div 
          className="breadcrumb-item back-wrapper" 
          onClick={() => history.goBack()}
          tabIndex={0}
          role="button"
          onKeyDown={(e) => e.key === keyboardEvents.ENTER && history.goBack()}
        >
          <NavigationArrowLeft className="back-icon" />
          <div className="back-text">{t("back")}</div>
          <div className="back-separator">|</div>
        </div>
      }
      <div 
        className={"breadcrumb-item home" + (path.length === 0 ? " active" : "")} 
        onClick={() => redirect()}
        tabIndex={0}
        role="button"
        onKeyDown={(e) => e.key === keyboardEvents.ENTER && redirect()}
      >
        {t("nav.home")}
      </div>
      {path?.length > 0 && path.map((item, index) => 
        <div className={"d-flex"} key={index}>
          <div className="ml-5 mr-5">/</div>
          <div 
            className={path.length - 1 === index ? "breadcrumb-item active" : "breadcrumb-item inactive"}
          >
            {props.pathName
              ? index + 1 === path.length
                ? props.pathName
                : t([`nav.${item.replaceAll("-","_")}`, item])
              : /\d/.test(item) || item.indexOf("AJ") === 0
              ? item?.replace(/%20/g, " ")
              : t([`nav.${item.replaceAll("-","_")}`, item])
            }
          </div>
        </div>
      )}
    </div>
  );
};

Breadcrumb.propTypes = {
  path: PropTypes.string,
};

export default Breadcrumb;
