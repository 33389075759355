const adminModulesPermission = "module:administration"
export const organizationAdminModulesPermission = "module:organization-admin"
const endUserSiteAccessPermission = "site:end-user-site-access"
export const dealerSiteAccessPermission = "site:dealer-site-access"
const colteneSiteAccessPermission = "site:coltene-site-access"
export const noSiteAccessPermission = "site:no-access"
const notificationsPermission = "page:notifications"
const storePermission = "page:store"
const instrumentTrackingPermission = "page:instrument-tracking"
export const myOrganizationPermission = "page:my-organization"
export const firmwarePermission = "page:firmware"
export const productsPermission = "page:products"
export const troubleshootPermission = "page:troubleshoot"
const documentProtectedPermission = "document:protected"
export const usersWritePermission = "users:write"
export const rolesWritePermission = "roles:write"
export const accountsWritePermission = "accounts:write"
export const policyWritePermission = "policy:write"
export const policyTypeWritePermission = "policy-type:write"
export const adminNotificationWritePermission = "admin-notification:write"
export const adminStoreWritePermission = "admin-store:write"
export const servicesWritePermission = "services:write"
export const businessRulesWritePermission = "business-rules:write"
export const reportProblemWritePermission = "report-problem:write"
export const videosWritePermission = "videos:write"
export const cycleWritePermission = "cycle:write"
export const registerProductWritePermission = "register-product:write"
export const profileWritePermission = "profile:write"
export const userNotificationWritePermission = "user-notification:write"
export const userProductsWritePermission = "user-products:write"
export const productsWritePermission = "products:write"
export const companiesWritePermission = "companies:write"
export const branchesWritePermission = "branches:write"
export const membersWritePermission = "members:write"
export const organizationRequestsWritePermission = "organization-requests:write"
export const priceListWritePermission = "price-list:write"
export const colteneTemplatesWritePermission = "coltene-templates:write"
export const usersDeletePermission = "users:delete"
export const rolesDeletePermission = "roles:delete"
export const accountsDeletePermission = "accounts:delete"
export const policyDeletePermission = "policy:delete"
export const policyTypeDeletePermission = "policy-type:delete"
export const adminNotificationDeletePermission = "admin-notification:delete"
export const adminStoreDeletePermission = "admin-store:delete"
export const servicesDeletePermission = "services:delete"
export const businessRulesDeletePermission = "business-rules:delete"
export const reportProblemDeletePermission = "report-problem:delete"
export const videosDeletePermission = "videos:delete"
export const userNotificationDeletePermission = "user-notification:delete"
export const enhancedEventLogPermission = "page:enhanced_event_log"
export const advancedTroubleshootingPermission = "page:advanced-troubleshooting"
export const productsDeletePermission = "products:delete"
export const companiesDeletePermission = "companies:delete"
export const branchesDeletePermission = "branches:delete"
export const membersDeletePermission = "members:delete"
export const organizationRequestsDeletePermission = "organization-requests:delete"
export const colteneTemplatesDeletePermission = "coltene-templates:delete"
export const bellNotificationsPermissions = "bell-notification:read"
export const deviceNotificationsReadPermissions = "device-notification:read"

export const hasAdminModulesPermission = (permissions) => {
  return checkPermission(permissions, adminModulesPermission)
}

export const hasOrganizationAdminModulesPermission = (permissions) => {
  return checkPermission(permissions, organizationAdminModulesPermission)
}

export const hasEndUserSiteAccess = (permissions) => {
  return checkPermission(permissions, endUserSiteAccessPermission)
}

export const hasDealerSiteAccess = (permissions) => {
  return checkPermission(permissions, dealerSiteAccessPermission)
}

export const hasColteneSiteAccess = (permissions) => {
  return checkPermission(permissions, colteneSiteAccessPermission)
}

export const hasNoSiteAccess = (permissions) => {
  return checkPermission(permissions, noSiteAccessPermission)
}

export const hasNotificationsPermission = (permissions) => {
  return checkPermission(permissions, notificationsPermission)
}

export const hasStorePermission = (permissions) => {
  return checkPermission(permissions, storePermission)
}

export const hasDocumentProtectedPermission = (permissions) => {
  return checkPermission(permissions, documentProtectedPermission)
}

export const hasInstrumentTrackingPermission = (permissions) => {
  return checkPermission(permissions, instrumentTrackingPermission)
}

export const hasEnhancedEventLogPermission = (permissions) => {
  return checkPermission(permissions, enhancedEventLogPermission)
}

export const hasAdvancedTroubleshootingPermission = (permissions) => {
  return checkPermission(permissions, advancedTroubleshootingPermission)
}

export const checkPermission = (permissions, permission) => {
  return Array.isArray(permissions) && permissions.includes(permission)
}