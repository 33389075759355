import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import Sterilization from "./Sterilization";
import RegisterProductModern from '../RegisterProductModern';
import DashboardHeader from "../common/DashboardHeader/DashboardHeader";
import SimpleButton from '../common/SimpleButton';
import Breadcrumb from "../common/Breadcrumb";
import "./reports.scss";

export default function Reports() {
  const { t } = useTranslation();
  const location = useLocation();
  const canGoBack = location.state && location.state.withBackNavigation;
  const [showRegisterProduct, setShowRegisterProduct] = useState(false);

  return (
    <div className="reports-wrapper">
      <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack}/>
      <DashboardHeader 
        headerText={t("nav.reports")}
      >
        <SimpleButton 
          className="submit-button" 
          onClick={() => setShowRegisterProduct(true)}
        >
          {t('device_context.register_product')}
        </SimpleButton>
      </DashboardHeader>
      <div className="separator" />
      <Sterilization />
      {showRegisterProduct && (
        <RegisterProductModern
          closeModal={() => setShowRegisterProduct(false)}
        />
      )}
    </div>
  );
}
