const PermissionsDescription = [
  {
    permission: "page:dashboard",
    translationKey: "dashboard"
  },
  {
    permission: "page:documents",
    translationKey: "documents"
  },
  {
    permission: "page:enhanced_event_log",
    translationKey: "enhanced_event_log"
  },
  {
    permission: "page:firmware",
    translationKey: "firmware"
  },
  {
    permission: "page:monitoring",
    translationKey: "monitoring"
  },
  {
    permission: "page:page_not_found",
    translationKey: "page_not_found"
  },
  {
    permission: "page:product",
    translationKey: "product"
  },
  {
    permission: "page:product_firmware",
    translationKey: "product_fw"
  },
  {
    permission: "page:product_notes",
    translationKey: "product_notes"
  },
  {
    permission: "page:products",
    translationKey: "products"
  },
  {
    permission: "page:register_product",
    translationKey: "register_product"
  },
  {
    permission: "page:remote_access",
    translationKey: "remote_access"
  },
  {
    permission: "page:remote_access_session",
    translationKey: "remote_access_session"
  },
  {
    permission: "page:report_problem",
    translationKey: "report_problem"
  },
  {
    permission: "page:report_problem_form",
    translationKey: "report_problem_form"
  },
  {
    permission: "page:reports",
    translationKey: "reports"
  },
  {
    permission: "page:spare_parts",
    translationKey: "spareparts"
  },
  {
    permission: "page:troubleshoot",
    translationKey: "troubleshoot"
  },
  {
    permission: "page:videos",
    translationKey: "videos"
  },
  {
    permission: "page:warranty",
    translationKey: "warranty"
  },
  {
    permission: "page:store",
    translationKey: "store"
  },
  {
    permission: "page:accounts",
    translationKey: "accounts"
  },
  {
    permission: "page:store-admin",
    translationKey: "store_admin"
  },
  {
    permission: "page:system-events",
    translationKey: "system_events"
  },
  {
    permission: "page:notifications",
    translationKey: "notifications"
  },
  {
    permission: "page:business-rules",
    translationKey: "business_rules"
  },
  {
    permission: "page:services",
    translationKey: "services"
  },
  {
    permission: "page:profile_settings",
    translationKey: "profile_settings"
  },
  {
    permission: "page:policy",
    translationKey: "policy"
  },
  {
    permission: "page:recycle-bin",
    translationKey: "recycle_bin"
  },
  {
    permission: "page:time-frame",
    translationKey: "time_frame"
  },
  {
    permission: "page:product-notification",
    translationKey: "product_notification"
  },
  {
    permission: "page:user-product",
    translationKey: "user_product"
  },
  {
    permission: "page:user-product-notification",
    translationKey: "user_product_notification"
  },
  {
    permission: "page:user-products",
    translationKey: "user_products"
  },
  {
    permission: "page:profile_settings",
    translationKey: "profile_settings"
  },
  {
    permission: "page:user-warranty",
    translationKey: "user_warranty"
  },
  {
    permission: "page:instrument-tracking",
    translationKey: "instrument_tracking"
  },
  {
    permission: "page:users",
    translationKey: "users"
  },
  {
    permission: "page:roles",
    translationKey: "roles"
  },
  {
    permission: "page:admin-device-notification",
    translationKey: "admin_device_notification"
  },
  {
    permission: "page:price-list",
    translationKey: "price_list"
  },
  {
    permission: "page:bulletins",
    translationKey: "bulletins"
  },
  {
    permission: "page:agreements",
    translationKey: "agreements"
  },
  {
    permission: "page:advanced-troubleshooting",
    translationKey: "advanced_troubleshooting"
  },
  {
    permission: "page:companies",
    translationKey: "companies"
  },
  {
    permission: "page:members",
    translationKey: "members"
  },
  {
    permission: "page:my-organization",
    translationKey: "my_organization"
  }
]

export const getPermissionTitleKey = (permission) => {
  const permissionDescription = PermissionsDescription.find(p => p.permission === permission)
  return permissionDescription ? permissionDescription.translationKey : ''
}

export const getPermissionDescriptionKey = (permission) => {
  const permissionDescription = PermissionsDescription.find(p => p.permission === permission)
  return permissionDescription ? `permission_context.${permissionDescription.translationKey}_description` : ''
}