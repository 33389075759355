import React, { Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import AddEditBranch from "./AddEditBranch"
import FiltersModal, { FilterType } from "../common/FiltersModal"
import SearchWithFilter from "../common/SearchWithFilter"
import EmptyPageResults from "../common/EmptyPageResults"
import LoadingSpinner from "../common/LoadingSpinner"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from "../common/SimpleButton"
import GenericTable from "../common/GenericTable"
import ActionsMenu from "../common/ActionsMenu"
import ConfirmationModal from '../common/ConfirmationModal'
import { toast } from "../common/Toast"
import {
  MenuKebabVerticalIcon,
  CaretDownIcon,
  MenuKebabVerticalBackgroundIcon,
} from "../../icons"
import { loadBranches, removeBranch, clearBranchesMessages, loadBranchesCountryOptions } from "../../actions/branches"
import { branchesDeletePermission } from "../../utils/permissionValidation"
import { useWindowSize } from "../../libs/hooks"
import { useDeepCompareEffect } from "../../hooks/useDeepCompareEffect"

const orderingFields = [
  {
    field: "branchName",
    order: "asc",
    translationKey: "branch_asc",
  },
  {
    field: "branchName",
    order: "desc",
    translationKey: "branch_desc",
  },
  {
    field: "companyName",
    order: "asc",
    translationKey: "organization_asc",
  },
  {
    field: "companyName",
    order: "desc",
    translationKey: "organization_desc",
  },
  {
    field: "members",
    order: "asc",
    translationKey: "members_asc",
  },
  {
    field: "members",
    order: "desc",
    translationKey: "members_desc",
  }
]

const Branches = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [branchesTableData, setBranchesTableData] = useState([])
  const [shouldApplyFilters, setShouldApplyFilters] = useState(true)
  const [countriesFilter, setCountriesFilter] = useState([])
  const [searchParam, setSearchParam] = useState(null)
  const [orderBy, setOrderBy] = useState(null)
  const [showSpinner, setShowSpinner] = useState(null)
  const [showFiltersModal, setShowFiltersModal] = useState(false)
  const [showActionsMenu, setShowActionsMenu] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [showAddEditBranchModal, setShowAddEditBranchModal] = useState(false)
  const [showDeleteBranchConfirmModal, setShowDeleteBranchConfirmModal] = useState(false)
  const [branchIdToDelete, setBranchIdToDelete] = useState(null)
  const [companyIdToDelete, setCompanyIdToDelete] = useState(null)
  const [expandedBranch, setExpandedBranch] = useState(0)
  const [selectedBranch, setSelectedBranch] = useState(null)

  useEffect(() => {
    if (props.countryOptions.length === 0) {
      props.actions.loadBranchesCountryOptions()
    }    
  }, [props.actions])

  useEffect(() => {
    const tableData = props.branches.map((branch) => {
      return {
        identifier: branch.id,
        branch: branch.branchName,
        company: branch.companyName,
        members: branch.members,
        actions: [
          {
            label: "edit",
            onActionClick: (e) => { e.stopPropagation(); handleOpenEditBranch(branch.id) },
          },
          {
            label: "remove",
            onActionClick: (e) => { e.stopPropagation(); showConfirmationModal(branch.id, branch.companyId) },
            requiredPermission: branchesDeletePermission,
          },
        ]
      }
    })

    setBranchesTableData(tableData)
  }, [props.branches])

  useEffect(() => {
    setCanLoadMore(props.canLoadMore)
  }, [props.canLoadMore])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  useEffect(() => {
    if (props.errorMessage) {
      toast.error(props.errorMessage)

      props.actions.clearBranchesMessages()
    }
  }, [props.errorMessage])

  useEffect(() => {
    if (props.successMessage) {
      toast.success(props.successMessage)

      props.actions.clearBranchesMessages()
    }
  }, [props.successMessage])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadBranches(false)
    }
  }, [selectedPage])

  useDeepCompareEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        if (shouldApplyFilters) {
          setSelectedPage(1)
          handleLoadBranches(true)
        }

        setShouldApplyFilters(true)
      },
      searchParam ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [searchParam, countriesFilter, orderBy])

  const handleLoadBranches = (withReset) => {
    let queryParams = createQueryParams()

    queryParams = {
      ...queryParams,
      offset: withReset ? 0 : (selectedPage - 1) * process.env.REACT_APP_PAGINATION_SIZE,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    props.actions.loadBranches(queryParams, withReset)
      .then(() => setIsFirstLoad(false))
  }

  const handleSearch = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSearchParam(value)
    } else if (value?.length === 0 && searchParam?.length > 0) {
      setShowSpinner(true)
      setSearchParam(value)
    }
  }

  const handleSort = (orderingField) => {
    setOrderBy(orderingFields.find(o => o.field === orderingField.field && o.order === orderingField.order))
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleResetFilters = () => {
    setCountriesFilter([])
  }

  const createQueryParams = () => {
    let queryParams = {}

    if (searchParam) {
      queryParams = { ...queryParams, query: searchParam }
    }

    if (countriesFilter.length > 0) {
      queryParams = { ...queryParams, country: countriesFilter }
    }

    if (orderBy?.field && orderBy?.order) {
      queryParams = { ...queryParams, orderBy: orderBy.field, order: orderBy.order }
    }

    return queryParams
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectOrderByOptions = (orderingField, toggleVisibility) => {
    handleSort(orderingField)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectOrderByOptionsInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className={"select-input"}>
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}>
        <div className="d-flex flex-align-center h-100">{orderBy ? t(`sortable_context.${orderBy.translationKey}`) : t("select_ordering")}</div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectOrderByDropdownOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {orderingFields?.map((orderingField, index) => (
        <div
          className={"option cursor-pointer no-wrap" + (orderingField === orderBy ? " selected-option" : "")}
          key={index}
          onClick={() => selectOrderByOptions(orderingField, toggleVisibility)}
        >
          {t(`sortable_context.${orderingField.translationKey}`)}
        </div>
      ))}
    </div>
  )

  const handleOpenEditBranch = (branchId) => {
    const branch = props.branches.find((branch) => branch.id === branchId)

    if (branch) {
      setShowAddEditBranchModal(true)
      setSelectedBranch(branch)
    }
  }

  const showConfirmationModal = (branchId, companyId) => {
    setBranchIdToDelete(branchId)
    setCompanyIdToDelete(companyId)
    setShowDeleteBranchConfirmModal(true)
  }

  const handleDeleteBranch = () => {
    setShowDeleteBranchConfirmModal(false)
    props.actions.removeBranch(companyIdToDelete, branchIdToDelete)
  }

  const closeAddEditModal = () => {
    setShowAddEditBranchModal(false)
    setSelectedBranch(null)
  }
 
  const toggleShowActionsMenu = (identifier) => {
    setShowActionsMenu(showActionsMenu === identifier ? null : identifier)
  }

  return (
    <Fragment>
      <div className="branches-wrapper">
        <div className="actions-wrapper">
          <SearchWithFilter
            onSearch={(e) => handleSearch(e)}
            showFiltersModal={() => setShowFiltersModal(true)}
            onResetFilters={() => handleResetFilters()}
            showCounterBadge={countriesFilter.length > 0}
            counterBadge={countriesFilter.length}
          />
          {/*A.S: Adding a branch is disabled for now */}
          {/* <SimpleButton
            className="action-button" 
            onClick={() => setShowAddEditBranchModal(true)}
            disabled={showSpinner}
          >
            {t('organization_context.add_branch')}
          </SimpleButton> */}
        </div>
        {isMobile && branchesTableData.length > 0 &&
          <div className="order-by-mobile-wrapper">
            <ClickOutside 
              itemRef="click-outside-wrapper" 
              eventItem={selectOrderByOptionsInput} 
              toDisplayItem={selectOrderByDropdownOptions} 
            />
          </div>
        }
        {!isMobile && branchesTableData.length > 0 && 
          <GenericTable
            data={branchesTableData}
            headers={[
              {
                title: t("branch"),
                orderKey: 'branchName'
              },
              {
                title: t("organization_context.company"),
                orderKey: 'companyName'
              },
              {
                title: t("nav.members"),
                orderKey: 'members'
              },
              {
                title: ''
              },
            ]}
            keys={[
              'branch',
              'company',
              'members',
              'action',
            ]}
            keyRenderer={{
              action: (item) => {
                return (
                  <div className="table-actions-wrapper" onClick={(e) => { e.stopPropagation(); toggleShowActionsMenu(item["identifier"]) }}>
                    {showActionsMenu === item["identifier"] ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                    {showActionsMenu === item["identifier"] &&
                      <ActionsMenu
                        setHideMenu={() => setShowActionsMenu(null)}
                        actions={item.actions}
                        isLoading={showSpinner}
                      />
                    }
                  </div>
                )
              }
            }}
            onRowClick={(branch) => handleOpenEditBranch(branch.identifier)}
            activeSort={orderBy}
            onSort={(orderingField) => handleSort(orderingField)}
            isLoading={showSpinner}
          />
        }
        {isMobile && branchesTableData.map((branch, index) =>
          <div 
            key={index}
            className="card" 
            onClick={() => setExpandedBranch(index)}
          >
            <div className={"card-item" + (expandedBranch !== index ? " align-center" : "")}>
              <div className="card-item-title">{t("branch")}</div>
              <div className={"card-item-body" + (expandedBranch !== index ? " align-center" : "")}>
                <div>{branch.branch}</div>
                <div 
                  className="card-actions"
                  onClick={(e) => { e.stopPropagation(); setShowActionsMenu(index) }}
                >
                  {showActionsMenu === index ? <MenuKebabVerticalBackgroundIcon /> : <MenuKebabVerticalIcon />}
                  {showActionsMenu === index &&
                    <ActionsMenu
                      setHideMenu={() => setShowActionsMenu(null)}
                      actions={branch.actions}
                      isLoading={showSpinner}
                    />
                  }
                </div>
              </div>
            </div>
            {expandedBranch === index && (
              <>
                <div className="card-item">
                  <div className="card-item-title">{t("organization_context.company")}</div>
                  <div className="card-item-body">{branch.company}</div>
                </div>
                <div className="card-item">
                  <div className="card-item-title">{t("nav.members")}</div>
                  <div className="card-item-body">{branch.members}</div>
                </div>
              </>
            )}
          </div>)
        }
        {!showSpinner && !isFirstLoad && branchesTableData.length === 0 && (
          <EmptyPageResults
            title={t("organization_context.no-branches-found")}
            subtitle={t("cannot_find_matching_search")}
          />
        )}
        {canLoadMore && (
          <div className="buttons-wrapper">
            <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
              {t("load_more")}
            </SimpleButton>
          </div>
        )}
      </div>
      {showFiltersModal && (
        <FiltersModal
          filters={[
            {
              name: t("country"),
              type: FilterType.multiSelect,
              dataset: props.countryOptions,
              input: countriesFilter,
              output: (filterCountries) => {
                setCountriesFilter(filterCountries)
              },
            },
          ]}
          shouldFilter={(shouldApply) => setShouldApplyFilters(shouldApply)}
          resetFilters={() => handleResetFilters()}
          closeFilters={() => setShowFiltersModal(false)}
        />
      )}
      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}
      {showAddEditBranchModal && (
        <AddEditBranch
          selectedBranch={selectedBranch}
          closeModal={closeAddEditModal}
          showSpinner={showSpinner}
        />
      )}
      {showDeleteBranchConfirmModal && (
        <ConfirmationModal
          onToggle={() => setShowDeleteBranchConfirmModal(false)}
          onAccept={() => handleDeleteBranch()}
          onCancel={() => setShowDeleteBranchConfirmModal(false)}
          message={t("organization_context.confirm_delete_branch")}
          acceptButtonText={t("delete")}
          isWarning
          requiredPermission={branchesDeletePermission}
        />)
      }
    </Fragment>
  )
}

function stateToProps({ branches }) {
  return {
    branches: branches?.branches || [],
    countryOptions: branches?.countryOptions?.map(country => country.countryCode) || [],
    isLoading: branches?.isLoading,
    canLoadMore: branches?.canLoadMore,
    errorMessage: branches?.errorMessage,
    successMessage: branches?.successMessage,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadBranches,
        loadBranchesCountryOptions,
        removeBranch,
        clearBranchesMessages,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(Branches)
