import React, { useEffect } from 'react'
import { Storage } from 'aws-amplify'
import './section-builder.scss'

const SectionBuilder = ({ id, style, imageKey, handleClick, onUpdate, children }) => {
  const heroMainId = "hero-main"

  useEffect(() => {
    const getS3Image = async (key) => {
      var img = await Storage.get(key, {
        level: "public",
      })

      onUpdate(id, { style: { backgroundImage: `url(${img})` } })
    }

    if (imageKey && !style?.backgroundImage) {
      getS3Image(imageKey)
    }
  }, [style?.backgroundImage])

  return (
    <div
      className={id === heroMainId ? "hero-section-builder" : "section-builder"}
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleClick()
      }}
    >
      {children}
    </div>
  )
}

export default SectionBuilder