import React, { useState, useEffect, useLayoutEffect } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import DOMPurify from "dompurify"
import DentistDashboard from "./DentistDashboard"
import DealerTechDashboard from "./DealerTechDashboard"
import ColteneUserDashboard from './ColteneUserDashboard'
import PendingApproval from "./PendingApproval"
import Login from "../Login"
import G4OnlineAccess from "../OnlineAccess/g4-online-access"
import LoadingSpinner from "../common/LoadingSpinner"
import Modal from "../common/Modal"
import EmptyPageResults from "../common/EmptyPageResults"
import { toast } from "../common/Toast"
import { LinkCards } from "../../utils/dashboard-links"
import { useAppContext } from "../../libs/contextLib"
import { handleSetApertures } from '../../actions/aperture'
import { updateAuthedUser } from "../../actions/authedUser"
import { handleSetQueryParams, QUERY_PARAMS_KEY } from "../../actions/queryParams"
import { handleSetUserProducts } from "../../actions/userProducts"
import { handleSetCurrentUserPermissions } from "../../actions/userPermissions"
import { acceptCompanyRequest, loadCompanyRequests } from "../../actions/userCompany"
import dashboardConfig from "../../utils/dashboardConfig.json"
import { getCurrentUserPermissions } from "../../utils/requests/usersAPI"
import { hasEndUserSiteAccess, hasDealerSiteAccess, hasNoSiteAccess, hasColteneSiteAccess, hasInstrumentTrackingPermission } from "../../utils/permissionValidation"
import { contactUrl } from "../../utils/fixedUrls"
import "./dashboard.scss"

function Dashboard(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const { isAuthenticated, showFeatureDev } = useAppContext()
  const [linkCards, setLinkCards] = useState([])
  const [userGroup, setUserGroup] = useState(props.authedUser?.userGroup ? props.authedUser.userGroup.toLowerCase().split("-")[0] : "dentist")
  const [isWhiteList, setIsWhiteList] = useState(null)
  const [showG4Access, setShowG4Access] = useState(false)
  const [loadingInvite, setLoadingInvite] = useState(false)
  const [defaultGroup, setDefaultGroup] = useState("")
  const [warrantyModal, setWarrantyModal] = useState({
    headerTitle: "",
    serial: "",
    message: "",
    closeLabel: "",
    onClose: () => {},
  })
  const colteneUserGroup = ["admin", "administrator", "scican_technician", "scican_customer_service", "embedded_software_group", "it", "sales", "business_unit", "marketing"]
  const specialDealerUserGroup = ["dealer_admin", "dealer_customer_service", "dealer_sales", "dealer_marketing"]
  const endUserSiteAccess = "site:end-user-site-access"
  const dealerSiteAccess = "site:dealer-site-access"
  const savedSearchParams = JSON.parse(localStorage.getItem(QUERY_PARAMS_KEY) || "{}")
  
  useEffect(() => {
    const individualUser = "individual"
    if (!colteneUserGroup.includes(userGroup) && props.authedUser?.companyAccountType === individualUser && props.companyRequests.length === 0) {
      props.actions.loadCompanyRequests()
    }
  }, [])
  
  useEffect(() => {
    const loadInvite = async () => {
      const invitation = props.companyRequests.find(request => request.id === Number(savedSearchParams?.inviteId))
      if (!invitation) {
        return
      }

      setLoadingInvite(true)

      try {
        await props.actions.acceptCompanyRequest(invitation.id, false)

        const permissions = await getCurrentUserPermissions()
        await props.actions.handleSetCurrentUserPermissions(permissions)

        props.actions.updateAuthedUser({ 
            userGroup: props.authedUser?.userGroup?.replace(/_limited_access/g,""),
        })

        
      } catch (error) {
        toast.error(t("error.something_wrong"))
      } finally {
        setLoadingInvite(false)

        localStorage.removeItem(QUERY_PARAMS_KEY)
        props.dispatch(
          handleSetQueryParams({
            role: null,
            email: null,
            inviteId: null,
          })
        )
      }
    }

    if(isAuthenticated && savedSearchParams.inviteId) {
      loadInvite()
    }
  }, [isAuthenticated,props.authedUser?.userGroup, props.companyRequests, savedSearchParams?.inviteId])

  useLayoutEffect(() => {
    if (hasEndUserSiteAccess(props.userPermissions)) {
      props.dispatch(handleSetUserProducts({cognitoSub: props.authedUser?.cognitoSub}))
    }

    if (hasInstrumentTrackingPermission(props.userPermissions)) {
      props.dispatch(handleSetApertures(props.authedUser?.cognitoSub))
    }
  }, [])

  useEffect(() => {
    const userGroup = props.authedUser?.userGroup ? props.authedUser.userGroup.toLowerCase().split("-")[0] : ""
    setUserGroup(userGroup)
  }, [props, history, t])

  useEffect(() => {
    if(history.location.state?.registrationSuccess) {
      toast.success(t("device_context.registration_success"));
    }

    if (history.location.state?.phaseSuccess) {
      setShowG4Access(true)
      setWarrantyModal({
        headerTitle: t("online-access.online_access_registration"),
        message: t("online-access.follow_instructions"),
        closeLabel: t("close"),
        onClose: async () => {
          setShowG4Access(false)
        },
      })
    }
  }, [history.location.state?.phaseSuccess, history.location.state?.registrationSuccess, props.authedUser?.cognitoSub, props.dispatch, t])

  useEffect(() => {
    const state = history.location.state
    delete state?.registrationSuccess
    delete state?.phaseSuccess
    history.replace(`/`, { ...history.location, state })
  }, [history])

  useEffect(() => {
    let intervalId
    if (hasDealerSiteAccess(props.userPermissions)) {
      function validateWhiteList() {
        if (!isWhiteList) {
          const noAccess = hasNoSiteAccess(props.userPermissions)
          try {
            if (noAccess) {
              setIsWhiteList(false)
            } else {
              setIsWhiteList(true)
            }
          } catch (error) {
            toast.error(t("error.something_wrong"))
            setIsWhiteList(false)
            return
          }
        }
      }
      savedSearchParams?.inviteId ? (loadingInvite && validateWhiteList()) : validateWhiteList()
      intervalId = setInterval(() => {
        validateWhiteList()
      }, 30000)
    }
    return () => clearInterval(intervalId)
  }, [isWhiteList, loadingInvite, props.authedUser?.cognitoSub, props.userPermissions, savedSearchParams?.inviteId])

  useEffect(() => {
    let dashboardLinks = []
     
    if (colteneUserGroup.includes(userGroup) || specialDealerUserGroup.includes(userGroup)) {
      dashboardLinks = dashboardConfig.find((config) => config.role === userGroup)?.links || []
      if (showFeatureDev && specialDealerUserGroup.includes(userGroup)) {
        const indexOfRequestHelp = dashboardLinks.indexOf("remote_access");
        if (indexOfRequestHelp !== -1) {
            dashboardLinks[indexOfRequestHelp] = "request_help";
        }
      }
    }
    
    if (dashboardLinks.length === 0 && !colteneUserGroup.includes(userGroup) && hasColteneSiteAccess(props.userPermissions)) {
      setDefaultGroup("coltene_default")
      dashboardLinks = dashboardConfig.find((config) => config.role === "coltene_default")?.links || [];   
    }

    if (dashboardLinks.length === 0 && hasEndUserSiteAccess(props.userPermissions)) {
      dashboardLinks = dashboardConfig.find((config) => config.permission === endUserSiteAccess)?.links || []
    }

    if (dashboardLinks.length === 0 && hasDealerSiteAccess(props.userPermissions)) {
      dashboardLinks = dashboardConfig.find((config) => config.permission === dealerSiteAccess)?.links || []
    }
    
    if (dashboardLinks.length === 0) {
      dashboardLinks = dashboardConfig.find((config) => config.permission === "default")?.links || []
    }

    const specificCards = []

    dashboardLinks.forEach((item) => {
      const card = LinkCards.find((card) => card.key === item)
      if (card) {
        specificCards.push(card)
      }
    })

    setLinkCards(specificCards)
  },[userGroup, props.userPermissions])

  const getDisplayComponent = () => {
    if ((colteneUserGroup.includes(userGroup) || defaultGroup === "coltene_default") && hasColteneSiteAccess(props.userPermissions)) {
      return <ColteneUserDashboard linkCards={linkCards} openRemoteModal={props.openRemoteModal} />
    }

    if (hasEndUserSiteAccess(props.userPermissions)) {
      return <DentistDashboard linkCards={linkCards} />
    }

    if (hasDealerSiteAccess(props.userPermissions)) {
      if (loadingInvite || isWhiteList === null) {
        return <LoadingSpinner />
      }
      
      if (isWhiteList || savedSearchParams?.inviteId) {
        return <DealerTechDashboard linkCards={linkCards} openRemoteModal={props.openRemoteModal} />
      }

      return <PendingApproval authedUser = {props.authedUser} />
    }

    const sanitizedMessage = DOMPurify.sanitize(
      t('dashboard.no_access_message')
        .replace('<link>', `<a href="${contactUrl}" class="contact-link" target="_blank" rel="noopener noreferrer">`)
        .replace('</link>', '</a>'),
      { ADD_ATTR: ['target'] }
    );
    
    const subtitle = (<p dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />)

    return (
      <>
        <div className="no-access-dashboard">
          <EmptyPageResults
            title={t("dashboard.no_access")}
            subtitle={subtitle}
          />
        </div>
      </>
    )
  }

  return !isAuthenticated ? (
    <Login />
  ) : (
    <div className="dashboard-wrapper">
      {getDisplayComponent()}
      {showG4Access && (
        <Modal
          toggle={showG4Access}
          onToggle={() => {
            warrantyModal.onClose()
          }}
          className="online-activation"
        >
          <G4OnlineAccess
            headerTitle={warrantyModal.headerTitle}
            message={warrantyModal.message}
            closeLabel={warrantyModal.closeLabel}
            onClose={warrantyModal.onClose}
          />
        </Modal>
      )}
    </div>
  )
}

function mapStateToProps({ authedUser, userPermissions, onlineAccess, userCompany }) {
  return {
    authedUser,
    userPermissions,
    onlineAccess,
    companyRequests: userCompany?.companyRequests || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        acceptCompanyRequest,
        updateAuthedUser,
        handleSetCurrentUserPermissions,
        loadCompanyRequests
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(Dashboard)
