import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { useTranslation } from "react-i18next"
import { Storage } from "aws-amplify"
import SimpleButton from "../common/SimpleButton"
import PageModal from "../common/PageModal"
import LoadingSpinner from "../common/LoadingSpinner"
import { SelectMenu } from "../common/SelectMenu"
import { loadProblemTypes } from "../../actions/requestHelp"
import { toast } from "../common/Toast"
import { TrashCanFull, UploadCloudFull } from "../../icons"
import { postRequestHelp } from "../../utils/requests/dealersAPI"
import { sendMail } from "../../utils/requests/mqttPublishAPI";
import config from "../../utils/config"
import "./request-help-modal.scss"

function RequestHelpModal(props) {
  const { t } = useTranslation()
  const { handleClose } = props 
  const [problemTypes, setProblemTypes] = useState([])
  const [showSpinner, setShowSpinner] = useState(false)
  const [selectedProblemTypeId, setSelectedProblemTypeId] = useState(null)
  const [selectedProblemTypeValue, setSelectedProblemTypeValue] = useState(null)
  const [title, setTitle] = useState("")
  const [comment, setComment] = useState("")
  const [selectedFiles, setSelectedFiles] = useState([])
  const s3RequestHelpFolder = "request-help"
  const locale = localStorage.getItem("i18nextLng").replace(/-/g, "_");
  const templateName = `dealer_request_help_${locale}`
  const email = `${config.requestHelp_email}`
  
  useEffect(() => {
    if (props.problemTypes.length === 0) {
      props.actions.loadProblemTypes()
    }
  }, [props.actions])

  useEffect(() => {
    if (props.problemTypes.length > 0) {
      setProblemTypes(props.problemTypes)
      setSelectedProblemTypeId(props.problemTypes[0].id)
      setSelectedProblemTypeValue(props.problemTypes[0].problemType)
    }
  }, [props.problemTypes])

  useEffect(() => {
    setShowSpinner(props.isLoading)
  }, [props.isLoading])

  const getFooter = () => {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={handleClose}>
          {t("cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={handleSubmit}
          disabled={showSpinner}
        >
          {t("submit")}
        </SimpleButton>
      </>
    )
  }

  const addFiles = async (event) => {
    setSelectedFiles([...selectedFiles, ...Array.from(event.target.files)])
  }
  
  const deleteFile = (index) => {
    setSelectedFiles(selectedFiles.filter((_, i) => i !== index))
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    const { files } = event.dataTransfer

    if (files && files.length) {
      setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...Array.from(files)])
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    
    if (selectedFiles.length === 0 && title.length === 0 && comment.length === 0) {
      toast.error(t("dealer-portal.required_fields"))
      return
    }
    
    setShowSpinner(true)

    let attachments = []
  
    try {
      attachments = await Promise.all(
        selectedFiles.map(async (file) => {
          const fileName = file.name.split(".")[0]
          const ext = file.name.split(".")[1]
          const userId = props.authedUser.cognitoSub
          const storagePath = `${s3RequestHelpFolder}/${userId}/${fileName}-${Date.now()}.${ext}`

          await Storage.put(storagePath, file, {
            level: "public",
            contentType: file.type,
          })

          return {
            url: `public/${storagePath}`,
            fileName: file.name,
            fileType: file.type,
          }
        })
      )
    } catch (error) {
      console.log({ error })
      toast.error(t("error.file_upload"))

      setShowSpinner(false)
      return
    }

    try {
      const requestHelpPayload = {
        problemTypeId: selectedProblemTypeId,
        title,
        comment,
        fileUrl: attachments.map(a => a.url),
      }

      await postRequestHelp(requestHelpPayload)

      const emailTemplateVars = {
        firstname: email,
        problemType: selectedProblemTypeValue,
        title,
        comment,
        linkUrl: attachments.map(a => a.url),
        name: props.authedUser.firstname,
        phone: props.authedUser.phone,
        email: props.authedUser.email,
      }

      sendMail(email, templateName, emailTemplateVars)

      handleClose()

      toast.success(t("dealer-portal.request_help_success"))
      setShowSpinner(false)
    } catch (e) {
      console.log("ERROR: ", e)
      setShowSpinner(false)
    }
  }

  return (
    <>
      <PageModal 
        toggle
        onToggle={handleClose}
        title={t("nav.request_help")}
        footerContent={getFooter()}
      >
        <div className="request-help-modal">
          <div className="form-container">
            <div className="header-info">
              <p>{t("dealer-portal.request_help_disclaimer")}</p>
            </div>
            <div className="form-group half">
              <label>{t("dealer-portal.problem_type")}</label>
              {problemTypes.length > 0 && (
                <SelectMenu
                  options={problemTypes.map((pT) => ({
                    ...pT,
                    key: pT.id,
                    value: pT.problemType,
                  }))}
                  onChange={(option) => {
                    setSelectedProblemTypeId(option.id)
                    setSelectedProblemTypeValue(option.problemType)
                  }}
                  initialSelectedKey={problemTypes[0]?.id}
                />
              )}
            </div>
            <div className="form-group">
              <label htmlFor="title">{t("title")}</label>
              <input
                type="text"
                id="title"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value)
                }}
              />
            </div>
            <div className="form-group">
              <label htmlFor="comments">{t("dealer-portal.comments")}</label>
              <textarea name="" id="" cols="30" rows="5" value={comment} onChange={(e) => setComment(e.target.value)}/>
            </div>
            <div className="form-group">
              <label htmlFor="uploaded_files">{t("dealer-portal.uploaded_files")}</label>
              <ul className="uploaded-files">
                {selectedFiles.map((file, index) => (
                  <li key={index}>
                    {file.name} <TrashCanFull onClick={() => deleteFile(index)} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="form-group">
              <label className="input-label">
                {`${t("report-form.attach-file")}`}
              </label>
              <div className="upload-wrapper" onDrop={(e) => handleDrop(e)} onDragOver={(e) => e.preventDefault()}>
                <label className="file-input-wrapper" htmlFor="file-input">
                  <UploadCloudFull />
                  <div>
                    <span>{t("other_context.drop_files_to_attach_or")}</span>
                    <span className="call-to-action">{` ${t("browse")}`}</span>
                  </div>
                </label>
                <input className="file-input" id="file-input" onChange={(e) => addFiles(e)} type="file" multiple />
              </div>
            </div>
          </div>
        </div>
      </PageModal>
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </>
  )
}

function mapStateToProps({ authedUser, requestHelp }) {
  return {
    authedUser,
    problemTypes: requestHelp?.problemTypes || [],
    isLoading: requestHelp?.isLoading || false,
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadProblemTypes,
      },
      dispatch
    ),
  }
}

export default connect(mapStateToProps, dispatchToProps)(RequestHelpModal)
