import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import PageModal from '../common/PageModal'
import SimpleButton from '../common/SimpleButton'
import LoadingSpinner from '../common/LoadingSpinner'
import { SelectMenu } from "../common/SelectMenu"
import { toast } from "../common/Toast"
import { getCFFeedbackOptions, addCFPredictionFeedback, updateCFPredictionFeedback } from '../../utils/requests/productsAPI'
import { ThumbUpIcon } from '../../icons'
import './cycle-fault-predictions-modal.scss'

const CycleFaultPredictionsModal = (props) => {
  const { t } = useTranslation()
  const { selectedPrediction, onUpdateFeedback, closeModal } = props
  const { serialNumber, cycleNumber, cycleFault, predictions, feedback, hasFeedback, withRequestFeedback } = selectedPrediction
  const [isFeedbackEnabled, setIsFeedbackEnabled] = useState(false)
  const [feedbackId, setFeedbackId] = useState(null)
  const [failureCauseOptions, setFailureCauseOptions] = useState([])
  const [actionTakenOptions, setActionTakenOptions] = useState([])
  const [selectedThumbsRating, setSelectedThumbsRating] = useState(null)
  const [selectedFailureCause, setSelectedFailureCause] = useState(null)
  const [failureCauseOther, setFailureCauseOther] = useState(null)
  const [isFailureCauseOtherEnabled, setIsFailureCauseOtherEnabled] = useState(false)
  const [selectedActionTaken, setSelectedActionTaken] = useState(null)
  const [isActionTakenOtherEnabled, setIsActionTakenOtherEnabled] = useState(false)
  const [actionTakenOther, setActionTakenOther] = useState(null)
  const [showSpinner, setShowSpinner] = useState(false)
  const isAccountTypeAdmin = props.userGroup === "admin"
  const thumbsDownRating = 0
  const thumbsUpRating = 1
  
  useEffect(() => {
    if (Object.keys(feedback).length === 0) {
      return
    }

    const thumbsFeedback = feedback.thumbsFeedback

    setFeedbackId(feedback.id)
    setSelectedThumbsRating(thumbsFeedback)
    setSelectedFailureCause(feedback.failureCause)
    setFailureCauseOther(feedback.failureCauseOther)
    setSelectedActionTaken(feedback.actionTaken)
    setActionTakenOther(feedback.actionTakenOther)

    if (thumbsFeedback !== null) {
      setIsFeedbackEnabled(true)
    }
  }, [feedback])

  useEffect(() => {
    if (isFeedbackEnabled) {
      setShowSpinner(true)

      getCFFeedbackOptions({
        sn: serialNumber,
        cf: cycleFault,
      })
        .then((result) => {
          setFailureCauseOptions(result?.failure_cause?.map((o) => ({ key: o.id, value: o.optionName })) || [])
          setActionTakenOptions(result?.action_taken?.map((o) => ({ key: o.id, value: o.optionName })) || [])
        })
        .finally(() => {
          setShowSpinner(false)
        })
    }
  }, [isFeedbackEnabled])

  useEffect(() => {
    setIsFailureCauseOtherEnabled(failureCauseOptions.find(o => o.key === selectedFailureCause)?.value?.toLowerCase()?.includes("other") || false)
  }, [selectedFailureCause, failureCauseOptions])

  useEffect(() => {
    setIsActionTakenOtherEnabled(actionTakenOptions.find(o => o.key === selectedActionTaken)?.value?.toLowerCase()?.includes("other") || false)
  }, [selectedActionTaken, actionTakenOptions])

  const handleThumbsFeedback = (rating) => {
    const newRating = rating === "up" ? thumbsUpRating : thumbsDownRating

    if (selectedThumbsRating !== null) {
      setSelectedThumbsRating(newRating)
      return
    }

    setShowSpinner(true)

    addCFPredictionFeedback({
      serialNumber: serialNumber,
      cycleNumber: Number(cycleNumber),
      cycleFault: Number(cycleFault),
      thumbsFeedback: newRating
    })
      .then((response) => {
        setFeedbackId(response.id)
        setSelectedThumbsRating(newRating)
        setIsFeedbackEnabled(true)
        onUpdateFeedback(cycleNumber, { id: response.id, thumbsFeedback: newRating })
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }

  const handleFormFeedback = () => {
    setShowSpinner(true)

    updateCFPredictionFeedback(feedbackId, {
      thumbsFeedback: selectedThumbsRating,
      failureCause: Number(selectedFailureCause) || null,
      failureCauseOther: failureCauseOther,
      actionTaken: Number(selectedActionTaken) || null,
      actionTakenOther: actionTakenOther
    })
      .then(() => {
        onUpdateFeedback(cycleNumber, {
          ...feedback, 
          thumbsFeedback: selectedThumbsRating,
          failureCause: Number(selectedFailureCause) || null,
          failureCauseOther: failureCauseOther,
          actionTaken: Number(selectedActionTaken) || null,
          actionTakenOther: actionTakenOther
        })
        toast.success(t("monitoring.predictions_disclaimer_thanks"))
        closeModal()
      })
      .catch(() => {
        toast.error(t("error.something_wrong"))
      })
      .finally(() => {
        setShowSpinner(false)
      })
    
  }

  return (
    <div className="cycle-fault-predictions-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => closeModal()}
        title={withRequestFeedback ? t("monitoring.ai_predictions_feedback") : t("monitoring.ai_predictions")}
      >
        <div className={"fault-disclaimer" + (withRequestFeedback ? " fault-feedback" : "")}>
          <p>
            {`${t("monitoring.predictions_disclaimer_identification")} ${t("sn")}: ${serialNumber}, C# ${cycleNumber || ''}, CF-${cycleFault} `}
          </p>
        </div>
        <ul className="fault-prediction-list">
          {predictions.map((prediction, index) =>
            <li key={index} className="fault-prediction">
              {prediction.prediction}
              <span className="probability">{prediction.probability ? `${prediction.probability} %` : t("monitoring.unknown")}</span>
            </li>)
          }
        </ul>
        {(withRequestFeedback || hasFeedback) && (
          <>
            <div className="fault-disclaimer fault-feedback">
              <div className="rating-wrapper">
                {`${t("monitoring.predictions_disclaimer_helpful")}`}
                <div className="rating-buttons">
                  <ThumbUpIcon 
                    className={selectedThumbsRating === thumbsUpRating  ? "selected-option" : ""}
                    onClick={() => (withRequestFeedback || isAccountTypeAdmin) && handleThumbsFeedback("up")}
                  />
                  <ThumbUpIcon 
                    className={"thumb-down" + (selectedThumbsRating === thumbsDownRating ? " selected-option" : "")}
                    onClick={() => (withRequestFeedback || isAccountTypeAdmin) && handleThumbsFeedback("down")}
                  />
                </div>
              </div>
            </div>
            {(hasFeedback || isFeedbackEnabled) && 
              <>
                <div className="title with-margin-top">
                  {t("monitoring.feedback_form")}
                </div>
                <div className="subtitle">
                  {t("monitoring.failure_cause")}
                </div>
                <SelectMenu
                  className={"feedback-dropdown" + ((!withRequestFeedback && !isAccountTypeAdmin) ? " disabled-dropdown" : "")}
                  placeholder={withRequestFeedback ? t("please_select") : t("indeterminate")}
                  options={failureCauseOptions}
                  initialSelectedKey={selectedFailureCause}
                  onChange={(option) => { setSelectedFailureCause(option.key); setFailureCauseOther(null) }}
                />
                {isFailureCauseOtherEnabled && (
                  <>
                    <div className="subtitle">
                      {t("monitoring.other_failure_cause")}
                    </div>
                    <input 
                      type="text" 
                      className={"feedback-input" + ((!withRequestFeedback && !isAccountTypeAdmin) ? " disabled-input" : "")}
                      value={failureCauseOther || ''}
                      onChange={(e) => setFailureCauseOther(e.target.value)}
                    />
                  </>
                )} 
                <div className="subtitle">
                  {t("monitoring.action_taken")}
                </div>
                <SelectMenu
                  className={"feedback-dropdown" + ((!withRequestFeedback && !isAccountTypeAdmin) ? " disabled-dropdown" : "")}
                  placeholder={withRequestFeedback ? t("please_select") : t("indeterminate")}
                  options={actionTakenOptions}
                  initialSelectedKey={selectedActionTaken}
                  onChange={(option) => { setSelectedActionTaken(option.key); setActionTakenOther(null) }}
                />
                {isActionTakenOtherEnabled && (
                  <>
                    <div className="subtitle">
                      {t("monitoring.other_action_taken")}
                    </div>
                    <input 
                      type="text" 
                      className={"feedback-input" + ((!withRequestFeedback && !isAccountTypeAdmin) ? " disabled-input" : "")}
                      value={actionTakenOther || ''}
                      onChange={(e) => setActionTakenOther(e.target.value)}
                    />
                  </>
                )}
                {(withRequestFeedback || isAccountTypeAdmin) && 
                  <>
                    <div className="feedback-buttons-wrapper">
                      <SimpleButton 
                        className="cancel-button"
                        onClick={() => closeModal()} 
                      >
                        {t("cancel")}
                      </SimpleButton>
                      <SimpleButton
                        className="submit-button"
                        onClick={() => handleFormFeedback()} 
                      >
                        {t("submit")}
                      </SimpleButton>
                    </div>
                  </>
                }
              </>
            }
          </>)
        }
        {showSpinner &&
          <div className="spinner-wrapper">
            <LoadingSpinner/>
          </div>
        }
      </PageModal>
    </div>
  )
}

function stateToProps({ authedUser }) {
  return {
    userGroup: authedUser?.userGroup,
  }
}

export default connect(stateToProps)(CycleFaultPredictionsModal)

