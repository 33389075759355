import { useEffect, useRef, useState } from "react"
import { CaretDownIcon } from "../../../icons"
import "./select-menu.scss"
import { useOnClickOutside } from "../../../hooks/useOnClickOutside"

export function SelectMenu({ options, initialSelectedKey, onChange, placeholder, className = "" }) {
  const [isSelectMenuOpen, setIsSelectMenuOpen] = useState(false)
  const [selectedKey, setSelectedKey] = useState(initialSelectedKey)
  const [optionsState, setOptionsState] = useState([])
  const ref = useRef()

  useEffect(() => {
    setOptionsState(options.sort((a,b) => a.value - b.value))
  }, [options])

  useEffect(() => {
    setSelectedKey(initialSelectedKey)
  }, [initialSelectedKey])

  useOnClickOutside(ref, () => { 
    setIsSelectMenuOpen(false)
  })

  return (
    <div className={`select-menu ${className}`} ref={ref}>
      <button className="selected-option" onClick={() => setIsSelectMenuOpen(!isSelectMenuOpen)}>
        {selectedKey && (
          <div>{optionsState.find((o) => o.key === selectedKey)?.value}</div>
        )}
        {placeholder && !selectedKey && (
          <div className="placeholder">
            {placeholder}
          </div>
        )}
        <CaretDownIcon className={"caret-dropdown-icon" + (isSelectMenuOpen ? " icon-dropdown-open" : "")} />
      </button>
      {isSelectMenuOpen && (
        <div className="options">
          {optionsState.map((option) => {
            return (
              <button
                key={option.key}
                className="option"
                onClick={() => {
                  setSelectedKey(option.key)
                  setIsSelectMenuOpen(false)
                  if (onChange && option.key !== selectedKey) {
                    onChange(option)
                  }
                }}
              >
                {option.value}
              </button>
            )
          })}
        </div>
      )}
    </div>
  )
}
