import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { Storage } from "aws-amplify"
import PageModal from "../common/PageModal"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from '../common/SimpleButton'
import LoadingSpinner from '../common/LoadingSpinner'
import CalendarInput from "../common/CalendarInput";
import RadioInput from "../common/RadioInput"
import Search from "../common/Search"
import { toast } from "../common/Toast"
import { CaretDownIcon, UploadCloudFull } from '../../icons'
import { getDeviceSpareParts } from "../../utils/requests/spareparts"
import { postReport } from "../../utils/requests/reportsAPI"
import { sendMail } from "../../utils/requests/mqttPublishAPI"
import { formatPhone } from "../../utils/functions"
import config from "../../utils/config"
import countries from "../../utils/countries"
import { reportProblemWritePermission } from "../../utils/permissionValidation"
import "./view-create-report-modal.scss"

const ViewCreateReportModal = (props) => {
  const { t } = useTranslation()
  const { isViewMode, report, prefilledModel, prefilledSerialNumber, onSuccesfulCreate, closeModal } = props
  const titles = ['Dr.', 'Miss.', 'Mr.', 'Mrs.']
  const reportTypes = [`${t("report-problem.complaint")}`, `${t("report-problem.problem")}`]
  const complaintTypes = [`${t("report-problem.electrical")}`, `${t("report-problem.fluids")}`, `${t("report-problem.hardware")}`, `${t("report-problem.other")}`, `${t("report-problem.software")}`]
  const locations = ["Clinic", "Hospital", "Institution", "Practice", "N/A"]
  const sampleAndInvolvementOptions = ["simple", "advanced"]
  const [products, setProducts] = useState([])
  const [spareParts, setSpareParts] = useState([])
  const [selectedReportType, setSelectedReportType] = useState(reportTypes[0])
  const [selectedProduct, setSelectedProduct] = useState(prefilledModel)
  const [serialNumber, setSerialNumber] = useState(prefilledSerialNumber || "")
  const [selectedComplaintType, setSelectedComplaintType] = useState(null)
  const [sparePart, setSparePart] = useState(null)
  const [attachment, setAttachment] = useState(null)
  const [selectedTitle, setSelectedTitle] = useState(null)
  const [fullName, setFullName] = useState("")
  const [country, setCountry] = useState("")
  const [phone, setPhone] = useState("")
  const [fax, setFax] = useState("")
  const [scicanTechnicianName, setScicanTechnicianName] = useState("")
  const [customerId, setCustomerId] = useState("")
  const [occupation, setOccupation] = useState("")
  const [customer, setCustomer] = useState("")
  const [complaintOrigin, setComplaintOrigin] = useState("")
  const [locationOfEvent, setLocationOfEvent] = useState(null)
  const [technicianOtherDetails, setTechnicianOtherDetails] = useState("")
  const [patientInvolvement, setPatientInvolvement] = useState(sampleAndInvolvementOptions[0])
  const [doctorName, setDoctorName] = useState("")
  const [scicanRep, setScicanRep] = useState("")
  const [doctorAddress, setDoctorAddress] = useState("")
  const [doctorCity, setDoctorCity] = useState("")
  const [doctorState, setDoctorState] = useState("")
  const [doctorZip, setDoctorZip] = useState("")
  const [doctorCountry, setDoctorCountry] = useState(null)
  const [reportingDate, setReportingDate] = useState(null)
  const [dateOfOccurence, setDateOfOccurence] = useState(null)
  const [techician, setTechician] = useState("")
  const [dealerBranch, setDealerBranch] = useState("")
  const [productNr, setProductNr] = useState("")
  const [cycleCount, setCycleCount] = useState("")
  const [expiryDate, setExpiryDate] = useState(null)
  const [productName, setProductName] = useState("")
  const [productOtherInformation, setProductOtherInformation] = useState("")
  const [sampleEnclosed, setSampleEnclosed] = useState(sampleAndInvolvementOptions[0])
  const [cf, setCf] = useState("")
  const [other, setOther] = useState("")
  const [jobNumber, setJobNumber] = useState("")
  const [issueDescription, setIssueDescription] = useState("")
  const [findings, setFindings] = useState("")
  const [resolution, setResolution] = useState("")
  const [hasValidProduct, setHasValidProduct] = useState(true)
  const [hasValidSerialNumber, setHasValidSerialNumber] = useState(true)
  // const [hasValidSparePart, setHasValidSparePart] = useState(true)
  const [hasValidFullName, setHasValidFullName] = useState(true)
  const [hasValidPhone, setHasValidPhone] = useState(true)
  const [hasValidOccupation, setHasValidOccupation] = useState(true)
  const [hasValidCustomer, setHasValidCustomer] = useState(true)
  const [hasValidComplaintOrigin, setHasValidComplaintOrigin] = useState(true)
  const [hasValidDoctorAddress, setHasValidDoctorAddress] = useState(true)
  const [hasValidDoctorCity, setHasValidDoctorCity] = useState(true)
  const [hasValidDoctorState, setHasValidDoctorState] = useState(true)
  const [hasValidDoctorZip, setHasValidDoctorZip] = useState(true)
  const [hasValidReportingDate, setHasValidReportingDate] = useState(true)
  const [hasValidDateOfOccurence, setHasValidDateOfOccurence] = useState(true)
  const [hasValidProductNr, setHasValidProductNr] = useState(true)
  const [hasValidCycleCount, setHasValidCycleCount] = useState(true)
  const [hasValidCycleFault, setHasValidCycleFault] = useState(true)
  const [hasValidIssueDescription, setHasValidIssueDescription] = useState(true)
  const [sparePartsFilterQuery, setSparePartsFilterQuery] = useState(null)
  const [sparePartsCurrentPage, setSparePartsCurrentPage] = useState(1)
  const [canLoadMoreSpareParts, setCanLoadMoreSpareParts] = useState(true)
  const [areSparePartsOptionsVisible, setAreSparePartsOptionsVisible] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const sparePartsOptionsRef = useRef()
  const countriesOptions = countries
  const phonePattern = new RegExp(/^\d*$/)
  const s3ReportProblemFolder = "report-problem"
  const locale = localStorage.getItem("i18nextLng").replace(/-/g, "_");

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideResults, true)
    return () => {
      document.removeEventListener("click", handleClickOutsideResults, true)
    }
  }, [])

  useEffect(() => {
    if (props.productTypes && Object.keys(props.productTypes).length > 0) {
      let arr = []

      for (const index in props.productTypes) {
        const deviceModels = props.productTypes[index].models
        deviceModels.map((model) => {
          arr.push(model.name)
        })
      }

      setProducts(arr.sort())
    }
  },[props.productTypes])

  useEffect(() => {
    if (selectedProduct) {
      let changeValueTimeout = window.setTimeout(
        () => {
          setSparePartsCurrentPage(1)
          handleLoadSpareParts(true)
        },
        sparePartsFilterQuery ? 1000 : 0
      )
      return () => {
        clearTimeout(changeValueTimeout)
      }    
    }    
  },[sparePartsFilterQuery, selectedProduct])

  useEffect(() => {
    if (sparePartsCurrentPage && sparePartsCurrentPage > 1) {
      handleLoadSpareParts(false)
    }
  }, [sparePartsCurrentPage])

  const handleLoadSpareParts = (withReset) => {
    let queryParams = {
      page: withReset ? 1 : sparePartsCurrentPage,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
      model: selectedProduct
    }

    if (sparePartsFilterQuery) {
      queryParams = { ...queryParams, search: sparePartsFilterQuery}
    }

    setShowSpinner(true)

    getDeviceSpareParts(queryParams)
      .then((data) => {
        if (data?.data) {
          const sparePartsRetrieved = withReset ? data.data : [...spareParts, ...data.data]
          
          setSpareParts(sparePartsRetrieved)
          setCanLoadMoreSpareParts(data.data.length === Number(process.env.REACT_APP_PAGINATION_SIZE) && queryParams.page !== data.data.totalPages)
          setShowSpinner(false)
        }
      })
      .catch(() => {
        toast.error(t('spareparts.error-loading-spareparts'))

        setCanLoadMoreSpareParts(false)
        setShowSpinner(false)
      })
  }

  const handleSearchSpareParts = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setSparePartsFilterQuery(value)
    } else if (value?.length === 0 && sparePartsFilterQuery?.length > 0) {
      setShowSpinner(true)
      setSparePartsFilterQuery(null)
    }
  }

  const handleLoadMoreSpareParts = () => {
    setSparePartsCurrentPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const createReportFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => handleOnClose()}
        >
          {t('cancel')}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={() => handleCreateReport()}
          disabled={showSpinner}
          requiredPermission={reportProblemWritePermission}
        >
          {t('save')}
        </SimpleButton>
      </>
    )
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectReportType = (reportType, toggleVisibility) => {
    setSelectedReportType(reportType)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectReportTypeInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedReportType ? selectedReportType : t("please_select")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectReportTypeOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {reportTypes.map((reportType, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (reportType === selectedReportType ? " selected-option" : "")} 
          key={index}
          onClick={() => selectReportType(reportType, toggleVisibility)}
        >
          {reportType}
        </div>)
      }
    </div>
  )

  const selectProduct = (product, toggleVisibility) => {
    setSelectedProduct(product)
    setSparePart(null)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectProductInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedProduct ? selectedProduct : t("no_product_selected")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectProductOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {products.map((product, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (product === selectedProduct ? " selected-option" : "")} 
          key={index}
          onClick={() => selectProduct(product, toggleVisibility)}
        >
          {product}
        </div>)
      }
    </div>
  )

  const selectComplaintType = (complaintType, toggleVisibility) => {
    setSelectedComplaintType(complaintType)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectComplaintTypeInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedComplaintType ? selectedComplaintType : t("please_select")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectComplaintTypeOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {complaintTypes.map((complaintType, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (complaintType === selectedComplaintType ? " selected-option" : "")} 
          key={index}
          onClick={() => selectComplaintType(complaintType, toggleVisibility)}
        >
          {complaintType}
        </div>)
      }
    </div>
  )

  const selectTitle = (title, toggleVisibility) => {
    setSelectedTitle(title)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectTitleInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedTitle ? selectedTitle : t("please_select")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectTitleOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {titles.map((title, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (title === selectedTitle ? " selected-option" : "")} 
          key={index}
          onClick={() => selectTitle(title, toggleVisibility)}
        >
          {title}
        </div>)
      }
    </div>
  )

  const selectLocation = (location, toggleVisibility) => {
    setLocationOfEvent(location)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectLocationInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {locationOfEvent ? locationOfEvent : t("please_select")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectLocationOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {locations.map((location, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (location === locationOfEvent ? " selected-option" : "")} 
          key={index}
          onClick={() => selectLocation(location, toggleVisibility)}
        >
          {location}
        </div>)
      }
    </div>
  )

  const selectCountry = (country, toggleVisibility) => {
    setDoctorCountry(country)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {doctorCountry ? doctorCountry.name : t("please_select")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {countriesOptions.map((country, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (country === doctorCountry ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCountry(country, toggleVisibility)}
        >
          {country.name}
        </div>)
      }
    </div>
  )

  const handleChangeSerialNumber = (value) => {
    const upperTrimmedValue = value?.toUpperCase()?.trim()
    setSerialNumber(upperTrimmedValue || "")
  }

  const handleUploadFile = async (file) => {
    setShowSpinner(true)

    try {
      const fileName = file.name.split(".")[0]
      const ext = file.name.split(".")[1]

      const storagePath = `${s3ReportProblemFolder}/${fileName}-${Date.now()}.${ext}`

      await Storage.put(storagePath, file, {
        level: "protected",
        contentType: file.type,
      })

      setAttachment({
        url: storagePath,
        fileName: file.name,
        fileType: file.type,
      })

      setShowSpinner(false)
    } catch (error) {
      toast.error("An error occured while uploading your file")

      setShowSpinner(false)
    }
  }

  const handleFileInput = async (event) => {
    const file = event?.target?.files[0]

    if (file) {
      await handleUploadFile(file)
    }
  }

  const handleDrop = async (event) => {
    event.preventDefault()
    const file = event?.dataTransfer?.files[0]

    if (file) {
      await handleUploadFile(file)
    }
  }

  const handleClickOutsideResults = (event) => {
    if (sparePartsOptionsRef.current && !sparePartsOptionsRef.current.contains(event.target)) {
      setAreSparePartsOptionsVisible(false)
      setSparePartsFilterQuery(null)
    }
  }

  const isValidSerialNumber = () => {
    const serialNumberLength = serialNumber.trim().length
    if (serialNumberLength === 0 || serialNumberLength > 12) {
      setHasValidSerialNumber(false)
      toast.error(t("error.invalid_serial_number"))

      return false
    }

    setHasValidSerialNumber(true)
    return true
  }

  const areValidRequiredFields = () => {
    if (!isValidSerialNumber()) {
      return false
    }

    if (!selectedProduct) {
      setHasValidProduct(false)
      toast.error(t("error.invalid_product"))

      return false
    }

    setHasValidProduct(true)

    // if (!sparePart) {
    //   setHasValidSparePart(false)
    //   toast.error(t("error.fill_all_fields"))

    //   return false
    // }

    // setHasValidSparePart(true)

    if (fullName.trim().length === 0) {
      setHasValidFullName(false)
      toast.error(t("error.fill_all_fields"))

      return false
    }

    setHasValidFullName(true)

    if (phone.trim().length === 0 || !phonePattern.test((phone).replaceAll("-",""))) {
      setHasValidPhone(false)
      toast.error(t("error.phone"))

      return false
    }

    setHasValidPhone(true)

    if (selectedReportType === reportTypes[0]) {
      if (occupation.trim().length === 0) {
        setHasValidOccupation(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidOccupation(true)

      if (customer.trim().length === 0) {
        setHasValidCustomer(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidCustomer(true)

      if (complaintOrigin.trim().length === 0) {
        setHasValidComplaintOrigin(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidComplaintOrigin(true)

      if (doctorAddress.trim().length === 0) {
        setHasValidDoctorAddress(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidDoctorAddress(true)

      if (doctorCity.trim().length === 0) {
        setHasValidDoctorCity(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidDoctorCity(true)

      if (doctorState.trim().length === 0) {
        setHasValidDoctorState(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidDoctorState(true)

      if (doctorZip.trim().length === 0) {
        setHasValidDoctorZip(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidDoctorZip(true)

      if (!reportingDate) {
        setHasValidReportingDate(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidReportingDate(true)

      if (!dateOfOccurence) {
        setHasValidDateOfOccurence(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidDateOfOccurence(true)

      if (productNr.trim().length === 0) {
        setHasValidProductNr(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidProductNr(true)

      if (cycleCount.trim().length === 0) {
        setHasValidCycleCount(false)
        toast.error(t("error.fill_all_fields"))

        return false
      }

      setHasValidCycleCount(true)
    }

    if (cf.trim().length === 0) {
      setHasValidCycleFault(false)
      toast.error(t("error.fill_all_fields"))

      return false
    }

    setHasValidCycleFault(true)

    if (issueDescription.trim().length === 0) {
      setHasValidIssueDescription(false)
      toast.error(t("error.fill_all_fields"))

      return false
    }

    setHasValidIssueDescription(true)

    return true
  }

  const handleCreateReport = () => {
    setShowSpinner(true)

    if (!areValidRequiredFields()) {
      setShowSpinner(false)
      return
    }

    const complaintData = {
      report_type: selectedReportType || "",
      serial_number: serialNumber,
      spareparts: [
        ...(sparePart ? [
          {
            id: sparePart.id,
            name: sparePart.name,
            component_item: sparePart.componentItem,
          }
        ] : []),
      ],
      attachment: [
        ...(attachment ? [
          {
            url: attachment.url,
            filename: attachment.fileName,
            file_type: attachment.fileType,
          }
        ] : []),
      ],
      first_name: fullName.split(" ")[0],
      last_name: fullName.split(" ")[1],
      country: country,
      phone: phone,
      fax: fax,
      scican_technician: scicanTechnicianName,
      customer_id: customerId,
      occupation: occupation,
      practice: customer,
      complaint_origin: complaintOrigin,
      occured_location: locationOfEvent || "",
      occured_location_other: technicianOtherDetails,
      patient_involvement: selectedReportType === reportTypes[0] ? patientInvolvement : "",
      doctor_name: doctorName,
      scican_rep: scicanRep,
      doctor_address: doctorAddress,
      doctor_city: doctorCity,
      doctor_state: doctorState,
      doctor_zip: doctorZip,
      doctor_country: doctorCountry?.name || "",
      techician: techician,
      dealer_branch: dealerBranch,
      product_numer: productNr,
      cycle_count: cycleCount,
      expiry_date: expiryDate || "",
      other_info: productOtherInformation,
      sample_enclosed: selectedReportType === reportTypes[0] ? sampleEnclosed : "",
      cycle_fault: cf,
      cycle_fault_other: other,
      job_number: jobNumber,
      issue_description: issueDescription,
      findings: findings,
      resolution: resolution,
      complaint_logged: false,
      status: "open",
      model: selectedProduct || "",
      complaint_type: selectedComplaintType || "",
      title: selectedTitle || "",
      report_date: reportingDate || "",
      occurrence_date: dateOfOccurence || "",
      updated_by: `${props.authedUser?.firstname} ${props?.authedUser?.lastname}`,
      report_by_email: props.authedUser?.email,
    }

    postReport(complaintData)
      .then((r) => {
        const ticket = r?.data?.insertId || "";

        toast.success(t("report-problem.success"));

        const email = `${config.distribution_email}`;
        const emailTemplate = `ocp_report_problem_${locale}`;

        const emailTemplateVars = {
          // country: country,
          firstname: "SciCan Admin",
          model: selectedProduct || "",
          serialNumber: serialNumber,
          ticketNumber: ticket,
          linkUrl: `${config.host}/report-problem?ticket=${ticket}`,
          name: fullName,
          phone: phone,
          email: props.authedUser?.email || "",
          subject: cf,
          description: issueDescription,
          resolution: resolution,
          followUp: ""
        }

        sendMail(email, emailTemplate, emailTemplateVars)

        setShowSpinner(false)
          
        onSuccesfulCreate()
        handleOnClose()
      })
      .catch(() => {
        toast.error(t("error.failure_msg"))

        setShowSpinner(false)
      })
  }

  const handleDownloadAttachedFile = async (url) => {
    setShowSpinner(true)

    Storage.configure({
      customPrefix: {
        public: "",
        protected: "",
        private: "",
      },
    })

    const result = await Storage.get(url)
    window.open(result, "_blank")

    setShowSpinner(false)
  }

  const handleOnClose = () => {
    if (closeModal) {
      closeModal()
    }
  }

  const renderViewForm = () => {
    return (
      <>
        <div className="section-title">
          {t("general_information").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("product")}`}
            </div>
            <div className="value">
              {report?.model}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("serial_number")}`}
            </div>
            <div className="value">
              {report?.sn}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.complaint-type")}`}
            </div>
            <div className="value">
              {report?.complaintType}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("nav.spareparts")}`}
            </div>
            <div className="value">
              {report?.spareparts}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.attach-file")}`}
            </div>
            {report?.attachments[0]?.fileName &&
              <div className="value">
                <span>{report?.attachments[0]?.fileName}</span>
                <span 
                  className="link-action"
                  onClick={() => handleDownloadAttachedFile(report.attachments[0].url)}
                >
                  {t("download")}
                </span>
              </div>
            }
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="separator"/>
        <div className="section-title">
          {t("report-problem.issue_reported_by").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("title")}`}
            </div>
            <div className="value">
              {report?.title}
            </div>
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("full_name")}`}
            </div>
            <div className="value">
              {`${report?.firstName} ${report?.lastName}`}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("country")}`}
            </div>
            <div className="value">
              {report?.country}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("phone")}`}
            </div>
            <div className="value">
              {report?.phone}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("fax")}`}
            </div>
            <div className="value">
              {report?.fax}
            </div>
          </div>
        </div>
        <div className="separator" />
        <div className="section-title">
          {t("report-problem.event_information_complaint_form").toUpperCase()}
        </div>
        <div className="section-subtitle">
          {t("report-form.technician")}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.technician-name")}`}
            </div>
            <div className="value">
              {report?.scicanTechnician}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.customer-id")}`}
            </div>
            <div className="value">
              {report?.customerId}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.occupation")}`}
            </div>
            <div className="value">
              {report?.occupation}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.practice")}`}
            </div>
            <div className="value">
              {report?.practice}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.complaint-origin")}`}
            </div>
            <div className="value">
              {report?.complaintOrigin}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.location")}`}
            </div>
            <div className="value">
              {report?.occurredLocation}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.other-info")}`}
            </div>
            <div className="value">
              {report?.occurredLocationOther}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.involvement")}`}
            </div>
            <div className="value">
              {report?.patientInvolvement}
            </div>
          </div>
        </div>
        <div className="inner-separator"/>
        <div className="section-subtitle">
          {t("report-form.doctor-info")}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.doctor")}`}
            </div>
            <div className="value">
              {report?.doctorName}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.scican-rep")}`}
            </div>
            <div className="value">
              {report?.doctorScicanRep}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("address")}`}
            </div>
            <div className="value">
              {report?.doctorAddress}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("city")}`}
            </div>
            <div className="value">
              {report?.doctorCity}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("state_province")}`}
            </div>
            <div className="value">
              {report?.doctorState}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("zip_postal_code")}`}
            </div>
            <div className="value">
              {report?.doctorZip}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("country")}`}
            </div>
            <div className="value">
              {report?.doctorCountry}
            </div>
          </div>
          <div className="input-wrapper empty"/>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.report-date")}`}
            </div>
            <div className="value">
              {report?.reportDate}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.occurrence-date")}`}
            </div>
            <div className="value">
              {report?.occurrenceDate}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("technician")}`}
            </div>
            <div className="value">
              {report?.technician}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.dealer-branch")}`}
            </div>
            <div className="value">
              {report?.dealerBranch}
            </div>
          </div>
        </div>
        <div className="inner-separator"/>
        <div className="section-subtitle">
          {t("report-form.product-info")}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.product-number")}`}
            </div>
            <div className="value">
              {report?.productNumber}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.cycle-count")}`}
            </div>
            <div className="value">
              {report?.cycleCount}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.expiry-date")}`}
            </div>
            <div className="value">
              {report?.expiryDate}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.product-name")}`}
            </div>
            <div className="value">
              {report?.model}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.other-info")}`}
            </div>
            <div className="value">
              {report?.otherInfo}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.sample-enclosed")}`}
            </div>
            <div className="value">
              {report?.sampleEnclosed}
            </div>
          </div>
        </div>
        <div className="separator"/>
        <div className="section-title">
          {t("report-problem.report_information").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.cycle-fault")}`}
            </div>
            <div className="value">
              {report?.cf}
            </div>
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.other")}`}
            </div>
            <div className="value">
              {report?.otherInfo}
            </div>
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.job-number")}`}
            </div>
            <div className="value">
              {report?.jobNumber}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.description-issue")}`}
            </div>
            <div className="value">
              {report?.issueDescription}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.findings")}`}
            </div>
            <div className="value">
              {report?.findings}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.resolution")}`}
            </div>
            <div className="value">
              {report?.resolution}
            </div>
          </div>
        </div>
      </>
    )
  }

  const renderEditForm = () => {
    return (
      <>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.report-type")}`}
              <span className="required">*</span>
            </div>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectReportTypeInput}
              toDisplayItem={selectReportTypeOptions}
            />
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="separator"/>
        <div className="section-title">
          {t("general_information").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("product")}`}
              <span className="required">*</span>
            </div>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              className={`${!hasValidProduct ? "has-error" : ""}`}
              eventItem={selectProductInput}
              toDisplayItem={selectProductOptions}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("serial_number")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidSerialNumber ? " has-error" : "") }
              value={serialNumber || ""}
              onChange={(e) => handleChangeSerialNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.complaint-type")}`}
            </div>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectComplaintTypeInput}
              toDisplayItem={selectComplaintTypeOptions}
            />
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="separator"/>
        <div className="section-title">
          {t("report-problem.additional_information").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("nav.spareparts")}`}
              {/* <span className="required">*</span> */}
            </div>
            <div className="search-with-options-wrapper">
              <div
                onClick={() => setAreSparePartsOptionsVisible(!sparePart)} 
                className={"input-item" + (!selectedProduct ? " disabled" : "")}
              >
                <Search 
                  onSearch={handleSearchSpareParts}
                  onCancel={() => setSparePart(null)}
                  placeholder={t("report-form.search-spare-part")}
                  searchText={sparePart ? `${sparePart.id}, ${sparePart.name}` : sparePartsFilterQuery}
                />
              </div>
              {areSparePartsOptionsVisible && !sparePart && (
                <div ref={sparePartsOptionsRef} className="options-wrapper">
                  {spareParts.length > 0 && (
                    <>
                      {spareParts.map((sparePart, index) =>
                        <div
                          key={`spare-part-option-${index}`}
                          className="result-wrapper"
                          onClick={() => {
                            setSparePart({
                              id: sparePart.item_id,
                              name: sparePart.description,
                              componentItem: sparePart.components[0]?.component_item,
                            })
                            setAreSparePartsOptionsVisible(false)
                            setSparePartsFilterQuery(null)
                          }}
                        >
                          <div className="spare-part-identifier">{`${sparePart.item_id}`}</div>
                          <div className="spare-part-description">{sparePart.description}</div>
                        </div>)
                      }
                      {canLoadMoreSpareParts && (
                        <div className="buttons-wrapper">
                          <SimpleButton className="load-more-button" onClick={() => handleLoadMoreSpareParts()}>
                            {t("load_more")}
                          </SimpleButton>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.attach-file")}`}
            </div>
            <div 
              className="upload-wrapper" 
              onDrop={(e) => handleDrop(e)} 
              onDragOver={(e) => e.preventDefault()}
            >
              <label className="file-input-wrapper" htmlFor="file-input">
                <UploadCloudFull />
                <div>
                  <span>{t("other_context.drop_files_to_attach_or")}</span>
                  <span className="call-to-action">{` ${t("browse")}`}</span>
                </div>
              </label>
              {attachment &&
                <div className="file-name">
                  {attachment.fileName}
                </div>
              }
              <input
                className="file-input"
                id="file-input"
                onChange={(e) => handleFileInput(e)}
                type="file"
              />
            </div>
          </div>
        </div>
        <div className="separator"/>
        <div className="section-title">
          {t("report-problem.issue_reported_by").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("title")}`}
            </div>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectTitleInput}
              toDisplayItem={selectTitleOptions}
            />
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("full_name")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidFullName ? " has-error" : "") }
              value={fullName || ""}
              onChange={(e) => setFullName(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("country")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={country || ""}
              onChange={(e) => setCountry(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("phone")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidPhone ? " has-error" : "")}
              value={phone ? formatPhone(phone) : ""}
              onChange={(e) => setPhone(formatPhone(e.target.value))}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("fax")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={fax || ""}
              onChange={(e) => setFax(e.target.value)}
            />
          </div>
        </div>
        <div className="separator"/>
        {selectedReportType === reportTypes[0] ? 
          renderComplaintForm()
        :
          renderProblemForm() 
        }
      </>
    )
  }

  const renderProblemForm = () => {
    return (
      <>
        <div className="section-title">
          {t("report-problem.report_information").toUpperCase()}
        </div>
        <div className="section-wrapper">
          <div className="instruction-wrapper">
            <div className="title">{t("report-form.problem-instructions-title")}</div>
            <div className="instruction">
              <span>{`1. `}</span>
              <span>{t("report-form.problem-instructions-first")}</span>
            </div>
            <div className="instruction">
              <span>{`2. `}</span>
              <span>{t("report-form.problem-instructions-second")}</span>
            </div>
            <div className="instruction">
              <span>{`3. `}</span>
              <span>{t("report-form.problem-instructions-third")}</span>
            </div>
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.cycle-fault")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidCycleFault ? " has-error" : "") }
              value={cf || ""}
              onChange={(e) => setCf(e.target.value)}
            />
          </div>
          <div className="input-wrapper empty">
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.other")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={other || ""}
              onChange={(e) => setOther(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.job-number")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={jobNumber || ""}
              onChange={(e) => setJobNumber(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.description-issue")}`}
              <span className="required">*</span>
            </div>
            <textarea
              className={"text-area-item" + (!hasValidIssueDescription ? " has-error" : "") }
              value={issueDescription || ""}
              onChange={(e) => setIssueDescription(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.findings")}`}
            </div>
            <textarea
              className={"text-area-item"}
              value={findings || ""}
              onChange={(e) => setFindings(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.resolution")}`}
            </div>
            <textarea
              className={"text-area-item"}
              value={resolution || ""}
              onChange={(e) => setResolution(e.target.value)}
            />
          </div>
        </div>
      </>
    )
  }

  const renderComplaintForm = () => {
    return (
      <>
        <div className="section-title">
          {t("report-problem.event_information_complaint_form").toUpperCase()}
        </div>
        <div className="section-subtitle">
          {t("report-form.technician")}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.technician-name")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={scicanTechnicianName || ""}
              onChange={(e) => setScicanTechnicianName(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.customer-id")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={customerId || ""}
              onChange={(e) => setCustomerId(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.occupation")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidOccupation ? " has-error" : "") }
              value={occupation || ""}
              onChange={(e) => setOccupation(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.practice")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidCustomer ? " has-error" : "") }
              value={customer || ""}
              onChange={(e) => setCustomer(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.complaint-origin")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidComplaintOrigin ? " has-error" : "") }
              value={complaintOrigin || ""}
              onChange={(e) => setComplaintOrigin(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.location")}`}
            </div>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectLocationInput}
              toDisplayItem={selectLocationOptions}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.other-info")}`}
            </div>
            <textarea
              className={"text-area-item"}
              value={technicianOtherDetails || ""}
              onChange={(e) => setTechnicianOtherDetails(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.involvement")}`}
            </div>
            <div className="radio-input-wrapper">
              <div className="radio-wrapper" onClick={() => setPatientInvolvement(sampleAndInvolvementOptions[0])}>
                <RadioInput
                  text={t("report-form.simple")}
                  checked={patientInvolvement === sampleAndInvolvementOptions[0]}
                />
              </div>
              <div className="radio-wrapper" onClick={() => setPatientInvolvement(sampleAndInvolvementOptions[1])}>
                <RadioInput
                  text={t("report-form.advanced")}
                  checked={patientInvolvement === sampleAndInvolvementOptions[1]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="inner-separator"/>
        <div className="section-subtitle">
          {t("report-form.doctor-info")}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.doctor")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={doctorName || ""}
              onChange={(e) => setDoctorName(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.scican-rep")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={scicanRep || ""}
              onChange={(e) => setScicanRep(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("address")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidDoctorAddress ? " has-error" : "") }
              value={doctorAddress || ""}
              onChange={(e) => setDoctorAddress(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("city")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidDoctorCity ? " has-error" : "") }
              value={doctorCity || ""}
              onChange={(e) => setDoctorCity(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("state_province")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidDoctorState ? " has-error" : "") }
              value={doctorState || ""}
              onChange={(e) => setDoctorState(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("zip_postal_code")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidDoctorZip ? " has-error" : "") }
              value={doctorZip || ""}
              onChange={(e) => setDoctorZip(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("country")}`}
            </div>
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectCountryInput}
              toDisplayItem={selectCountryOptions}
            />
          </div>
          <div className="input-wrapper empty"/>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.report-date")}`}
              <span className="required">*</span>
            </div>
            <CalendarInput
              onChangeDay={(e) => setReportingDate(e)}
              value={reportingDate}
              yearsLimit={5}
              error={!hasValidReportingDate}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.occurrence-date")}`}
              <span className="required">*</span>
            </div>
            <CalendarInput
              onChangeDay={(e) => setDateOfOccurence(e)}
              value={dateOfOccurence}
              yearsLimit={5}
              error={!hasValidDateOfOccurence}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("technician")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={techician || ""}
              onChange={(e) => setTechician(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.dealer-branch")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={dealerBranch || ""}
              onChange={(e) => setDealerBranch(e.target.value)}
            />
          </div>
        </div>
        <div className="inner-separator"/>
        <div className="section-subtitle">
          {t("report-form.product-info")}
        </div>
        <div className="section-wrapper">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.product-number")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidProductNr ? " has-error" : "")}
              value={productNr || ""}
              onChange={(e) => setProductNr(e.target.value)}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.cycle-count")}`}
              <span className="required">*</span>
            </div>
            <input
              type="text" 
              className={"input-item" + (!hasValidCycleCount ? " has-error" : "")}
              value={cycleCount || ""}
              onChange={(e) => setCycleCount(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.expiry-date")}`}
            </div>
            <CalendarInput
              onChangeDay={(e) => setExpiryDate(e)}
              value={expiryDate}
              yearsLimit={5}
            />
          </div>
          <div className="input-wrapper">
            <div className="input-label">
              {`${t("report-form.product-name")}`}
            </div>
            <input
              type="text" 
              className={"input-item"}
              value={productName || ""}
              onChange={(e) => setProductName(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.other-info")}`}
            </div>
            <textarea
              className={"text-area-item"}
              value={productOtherInformation || ""}
              onChange={(e) => setProductOtherInformation(e.target.value)}
            />
          </div>
        </div>
        <div className="section-wrapper with-margin-top with-margin-bottom">
          <div className="input-wrapper full-width">
            <div className="input-label">
              {`${t("report-form.sample-enclosed")}`}
            </div>
            <div className="radio-input-wrapper">
              <div className="radio-wrapper" onClick={() => setSampleEnclosed(sampleAndInvolvementOptions[0])}>
                <RadioInput
                  text={t("report-form.simple")}
                  checked={sampleEnclosed === sampleAndInvolvementOptions[0]}
                />
              </div>
              <div className="radio-wrapper" onClick={() => setSampleEnclosed(sampleAndInvolvementOptions[1])}>
                <RadioInput
                  text={t("report-form.advanced")}
                  checked={sampleEnclosed === sampleAndInvolvementOptions[1]}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="separator"/>
        {renderProblemForm()}
      </>
    )
  }

  return (
    <div className="view-create-report-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleOnClose()}
        title={isViewMode? t("report-problem.report_details") : t("nav.report_problem")}
        footerContent={!isViewMode && createReportFooterContent()}
        className={isViewMode ? "view-mode" : ""}
      >
        <div className="report-modal-content">
          {isViewMode ?
            renderViewForm()
          :
            renderEditForm()
          }
        </div>
      </PageModal>
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function mapStateToProps({ authedUser, productTypes }) {
  return {
    authedUser,
    productTypes,
  }
}
export default connect(mapStateToProps)(ViewCreateReportModal)
