import { API } from "aws-amplify"
import { getIso639_2FromLocale } from "../languages"
import { store } from "../.."
import { setAuthedUser } from "../../actions/authedUser"

async function validateSession() {
  try {
    await API.Auth.currentSession()
  } catch (e) {
    if (e === "No current user") {
      store.dispatch(setAuthedUser(null))
    } else {
      throw e
    }
  }
}

async function trimDoubleSlashes(path, api) {
  // Avoid double slashes //
  const endpoint = await API.endpoint(api)
  if (endpoint.substr(-1) === "/" && path.substr(0, 1) === "/") {
    path = path.substr(1)
  }

  return path
}

export async function _baseGetFetch(path, baseParams, api) {
  let apiName = selectApi(api)
  const selectedLang = getIso639_2FromLocale(localStorage.getItem("i18nextLng"))
  await validateSession()
  return API.get(apiName, await trimDoubleSlashes(path), { queryStringParameters: { ...baseParams } })
}

export async function _baseGetFetchNoLang(path, baseParams, api) {
  let apiName = selectApi(api)
  await validateSession()
  return API.get(apiName, await trimDoubleSlashes(path), { queryStringParameters: { ...baseParams } })
}

export async function _basePostFetch(path, baseParams, api) {
  let apiName = selectApi(api)
  await validateSession()
  return API.post(apiName, await trimDoubleSlashes(path), { body: baseParams })
}

export async function _basePutFetch(path, baseParams, api) {
  let apiName = selectApi(api)
  await validateSession()
  return API.put(apiName, await trimDoubleSlashes(path), { body: baseParams })
}

export async function _basePatchFetch(path, baseParams, api) {
  let apiName = selectApi(api)
  await validateSession()
  return API.patch(apiName, await trimDoubleSlashes(path), { body: baseParams })
}

export async function _baseDeleteFetch(path, baseParams, api) {
  let apiName = selectApi(api)
  await validateSession()
  return API.del(apiName, await trimDoubleSlashes(path), { queryStringParameters: baseParams })
}

export async function _baseDeleteWithBodyFetch(path, baseParams, api) {
  let apiName = selectApi(api)
  await validateSession()
  return API.del(apiName, await trimDoubleSlashes(path), { body: baseParams })
}

const selectApi = (name) => {
  switch (name) {
    case ApiEnum.instrumentTracking: {
      return "mySciCanTrackingApiGateway"
    }
    case ApiEnum.records: {
      return "mySciCanRecordsApiGateway"
    }
    case ApiEnum.telemetry: {
      return "mySciCanTelemetryApiGateway"
    }
    case ApiEnum.admin: {
      return "mySciCanAdminApiGateway"
    }
    case ApiEnum.store: {
      return "mySciCanApiGatewayStore"
    }
    case ApiEnum.ai: {
      return "mySciCanApiGatewayAi"
    }
    default: {
      return "mySciCanApiGateway"
    }
  }
}

export const ApiEnum = Object.freeze({
  instrumentTracking: "instrumentTracking",
  records: "records",
  telemetry: "telemetry",
  store: "store",
  admin: "admin",
  ai: "ai"
})
