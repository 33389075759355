import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import AccountDetails from './AccountDetails'
import Notifications from "./Notifications"
import PaymentMethods from './PaymentMethods'
import MySubscriptions from "./MySubscriptions"
import MyOrders from "./MyOrders"
import MyOrganization from "./MyOrganization"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import WrappedTabs from '../common/WrappedTabs'
import Breadcrumb from "../common/Breadcrumb"
import { Roles } from "../../utils/roles"
import { Pages } from "../../utils/pages"
import { capitalizeWords } from "../../utils/functions"
import { useAppContext } from "../../libs/contextLib"
import { bellNotificationsPermissions, checkPermission } from "../../utils/permissionValidation"
import "./my-account.scss"

const MyAccount = (props) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { supportsColteneStore } = useAppContext()
  const [availablePages, setAvailablePages] = useState([])
  const [selectedPage, setSelectedPage] = useState(null)
  const myAccountPages =  useMemo(() => ([
    {
      key: 0,
      title: "account_context.profile",
      content: <AccountDetails />,
      roles: Roles.accountDetails.roles,
      permission: Pages.accountDetails.permission,
      path: Pages.accountDetails.route,
    },
    ...(checkPermission(props.userPermissions, bellNotificationsPermissions) ? [
      {
        key: 1,
        title: "nav.notifications",
        content: <Notifications />,
        roles: Roles.notifications.roles,
        permission: Pages.notifications.permission,
        path: Pages.notifications.route,
      }
    ] : []),
    ...(supportsColteneStore ? [
      {
        key: 2,
        title: "nav.payment_methods",
        content: <PaymentMethods />,
        roles: Roles.paymentMethods.roles,
        permission: Pages.paymentMethods.permission,
        path: Pages.paymentMethods.route,
      },
      {
        key: 3,
        title: "nav.my_subscriptions",
        content: <MySubscriptions />,
        roles: Roles.userSubscriptions.roles,
        permission: Pages.userSubscriptions.permission,
        path: Pages.userSubscriptions.route,
      },
      {
        key: 4,
        title: "nav.my_orders",
        content: <MyOrders />,
        roles: Roles.userOrders.roles,
        permission: Pages.userOrders.permission,
        path: Pages.userOrders.route,
      },
    ] : []),
    {
      key: 5,
      title: "nav.my_organization",
      content: <MyOrganization />,
      roles: Roles.userCompany.roles,
      permission: Pages.userCompany.permission,
      path: Pages.userCompany.route,
    },
  ]), [supportsColteneStore])
  
  useEffect(() => {
    let accessAllowedPages = []

    const permissionArr = props.userPermissions ? (Array.isArray(props.userPermissions) ? props.userPermissions : Object.values(props.userPermissions)) : []
    accessAllowedPages = myAccountPages.filter((page) => permissionArr.includes(page.permission?.toLowerCase()))

    const currentPageIndex = accessAllowedPages.findIndex((page) => page.path === history.location.pathname)

    setAvailablePages(accessAllowedPages)
    setSelectedPage(accessAllowedPages[currentPageIndex] ?? null)
  }, [history.location.pathname, myAccountPages, props.userPermissions])

  const handleTabClick = (index) => {
    const path = availablePages.find(({key}) => key === index).path
    history.push(path)
  }

  return (
    <div className="my-account-wrapper">
      <Breadcrumb path={window.location.pathname} />
      <DashboardHeader headerText={t("nav.my_account")} />
      <WrappedTabs tabs={availablePages.map((page) => ({ id: page.key, name: capitalizeWords(t(page.title)) }))} activeTab={selectedPage?.key} changeTab={handleTabClick} />
      {selectedPage?.content && selectedPage.content}
    </div>
  )
}

function mapStateToProps({ userPermissions }) {
  return {
    userPermissions,
  }
}

export default connect(mapStateToProps)(MyAccount)
