import { useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import SimpleButton from "../../common/SimpleButton"
import PageModal from "../../common/PageModal"
import LoadingSpinner from "../../common/LoadingSpinner"
import { toast } from "../../common/Toast"
import { usersRequestToJoinCompany } from "../../../utils/requests/companyAPI"
import "./request-join-modal.scss"

const RequestJoinModal = (props) => {
  const { t } = useTranslation()
  const { handleClose, currentUserEmail, fetchInvites } = props
  const [email, setEmail] = useState("")
  const [showSpinner, setShowSpinner] = useState(false)

  const getFooter = () => {
    return (
      <>
        <SimpleButton
          className="cancel-button"
          onClick={handleClose}
        >
          {t("cancel")}
        </SimpleButton>
        <SimpleButton 
          className="submit-button"
          onClick={handleSubmit}
          disabled={!email || email === currentUserEmail}
        >
          {t("send")}
        </SimpleButton>
      </>
    )
  }

  const handleSubmit = async () => {
    try {
      setShowSpinner(true)

      await usersRequestToJoinCompany({
        email,
      })

      fetchInvites()
  
      toast.success(t("account_context.request_sent"))

      setShowSpinner(false)
    } catch (error) {
      toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))

      setShowSpinner(false)
    }

    handleClose()
  }

  return (
    <PageModal
      toggle
      className="request-join-modal"
      onToggle={handleClose}
      title={t("account_context.request_to_join")}
      footerContent={getFooter()}
    >
      <div className="request-join-modal-content">
        <div className="input-wrapper">
          <p className="input-option">{t("account_context.admin_email")}*</p>
          <input 
            type="text" 
            className={"email-input"}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </PageModal>
  )
}

function stateToProps({ authedUser }) {
  return {
    currentUserEmail: authedUser?.email,
  }
}

export default connect(stateToProps)(RequestJoinModal)