import React, { useState } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import PageModal from "../../common/PageModal"
import SimpleButton from '../../common/SimpleButton'
import LoadingSpinner from '../../common/LoadingSpinner'
import GoogleAddressInput from "../../common/GoogleAddressInput"
import { toast } from "../../common/Toast"
import ConfirmationModal from "../../common/ConfirmationModal"
import { formatPhone } from "../../../utils/functions"
import { updateAccountToOrganization} from "../../../utils/requests/companyAPI"
import { getCurrentUserPermissions, validateCompanyName } from '../../../utils/requests/usersAPI'
import { handleUpdateAuthedUser } from "../../../actions/authedUser"
import { handleSetCurrentUserPermissions } from "../../../actions/userPermissions"
import countries from "../../../utils/countries.json"
import "./convert-to-organization.scss"

const ConvertToOrganizationModal = (props) => {
  const { t } = useTranslation()
  const { closeModal } = props
  const [companyName, setCompanyName] = useState(props.authedUser?.company || "")
  const [address, setAddress] = useState(props.authedUser?.address || "")
  const [city, setCity] = useState(props.authedUser?.city || "")
  const [stateProvince, setStateProvice] = useState(props.authedUser?.stateprovince || "")
  const [zipCode, setZipCode] = useState(props.authedUser?.postalcode || "")
  const [phone, setPhone] = useState(props.authedUser?.phone || "")
  const [showSpinner, setShowSpinner] = useState(false)
  const [haveError, setHaveError] = useState({})
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const phonePattern = new RegExp(/^\d*$/)
  const dentistUserGroup = "dentist"
  const dentistAdmin = "dentist_admin"

  const convertToOrganizationFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => closeModal()}
        >
          {t('cancel')}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={handleSubmitConvertRequest}
          disabled={showSpinner}
        >
          {t('submit')}
        </SimpleButton>
      </>
    )
  }

  const handleCheckCompanyExists = async () => {
    if (companyName.trim().length > 0) {
      setShowSpinner(true)

      const queryParams = { name: companyName.trim() }
      const companyValidationResponse = await validateCompanyName(queryParams)

      setShowSpinner(false)

      if (companyValidationResponse.isCompanyExists) {
        toast.error(t("error.company_name_exists_support"))

        setHaveError(prevError => ({
          ...prevError,
          companyName: true
        }))

        return
      }

      setHaveError(prevError => ({
          ...prevError,
          companyName: false
      }))
    }
  }

  const handleSubmitConvertRequest = () => {
    if (!isValidInput()) {
      toast.error(t("error.fill_all_fields"))
      return
    }

    setShowConfirmationModal(true)
  }

  const isValidInput = () => {
    let errors = {}
    let isValid = !haveError["companyName"]

    if (companyName.trim().length === 0) {
      errors = { ...errors, companyName: true }
      isValid = false
    }

    if (address.trim().length === 0) {
      errors = { ...errors, address: true }
      isValid = false
    }

    if (city.trim().length === 0) {
      errors = { ...errors, city: true }
      isValid = false
    }
    
    if (stateProvince.trim().length === 0) {
      errors = { ...errors, stateProvince: true }
      isValid = false
    }

    if (zipCode.trim().length === 0) {
      errors = { ...errors, zipCode: true }
      isValid = false
    }

    if (phone.trim().length === 0 || !phonePattern.test((phone).replaceAll("-",""))) {
      errors = { ...errors, phone: true }
      isValid = false
    }

    setHaveError(prevError => ({ ...errors, companyName: prevError.companyName }))
    return isValid
  }

  const handleConvertToOrganization = async () => {
    try {
      setShowSpinner(true)

      const bodyParams = {
        companyName: companyName,
        country: props.authedUser?.country,
        officeAddressOne: address,
        officeAddressTwo: "",
        city: city,
        stateProvinceRegion: stateProvince,
        zipPostalCode: zipCode,
        telephone: phone,
      }
      

      await updateAccountToOrganization(bodyParams)

      const userGroup = props.authedUser?.userGroup.split(/[_-]/)[0]

      if (userGroup === dentistUserGroup) {
        const permissions = await getCurrentUserPermissions()
        props.dispatch(handleSetCurrentUserPermissions(permissions))
      }

      props.dispatch(handleUpdateAuthedUser(
        { 
          companyAccountType: "organization_admin",
          ...(userGroup === dentistUserGroup ? { userGroup: dentistAdmin } : {}),
        }
      ))

      closeModal()
    } catch (error) {
      const errorCode = error.response?.data?.code
      const branchExistsErrorCode = "companyExists"
  
      if (errorCode === branchExistsErrorCode) {
        toast.error(t("error.company_name_exists_support"))
    
        setHaveError(prevError => ({
          ...prevError,
          companyName: true
        }))

        return
      }

      toast.error(t([`error.${errorCode}`, "error.something_wrong"]))
    } finally {
      setShowSpinner(false)
    }
  }

  return (
    <PageModal 
      toggle
      className="convert-to-organization-modal"
      onToggle={() => closeModal()} 
      title={t("account_context.convert_to_organization")} 
      footerContent={convertToOrganizationFooterContent()}
    >
      <div className="convert-to-organization-modal-content">
        <div className="row-wrapper">
          <div className="input-wrapper">
            <div className="modal-section-disclaimer">
              <p>
                {t("account_context.convert_to_organization_disclaimer")}
              </p>
            </div>
          </div>
        </div>
        <div className="row-wrapper">
          <div className="input-wrapper">
            <p className="input-option">{t("organization")}*</p>
            <input
              type="text" 
              className={"input-item" + (haveError["companyName"] ? " has-error" : "") }
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              onBlur={handleCheckCompanyExists}
            />
          </div>
        </div>
        <div className="row-wrapper">
          <div className="input-wrapper">
            <p className="input-option">{t("country")}</p>
            <input
              type="text" 
              className={"input-item"}
              value={countries.find(c => c.code === props.authedUser?.country)?.name || ''}
              disabled
            />
          </div>
        </div>
        <div className="row-wrapper">
          <GoogleAddressInput
            country={props.authedUser?.country}
            address={address}
            handleSetAddress={(e) => setAddress(e)}
            city={city}
            handleSetCity={(e) => setCity(e)}
            stateProvince={stateProvince}
            handleSetStateProvince={(e) => setStateProvice(e)}
            zipCode={zipCode}
            handleSetZipCode={(e) => setZipCode(e)}
            errors={haveError}
          />
        </div>
        <div className="row-wrapper">
          <div className="input-wrapper">
            <p className="input-option">{t("phone_number")}*</p>
            <input
              type="text" 
              className={"input-item" + (haveError["phone"] ? " has-error" : "") }
              value={phone ? formatPhone(phone) : ""}
              onChange={(e) => setPhone(formatPhone(e.target.value))}
            />
          </div>
        </div>
      </div>
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          className="convert-company-confirmation"
          onToggle={() => setShowConfirmationModal(false)}
          message={t("account_context.convert_organization_confirmation")}
          acceptButtonText={t("confirm")}
          onCancel={() => setShowConfirmationModal(false)}
          onAccept={() => {
            handleConvertToOrganization()
            setShowConfirmationModal(false)
          }}
        >
          <div className="convert-confirmation-wrapper">
            <div className="disclaimer">
              <p>
                {t("account_context.convert_organization_confirmation_disclaimer")}
              </p>
            </div>
          </div>
        </ConfirmationModal>
      )}
    </PageModal>
  )
}

function mapStateToProps({ authedUser }) {
  return {
    authedUser
  }
}
export default connect(mapStateToProps)(ConvertToOrganizationModal)
