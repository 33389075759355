import React from "react"
import { useTranslation } from "react-i18next"
import { Link } from 'react-router-dom'
import PageModal from "../../common/PageModal"
import SimpleButton from "../../common/SimpleButton"
import { SuccessCircleTick } from "../../../icons"
import { Pages } from '../../../utils/pages'
import { capitalizeWords } from '../../../utils/functions'
import "./order-status-modal.scss"

const OrderStatusModal = (props) => {
  const { t } = useTranslation()
  const { orderId, onClose } = props

  const handleOnClose = () => {
    if (onClose) {
      onClose()
    }    
  }

  return (
    <PageModal
      toggle
      onToggle={() => handleOnClose()}
      className="order-status-modal-wrapper"
    >
      <div className="order-status-modal-content">
        <SuccessCircleTick />
        <div className="title">{t("coltene_store.order_modal.ty-order")}</div>
        <div className="details">
          {t("coltene_store.order_modal.reciept-for-order")}
          <span className="strong">{orderId}</span> {t("coltene_store.order_modal.will-be-emailed")}{" "}
          <Link to={{
              pathname: Pages.userOrders.route,
              state: { orderId: orderId}
            }}
          >
            {`${capitalizeWords(t("nav.order_details"))} `}
          </Link>
          {t("coltene_store.order_modal.page")}
        </div>
        <SimpleButton 
          className="submit-button" 
          onClick={() => handleOnClose()}
        >
          {t("store_context.continue_shopping")}
        </SimpleButton>
      </div>
    </PageModal>
  )
}

export default OrderStatusModal
