import React, { useLayoutEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import ClickOutside from "../../common/ClickOutside"
import { loadAdminStoreProductCategories } from "../../../actions/adminStore"
import { loadServices } from "../../../actions/services"
import { CaretDownIcon } from "../../../icons"
import { useWindowSize } from "../../../libs/hooks"
import { capitalizeFirstLetter } from "../../../utils/functions"

const hasEnabledDailyPlans = process.env.REACT_APP_ONE_DAY_SUBSCRIPTION_TOGGLE?.toLowerCase() === "on"

const FormProductDetails = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { 
    productDetailsFormData,
    productDetailsErrors,
    isEditMode,
    updateProductDetails,
    productCategories,
    services,
    pricePlans,
  } = props
  const [productCategoryOptions, setProductCategoryOptions] = useState([])
  const [serviceOptions, setServiceOptions] = useState([])
  const isDigitalProductOptions = [t("no"), t("yes")]
  const filteredPricePlans = pricePlans.filter((p) => hasEnabledDailyPlans || p.recurrenceInterval !== "day")

  useLayoutEffect(() => {
    if (props.productCategories.length === 0) {
      props.actions.loadAdminStoreProductCategories()
    }

    if (props.services.length === 0) {
      props.actions.loadServices({ isEnabled: true }, false, true) 
    }
  }, [props.actions])

  useLayoutEffect(() => {
    let categoryId = productDetailsFormData.categoryId
    let serviceId = productDetailsFormData.serviceId

    const categories = props.productCategories
      .filter((category) => productDetailsFormData.isDigital === category.isDigital)
      .map((category) => { return { id: category.id, name: category.name }})

    setProductCategoryOptions(categories)
    
    if (categories.length > 0) {
      categoryId = categoryId ? categoryId : categories[0].id
    }

    if (productDetailsFormData.isDigital) {
      const services = props.services.map((service) => { return { id: service.id, name: service.name }})

      setServiceOptions(services)

      if (services.length > 0) {
        serviceId = serviceId ? serviceId : services[0].id
      }
    }

    updateProductDetails({
      ...productDetailsFormData,
      categoryId: categoryId,
      serviceId: serviceId,
    })
  }, [props.productCategories, props.services, productDetailsFormData.isDigital])

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectIsDigitalProduct = (isDigitalProduct, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    if ((isDigitalProduct === isDigitalProductOptions[1] && productDetailsFormData.isDigital) || (isDigitalProduct === isDigitalProductOptions[0] && !productDetailsFormData.isDigital)) {
      return
    }

    updateProductDetails({ 
      ...productDetailsFormData, 
      isDigital: isDigitalProduct === isDigitalProductOptions[1],
      categoryId: null,
      serviceId: null,
      planType: null,
    })
  }

  const selectIsDigitalProductInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {productDetailsFormData.isDigital ? isDigitalProductOptions[1] : isDigitalProductOptions[0]}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectIsDigitalProductOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {isDigitalProductOptions.map((isDigitalProductOption, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + ((isDigitalProductOption === isDigitalProductOptions[0] && !productDetailsFormData.isDigital) || (isDigitalProductOption === isDigitalProductOptions[1] && productDetailsFormData.isDigital) ? " selected-option" : "")} 
          onClick={() => selectIsDigitalProduct(isDigitalProductOption, toggleVisibility)}
        >
          {isDigitalProductOption}
        </div>)
      }
    </div>
  )

  const selectCategory = (categoryId, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    if (categoryId === productDetailsFormData.categoryId) {
      return
    }
    
    updateProductDetails({ ...productDetailsFormData, categoryId: categoryId })
  }

  const selectCategoryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {productDetailsFormData.categoryId ? productCategoryOptions.find((c) => c.id === productDetailsFormData.categoryId)?.name : t("coltene_store.create_product.select_option")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCategoryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {productCategoryOptions.map((categoryOption, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (categoryOption.id === productDetailsFormData.categoryId ? " selected-option" : "")} 
          onClick={() => selectCategory(categoryOption.id, toggleVisibility)}
        >
          {categoryOption.name}
        </div>)
      }
    </div>
  )

  const selectService = (serviceId, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    if (serviceId === productDetailsFormData.serviceId) {
      return
    }

    updateProductDetails({ ...productDetailsFormData, serviceId: serviceId })
  }

  const selectServiceInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {productDetailsFormData.serviceId ? serviceOptions.find((c) => c.id === productDetailsFormData.serviceId)?.name : t("coltene_store.create_product.select_option")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectServiceOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {serviceOptions.map((serviceOption, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (serviceOption.id === productDetailsFormData.serviceId ? " selected-option" : "")} 
          onClick={() => selectService(serviceOption.id, toggleVisibility)}
        >
          {serviceOption.name}
        </div>)
      }
    </div>
  )

  const selectPlanType = (planType, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    if (planType === productDetailsFormData.planType) {
      return
    }

    updateProductDetails({ ...productDetailsFormData, planType: planType })
  }

  const selectPlanInput = ({toggleVisibility, isComponentVisible}) => (
    <div>
      <div 
        className={`height d-flex flex-align-center flex-justify-between`} 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {productDetailsFormData.planType ? filteredPricePlans.find((p) => p.recurrenceInterval === productDetailsFormData.planType)?.title : t("coltene_store.create_product.select_option")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )
  
  const selectPlanTypeOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {filteredPricePlans.map((pricePlan, index) => 
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (pricePlan.recurrenceInterval === productDetailsFormData.planType ? " selected-option" : "")}
          onClick={() => selectPlanType(pricePlan.recurrenceInterval, toggleVisibility)}
        >
          {pricePlan.title}
        </div>
      )}
    </div>
  )

  return (
    <div className="product-details-wrapper">
      <div className="fixed-input-wrapper one-third-wrapper">
        <div className="input-label">
          {capitalizeFirstLetter(t("part_number"))}
          <span className="required-mark">*</span>
        </div>
        {!isEditMode ?
          <input
            type="text"
            className={"input-item" + (productDetailsErrors?.partNumber ? " input-error" : "") }
            value={productDetailsFormData.partNumber} 
            onChange={(e) => updateProductDetails({ ...productDetailsFormData, partNumber: e.target.value })}
          />
        :
          <input
            type="text"
            className="input-item"
            value={productDetailsFormData.partNumber}
            disabled
          />
        }
      </div>
      <div className="fixed-input-wrapper two-third-wrapper">
        <div className="input-label">
          {t("coltene_store.create_product.product-main-title")}
          <span className="required-mark">*</span>
        </div>
        <input
          type="text"
          className={"input-item" + (productDetailsErrors?.productMainTitle ? " input-error" : "") }
          value={productDetailsFormData.productMainTitle} 
          onChange={(e) => updateProductDetails({ ...productDetailsFormData, productMainTitle: e.target.value })}
        />
      </div>
      <div className="fixed-input-wrapper two-third-wrapper">
        <div className="input-label">
          {t("coltene_store.create_product.subtitle")}
          <span className="required-mark">*</span>
        </div>
        <input
          type="text"
          className={"input-item" + (productDetailsErrors?.productSubtitle ? " input-error" : "") }
          value={productDetailsFormData.productSubtitle} 
          onChange={(e) => updateProductDetails({ ...productDetailsFormData, productSubtitle: e.target.value })}
        />
      </div>
      <div className={`product-options-wrapper ${isMobile && "flex-column"}`}>
        <div className="fixed-input-wrapper one-third-wrapper">
          <div className="input-label">
            {t("coltene_store.create_product.digital-product")}
            <span className="required-mark">*</span>
          </div>
          {!isEditMode ?
            <ClickOutside
              itemRef="click-outside-wrapper"
              className={productDetailsErrors?.isDigital ? "input-error" : ""}
              eventItem={selectIsDigitalProductInput}
              toDisplayItem={selectIsDigitalProductOptions}
            />
          :
            <input 
              className="input-item"
              value={productDetailsFormData.isDigital ? t("yes") : t("no")} 
              disabled
            />
          }
        </div>
        <div className="fixed-input-wrapper one-third-wrapper">
          <div className="input-label">
            {t("category")}
            <span className="required-mark">*</span>
          </div>
          {!isEditMode ?
            <ClickOutside
              itemRef="click-outside-wrapper"
              className={productDetailsErrors?.categoryId ? "input-error" : ""}
              eventItem={selectCategoryInput}
              toDisplayItem={selectCategoryOptions}
            />
          :
            <input 
              className="input-item"
              value={
                 productDetailsFormData.categoryId ? 
                 productCategories.find(e => e.id === productDetailsFormData.categoryId)?.name || "" : 
                 ""
              } 
              disabled
            />
          }
        </div>
        {productDetailsFormData.isDigital &&
          <div className="fixed-input-wrapper one-third-wrapper">
            <div className="input-label">
              {t("coltene_store.create_product.service")}
              <span className="required-mark">*</span>
            </div>
            {!isEditMode ?
              <ClickOutside
                itemRef="click-outside-wrapper"
                className={productDetailsErrors?.serviceId ? "input-error" : ""}
                eventItem={selectServiceInput}
                toDisplayItem={selectServiceOptions}
              />
            :
              <input 
                className="input-item"
                value={
                  productDetailsFormData.serviceId ? 
                  services.find(e => e.id === productDetailsFormData.serviceId)?.name || ""
                  : ''
                }
                disabled
              />
            }
          </div>
        }
      </div>
      {productDetailsFormData.isDigital &&
        <div className="fixed-input-wrapper one-third-wrapper">
          <div className="input-label">
            {t("coltene_store.subscription_history.planType")}
            <span className="required-mark">*</span>
          </div>
          {!isEditMode ?
            <ClickOutside
              itemRef="click-outside-wrapper"
              className={productDetailsErrors?.planType ? "input-error" : ""}
              eventItem={selectPlanInput}
              toDisplayItem={selectPlanTypeOptions}
            />
          :
            <input 
              className="input-item"
              value={
                 productDetailsFormData.planType ? 
                 filteredPricePlans.find(e => e.recurrenceInterval === productDetailsFormData.planType)?.title || "" : 
                 ""
              }
              disabled
            />
          }
        </div>
      }
    </div>
  )
}

function stateToProps({ adminStore, services }) {
  return {
    productCategories: adminStore?.productCategories || [],
    services: services?.services || [],
    pricePlans: adminStore?.pricePlans || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        loadAdminStoreProductCategories,
        loadServices,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps, dispatchToProps)(FormProductDetails)
