import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import PageModal from "../common/PageModal"
import SimpleButton from "../common/SimpleButton"
import { toast } from "../common/Toast"
import { postInstrumentReprocessingNote } from "../../utils/requests/usersAPI"
import './add-note-modal.scss'

const AddNoteModal = (props) => {
  const { t } = useTranslation()
  const { identifier, onSuccesfullAddNote, setShowSpinner, closeModal } = props
  const [note, setNote] = useState("")

  const addNoteFooter = () => {
    return (
      <>
        <SimpleButton className="cancel-button" onClick={() => closeModal()}>
          {t('cancel')}
        </SimpleButton>
        <SimpleButton className="submit-button" disabled={note.length === 0} onClick={() => handleAddNote()}>
          {t('save')}
        </SimpleButton>
      </>
    )
  }

  const handleAddNote = async () => {
    if (!identifier) {
      toast.error(t("error.something_wrong"))
    
      return
    }

    setShowSpinner(true)

    const bodyParams = {
      note: note,
      sn: identifier.serialNumber,
      printoutFileName: identifier.fileName || "",
      cycleNumber: Number(identifier.cycleFault),
      date: identifier.date,
      pedSource: identifier.pedSource,
      isG4Plus: identifier.isG4Plus,
      modelId: identifier.modelId || "",
      id: identifier.id
    }
    
    try {
      await postInstrumentReprocessingNote(props.userUuid, bodyParams)

      toast.success(t("reports.add_note_success"))

      onSuccesfullAddNote(identifier, note)
      closeModal()
      setShowSpinner(false)
    } catch (error) {
      toast.error(t("error.something_wrong"))

      setShowSpinner(false)
    }
  }

  return (
    <PageModal
      toggle
      className="add-report-note-modal"
      title={t('reports.add_note')}
      onToggle={() => closeModal()}
      footerContent={addNoteFooter()}
    >
      <div className="add-note-content">
        <p className="input-option">{t('reports.note')}</p>
        <textarea
          className="note-input"
          value={note || ""}
          onChange={(e) => setNote(e.target.value)}>
        </textarea>
      </div>
    </PageModal>
  )
}

export default AddNoteModal
