import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import PageModal from "../common/PageModal"
import ClickOutside from "../common/ClickOutside"
import SimpleButton from '../common/SimpleButton'
import GoogleAddressInput from "../common/GoogleAddressInput"
import { toast } from "../common/Toast"
import { CaretDownIcon } from "../../icons"
import { putBranch } from "../../actions/branches"
import countries from "../../utils/countries.json"
import { branchesWritePermission } from "../../utils/permissionValidation"
import "./add-edit-branch.scss"

const AddEditBranch = (props) => {
  const { t } = useTranslation()
  const { selectedBranch, closeModal, showSpinner } = props
  const [branchName, setBranchName] = useState("")
  const [country, setCountry] = useState("")
  const [address, setAddress] = useState("")
  const [city, setCity] = useState("")
  const [stateProvince, setStateProvice] = useState("")
  const [zipCode, setZipCode] = useState("")
  const [haveError, setHaveError] = useState({})

  useEffect(() => {
    if (selectedBranch) {
      setBranchName(selectedBranch.branchName)
      setCountry(selectedBranch.country || "")
      setAddress(selectedBranch.officeAddressOne || "")
      setCity(selectedBranch.city || "")
      setStateProvice(selectedBranch.stateProvinceRegion || "")
      setZipCode(selectedBranch.zipPostalCode || "")
    }
  },[selectedBranch])

  const branchFooterContent = () => {
    return (
      <>
        <SimpleButton 
          className="cancel-button" 
          onClick={() => closeModal()}
        >
          {t('cancel')}
        </SimpleButton>
        <SimpleButton 
          className="submit-button" 
          onClick={() => handleAddEditBranch()}
          disabled={showSpinner || hasSameInput()}
          requiredPermission={branchesWritePermission}
        >
          {t('save')}
        </SimpleButton>
      </>
    )
  }

  const hasSameInput = () => {
    return (
      selectedBranch &&
      branchName === selectedBranch.branchName &&
      country === selectedBranch.country &&
      address === selectedBranch.officeAddressOne &&
      city === selectedBranch.city &&
      stateProvince === selectedBranch.stateProvinceRegion &&
      zipCode === selectedBranch.zipPostalCode
    )
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCountry = (country, toggleVisibility) => {
    setCountry(country)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {country ? countries?.find((c) => c.code === country)?.name : t("users-management.select-country")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {countries?.map((countryOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (countryOption["code"] === country ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCountry(countryOption["code"], toggleVisibility)}
        >
          {countryOption["name"]}
        </div>
      ))
      }
    </div>
  )

  const isValidInput = () => {
    let errors = {}
    let isValid = true

    if (!branchName.trim()) {
      errors = { ...errors, branchName: true }
      isValid = false
    }

    if (!country.trim()) {
      errors = { ...errors, country: true }
      isValid = false
    }

    if (!address.trim()) {
      errors = { ...errors, address: true }
      isValid = false
    }

    if (!city.trim()) {
      errors = { ...errors, city: true }
      isValid = false
    }

    if (!stateProvince.trim()) {
      errors = { ...errors, stateProvince: true }
      isValid = false
    }

    if (!zipCode.trim()) {
      errors = { ...errors, zipCode: true }
      isValid = false
    }

    setHaveError(errors)
    return isValid
  }

  const handleAddEditBranch = () => {
    if (!isValidInput()) {
      toast.error(t("error.fill_all_fields"))
      return
    }

    const bodyParams = {
      branchName: branchName.trim(),
      email: selectedBranch.email,
      country: country.trim(),
      officeAddressOne: address.trim(),
      city: city.trim(),
      stateProvinceRegion: stateProvince.trim(),
      zipPostalCode: zipCode.trim(),
    }

    if (selectedBranch) {
      props.actions.putBranch(selectedBranch.companyId, selectedBranch.id, bodyParams)
    }

    closeModal()
  }

  return (
    <PageModal
      toggle
      onToggle={() => closeModal()}
      className="add-edit-branch-modal"
      title={selectedBranch ? t("organization_context.edit_branch") : t("organization_context.add_branch")}
      footerContent={branchFooterContent()}
    >
      <div className="add-edit-branch-modal-content">
        <div className="input-wrapper">
          <p className="input-option">{t('name')}*</p>
          <input 
            type="text" 
            className={"name-input" + (haveError["branchName"] ? " has-error" : "")}
            value={branchName}
            onChange={(e) => setBranchName(e.target.value)}
          />
        </div>
        <div className="input-wrapper">
          <p className="input-option">{t('email')}</p>
          <input 
            type="text" 
            className="name-input"
            value={selectedBranch?.email || ""}
            disabled
          />
        </div>
        <div className="input-wrapper">
          <p className="input-option">{t('country')}*</p>
          <ClickOutside
            itemRef="click-outside-wrapper"
            className={haveError["country"] ? " has-error" : ""}
            eventItem={selectCountryInput}
            toDisplayItem={selectCountryOptions}
          />
        </div>
        <GoogleAddressInput
          country={country}
          address={address}
          handleSetAddress={(e) => setAddress(e)}
          city={city}
          handleSetCity={(e) => setCity(e)}
          stateProvince={stateProvince}
          handleSetStateProvince={(e) => setStateProvice(e)}
          zipCode={zipCode}
          handleSetZipCode={(e) => setZipCode(e)}
          errors={haveError}
        />
      </div>
    </PageModal>
  )
}

function stateToProps({ companies }) {
  return {
    companies: companies?.companies || [],
  }
}

function dispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        putBranch,
      },
      dispatch
    ),
  }
}

export default connect(stateToProps,dispatchToProps)(AddEditBranch)
