import React, { useState, useRef, useEffect, useCallback } from "react"
import { useTranslation } from "react-i18next"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import StatusLegend from "./StatusLegend"
import Calendar from "../common/Calendar"
import LoadingSpinner from "../common/LoadingSpinner"
import CycleDetailsModal from "../common/CycleDetailsModal"
import { toast } from "../common/Toast"
import { getProductCycles, getProductCycleDetails } from "../../utils/requests/productsAPI"
import { getCycleDetails, getMQTTCycles } from '../../utils/requests/trackingAPI'
import { convertToSimpleDateWithFullTime, formatDate, formatVal, months } from "../../utils/filters/date"
import {useWindowSize} from "../../libs/hooks"
import { useAppContext } from "../../libs/contextLib"
import "./cycle-calendar.scss"

const CycleCalendar = (props) => {
  const { t, i18n } = useTranslation()
  const { showFeatureDev } = useAppContext()
  const windowSize = useWindowSize()[0]
  const lastCycleDate = props.lastCycleDate
  const isG4Plus = window.history.state?.state?.is_g4_plus || props.isG4Plus
  const hasOnlineAccess = props.hasOnlineAccess || window.history.state?.hasOnlineAccess
  const [events, setEvents] = useState([])
  const [currentDate, setCurrentDate] = useState(null)
  const [cycleDetails, setCycleDetails] = useState({})
  const [cycleDetailsDeviceData, setCycleDetailsDeviceData] = useState({})
  const [selectedDay, setSelectedDay] = useState(lastCycleDate ? 
    `${formatDate(lastCycleDate)}` : 
    `${(new Date()).getFullYear()}-${formatVal((new Date()).getMonth()+1)}-${formatVal((new Date()).getDate())}`
  )
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showCycleDetails, setShowCycleDetails] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const calendarRef = useRef(null)
  const calendarWrapperRef = useRef(null)
  const langCode = i18n.language ? i18n.language.substring(0, 2) : 'en'
  const localMonths = months()
  const eventDisp = "block"
  const legendData = [
    {
      icon: <div className="success-icon status-icon"></div>,
      label: t("device_context.successful"),
    },
    {
      icon: <div className="error-icon status-icon"></div>,
      label: t("device_context.fault_cycle"),
    },
    {
      icon: <div className="warning-icon status-icon"></div>,
      label: t("device_context.interrupted_no_cf"),
    },
    // {
    //   icon: <div className="pending-icon status-icon"></div>,
    //   label: t("device_context.check_usb"),
    // },
  ]
  const cycleFaultDate = window.history.state?.state?.cycleFaultDate
  const cycleFault = window.history.state?.state?.cycleFault
  const cycleNumber = window.history.state?.state?.cycleNumber
  const isG4PlusAPIEnabled = process.env.REACT_APP_G4PLUS_API_TOGGLE === "on"

  useEffect(() => {
    const forceUpdateSize = () => {
      if (calendarRef.current) {
        calendarRef.current.getApi().updateSize()
      }
    }

    if (calendarWrapperRef.current) {
      const observer = new ResizeObserver((entries) => {
        for (let entry of entries) {
          if (entry.contentRect.width > 0 && entry.contentRect.height > 0) {
            forceUpdateSize()
          }
        }
      })

      observer.observe(calendarWrapperRef.current)

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      window.history.replaceState({
        lastCycleDate: lastCycleDate,
        hasOnlineAccess: hasOnlineAccess,
      }, '')
    })

    return () => {
      window.removeEventListener('beforeunload', () => {})
    }
  }, [lastCycleDate])

  useEffect(() => {
    if (props.isDentist && !hasOnlineAccess) {
      return
    }

    if (!currentDate) {
      return
    }

    const startDate = new Date(currentDate.start)
    const endDate = new Date(currentDate.end)

    if (isFirstLoad && (lastCycleDate || cycleFaultDate)) {
      setIsFirstLoad(false)
      return
    }

    if (isG4Plus && showFeatureDev && isG4PlusAPIEnabled) {
      getMQTTProductCycles(startDate, endDate)
    } else {
      getClassicProductCycles(startDate, endDate)
    }
  }, [currentDate])

  useEffect(() => {
    if (lastCycleDate || cycleFaultDate) {
      goToSelectedDate(lastCycleDate || cycleFaultDate)
    }
  }, [lastCycleDate, cycleFaultDate])

  useEffect(() => {
    const cycleFaultEvent = events.find((event) => 
      event.cycleDate?.split(/[ T]/)[0] === cycleFaultDate && 
      event.cycle_fault === cycleFault &&
      event.cycle_number === Number(cycleNumber)
    )

    if (cycleFaultEvent && calendarRef.current) {
      const calendarApi = calendarRef.current.getApi()

      const event = calendarApi.getEventById(cycleFaultEvent.id)
      if (event) {
        handleEventClick({ event })
      }
    }
  }, [events, cycleFaultDate, cycleFault, cycleNumber])

  const goToSelectedDate = (e) => {
    const date = new Date(e)

    let calendarApi = calendarRef.current.getApi()
    calendarApi.gotoDate(date)

    document.querySelector(".calendar").style.display = "none"
    setShowDatePicker(false)
  }

  const formatSelectedDay = (date) => {
    const day = date.substring(8, 10)
    return `${day} ${localMonths[(new Date(date)).getMonth()]} ${(new Date(date)).getFullYear()}`
  }

  const handleSelectedDay = (e) => {
    setSelectedDay(formatSelectedDay(e))

    goToSelectedDate(e)
  }

  const handleDateChange = (date) => {
    setCurrentDate({
      start: date?.start,
      end: date?.end,
    })
  }

  const handleEventClick = (e) => {
    const fcPopover = document.querySelector(".fc-popover")

    if (fcPopover) {
      fcPopover.style.display = "none"
    }

    setShowSpinner(true)

    setCycleDetailsDeviceData({
      model: props.model,
      cycleNumber: e.event.extendedProps.cycle_number,
      cycleStartTime: e.event.extendedProps.cycleDate,
      deviceSerialNumber: e.event.extendedProps.serial_number,
      cid: e.event.extendedProps.cid,
      cycleFault: e.event.extendedProps.cycle_fault,
    })

    let fetchCycleDetailsPromise

    if (showFeatureDev && isG4Plus && isG4PlusAPIEnabled) {
      fetchCycleDetailsPromise = getCycleDetails(e.event.extendedProps.recordsUuid, e.event.extendedProps.serial_number)
    } else {
      fetchCycleDetailsPromise = getProductCycleDetails(e.event.extendedProps.serial_number, e.event.extendedProps.cid, { model_id: props.modelId || ""})
    }

    fetchCycleDetailsPromise
      .then((res) => {
        setShowSpinner(false)

        setCycleDetails(res.data || res || {})

        setShowCycleDetails(true)
      })
      .catch((error) => {
        setShowSpinner(false)

        toast.error(t([`error.${error?.response?.data?.code}`, "error.something_wrong"]))

        setShowCycleDetails(true)
      })
  }

  const getClassicProductCycles = async (startDate, endDate) => {
    setShowSpinner(true)

    getProductCycles(props.sn, {
      "start_date": formatDate(startDate),
      "end_date": formatDate(endDate),
    })
      .then((cycles) => {
        let eventData = []
        if(cycles.data) {
          eventData = cycles.data.map((cycle) => {
            return {
              id: cycle.cid,
              title: cycle.cycle_fault ? `${t("cycle")} ${cycle.cycle_number} CF-${cycle.cycle_fault}` : `${t("cycle")} ${cycle.cycle_number}`,
              className: cycle.cycle_event
                ? cycle.cycle_event.toLowerCase().replace(/_/g, "-")
                : "cycle-pending",
              cycleDate: cycle.start,
              ...cycle,
            }
          })
        }

        setShowSpinner(false)

        setEvents(eventData)
      })
      .catch((e) => {
        console.log("error", e.message)
        setShowSpinner(false)
      })
  }

  const getMQTTProductCycles = async (startDate, endDate) => {
    setShowSpinner(true)

    getMQTTCycles(props.sn, {
      "cycleStartDate": formatDate(startDate).replace(/-/g, ""),
      "cycleEndDate": formatDate(endDate).replace(/-/g, ""),
    })
      .then((cycles) => {
        let eventData = []
        if(cycles.length > 0) {
          eventData = cycles.map((cycle) => {
            return {
              id: `${cycle.cycleNumber}-${cycle.cycleFault}-${cycle.cycleStartDateTime}`,
              title: cycle.cycleFault ? `${t("cycle")} ${cycle.cycleNumber} CF-${cycle.cycleFault}` : `${t("cycle")} ${cycle.cycleNumber}`,
              className: cycle.cycleEvent
                ? cycle.cycleEvent?.toLowerCase().replace(/_/g, "-")
                : "cycle-pending",
              start: convertToSimpleDateWithFullTime(cycle.cycleStartDateTime),
              end: convertToSimpleDateWithFullTime(cycle.cycleEndDateTime),
              cycleDate: cycle.cycleStartDateTime,
              serial_number: cycle.deviceSerialNumber,
              cycle_number: cycle.cycleNumber,
              cycle_fault: cycle.cycleFault,
              recordsUuid: cycle.recordsUuid,
              cycle_start_time: cycle.cycle_start_time,
            }
          })
        }

        setShowSpinner(false)

        setEvents(eventData)
      })
      .catch((e) => {
        console.log("error", e.message)
        setShowSpinner(false)
      })
  }
  
  function renderEventContent(eventInfo) {
    if(windowSize > 1240) {
      return (
        <>
          <span>{eventInfo.event.title}</span>
          <span>{eventInfo.event._def.extendedProps.cycle_start_time ? eventInfo.timeText : <span> </span> }</span>
        </>
      )
    }

    if (windowSize > 360) {
      return (
        <span>{eventInfo.event.title.split(" ", 2).toString().replaceAll(",", " ")}</span>
      )
    }

    return (
      <span>{eventInfo.event.title.split(" ", 2).toString().replace("Cycle", "Cyc").replaceAll(",", " ")}</span>
    )
  }

  const renderMoreLinkContent = (eventInfo) => {
    return (
      <>
        <span>{eventInfo.shortText}</span>
        <span> {t("more")}</span>
      </>
    )
  }
  
  return (
    <div className="cycle-calendar-wrapper" ref={calendarWrapperRef}>
      <div>
        <div className="calendar-legend">
          <StatusLegend statusData={legendData} />
        </div>
      </div>

      <div className="bg-white" style={{ flexGrow: 1 }}>
        <FullCalendar
          ref={calendarRef}
          locale={langCode}
          plugins={[dayGridPlugin, timeGridPlugin]}
          eventDisplay={eventDisp}
          customButtons={{
            selectDate: {
              text: t("date"),
              click: (e) => {
                const curNode = document.querySelector(".fc-selectDate-button");

                if (curNode.querySelectorAll(".calendar").length === 0) {
                  const elem = document.querySelector(".calendar");

                  curNode.parentNode.insertBefore(elem, curNode.nextSibling);
                }

                document.querySelector(".calendar").style.display = showDatePicker ? "none" : "block";
                setShowDatePicker(!showDatePicker);
              },
            },
          }}
          headerToolbar={{
            left: "today,selectDate",
            center: "prev title next",
            right: "dayGridMonth,timeGridWeek",
          }}
          buttonText= {{
            today: t("today"),
            month: t("month"),
            week: t("calendar.week"),
            day: t("calendar.day")
          }}
          initialView="dayGridMonth"
          dayMaxEvents={true}
          eventContent={renderEventContent}
          moreLinkContent={renderMoreLinkContent}
          views={{
            dayGrid: {
              dayMaxEvents: 3,
            },
          }}
          events={events}
          eventOrderStrict={true}
          eventOrder="cycle_number"
          datesSet={handleDateChange}
          eventClick={handleEventClick}
          eventTimeFormat={{
            hour: "numeric",
            minute: "2-digit",
            meridiem: "short",
          }}
          allDaySlot={false}
        />
        <div
          className={"calendar-wrapper" + (!showDatePicker ? " visually-hidden" : "")}
        >
          <Calendar onChangeDay={handleSelectedDay} onChangeMonth={() => {}} selectedDay={selectedDay}/>
        </div>
      </div>
      {showCycleDetails &&
        <CycleDetailsModal
          deviceData={cycleDetailsDeviceData}
          cycleDetails={cycleDetails}
          closeModal={() => {
            setShowCycleDetails(false)
            setCycleDetails({})
            setCycleDetailsDeviceData({})
          }}
          hasCycleNavigation
          isG4Plus={isG4Plus}
        />
      }
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default CycleCalendar