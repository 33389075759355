import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import OrderDetailsItem from "./OrderDetailsItem"
import SimpleButton from "../../common/SimpleButton"
import LoadingSpinner from "../../common/LoadingSpinner"
import ConfirmationModal from "../../common/ConfirmationModal"
import { toast } from "../../common/Toast"
import { useWindowSize } from "../../../libs/hooks"
import { formatStoreProductPrice } from "../../../utils/filters/filters"
import { convertToSimpleDateWithTime } from "../../../utils/filters/date"
import { getUserOrderDocuments, getUserOrderDocumentS3Location } from "../../../utils/requests/productsAPI"
import { BackSimpleIcon, CheckCircleFilledIcon, PendingIcon, ErrorCircleActiveIcon } from "../../../icons"
import "./order-details.scss"

const OrderDetails = (props) => {
  const { t } = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const { onToggle, order, canRequestRefund, handleRequestRefund } = props
  const [showCancelOrderModal, setShowCancelOrderModal] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [userOrderDocuments, setUserOrderDocuments] = useState([])
  const [isPromotion, setIsPromotion] = useState(false)
  const [totalItemsPrice, setTotalItemsPrice] = useState(0)

  useEffect(() => {
    getUserOrderDocuments(order.id).then((result) => setUserOrderDocuments(result))
  }, [])

  useEffect(() => {
    if (Number(order?.paymentAmount) === 0) {
      setIsPromotion(true)

      const itemsPrice = order?.items?.reduce((acc, item) => {
        const itemTotal = (Number(item.product?.price) || 0) * (item.quantity || 0)
        return acc + itemTotal
      }, 0) || 0

      setTotalItemsPrice(itemsPrice)
    }
  }, [order])

  const handleDownloadInvoice = (order, e) => {
    e.stopPropagation()

    if (showSpinner) {
      return
    }

    setShowSpinner(true)

    getUserOrderDocumentS3Location(order.id, userOrderDocuments[0]?.id)
      .then((orderLink) => {
        window.open(orderLink, "_blank")
      })
      .catch((error) => {
        toast.error(t("error.something_wrong"))
      })
      .finally(() => {
        setShowSpinner(false)
      })
  }
  
  return (
    <div className="order-details-wrapper">
      <div className="header">
        <BackSimpleIcon className="back-icon" onClick={() => onToggle()} />
        <div className="order-title">
          {t("coltene_store.order_details.order-no")} {order.id}
        </div>
      </div>
      {!isMobile &&
        <div className="order-details-container">
          <div className="ordered-products-container">
            <div className="ordered-products-title">{t("store_context.ordered_products")}</div>
            <div className="table-products d-flex flex-column">
              <div className="table-products-content">
                <div className="table-products-header">
                  <div className="header-products d-flex">
                    <div className="header-products-name">{t("product")}</div>
                  </div>
                  <div className="header-products d-flex">
                    <div className="header-products-name">{t("quantity")}</div>
                  </div>
                  <div className="header-products d-flex">
                    <div className="header-products-name">{t("price")}</div>
                  </div>
                  <div className="header" />
                </div>
                {order.items && order.items.map((item, index) => 
                  <div key={index} className="table-products-row">
                    <div className="row-product-content d-flex flex-align-center">
                      <div className="row-product-data">
                        <OrderDetailsItem item={item} />
                      </div>
                    </div>
                    <div className="row-product-content d-flex flex-align-center">
                      <div className="row-product-data text-data">{item.quantity}</div>
                    </div>
                    <div className="row-product-content d-flex flex-align-center">
                      <div className="row-product-data text-data">{formatStoreProductPrice((item.product?.price * item.quantity) || 0, item.paidCurrency)}</div>
                    </div>
                  </div>)
                }
                <div className="table-footer">
                  {isPromotion && (
                    <>
                      <div className="footer" />
                      <div className="footer">
                        <div className="footer-text">{t("promotion")}</div>
                      </div>
                      <div className="footer promotion-footer">
                        <div className="footer-text">{`- ${formatStoreProductPrice(totalItemsPrice, order.paymentCurrency)}`}</div>
                      </div>
                    </>
                  )}
                  <div className="footer" />
                  <div className="footer">
                    <div className="footer-text">{t("subtotal")}</div>
                  </div>
                  <div className="footer">
                    <div className="footer-text">{formatStoreProductPrice((Number(order.paymentAmount) || 0) - (Number(order.taxAmount) || 0), order.paymentCurrency)}</div>
                  </div>
                  <div className="footer" />
                  <div className="footer">
                    <div className="footer-text">{t("tax")}</div>
                  </div>
                  <div className="footer">
                    <div className="footer-text">{formatStoreProductPrice(order.taxAmount, order.paymentCurrency)}</div>
                  </div>
                  <div className="footer" />
                  <div className="footer footer-bolded">
                    <div className="footer-text">{t("total_amount_paid")}</div>
                  </div>
                  <div className="footer footer-bolded">
                    <div className="footer-text" data-testid="total-price">{formatStoreProductPrice(order.paymentAmount, order.paymentCurrency)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="payment-details-container">
            <div className="payment-details-title">{t("coltene_store.order_details.payment-details")}</div>
            <div className="payment-details-wrapper">
              <div className="details-order-status-wrapper d-flex">
                <div className="details-order-status-title">{t("coltene_store.order_details.order-status")}</div>
                <div className="status-and-icon-wrapper d-flex">
                  {order.status === "Paid" || order.status === "Complete" || order.status === "Shipped" ? (
                    <CheckCircleFilledIcon className="success-svg" />
                  ) : order.status === "Refund In Progress" || order.status === "Refund Requested" ? (
                    <PendingIcon />
                  ) : (
                    <ErrorCircleActiveIcon className="fail-svg" />
                  )}
                  <div
                    className={`details-order-status ${order.status === "Paid" || order.status === "Complete" || order.status === "Shipped"
                      ? "processed"
                      : order.status === "Refund In Progress" || order.status === "Refund Requested"
                        ? "processing"
                        : "cancelled"
                      } d-flex flex-align-center`}
                  >
                    {order.status}
                  </div>
                </div>
              </div>
              <div className="details-payment-date-wrapper d-flex">
                <div className="details-payment-date-title">{t("coltene_store.order_details.payment-date")}</div>
                <div className="details-payment-date">{convertToSimpleDateWithTime(order.purchasedAt)}</div>
              </div>
              <div className="details-button-area d-flex">
                {canRequestRefund(order.id) && (
                  <SimpleButton className="cancel-button" onClick={() => setShowCancelOrderModal(true)}>
                    {t("store_context.cancel_order")}
                  </SimpleButton>
                )}
                {userOrderDocuments && userOrderDocuments.length > 0 && (
                  <SimpleButton className="submit-button" onClick={(e) => handleDownloadInvoice(order, e)}>
                    {t("store_context.download_invoice")}
                  </SimpleButton>
                )}
              </div>
            </div>
          </div>
          <div className="customer-details-container">
            <div className="customer-details-title">{t("coltene_store.order_details.customer-details")}</div>
            <div className="customer-details-wrapper">
              <div className="customer-email-wrapper d-flex">
                <div className="customer-email-title">{t("email")}</div>
                <div className="customer-email">{order.customerEmail}</div>
              </div>
              <div className="customer-email-wrapper d-flex">
                <div className="customer-email-title">{t("full_name")}</div>
                <div className="customer-email">{`${order.firstName || ""} ${order.lastName || ""}`}</div>
              </div>
            </div>
          </div>
        </div>
      }
      {isMobile &&
        <div className="order-details-container">
          <div className="ordered-products-container">
            <div className="mobile-view-products">
              <div className="ordered-products-title">{t("store_context.ordered_products")}</div>
              {order.items && order.items.map((item, index) => 
                  <div key={index} className="table-products-row">
                  <div className="row-product-data">
                    <OrderDetailsItem item={item} />
                  </div>
                  <div className="quantity-price-info">
                    <div className="row-product-content">
                      <div className="title">{t("quantity")}</div>
                      <div className="value">{item.quantity}</div>
                    </div>
                    <div className="row-product-content">
                      <div className="title">{t("price")}</div>
                      <div className="value">{formatStoreProductPrice((item.product?.price * item?.quantity) || 0, item.paidCurrency)}</div>
                    </div>
                  </div>
                </div>)
              }
              <div className="price-overview">
                {isPromotion && (
                  <>
                    <div className="price">
                      <div className="label">{t("promotion")}</div>
                      <div className="value">{`- ${formatStoreProductPrice(totalItemsPrice, order.paymentCurrency)}`}</div>
                    </div>
                  </>
                )}
                <div className="price">
                  <div className="label">{t("subtotal")}</div>
                  <div className="value">{formatStoreProductPrice((Number(order.paymentAmount) || 0) - (Number(order.taxAmount) || 0), order.paymentCurrency)}</div>
                </div>
                <div className="price">
                  <div className="label">{t("tax")}</div>
                  <div className="value">{formatStoreProductPrice(order.taxAmount, order.paymentCurrency)}</div>
                </div>
                <div className="price">
                  <div className="label bolded">{t("total_amount_paid")}</div>
                  <div className="value bolded">{formatStoreProductPrice(order.paymentAmount, order.paymentCurrency)}</div>
                </div>
              </div>
              <div className="payment-details">
                <div className="payment-details-container">
                  <div className="payment-details-title">{t("coltene_store.order_details.payment-details")}</div>
                  <div className="payment-details-wrapper">
                    <div className="details-order-status-wrapper d-flex">
                      <div className="details-order-status-title">{t("coltene_store.order_details.order-status")}</div>
                      <div className="status-and-icon-wrapper d-flex">
                        {order.status === "Paid" || order.status === "Complete" || order.status === "Shipped" ? (
                          <CheckCircleFilledIcon className="success-svg" />
                        ) : order.status === "Refund In Progress" || order.status === "Refund Requested" ? (
                          <PendingIcon />
                        ) : (
                          <ErrorCircleActiveIcon className="fail-svg" />
                        )}
                        <div
                          className={`details-order-status ${order.status === "Paid" || order.status === "Complete" || order.status === "Shipped"
                            ? "processed"
                            : order.status === "Refund In Progress" || order.status === "Refund Requested"
                              ? "processing"
                              : "cancelled"
                            } d-flex flex-align-center`}
                        >
                          {order.status}
                        </div>
                      </div>
                    </div>
                    <div className="details-payment-date-wrapper d-flex">
                      <div className="details-payment-date-title">{t("coltene_store.order_details.payment-date")}</div>
                      <div className="details-payment-date">{convertToSimpleDateWithTime(order.purchasedAt)}</div>
                    </div>
                    <div className="details-button-area d-flex">
                      {canRequestRefund(order.id) && (
                        <SimpleButton className="cancel-button" onClick={() => setShowCancelOrderModal(true)}>
                          {t("store_context.cancel_order")}
                        </SimpleButton>
                      )}
                      {userOrderDocuments && userOrderDocuments.length > 0 && (
                        <SimpleButton className="submit-button" onClick={(e) => handleDownloadInvoice(order, e)}>
                          {t("store_context.download_invoice")}
                        </SimpleButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="customer-details-container">
                <div className="customer-details-title">{t("coltene_store.order_details.customer-details")}</div>
                <div className="customer-details-wrapper">
                  <div className="customer-email-wrapper d-flex">
                    <div className="customer-email-title">{t("email")}</div>
                    <div className="customer-email">{order.customerEmail}</div>
                  </div>
                  <div className="customer-name-wrapper d-flex">
                    <div className="customer-name-title">{t("full_name")}</div>
                    <div className="customer-name">{`${order.firstName || ""} ${order.lastName || ""}`}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      {showSpinner && (
        <div className={"spinner-wrapper"}>
          <LoadingSpinner />
        </div>
      )}

      {showCancelOrderModal && (
        <ConfirmationModal
          onToggle={() => {
            setShowCancelOrderModal(false)
          }}
          onCancel={() => {
            setShowCancelOrderModal(false)
          }}
          onAccept={() => {
            setShowCancelOrderModal(false)
            handleRequestRefund(order.id)
            onToggle()
          }}
          message={t("coltene_store.order_history.cancelation-confirmation")}
        />
      )}
    </div>
  )
}

export default OrderDetails
