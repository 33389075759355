import React from "react";
import { connect } from "react-redux";
import { useTranslation } from 'react-i18next'
import UpdateIcon from "../../icons/update.svg";
import VerifiedIcon from "../../icons/verified-icon.svg";
import ApertureIcon from "../../icons/aperture_B.svg";
import GridIcon from "../../icons/grid.svg";
import GridActiveIcon from "../../icons/grid_active.svg";
import ListIcon from "../../icons/list.svg";
import ListActiveIcon from "../../icons/list_active.svg";
import CheckDoubleIcon from "../../icons/check_double.svg";
import "./dashboard.scss";

function Legend({ handleGridBtn, handleListBtn, canAccessInstrumentTracking, isDentist }) {
  const [gridActive, setGridActive] = React.useState(true)
  const { t } = useTranslation()
  function handleGrid() {
    handleGridBtn()
    setGridActive(true)
  }
  function handleList() {
    handleListBtn()
    setGridActive(false)
  }

  return (
      <div className="legend-wrapper">
        <div className="svg-wrapper">       
          <div>
            <div className="legend">
              <span className="dashboard-span"><img src={UpdateIcon} alt="update"/></span>
              <span className="dashboard-span">{t("device_context.update_available")}</span>
            </div>
            <div className="legend">
              <span className="dashboard-span"><img src={VerifiedIcon} alt="verified"/></span>
              <span className="dashboard-span">{t("device_context.online_access_active")}</span>
            </div>
            {(canAccessInstrumentTracking || !isDentist) && ( 
              <div className="legend">
                <span className="dashboard-span"><img src={ApertureIcon} alt="aperture"/></span>
                <span className="dashboard-span">{t("products.ic_track")}</span>
              </div>)
            }
            <div className="legend">
              <span className="dashboard-span"><img src={CheckDoubleIcon} alt="privacy-check"/></span>
              <span className="dashboard-span">{t("device_context.privacy_policy_checked")}</span>
            </div>
          </div>
        </div>
        <div className="filter">
          {handleGridBtn && handleListBtn && gridActive && (
            <div className="grid">
              <div>
                <img src={GridActiveIcon} alt="grid" onClick={handleGrid} className="active"/>
              </div>
              <div className="not-active">
                <img src={ListIcon} alt="list" onClick={handleList} />
              </div>
            </div>
          )}
          {handleGridBtn && handleListBtn && !gridActive && (
            <div className="grid">
              <div className="not-active">
                <img src={GridIcon} alt="grid" onClick={handleGrid} />
              </div>
                <div>
                  <img src={ListActiveIcon} alt="list" onClick={handleList} className="active"/>
                </div>
            </div>
          )}
          
        </div>
      </div>
  );
}

function mapStateToProps({ authedUser, userProducts }) {
  return {
    authedUser,
    userProducts,
  };
}

export default connect(mapStateToProps)(Legend);