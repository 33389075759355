import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import ServicePlan from "./ServicePlan"
import LoadingSpinner from "../../common/LoadingSpinner"
import SimpleButton from "../../common/SimpleButton"
import ClickOutside from "../../common/ClickOutside"
import { SortingArrow, CaretDownIcon } from "../../../icons"
import { formatStoreProductPrice } from "../../../utils/filters/filters"
import countries from "../../../utils/countries.json"
import { capitalizeFirstLetter } from "../../../utils/functions"

const ProductSummary = (props) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { product, activeSubscriptions, addToBasket, isPreview, inBasket, setShowStoreBasket } = props
  const [selectedPlan, setSelectedPlan] = useState(null)
  const [noItems, setNoItems] = useState(1)
  const [subscribed, setSubscribed] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [hasOffer, setHasOffer] = useState(false)
  const [availableCountries, setAvailableCountries] = useState([])
  const [selectedCountry, setSelectedCountry] = useState(null)
  const isDays = selectedPlan?.productOffer?.duration < 28
  const searchParams = new URLSearchParams(location.search)
  const subscriptionIdParam = searchParams.get("subscriptionId") ? Number(searchParams.get("subscriptionId")) : null
  
  useEffect(() => {
    if (product?.isDigital) {
      if (!isPreview) {
        if (!activeSubscriptions) {
          setIsLoading(true)
          return
        }

        setSubscribed(activeSubscriptions.includes(product.id))
        setIsLoading(false)
      }

      if (subscriptionIdParam) {
        setActive(subscriptionIdParam)
      }
    }
  }, [product, activeSubscriptions])

  useEffect(() => {
    if (product) {
      if (isPreview) {
        const uniqueCountryCodes = [...new Set(product.prices.map((price) => price.countryCode))]
        setAvailableCountries(uniqueCountryCodes)

        if (uniqueCountryCodes.length > 0) {
          setSelectedCountry(uniqueCountryCodes[0])
        }

        return
      }
      
      if (props.authedUser?.country) {
        setSelectedCountry(props.authedUser.country)
      }
    }
  }, [product, isPreview])

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCountry = (countryCode, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }
    
    if (selectedCountry === countryCode) {
      return
    }

    if (product.isDigital) {
      setSelectedPlan(null)
    }

    setSelectedCountry(countryCode)
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <div className="text-wrapper">
          {selectedCountry ? countries.find((c) => c.code === selectedCountry)?.name : ""}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {availableCountries.map((availableCountry, index) =>
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (availableCountry === selectedCountry ? " selected-option" : "")} 
          onClick={() => selectCountry(availableCountry, toggleVisibility)}
        >
          {countries.find((c) => c.code === availableCountry)?.name || ""}
        </div>)
      }
    </div>
  )

  const handleInputChange = (e) => {
    e.stopPropagation()

    return setNoItems(e.target.value === "" ? 1 : Number(e.target.value))
  }

  const increaseNumberOfItems = (e) => {
    e.stopPropagation()

    setNoItems((prevValue) => prevValue + 1)
  }

  const decreaseNumberOfItems = (e) => {
    e.stopPropagation()

    if (noItems > 1) {
      setNoItems((prevValue) => prevValue - 1)
    }
  }

  const setActive = (id) => {
    const productPlan = product.prices?.find((price) => price.id === id)

    setSelectedPlan(productPlan)
    setHasOffer(hasNonNullOrUndefinedValue(productPlan.productOffer))
  }
  
  const hasNonNullOrUndefinedValue = (offer) => {
    return Object.values(offer).some(value => value !== null && value !== undefined)
  }
  
  const toMonths = (duration) => {
    return Math.ceil(duration / 30);
  }
  
  const handleAddDigitalProductToBasket = () => {
    Object.values(props.basket).forEach((item) => {
      if (item.isDigital && item.id !== product.id) {
        addToBasket(item, 0)
      }
    })

    const digitalProduct = {...product, prices: product.prices.map((p) => p.id === selectedPlan.id ? {...p, isSelectedPlan: true} : {...p, isSelectedPlan: false})}
    addToBasket(digitalProduct, 1)

    setShowStoreBasket()
  }

  return (
    <>
      {!isLoading && product?.isDigital && (
        <div className="service-summary-wrapper">
          {availableCountries.length > 1 && (
            <ClickOutside itemRef="click-outside-wrapper" eventItem={selectCountryInput} toDisplayItem={selectCountryOptions} />
          )}
          <div className="service-product-type">{t("coltene_store.product_details.subscription")}</div>
          <div className="service-name">{product.title}</div>
          <div className="service-subtitle">{product.subtitle}</div>
          {subscribed && <ServicePlan isWarning />}
          {!subscribed && (
            <>
              <div className="service-plans">
                <div className="service-plans-title">{t("coltene_store.cart.payment_plan")}</div>
                {product.prices
                  ?.filter((p) => p.countryCode === selectedCountry)
                  .filter((p) => {
                    if (subscriptionIdParam) {
                      if (p.id === subscriptionIdParam) {
                        return true
                      }
                      return false
                    }
                    return true
                  })
                  .map((price, index) => (
                    <ServicePlan
                      key={`service-plan-${index}`}
                      plan={price}
                      checked={selectedPlan?.id === price.id}
                    />
                  ))}
              </div>
              <div className="service-price">
                {selectedPlan &&
                  (hasNonNullOrUndefinedValue(selectedPlan?.productOffer) ? "" : formatStoreProductPrice(selectedPlan?.amount, selectedPlan?.currencyCode))}
              </div>
              <SimpleButton
                className="submit-button"
                disabled={isPreview || !selectedPlan || !product.prices}
                onClick={() => handleAddDigitalProductToBasket()}
              >
                {hasOffer ? t("try_it_free") : t("store_context.add_to_cart")}
              </SimpleButton>
              {hasOffer && (
                <div className="additional-info">
                  {isDays
                    ? t("coltene_store.days_offer_info", {
                        duration: selectedPlan?.productOffer?.duration,
                        price: selectedPlan?.amount,
                        plan: selectedPlan?.recurrenceInterval,
                      })
                    : t("coltene_store.offer_info", {
                        duration: toMonths(selectedPlan?.productOffer?.duration),
                        price: selectedPlan?.amount,
                        plan: selectedPlan?.recurrenceInterval,
                      })}
                </div>
              )}
            </>
          )}
        </div>
      )}
      {!isLoading && product && !product.isDigital && (
        <div className="product-summary-wrapper">
          {availableCountries.length > 1 && (
            <ClickOutside itemRef="click-outside-wrapper" eventItem={selectCountryInput} toDisplayItem={selectCountryOptions} />
          )}
          <div className="product-type">{t("coltene_store.product_details.physical_product")}</div>
          <div className="product-name"> {product.title}</div>
          <div className="product-subtitle">{product.subtitle}</div>
          <div className="product-part-number">
            {capitalizeFirstLetter(t("part_number")) + ": "}
            <div className="product-number">{product.partNumber}</div>
          </div>
          <div className="product-quantity">
            <div className="item-quantity">{t("coltene_store.product_details.no_items")}</div>
            <div className="item-input-and-price">
              <div className="increaser-decreaser-input">
                <input
                  type="number"
                  min="1"
                  step="1"
                  value={(Number(noItems) || 1).toString()}
                  onChange={handleInputChange}
                  onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                />
                <div className="arrows-wrapper">
                  <SortingArrow onClick={(e) => increaseNumberOfItems(e)} />
                  <SortingArrow onClick={(e) => decreaseNumberOfItems(e)} />
                </div>
              </div>

              {product.prices?.filter((p) => p.countryCode === selectedCountry)?.length > 0 && (
                <div className="product-price">
                  {formatStoreProductPrice(
                    product.prices.find((p) => p.countryCode === selectedCountry).amount,
                    product.prices.find((p) => p.countryCode === selectedCountry).currencyCode
                  )}
                </div>
              )}
            </div>
          </div>
          <SimpleButton
            className="submit-button"
            disabled={isPreview || !product.prices}
            onClick={() => {
              addToBasket(product, inBasket + noItems)
              setShowStoreBasket()
            }}
          >
            {t("store_context.add_to_cart")}
          </SimpleButton>
        </div>
      )}
      {isLoading && <LoadingSpinner />}
    </>
  )
}

function stateToProps({ colteneStore, adminStore, authedUser }) {
  return {
    authedUser,
    product: colteneStore?.productDetails || adminStore?.productDetails,
    isLoading: colteneStore?.isLoading || adminStore?.isLoading,
    basket: colteneStore?.basket || {},
  }
}

export default connect(stateToProps)(ProductSummary)
