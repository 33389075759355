import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ExpandLessIcon, ExpandMoreIcon } from "../../icons"
import "./configuration.scss"

const Configuration = (props) => {
  const { t } = useTranslation()
  const { configuration } = props
  const dataOffsets = configuration.offsets || {}
  const [showOffsets, setShowOffsets] = useState(true)

  const toggleOffsets = () => {
    setShowOffsets((prevVal) => !prevVal)
  }

  return (
    <div className="configuration-wrapper">
      <div className={`configuration-expand-wrapper ${!showOffsets ? "wrapper-unactive" : ""}`}>
        <div className="configuration-expand-header" onClick={() => toggleOffsets()}>
          <p className="title">{t("device_context.offsets")}</p>
          <div className="icon">{showOffsets ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
        </div>
        {showOffsets && (
          <div className="list-wrapper bg-white d-flex">
            <div className="list-info">
              <div className="section-title">{t("device_context.offsetlabels.factory_settings")}</div>
              <p>
                <span className="label">{t("device_context.offsetlabels.chamber_temperature_offset")}: </span>
                <span className="label-data">{dataOffsets.factory?.temperature_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.temperature_validation_offset")}: </span>
                <span className="label-data">{dataOffsets.factory?.temperature_validation_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.pressure_atmospheric_offset")}: </span>
                <span className="label-data">{dataOffsets.factory?.pressure_atmospheric_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.pressure_sterilization_offset")}: </span>
                <span className="label-data">{dataOffsets.factory?.pressure_sterilization_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.pressure_vacuum_offset")}: </span>
                <span className="label-data">{dataOffsets.factory?.pressure_vacuum_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.interface_version")}: </span>
                <span className="label-data">{dataOffsets.factory?.interface_version || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.secondary_version")}: </span>
                <span className="label-data">{dataOffsets.factory?.secondary_version || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.offset_date")}: </span>
                <span className="label-data">{dataOffsets.factory?.offset_date || t("n_a")}</span>
              </p>
            </div>
            <div className="list-info">
              <div className="section-title">{t("device_context.offsetlabels.field_settings")}</div>
              <p>
                <span className="label">{t("device_context.offsetlabels.chamber_temperature_offset")}: </span>
                <span className="label-data">{dataOffsets.field?.temperature_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.temperature_validation_offset")}: </span>
                <span className="label-data">{dataOffsets.field?.temperature_validation_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.pressure_atmospheric_offset")}: </span>
                <span className="label-data">{dataOffsets.field?.pressure_atmospheric_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.pressure_sterilization_offset")}: </span>
                <span className="label-data">{dataOffsets.field?.pressure_sterilization_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.pressure_vacuum_offset")}: </span>
                <span className="label-data">{dataOffsets.field?.pressure_vacuum_offset || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.interface_version")}: </span>
                <span className="label-data">{dataOffsets.field?.interface_version || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.secondary_version")}: </span>
                <span className="label-data">{dataOffsets.field?.secondary_version || t("n_a")}</span>
              </p>
              <p>
                <span className="label">{t("device_context.offsetlabels.offset_date")}: </span>
                <span className="label-data">{dataOffsets.field?.offset_date || t("n_a")}</span>
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Configuration
