import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next"
import PageModal from '../common/PageModal'
import Search from "../common/Search"
import SearchWithFilter from '../common/SearchWithFilter'
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import { CaretDownIcon, ArrowBackThickIcon } from "../../icons"
import { getImageByModel } from '../../utils/functions'
import { no_image_200x200 } from '../../images'
import './spareparts-details-modal.scss'
import { useAppContext } from '../../libs/contextLib'

const SparepartsDetailsModal = (props) => {
  const {t} = useTranslation()
  const { 
    selectedProductType, 
    selectedModel, 
    spareParts, 
    isFirstSparePartsLoad,
    totalResults, 
    filterQuery,
    expandedSparePart,
    canLoadMore,
    showSpinner,
    onSelectModel,
    onSearchSpareParts,
    onLoadMore,
    onExpandSparePart,
    closeModal, 
  } = props
  const [rawModels, setRawModels] = useState([])
  const [models, setModels] = useState([])
  const { showFeatureDev } = useAppContext()
  
  useEffect(() => {
    if (selectedProductType) {
      setRawModels(selectedProductType.models)
      setModels(selectedProductType.models)
    }
  }, [selectedProductType])

  const sparePartsHeaderContent = () => {
    return (
      <div className="spareparts-header" onClick={() => handleCloseModal()}>
        <div className="back-button" >
          <div className="back-icon">
            <ArrowBackThickIcon />
          </div>
        </div>
        <p className="title">{t("back")}</p>
      </div>
    )
  }

  const searchModel = (value) => {
    if (value) {
      const filteredModels = rawModels.filter(model => model.name.toLowerCase().includes(value.toLowerCase()))
      setModels(filteredModels)
    } else {
      setModels(rawModels)
    }
  }

  const selectModel = (model, toggleVisibility) => {    
    onSelectModel(model, toggleVisibility)
    toggleVisibility(false)
  }

  const onSelectModelInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setModels(rawModels)
    } else {
      setModels(rawModels)
      toggleVisibility(true)
    }
  }

  const selectModelInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectModelInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedModel ? selectedModel.name : t('spareparts.no-option-selected')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectModelOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchModel(e)}
          placeholder={`${t("search_filters")}...`}
        />
      </div>
      {models?.filter(model => !["STATIM 6000B"].includes(model.name)).map((model, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (model === selectedModel ? " selected-option" : "")} 
          key={index}
          onClick={() => selectModel(model, toggleVisibility)}
        >
          {model.name}
        </div>)
      }
    </div>
  )

  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }
  
  return (
    <div className="spareparts-details-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
        headerContent={sparePartsHeaderContent()}
      >
        <div className="spareparts-details-modal-content">
          <div className="product-type-card">
            <div className="image-wrapper d-flex flex-align-center">
              <img
                src={getImageByModel(selectedProductType.models?.length > 0 ? selectedProductType.models[0].name : "")}
                className="img"
                alt="product"
              />
            </div>
            <p className="product-type-name">{selectedProductType.name}</p>
          </div>
          <p className="model-select-title">{t('model')}</p>
          <ClickOutside 
            itemRef="click-outside-wrapper"
            eventItem={selectModelInput}
            toDisplayItem={selectModelOptions}
          />
          {selectedModel &&
            <div className="table-wrapper">
              <div className="product-header-wrapper">
                <div className="image-wrapper d-flex flex-align-center">
                  <img
                    src={getImageByModel(selectedModel.name)}
                    className="img"
                    alt={selectedModel.name}
                  />
                </div>
                <div className="product-name-wrapper">
                  <div className="product-name">
                    {selectedModel.name}
                  </div>
                  <div className="product-quantity">
                    {!showSpinner && `${totalResults} items`}
                  </div>
                </div>
              </div>
              <SearchWithFilter
                searchText={filterQuery}
                onSearch={(e) => onSearchSpareParts(e)}
              />
              {spareParts?.length > 0 && spareParts.map((item, index) =>
                <div key={index} className="spare-parts-card">
                  <div className="card-item">
                      {showFeatureDev && (
                        <>
                          <div className="card-item-title">
                            {t("image")}
                          </div>
                          <div className="card-item-body">
                            <div className="image-wrapper">
                              <img src={item.src || no_image_200x200} alt="sparepart" />
                            </div>
                          </div>
                        </>
                      )}
                  </div>
                  <div className="card-item">
                    <div className="card-item-title">
                      {t("spareparts.item-id")}
                    </div>
                    <div className="card-item-body">
                      {item.item_id}
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="card-item-title">
                      {t("description")}
                    </div>
                    <div className="card-item-body">
                      {item.description}
                    </div>
                  </div>    
                  <div className="card-item">
                    <div className="card-item-title">
                      {t("spareparts.components")}
                    </div>
                    <div className="card-item-body">
                      {item.components?.length || 0}
                      {item.components?.length > 0 &&
                        <>
                          {expandedSparePart === item.item_id ? 
                            <SimpleButton className="action-button" onClick={() => onExpandSparePart(item.components.length, null)}>{t("hide")}</SimpleButton> 
                          : <SimpleButton className="cancel-button" onClick={() => onExpandSparePart(item.components.length, item.item_id)}>{t("view")}</SimpleButton>
                          }
                        </>
                      }
                    </div>
                  </div>      
                  {expandedSparePart === item.item_id &&
                    <div className="card-item">
                      <div className="card-item-title">
                      </div>
                      <div className="card-item-body">
                        <div className="components-list-wrapper">
                          <ul className="components-list">
                            {item.components.map((component, index) => 
                              <li className="component-wrapper" key={index}>
                                <div className="component-description">{component.description}</div>
                              </li>)
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  }       
                </div>)  
              }
              {spareParts?.length === 0 && !showSpinner && !isFirstSparePartsLoad &&
                <EmptyPageResults
                  className="fit-within-page"
                  title={t('spareparts.no-spareparts')}
                  subtitle={t('cannot_find_matching_search')}
                />
              }
              {canLoadMore && (
                <div className="buttons-wrapper">
                  <SimpleButton className="load-more-button" onClick={() => onLoadMore()}>
                    {t('load_more')}
                  </SimpleButton>
                </div>)
              }
            </div>
          }
          {!selectedModel && 
            <EmptyPageResults
              className="fit-within-page"
              title={t('spareparts.no-model-selected')}
              subtitle={t('spareparts.select-model')}
            />
          }
        </div>
      </PageModal>
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

export default SparepartsDetailsModal
