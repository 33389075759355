import { useTranslation } from "react-i18next"

export default function SterilizerDiagramLegend() {
  const { t } = useTranslation()

  return (
    <div className={"sterilizer-diagram-legend"}>
      <div className={"sterilizer-diagram-legend-header"}>{t("advance_troubleshooting_context.systems_valves")}</div>
      <div className={"sterilizer-diagram-legend-divider-pink"}></div>
      <div className={"sterilizer-diagram-legend-title"}>{t("advance_troubleshooting_context.steam_system")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.steam_generator_1")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_boiler_1")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.water_pump_1")}</div>
      <div className={"sterilizer-diagram-legend-divider"}></div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.steam_generator_2")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_boiler_2")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.water_pump_2")}</div>
      <div className={"sterilizer-diagram-legend-divider"}></div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.water_reservoir")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_fill_water")}</div>
      <div className={"sterilizer-diagram-legend-divider-blue"}></div>
      <div className={"sterilizer-diagram-legend-title"}>{t("advance_troubleshooting_context.chamber_system")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_drain_reservoir")}</div>
      <div className={"sterilizer-diagram-legend-divider"}></div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.band_heater_top")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.chamber")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.band_heater_bottom")}</div>
      <div className={"sterilizer-diagram-legend-divider"}></div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_exhaust_chamber")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_vent_chamber")}</div>
      <div className={"sterilizer-diagram-legend-divider-green"}></div>
      <div className={"sterilizer-diagram-legend-title"}>{t("advance_troubleshooting_context.vacuum_system")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.vacuum_pump")}</div>
      <div className={"sterilizer-diagram-legend-divider-yellow"}></div>
      <div className={"sterilizer-diagram-legend-title"}>{t("advance_troubleshooting_context.ventilation_system")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.biological_filter")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.fan")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.heat_exchanger")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.valve_drain_heat_exchanger")}</div>
      <div className={"sterilizer-diagram-legend-text"}>{t("advance_troubleshooting_context.water_bottle_drain")}</div>
      <div className={"sterilizer-diagram-legend-divider"}></div>
    </div>
  )
}
