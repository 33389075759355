import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import VideoModal from "../common/VideoModal"
import SimpleButton from "../common/SimpleButton"
import ClickOutside from "../common/ClickOutside"
import LoadingSpinner from "../common/LoadingSpinner"
import EmptyPageResults from "../common/EmptyPageResults"
import { toast } from "../common/Toast"
import { VideoPlayIcon, CaretDownIcon, CloseIcon } from "../../icons"
import { getVideosList, getVideosCategories } from "../../utils/requests/videosAPI"
import "./videos.scss"

const Videos = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { model } = props
  const [videos, setVideos] = useState([])
  const [videoCategories, setVideoCategories] = useState([])
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState(null)
  const [selectedPage, setSelectedPage] = useState(1)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  useEffect(() => {
    if (model) {
      getVideosCategories()
        .then((data) => {
          setVideoCategories(data.data)
        })
    }
  }, [])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadVideos(false)
    }
  }, [selectedPage])

  useEffect(() => {
    let changeValueTimeout = window.setTimeout(
      () => {
        setSelectedPage(1)
        handleLoadVideos(true)
      },
      selectedCategory ? 1000 : 0
    )
    return () => {
      clearTimeout(changeValueTimeout)
    }
  }, [selectedCategory])

  const handleLoadVideos = (withReset) => {
    if (!model) {
      setIsFirstLoad(false)

      return
    }

    let queryParams = {
      model: model,
      page: withReset ? 1 : selectedPage,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
    }

    if (selectedCategory) {
      queryParams = { ...queryParams, category: selectedCategory }
    }

    setShowSpinner(true)

    getVideosList(queryParams)
      .then((data) => {
        if (data?.data) {
          setVideos(withReset ? data.data : [...videos, ...data.data])
          setCanLoadMore(data.data.length === Number(process.env.REACT_APP_PAGINATION_SIZE))
        }        
      })
      .catch(() => {
        toast.error(t("error.failure_msg"))

        setCanLoadMore(false)
      })
      .finally(() => {
        setShowSpinner(false)
        setIsFirstLoad(false)
      })
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const handleNavigateToVideos = () => {
    history.push({
      pathname: `/videos`,
      state: {
        model: model,
        withBackNavigation: true
      },
    })
  }

  const openVideoModal = (video) => {
    setSelectedVideo(video)
  }

  const closeVideoModal = () => {
    setSelectedVideo(null)
  }

  const onSelectCategoryInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }
  }

  const selectCategory = (category, toggleVisibility) => {
    setSelectedCategory(category)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCategoryInput = ({ toggleVisibility, isComponentVisible }) => (
    <div className="select-input">
      <div className="height d-flex flex-align-center flex-justify-between" onClick={() => onSelectCategoryInput(isComponentVisible, toggleVisibility)}>
        <div className="text-wrapper d-flex flex-align-center h-100">{selectedCategory ? selectedCategory : t("all")}</div>
        {selectedCategory && (
          <button
            className="reset-filter-button"
            onClick={(e) => {
              e.stopPropagation()
              setSelectedCategory(null)
            }}
          >
            <CloseIcon />
          </button>
        )}
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")} />
      </div>
    </div>
  )

  const selectCategoryOptions = ({ toggleVisibility }) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {videoCategories.map((videoCategory, index) => 
        <div
          key={index}
          className={"option cursor-pointer no-wrap" + (videoCategory === selectedCategory ? " selected-option" : "")}
          onClick={() => selectCategory(videoCategory, toggleVisibility)}
        >
          {videoCategory}
        </div>)
      }
    </div>
  )

  return (
    <div className="products-modern-videos-wrapper">
      <div className="videos-header d-flex">
        <div className="category-wrapper d-flex">
          <div className="category-title">{t("category")}</div>
          <ClickOutside 
            itemRef="click-outside-wrapper"
            eventItem={selectCategoryInput}
            toDisplayItem={selectCategoryOptions}
          />
        </div>
        <SimpleButton className="action-button" onClick={() => handleNavigateToVideos()}>
          {t("video_context.view_all_videos")}
        </SimpleButton>
      </div>
      {videos.length > 0 &&
        <>
          <div className="videos-container d-flex">
            <div className="videos-content d-flex">
              {videos.map((video, index) => (
                <div 
                  key={index} 
                  className="video-item-wrapper" 
                  onClick={() => openVideoModal(video)}
                >
                  <div className="thumbnail-wrapper">
                    <div className="thumbnail-icon">
                      <VideoPlayIcon />
                    </div>
                    <img src={video.thumbnail_url} alt="video" />
                  </div>
                  <p className="title">{video.title}</p>
                </div>
              ))}
            </div>
            {canLoadMore &&
              <div className="buttons-wrapper">
                <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
                  {t("load_more")}
                </SimpleButton>
              </div>
            }
          </div>
        </>
      }
      {!isFirstLoad && videos.length === 0 && 
        <EmptyPageResults className="empty-list" title={t("no_data_available")} />
      }
      {selectedVideo && 
        <VideoModal 
          title={selectedVideo.title}
          videoId={selectedVideo.video_id}
          onCloseVideo={closeVideoModal} 
        />
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      }
    </div>
  )
}

export default Videos
