import React from "react"
import { useTranslation } from "react-i18next"
import { DownloadModernIcon, PdfModernIcon } from "../../icons"
import { keyboardEvents } from "../../utils/keyboardEvents"

const DocumentCard = ({ type, isLast, cardContent, handleDownload }) => {
  const { t } = useTranslation()

  const handleKeyDown = (e) => {
    if (e.key === keyboardEvents.ENTER) {
      handleDownload(cardContent?.filePath || cardContent?.file_path, cardContent?.fileName || cardContent?.file_name)
    }
  }

  const renderCard = (content) => (
    <div
      className={`document-card d-flex flex-justify-between${isLast ? " last-document" : ""}`}
      onClick={() => handleDownload(content.filePath || content.file_path, content.fileName || content.file_name)}
      tabIndex={0}
      role="button"
      onKeyDown={handleKeyDown}
    >
      <div className="svg-wrapper without-background">
        <DownloadModernIcon width="24px" height="24px" />
      </div>
      <div className="svg-wrapper">
        <PdfModernIcon width="16px" height="16px" />
      </div>
      <div className="document-details">
        <div className="date d-flex">
          <div>{`${t("document_context.doc_date")}: ${content.documentYear || content.document_year || "-"}`}</div>
          <div>{`${type === "bulletin" ? `${t("bulletin")}#` : `${t("document_context.tsb")}#`} ${content.documentNumber || content.document_number || "-"}`}</div>
        </div>
        <div className="document-title">{content.documentTitle || content.document_title}</div>
      </div>
    </div>
  )

  return type === "bulletin" ? renderCard(cardContent) : renderCard(cardContent)
}

export default DocumentCard