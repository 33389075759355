import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import LoadingSpinner from "../common/LoadingSpinner"
import ResponsiveTable from "../common/ResponsiveTable"
import EmptyPageResults from "../common/EmptyPageResults"
import SimpleButton from '../common/SimpleButton'
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { convertToSimpleDate, convertToSimpleDateWithFullTime } from "../../utils/filters/date"
import { getProductCyclesFaultsNotes } from "../../utils/requests/productsAPI"
import "./notes.scss"

const Notes = (props) => {
  const { t } = useTranslation()
  const history = useHistory()
  const isMobile = useWindowSize()[0] <= 768
  const { serialNumber, faultId } = props
  const [notesTableData, setNotesTableData] = useState([])
  const [showSpinner, setShowSpinner] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const tableHeaders = [
    {
      field: "cycle",
      translation: `${t("cycle")} #`,
      hasOrdering: false,
    },
    {
      field: "cycle_fault",
      translation: t("cf"),
      hasOrdering: false,
    },
    {
      field: "cycle_date",
      translation: t("products.notes.cycle-date"),
      hasOrdering: false,
    },
    {
      field: "comment",
      translation: t("products.notes.comment"),
      hasOrdering: false,
    },
    {
      field: "user",
      translation: t("products.notes.user"),
      hasOrdering: false,
    },
    {
      field: "comment_date",
      translation: t("products.notes.comment-date"),
      hasOrdering: false,
    },
  ]

  useEffect(() => {
    if (faultId) {
      setShowSpinner(true)

      getProductCyclesFaultsNotes(faultId)
        .then((data) => {
          if (data?.data?.notes?.length > 0) {
            const tableData = data.data.notes.map((item) => {
              return {
                cycle: data.data.cycle_number,
                cycle_fault: `${t("cf")}-${data.data.cycle_fault}`,
                cycle_date: convertToSimpleDate(data.data.fault_date),
                comment: item.note,
                user: item.user,
                comment_date: convertToSimpleDateWithFullTime(item.date),
              }
            })

            setNotesTableData(tableData)
          }
        })
        .catch(() => {
          toast.error(t("error.failure_msg"))
        })
        .finally(() => {
          setShowSpinner(false)
          setIsFirstLoad(false)
        })
    }
  }, [faultId])

  const handleNavigateToNotes = () => {
    props.handleNavigationWithState()
  
    history.push({
      pathname: `/products/${serialNumber}/notes`,
      state: {
        withBackNavigation: true,
      }
    })
  }

  return (
    <div className="notes-wrapper">
      {!isMobile ?
        <>
          {notesTableData.length > 0 && 
            <ResponsiveTable 
              className="notes-table" 
              headers={tableHeaders} 
              data={notesTableData} 
              expandingColumn={5} 
            />
          }
        </>
      :
        <div className="mobile-content">
          {notesTableData.map((item, index) =>
            <div key={index} className="card">
              <div className="card-item">
                <div className="card-item-title">{`${t("cycle")} #`}</div>
                <div className="card-item-body">{item.cycle}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("cycle_fault")}</div>
                <div className="card-item-body cycle-fault">{item.cycle_fault}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.cycle-date")}</div>
                <div className="card-item-body">{item.cycle_date}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.comment")}</div>
                <div className="card-item-body">{item.comment}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.user")}</div>
                <div className="card-item-body">{item.user}</div>
              </div>
              <div className="card-item">
                <div className="card-item-title">{t("products.notes.comment-date")}</div>
                <div className="card-item-body">{item.comment_date}</div>
              </div>
            </div>)
          }
        </div>
      }
      {notesTableData.length > 0 &&
        <div className="buttons-wrapper">
          <SimpleButton 
            className="action-button"
            onClick={() => handleNavigateToNotes()}
          >
              {t("monitoring.view_all_notes")}
          </SimpleButton>
        </div>
      }
      {!isFirstLoad && notesTableData.length === 0 &&
        <div className="no-notes-wrapper">
          <EmptyPageResults
            title={t("no_data_available")}
          />
        </div>
      }
      {showSpinner && (
        <div className="spinner-wrapper">
          <LoadingSpinner />
        </div>
      )}
    </div>
  )
}

export default Notes
