import React, { useState } from 'react'
import { useTranslation } from "react-i18next"
import PageModal from '../common/PageModal'
import SimpleButton from '../common/SimpleButton'
import { userProductsWritePermission } from '../../utils/permissionValidation'
import './nickname-setup-modal.scss'

const NicknameSetupModal = (props) => {
  const { t } = useTranslation()
  const { nickname, productIsAperture, showSpinner, handleChangeNickname, closeModal } = props
  const [newProductSerialNickname, setNewProductSerialNickname] = useState(nickname || "")
  
  const handleCloseModal = () => {
    if (closeModal) {
      closeModal()
    }
  }

  return (
    <div className="nickname-setup-modal-wrapper">
      <PageModal
        toggle
        onToggle={() => handleCloseModal()}
      >
        <div className="title">
          {t("products.setup_nickname")}
        </div>
        <div className="subtitle">
          {t("products.setup_nickname_instructions")}
        </div>
        <input 
          type="text" 
          className="nickname-input"
          value={newProductSerialNickname || ""}
          onChange={(e) => setNewProductSerialNickname(e.target.value)}
        />
        <div className="buttons-wrapper">
          <SimpleButton 
            className="cancel-button"
            onClick={() => handleCloseModal()} 
          >
            {t("cancel")}
          </SimpleButton>
          <SimpleButton
            className="submit-button" 
            onClick={() => { handleChangeNickname(newProductSerialNickname); handleCloseModal() }}
            disabled={showSpinner || (productIsAperture && newProductSerialNickname.length === 0)}
            requiredPermission={userProductsWritePermission}
          >
            {t("save")}
          </SimpleButton>
        </div>
      </PageModal>
    </div>
  )
}

export default NicknameSetupModal
