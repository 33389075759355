import React, { useState, useEffect, useLayoutEffect } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"
import { useLocation, useHistory } from 'react-router-dom'
import TechnicalService from "./TechnicalService"
import Download from "./Download"
import { BoxTabs, Tab } from "../common/Tabs"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import Breadcrumb from "../common/Breadcrumb"
import { hasDocumentProtectedPermission, hasEndUserSiteAccess } from "../../utils/permissionValidation"
import "./documents.scss"

function Documents(props) {
  const { t } = useTranslation()
  const history = useHistory()
  const location = useLocation()
  const stateModel = location.state && location.state.model
  const canGoBack = location.state && location.state.withBackNavigation 
  const activeIndex = location.state && location.state.activeIndex
  const model = props.model || stateModel
  const className = props?.className
  const hideBreadcrumb = props.hideBreadcrumb
  const hideFiltering = props.hideFiltering
  const [showTSBTab, setShowTSBTab] = useState(false)

  useLayoutEffect(() => {
    const includesTSBPermission = hasDocumentProtectedPermission(props.userPermissions)  
    setShowTSBTab(includesTSBPermission)
  }, [props.userPermissions])

  useEffect(() => {
    if (showTSBTab) {
      const state = location.state
      delete state?.model
      history.replace(`${history.location.pathname}`, { ...state })
    }
  }, [showTSBTab])

  const generateTabs = () => {
    const tabs = [
      {
        label: t("nav.documents"),
        component: <Download model={model} />
      }
    ]

    if (showTSBTab) {
      tabs.push({
        label: t("technical_service_bulletins"),
        component: <TechnicalService />
      })
    }

    return tabs
  }

  return (
    <div className={`documents-wrapper ${className}`}>
      {!hideBreadcrumb && (
        <Breadcrumb path={window.location.pathname} withBackNavigation={canGoBack} />
      )}
      <DashboardHeader headerText={t("nav.documents")} />
      {hideFiltering && (
        <div className="divider"/>
      )}
      {!hasEndUserSiteAccess(props.userPermissions) && !hideFiltering ? 
        <BoxTabs className="underlined-box-tab" defaultIndex={activeIndex || 0}>
          {generateTabs().map((tab, index) =>
            <Tab key={index} index={index} label={tab.label}>
              {tab.component}
            </Tab>)
          }
        </BoxTabs>
      :
        <Download
          model={model}
          hideFiltering={hideFiltering}
        />
      }
    </div>
  )
}

function mapStateToProps({ userPermissions }) {
  return {
    userPermissions
  }
}

export default connect(mapStateToProps)(Documents)
