import { PlayIcon } from '../../../../icons';
import './video-builder.scss'

const VideoBuilder = ({ id, style, handleClick, children }) => {
  return (
    <div
      className='video-builder-section'
      style={style}
      onClick={(e) => {
        e.stopPropagation();
        handleClick()
      }}
    >
      {children}
      <PlayIcon className="play-icon"/>
    </div>
  )
}

export default VideoBuilder