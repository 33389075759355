import React from "react"
import { useTranslation } from "react-i18next"
import { Elements } from '@stripe/react-stripe-js'
import UpdateForm from './UpdateForm'
import PageModal from "../../common/PageModal"
import { useAppContext } from "../../../libs/contextLib"
import './payment-methods.scss'

const CreatePaymentMethodModal = (props) => {
  const { t } = useTranslation()
  const { stripe } = useAppContext()
  const { paymenthMethodIdToDelete, closeModal, clientSecret } = props
  const options = {
    clientSecret: clientSecret,
  }

  return (
    <PageModal
      toggle
      className="create-payment-method-modal"
      title={t('account_context.add_payment_method')}
      onToggle={() => closeModal()}
    >
      <div className="create-payment-method-content">
        <Elements stripe={stripe} options={options}>
          <UpdateForm
            paymenthMethodIdToDelete={paymenthMethodIdToDelete}
            closeModal={closeModal}
          />
        </Elements>
      </div>
    </PageModal>
  )
}

export default CreatePaymentMethodModal
