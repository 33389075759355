import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { Storage } from "aws-amplify"
import Auth from "@aws-amplify/auth"
import LoadingSpinner from "../../common/LoadingSpinner"
import SimpleButton from "../../common/SimpleButton"
import ClickOutside from "../../common/ClickOutside"
import CheckboxInput from "../../common/CheckboxInput"
import { toast } from "../../common/Toast"
import GoogleAddressInput from '../../common/GoogleAddressInput'
import { EmailIcon, PersonIcon, PhoneIcon, WorldIcon, LockIcon, FlagIcon, EyeIcon, EyeOffIcon, PhotoIcon, CaretDownIcon } from '../../../icons'
import languages, { getIso639_2FromLocale, getLanguageFromLocale } from '../../../utils/languages'
import countries from "../../../utils/countries.json"
import { updateUser, updateNewsLetter } from "../../../utils/requests/usersAPI"
import { formatPhone } from '../../../utils/functions'
import { handleUpdateAuthedUser } from "../../../actions/authedUser"
import { checkPermission, profileWritePermission } from '../../../utils/permissionValidation'
import { contactUrl, privacyUrl } from '../../../utils/fixedUrls'
import './account-details.scss'

const AccountDetails = (props) => {
  const { t } = useTranslation()
  const [firstName, setFirstName] = useState(props.authedUser?.firstname)
  const [lastName, setLastName] = useState(props.authedUser?.lastname)
  const [email, setEmail] = useState(props.authedUser?.email)
  const [country, setCountry] = useState(props.authedUser?.country)
  const [address, setAddress] = useState(props.authedUser?.address)
  const [state, setState] = useState(props.authedUser?.stateprovince)
  const [city, setCity] = useState(props.authedUser?.city)
  const [zip, setZip] = useState(props.authedUser?.postalcode)
  const [phone, setPhone] = useState(props.authedUser?.phone)
  const [language, setLanguage] = useState(props.authedUser?.language)
  const [userAvatar, setUserAvatar] = useState(null)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [hasAcceptedSubscriptionTerms, setHasAcceptedSubscriptionTerms] = useState(props.authedUser?.newsletter === "true")
  const [showCurrentPassword, setShowCurrentPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showSpinner, setShowSpinner] = useState(null)
  const [isValidFirstName, setIsValidFirstName] = useState(true)
  const [isValidLastName, setIsValidLastName] = useState(true)
  const [isValidPhone, setIsValidPhone] = useState(true)
  const [hasProfileFormChanges, setHasProfileFormChanges] = useState(false)
  const [hasDetailsFormChanges, setHasDetailsFormChanges] = useState(false)
  const passwordPattern = new RegExp(/^.*(?=.{8,})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/)
  const phonePattern = new RegExp(/^[+]?[\s./0-9]*[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/g)

  useEffect(() => {
    let isMounted = true

    if (props.authedUser == null) {
      return
    }

    setShowSpinner(true)

    Storage.list("", {level: "protected" , pageSize: 1000})
      .then((files) => {
        if (isMounted) {
          const avatar = files?.results?.filter((file) => file.key.match(/my_avatar.*/i))
          if (avatar.length > 0) {
            Storage.get(avatar[0].key, { level: "protected"})
              .then((response) => {
                if (isMounted) {
                  setUserAvatar(response)
                }
              })
          }
        }
      })
      .finally(() => setShowSpinner(false))

    return () => {
      isMounted = false
    }
  }, [props.authedUser?.email])

  useEffect(() => {
    const hasChanges = firstName !== props.authedUser?.firstname
      || lastName !== props.authedUser?.lastname 
   
    setHasProfileFormChanges(hasChanges)
  },[firstName, lastName])

  useEffect(() => {
    const hasChanges = country !== props.authedUser?.country
      || address !== props.authedUser?.address
      || state !== props.authedUser?.stateprovince
      || city !== props.authedUser?.city
      || zip !== props.authedUser?.postalcode
      || phone !== props.authedUser?.phone
      || language !== props.authedUser?.language
      || hasAcceptedSubscriptionTerms !== (props.authedUser?.newsletter === "true")
   
    setHasDetailsFormChanges(hasChanges)
  },[country, address, state, city, zip, phone, language, hasAcceptedSubscriptionTerms])

  const canChangePassword = () => {
    const isValidNewPassword = passwordPattern.test(newPassword)
    return currentPassword.length > 0 && isValidNewPassword && newPassword === confirmPassword
  }

  const onSelectInput = (isComponentVisible, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(!isComponentVisible)
    }    
  }

  const selectCountry = (country, toggleVisibility) => {
    setCountry(country)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectCountryInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <FlagIcon className="ml-15" />
        <div className="d-flex flex-align-center h-100">
          {country ? countries?.find((c) => c.code === country)?.name : t("country")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectCountryOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {countries?.map((countryOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (countryOption["code"] === country ? " selected-option" : "")} 
          key={index}
          onClick={() => selectCountry(countryOption["code"], toggleVisibility)}
        >
          {countryOption["name"]}
        </div>
      ))
      }
    </div>
  )

  const selectLanguage = (language, toggleVisibility) => {
    setLanguage(language)

    if (toggleVisibility) {
      toggleVisibility(false)
    }
  }

  const selectLanguageInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectInput(isComponentVisible, toggleVisibility)}
      >
        <WorldIcon className="ml-15" />
        <div className="d-flex flex-align-center h-100">
          {language ? (getLanguageFromLocale(language.replace('_', '-')) ? t(`${getLanguageFromLocale(language.replace('_', '-'))}`) : t(`${getLanguageFromLocale('en-CA')}`)): t("language")}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectLanguageOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      {Object.keys(languages)?.map((languageOption, index) => (
        <div 
          className={"option cursor-pointer no-wrap" + (languageOption === language ? " selected-option" : "")} 
          key={index}
          onClick={() => selectLanguage(languageOption, toggleVisibility)}
        >
          {t([`${getLanguageFromLocale(languageOption)}`,""])}
        </div>
      ))
      }
    </div>
  )

  const handleChangePassword = () => {
    setShowSpinner(true)

    Auth.currentAuthenticatedUser()
      .then((user) => {
        setShowSpinner(false)
        return Auth.changePassword(user, currentPassword, newPassword)
      })
      .then(() => {
        setCurrentPassword("")
        setNewPassword("")
        setConfirmPassword("")

        toast.success(t("account_context.profile_updated"))
      })
      .catch((err) => {
        toast.error(err.message)

        setShowSpinner(false)
      })
  }

  const handleClearPasswordChanges = () => {
    setCurrentPassword("")
    setNewPassword("")
    setConfirmPassword("")
  }

  const handleCancelProfileFormChanges = () => {
    setHasProfileFormChanges(false)
    setFirstName(props.authedUser?.firstname)
    setLastName(props.authedUser?.lastname)
  }
  const handleCancelDetailsFormChanges = () => {
    setHasDetailsFormChanges(false)
    setCountry(props.authedUser?.country)
    setAddress(props.authedUser?.address)
    setState(props.authedUser?.stateprovince)
    setCity(props.authedUser?.city)
    setZip(props.authedUser?.postalcode)
    setPhone(props.authedUser?.phone)
    setLanguage(props.authedUser?.language)
    setHasAcceptedSubscriptionTerms(props.authedUser?.newsletter === "true")
  }

  const updateDatabase = (avatarUrl, showToast, updateCategory) => {
    const { dispatch } = props

    let updatedFirstName = props.authedUser?.firstname
    let updatedLastName = props.authedUser?.lastname
    let updatedLanguage = props.authedUser?.language
    let updatedPhone = props.authedUser?.phone
    let updatedCity = props.authedUser?.city
    let updatedState = props.authedUser?.stateprovince
    let updatedCountry = props.authedUser?.country
    let updatedAddress = props.authedUser?.address
    let updatedZip = props.authedUser?.postalcode
    let updatedLocale = props.authedUser?.language
    let updatedAvatarUrl = userAvatar
    let updatedNewsletter = props.authedUser?.newsletter

    switch (updateCategory) {
      case "profile":
        updatedFirstName = firstName || ""
        updatedLastName = lastName || ""
        break
      case "details":
        updatedLanguage = language || ""
        updatedPhone = phone || ""
        updatedCity = city || ""
        updatedState = state || ""
        updatedCountry = country || ""
        updatedAddress = address || ""
        updatedZip = zip || ""
        updatedLocale = language || ""
        updatedNewsletter = hasAcceptedSubscriptionTerms ? "true" : "false"
        break
      case "avatar":
        updatedAvatarUrl = avatarUrl
        break
    }

    dispatch(
      handleUpdateAuthedUser({
        firstname: updatedFirstName,
        lastname: updatedLastName,
        language: updatedLanguage,
        phone: updatedPhone,
        city: updatedCity,
        stateprovince: updatedState,
        country: updatedCountry,
        address: updatedAddress,
        postalcode: updatedZip,
        locale: updatedLocale,
        newsletter: updatedNewsletter,
      })
    )

    const cognitoSub = props.authedUser.cognitoSub

    updateUser(cognitoSub,{
      "username": email,
      "email": email,
      "title": '',
      "first_name": updatedFirstName, 
      "middle_name": '',
      "last_name": updatedLastName,
      "phone": updatedPhone,
      "city": updatedCity,
      "postal_code": updatedZip,
      "state_province": updatedState,
      "country": updatedCountry,
      "address": updatedAddress,
      "preferred_contact": '',
      "identity": props.authedUser?.identity || '',
      "lang":  getIso639_2FromLocale(updatedLocale)|| '',
      "locale": updatedLanguage,
      "user_group": props.authedUser?.userGroup || 'dentist',
      "avatar_url": updatedAvatarUrl,
      "newsletter_accepted": updatedNewsletter
    })
    .then(() => {
      setShowSpinner(false)

      if (showToast) {
        toast.success(t("account_context.profile_updated"))
      }

      if (updateCategory === "profile") {
        setHasProfileFormChanges(false)
      }

      if (updateCategory === "details") {
        setHasDetailsFormChanges(false)
      }
    })
    .catch((e) => {
      setShowSpinner(false)

      console.log('Error updating backend -- ', e.message || e)

      toast.error(t("error.failure_msg"))
    })
  }

  const handleSaveProfileFormChanges = async () => {
    const isValidFirstName = firstName?.length > 0
    const isValidLastName = lastName?.length > 0
    
    setIsValidFirstName(isValidFirstName)
    setIsValidLastName(isValidLastName)

    if (!isValidFirstName || !isValidLastName) {
      return
    }

    setShowSpinner(true)

    const user = await Auth.currentAuthenticatedUser()
    
    await Auth.updateUserAttributes(user, {
      given_name: firstName || "",
      family_name: lastName || "",
    })

    updateDatabase('', true, "profile")
  }

  const handleSaveDetailsFormChanges = async () => {
    const isValidPhone = phone?.length > 0 && phonePattern.test(phone)
    setIsValidPhone(isValidPhone)

    if (!isValidPhone) {
      return
    }

    setShowSpinner(true)

    const user = await Auth.currentAuthenticatedUser()
    
    await Auth.updateUserAttributes(user, {
      "custom:phone_number": phone ? phone.replaceAll("-", "") : "",
      locale: language || "",
      "custom:country": country || "",
      "custom:address": address || "",
      "custom:city": city || "",
      "custom:state": state || "",
      "custom:postal_code": zip || "",
      "custom:newsletter": hasAcceptedSubscriptionTerms ? "true" : "false",
    })

    if (hasAcceptedSubscriptionTerms !== (props.authedUser.newsletter === "true")) {
      await updateNewsLetter(props.authedUser.cognitoSub, {
        newsletter: hasAcceptedSubscriptionTerms,
        note: null,
      })
    }

    updateDatabase('', true, "details")
    
    props.dispatch(handleUpdateAuthedUser({language}))
  }

  const handleRemoveAvatar = () => {
    setShowSpinner(true)

    try {
      Storage.list("", {
        identityId: props.authedUser?.identity,
        level: "protected",
        pageSize: 1000,
      }).then((files) => {
        const avatars = files?.results?.filter((file) => file.key.match(/my_avatar.*/i))
        avatars.forEach((avatar) => {
          Storage.remove(avatar.key, {
            level: "protected",
          }).then(() => {
            toast.success("Profile picture removed successfully")

            setUserAvatar(null)
            updateDatabase(null, false, "avatar")
          })
        })
      })    
    } catch(error) {
      console.log("error", error)

      toast.error("An error occured while removing the profile picture")

      setShowSpinner(false)
    }    
  }

  const handleUploadAvatar = async (file) => {
    let isSuccesfulUpload = true

    try {
      if (!file) {
        return
      }

      if (file?.size > 2 * 1024 * 1024) {
        toast.error("File size should be less than 2 MB")
        return
      }

      Storage.list("", {
        identityId: props.authedUser?.identity,
        level: "protected",
        pageSize: 1000,
      }).then((files) => {
        const avatars = files?.results?.filter((file) => file.key.match(/my_avatar.*/i))
        avatars.forEach((avatar) => {
          Storage.remove(avatar.key, {
            level: "protected",
          })
        })
      })

      const reader = new FileReader()
      reader.addEventListener("load", function () {
        setUserAvatar(reader.result)
      })
      reader.readAsDataURL(file)

      var ext = file.name.split(".").pop()
      var { key } = await Storage.put(`my_avatar.${ext}`, file, {
        level: "protected",
        contentType: "image/*",
      })
      toast.success("Profile picture updated successfully")
      var img = Storage.get(key, {
        level: "protected",
      })
    } catch (error) {
      console.log("error", error)

      toast.error("An error occured while updating profile picture")
      
      isSuccesfulUpload = false
    }

    if (isSuccesfulUpload) {
      const avatarUrl = `protected/${props.authedUser.identity}/my_avatar.${ext}`
      updateDatabase(avatarUrl, false, "avatar")
    }
  }

  const handleFileInput = async (event) => {
    if(!checkPermission(props.userPermissions, profileWritePermission)) {
      return
    }

    const file = event?.target?.files[0]

    await handleUploadAvatar(file)
  }

  return (
    <div className="account-details-wrapper d-flex flex-column">      
      <div className="sections-wrapper">
        <p className="wrapper-title">{t('account_context.profile_details')}</p>
        <div className="section">
          <div className="section-title">
            <p>{t('account_context.profile_picture')}</p>
            <p className="disclaimer">{t("account_context.photo_discalimer")}</p>
          </div>
          <div className="section-content">
            <div className="avatar-wrapper">
              <label className="file-input-wrapper" htmlFor="file-input">
                <div className="avatar-img">
                  {userAvatar ?
                    <img className="image" src={userAvatar} alt="avatar"/>
                  :
                    <div className="avatar-initials">
                      <span>{`${firstName?.charAt(0)}${lastName?.charAt(0)}`}</span>
                    </div>
                  }
                </div>
                <PhotoIcon />
              </label>               
              <input
                className="file-input"
                id="file-input"
                onChange={(e) => handleFileInput(e)}
                type="file"
                accept="image/*"
              />
            </div>
            <SimpleButton 
              className="action-button remove-image-button"
              onClick={() => handleRemoveAvatar()}
              disabled={!userAvatar || showSpinner}
              requiredPermission={profileWritePermission}
            >
              {t("remove")}
            </SimpleButton>
          </div>
        </div>
        <div className="separator"/>
        <div className="section">
          <div className="section-title">{t('account_context.account_type')}</div>
          <div className="section-content">
            <div className="input-item-wrapper">
              <div className="input-item-icon">
                <PersonIcon />
              </div>
              <input
                readOnly
                disabled
                type="text"
                className="input-item"
                value={t([`register.${props.authedUser?.userGroup?.toLowerCase()?.split("-")[0]}`, props.authedUser?.userGroup?.toLowerCase()?.split("-")[0]])}
              />
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title">{t('full_name')}</div>
          <div className="section-content">
            <div className={"input-item-wrapper" + (isValidFirstName ? "": " has-error")}>
              <div className="input-item-icon">
                <PersonIcon />
              </div>
              <input
                type="text" 
                className="input-item"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder={t('first_name')}
              />
              {!isValidFirstName && <p className="error-message">{t("error.firstname")}</p>}
            </div>
            <div className={"input-item-wrapper" + (isValidLastName ? "": " has-error")}>
              <div className="input-item-icon">
                <PersonIcon />
              </div>
              <input
                type="text" 
                name="lastname"
                className="input-item"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder={t('last_name')}
              />
              {!isValidLastName && <p className="error-message">{t("error.lastname")}</p>}
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title">{t('email_address')}</div>
          <div className="section-content">
            <div className="input-item-wrapper larger-input">
              <div className="input-item-icon">
                <EmailIcon />
              </div>
              <input
                readOnly
                disabled
                type="text" 
                className="input-item"
                name="email"
                value={email}
              />
            </div>
          </div>
        </div>
        <div className="section full-width">
          <div className="section-title empty"/>
          <div className="section-content buttons">
            <SimpleButton
              className="cancel-button"
              disabled={!hasProfileFormChanges}
              onClick={() => handleCancelProfileFormChanges()}
            >
                {t("cancel")}
            </SimpleButton>
            <SimpleButton
              className="submit-button"
              disabled={!hasProfileFormChanges || showSpinner}
              onClick={() => handleSaveProfileFormChanges()}
              requiredPermission={profileWritePermission}
            >
              {t("save")}
            </SimpleButton>
          </div>
        </div>
      </div>

      <div className="sections-wrapper">
        <p className="wrapper-title">{t('account_context.manage_password')}</p>
        <div className="section">
          <div className="section-title">{t('account_context.current_password')}</div>
          <div className="section-content">
            <div className="input-item-wrapper">
              <div className="input-item-icon">
                <LockIcon />
              </div>
              <input
                type={showCurrentPassword ? "text" : "password"}
                className="input-item"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
              <div className="input-item-icon is-action" onClick={() => setShowCurrentPassword(prevValue => !prevValue)}>
                {showCurrentPassword ? <EyeOffIcon /> : <EyeIcon />}
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title">{t('account_context.new_password')}</div>
          <div className="section-content">
            <div className="input-item-wrapper">
              <div className="input-item-icon">
                <LockIcon />
              </div>
              <input
                type={showNewPassword ? "text" : "password"}
                className="input-item"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <div className="input-item-icon is-action" onClick={() => setShowNewPassword(prevValue => !prevValue)}>
                {showNewPassword ? <EyeOffIcon /> : <EyeIcon />}
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title">{t('account_context.confirm_new_password')}</div>
          <div className="section-content">
            <div className="input-item-wrapper">
              <div className="input-item-icon">
                <LockIcon />
              </div>
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="input-item"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              <div className="input-item-icon is-action" onClick={() => setShowConfirmPassword(prevValue => !prevValue)}>
                {showConfirmPassword ? <EyeOffIcon /> : <EyeIcon />}
              </div>
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title empty"/>
          <div className="section-content">
            <p className="disclaimer">{t('account_context.password_message')}</p>
          </div>
        </div>
        <div className="section full-width">
          <div className="section-title empty"/>
          <div className="section-content buttons">
            <SimpleButton
              className="cancel-button"
              disabled={!canChangePassword()}
              onClick={() => handleClearPasswordChanges()}
            >
                {t("cancel")}
            </SimpleButton>
            <SimpleButton
              className="submit-button"
              disabled={!canChangePassword() || showSpinner}
              onClick={() => handleChangePassword()}
              requiredPermission={profileWritePermission}
            >
              {t("save")}
            </SimpleButton>
          </div>
        </div>
      </div>

      <div className="sections-wrapper">
        <p className="wrapper-title">{t('account_context.other_account_details')}</p>
        <div className="section">
          <div className="section-title">{t('account_context.contact')}</div>
          <div className="section-content">
            <div className={"input-item-wrapper" + (isValidPhone ? "": " has-error")}>
              <div className="input-item-icon">
                <PhoneIcon />
              </div>
              <input
                type="text" 
                className="input-item"
                value={formatPhone(phone)}
                onChange={(e) => setPhone(formatPhone(e.target.value))}
                placeholder={t('phone')}
              />
              {!isValidPhone && <p className="error-message">{t("error.phone")}</p>}
            </div>
          </div>
        </div>
        <div className="section">
          <div className="section-title empty"/>
          <div className="section-content">
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectCountryInput}
              toDisplayItem={selectCountryOptions}
            />
          </div>
        </div>
        <div className="section">
          <div className="section-title empty"/>
          <div className="section-content">
            <GoogleAddressInput
              country={country}
              address={address || ""}
              handleSetAddress={(e) => setAddress(e)}
              city={city}
              handleSetCity={(e) => setCity(e)}
              stateProvince={state}
              handleSetStateProvince={(e) => setState(e)}
              zipCode={zip}
              handleSetZipCode={(e) => setZip(e)}
              withoutLabels
              withIcons
            />
          </div>
        </div>
        <div className="separator"/>
        <div className="section">
          <div className="section-title">{t('language')}</div>
          <div className="section-content">
            <ClickOutside 
              itemRef="click-outside-wrapper"
              eventItem={selectLanguageInput}
              toDisplayItem={selectLanguageOptions}
            />
          </div>
        </div>
        <div className="separator"/>
        <div className="section">
          <div className="section-title">{t('newsletter')}</div>
          <div className="section-content">
            <div className="subscription-wrapper">
              <CheckboxInput 
                checked={hasAcceptedSubscriptionTerms}
                onChange={() => setHasAcceptedSubscriptionTerms(!hasAcceptedSubscriptionTerms)}
              />
              <p>
                {t('account_context.newsletter-description')}
                <a
                  className="link"
                  href={privacyUrl} 
                  target="_blank" 
                  rel="noreferrer"
                >
                  {t("privacy_policy")}
                </a>
                {","}
                <a
                  className="link"
                  href={contactUrl} 
                  target="_blank" 
                  rel="noreferrer"
                >
                  {t("contact_us")}
                </a>
                {"."}
              </p>
            </div>
          </div>
        </div>
        <div className="section full-width">
          <div className="section-title empty"/>
          <div className="section-content buttons">
            <SimpleButton
              className="cancel-button"
              disabled={!hasDetailsFormChanges}
              onClick={() => handleCancelDetailsFormChanges()}
            >
                {t("cancel")}
            </SimpleButton>
            <SimpleButton
              className="submit-button"
              disabled={!hasDetailsFormChanges || showSpinner}
              onClick={() => handleSaveDetailsFormChanges()}
              requiredPermission={profileWritePermission}
            >
              {t("save")}
            </SimpleButton>
          </div>
        </div>
      </div>      
      {showSpinner &&
        <div className={"spinner-wrapper"}>
            <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function stateToProps({ authedUser, userPermissions }) {
  return {
    authedUser,
    userPermissions
  }
}

export default connect(stateToProps)(AccountDetails)
