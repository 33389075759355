import './carousel-builder-toolbox.scss'

const CarouselBuilderToolbox = () => {

  return (
    <div className="carusel-toolbox">
    </div>
  )
}

export default CarouselBuilderToolbox