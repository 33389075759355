import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import Search from "../Search"
import { CloseIcon, FilterTableIcon } from "../../../icons"
import "./search-with-filter.scss"
import { keyboardEvents } from "../../../utils/keyboardEvents"

const SearchWithFilter = (props) => {
  const { t } = useTranslation()
  const {
    onSearch,
    showFiltersModal,
    onResetFilters,
    onKeyDown,
    showCounterBadge,
    counterBadge,
    searchText,
    placeholder,
    disabled = false,
  } = props
  
  const [filterQuery, setFilterQuery] = useState("")

  const handleInternalSearch = (value) => {
    const trimmedValue = value.trim()
    if (trimmedValue.length > 2 && trimmedValue !== filterQuery) {
      setFilterQuery(trimmedValue)
      onSearch(trimmedValue)
    } else if (trimmedValue.length === 0 && filterQuery.length > 0) {
      setFilterQuery("")
      onSearch('')
    }
  }

  return (
    <div className={`search-with-filter-wrapper ${props.className}`}>
      <Search
        placeholder={placeholder || t("search")}
        onSearch={(e) => handleInternalSearch(e)}
        searchText={searchText || ""}
        onKeyDown={onKeyDown}
        disabled={disabled}
      />
      <div className="filter-button-wrapper">
        <div 
          className="filter-button" 
          onClick={() => showFiltersModal()}
          tabIndex={0}
          role="button"
          onKeyDown={(event) => {
            if (event.key === keyboardEvents.ENTER) {
              showFiltersModal()
            }
          }}
        >
          <div className="icon-wrapper">
            <FilterTableIcon />
          </div>          
          <span>{t("events.filter")}</span>
        </div>              
        {showCounterBadge && (
          <div className="filters-counter">
            <div className="counter-badge">{counterBadge}</div>
            <button className="reset-filters-button" onClick={() => onResetFilters()}>
              <CloseIcon/>
            </button>
          </div>)
        }
      </div>
    </div>
  )
}

export default SearchWithFilter
