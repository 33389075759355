import React, {Fragment, useEffect, useState, useLayoutEffect} from 'react'
import { useTranslation } from "react-i18next"
import {connect} from "react-redux"
import SparepartsDetailsModal from './SparepartsDetailsModal'
import Search from "../common/Search"
import SearchWithFilter from '../common/SearchWithFilter'
import ClickOutside from "../common/ClickOutside"
import Breadcrumb from "../common/Breadcrumb"
import LoadingSpinner from "../common/LoadingSpinner"
import DashboardHeader from "../common/DashboardHeader/DashboardHeader"
import DataPlaceholder from '../common/DataPlaceholder'
import EmptyPageResults from '../common/EmptyPageResults'
import SimpleButton from '../common/SimpleButton'
import ExpandableText from '../common/ExpandableText'
import { toast } from "../common/Toast"
import { useWindowSize } from "../../libs/hooks"
import { CaretDownIcon, ExpandMoreIcon, ExpandLessIcon } from "../../icons"
import { getImageByModel } from '../../utils/functions'
import { getDeviceSpareParts } from "../../utils/requests/spareparts"
import { no_image_200x200 } from '../../images'
import './spareparts-modern.scss'
import { Storage } from 'aws-amplify'
import { useAppContext } from '../../libs/contextLib'
import { keyboardEvents } from '../../utils/keyboardEvents'

const SparepartsModern = (props) => {
  const {t} = useTranslation()
  const isMobile = useWindowSize()[0] <= 768
  const [productTypes, setProductTypes] = useState([])
  const [selectedProductType, setSelectedProductType] = useState(null)
  const [rawModels, setRawModels] = useState([])
  const [models, setModels] = useState([])
  const [selectedModel, setSelectedModel] = useState(null)
  const [spareParts, setSpareParts] = useState([])
  const [totalResults, setTotalResults] = useState(0)
  const [filterQuery, setFilterQuery] = useState(undefined)  
  const [showSpinner, setShowSpinner] = useState(null)
  const [canLoadMore, setCanLoadMore] = useState(false)
  const [selectedPage, setSelectedPage] = useState(1)
  const [expandedSparePart, setExpandedSparePart] = useState(null)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [isFirstSparePartsLoad, setIsFirstSparePartsLoad] = useState(true)
  const { showFeatureDev } = useAppContext()

  
  useLayoutEffect(() => {
    if (props.productTypes) {
      const mappedProductTypes = Object.values(props.productTypes)?.filter((productType) => productType.name !== "BRAVO")

      setProductTypes(mappedProductTypes)
      setIsFirstLoad(false)
    }
  }, [props.productTypes])

  useEffect(() => {
    if (selectedPage && selectedPage > 1) {
      handleLoadSpareParts(false)
    }
  }, [selectedPage])

  useEffect(() => {
    if (selectedModel) {
      let changeValueTimeout = window.setTimeout(
        () => {
          setSelectedPage(1)
          handleLoadSpareParts(true)
        },
        filterQuery ? 1000 : 0
      )
      return () => {
        clearTimeout(changeValueTimeout)
      }    
    }    
  },[filterQuery, selectedModel])

  const handleLoadSpareParts = (withReset) => {
    let queryParams = {
      page: withReset ? 1 : selectedPage,
      limit: process.env.REACT_APP_PAGINATION_SIZE,
      model: selectedModel.name
    }

    if (filterQuery) {
      queryParams = { ...queryParams, search: filterQuery}
    }

    setShowSpinner(true)

    getDeviceSpareParts(queryParams)
      .then(async (response) => {
        if (response?.data) {
          setTotalResults(response.totalResults)
          Storage.configure({
            customPrefix: {
              public: "",
              protected: "",
              private: "",
            },
          })
          const nextSparePartsWithoutImageSrc = withReset ? response.data : [...spareParts, ...response.data]
          const nextSparePartsImagePromises = nextSparePartsWithoutImageSrc.map(async sparePart => {
            if (sparePart.filename && sparePart.filepath && showFeatureDev) {
              try {
                const imgSrc = await Storage.get(`${sparePart.filepath}/${sparePart.filename}`, {validateObjectExistence: true})
                return {
                  ...sparePart,
                  src: imgSrc
                }
              } catch {
                return sparePart
              }
            } else {
              return sparePart
            }
          })
          
          const nextSparePartsWithImg = await Promise.all(nextSparePartsImagePromises)
          setSpareParts(nextSparePartsWithImg)
          setCanLoadMore(response.currentPage < response.totalPages)
        }
      })
      .catch(() => {
        toast.error(t('spareparts.error-loading-spareparts'))

        setCanLoadMore(false)
      })
      .finally(()=> {
        setShowSpinner(false)
        setIsFirstSparePartsLoad(false)
      })
  }
  
  const handleSearchSpareParts = (value) => {
    if (value?.length > 2) {
      setShowSpinner(true)
      setFilterQuery(value)
    } else if (value?.length === 0 && filterQuery?.length > 0) {
      setShowSpinner(true)
      setFilterQuery(undefined)
    }
  }

  const handleLoadMore = () => {
    setSelectedPage((prevValue) => (prevValue ? prevValue + 1 : 2))
  }

  const searchModel = (value) => {
    if (value) {
      const filteredModels = rawModels.filter(model => model.name.toLowerCase().includes(value.toLowerCase()))
      setModels(filteredModels)
    } else {
      setModels(rawModels)
    }
  }

  const selectProductType = (productType, toggleVisibility) => {
    if (toggleVisibility) {
      toggleVisibility(false)
    }

    if (selectedProductType === productType) {
      return
    }

    setSelectedProductType(productType)

    setModels(productType.models)
    setRawModels(productType.models)
    setSpareParts([])
    setIsFirstSparePartsLoad(true)
    setCanLoadMore(false)
    setSelectedModel(null)
    setFilterQuery(null)
    setExpandedSparePart(null)
  }

  const selectModel = (model, toggleVisibility) => {
    toggleVisibility(false)

    setModels(rawModels)

    if (selectedModel === model) {
      return
    }
    
    setTotalResults(0)
    setFilterQuery(undefined)
    setExpandedSparePart(null)
    setSpareParts([])
    setIsFirstSparePartsLoad(true)
    setCanLoadMore(false)
    setSelectedModel(model)
  }

  const onSelectModelInput = (isComponentVisible, toggleVisibility) => {
    if (isComponentVisible) {
      toggleVisibility(false)
      setModels(rawModels)
    } else {
      setModels(rawModels)
      toggleVisibility(true)
    }
  }

  const selectModelInput = ({toggleVisibility, isComponentVisible}) => (
    <div className={"select-input"}>
      <div 
        className="height d-flex flex-align-center flex-justify-between" 
        onClick={() => onSelectModelInput(isComponentVisible, toggleVisibility)}
      >
        <div className="d-flex flex-align-center h-100">
          {selectedModel ? selectedModel.name : t('spareparts.no-option-selected')}
        </div>
        <CaretDownIcon className={"mr-15 caret-dropdown-icon" + (isComponentVisible ? " icon-dropdown-open" : "")}/>
      </div>
    </div>
  )

  const selectModelOptions = ({toggleVisibility}) => (
    <div className="options-wrapper d-flex flex-align-center flex-column">
      <div className="options-filter">
        <Search
          className="search"
          onSearch={(e) => searchModel(e)}
          placeholder={`${t("search_filters")}...`}
        />
      </div>
      {models?.filter(model => !["STATIM 6000B"].includes(model.name)).map((model, index) => 
        <div 
          className={"option cursor-pointer no-wrap" + (model === selectedModel ? " selected-option" : "")} 
          key={index}
          onClick={() => selectModel(model, toggleVisibility)}
        >
          {model.name}
        </div>)
      }
    </div>
  )

  const handleExpandComponent = (componentsLength, itemId) => {
    if (componentsLength === 0) {
      return;
    }

    setExpandedSparePart(expandedSparePart === itemId ? null : itemId)
  }
  
  return (
    <div className="spareparts-modern-wrapper d-flex flex-column">
      <Breadcrumb path={window.location.pathname}/>
      <DashboardHeader headerText={t('nav.spareparts')} />
      <div className="separator"/>
      {!isMobile ?
        <div className="content">
          {productTypes?.length > 0 &&
            <>
              <div className="product-types">
                {productTypes.filter(productType => !["BIOSONIC", "STATCLAVE"].includes(productType.name)).map((item, index) => 
                  <div
                    key={index}
                    className={"card d-flex flex-align-center" + (selectedProductType === item ? " selected-card" : "")}
                    onClick={() => selectProductType(item)}
                    tabIndex={0}
                    role="button"
                    onKeyDown={(e) => e.key === keyboardEvents.ENTER && selectProductType(item)}
                  >
                    <div className="image-wrapper d-flex flex-align-center">
                      <img
                        src={getImageByModel(item.models?.length > 0 ? item.models[0].name : "")}
                        className="img"
                        alt="product"
                      />
                    </div>
                    <p className="product-type-name">{item.name}</p>
                  </div>)
                }
              </div>

              {selectedProductType ?
                <div className="models-wrapper">
                  <p className="model-select-title">{t('model')}</p>
                  <ClickOutside 
                    itemRef="click-outside-wrapper"
                    eventItem={selectModelInput}
                    toDisplayItem={selectModelOptions}/>

                  {selectedModel &&
                    <div className="table-wrapper">
                      <div className="product-header-wrapper">
                        <div className="image-wrapper d-flex flex-align-center">
                          <img
                            src={getImageByModel(selectedModel.name)}
                            className="img"
                            alt={selectedModel.name}
                          />
                        </div>
                        <div className="product-name-wrapper">
                          <div className="product-name">
                            {selectedModel.name}
                          </div>
                          <div className="product-quantity">
                            {!showSpinner && `${totalResults} items`}
                          </div>
                        </div>
                        <SearchWithFilter
                          searchText={filterQuery}
                          onSearch={(e) => handleSearchSpareParts(e)}
                        />
                      </div>
                      {spareParts.length > 0 &&
                        <table className="spare-parts-table">
                          <thead>
                            <tr className="table-row header-row">
                              {showFeatureDev && <th className="table-column image">{t("image")}</th>}
                              <th className="table-column item-id">{t("spareparts.item-id")}</th>
                              <th className="table-column description">{t("description")}</th>
                              <th className="table-column components last-column">{t("spareparts.components")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {spareParts.map((item, index) =>
                              <Fragment key={index}>
                                <tr className={"table-row" + (expandedSparePart === item.item_id ? " without-border" : "")}>
                                  {showFeatureDev && <td className="table-column image">
                                    <img 
                                      src={item.src || no_image_200x200}
                                      alt="sparepart"
                                    />
                                  </td>}
                                  <td className="table-column item-id">
                                    {item.item_id}
                                  </td>
                                  <td className="table-column description">
                                    <ExpandableText text={item.description} />
                                  </td>
                                  <td
                                    className={"table-column components last-column" + (item.components?.length > 0 ? " is-expandable" : "")} 
                                    onClick={() => handleExpandComponent((item.components?.length || 0), item.item_id)}
                                    tabIndex={0}
                                    role="button"
                                    onKeyDown={(e) => e.key === keyboardEvents.ENTER && handleExpandComponent((item.components?.length || 0), item.item_id)}
                                  >
                                    <div className="components-column-wrapper">
                                      {item.components?.length || 0}
                                      {item.components?.length > 0 &&
                                        <>
                                          {expandedSparePart === item.item_id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                        </>
                                      }
                                    </div>                                  
                                  </td>
                                </tr>
                                {expandedSparePart === item.item_id &&
                                  <tr className="table-row">
                                    <td className="table-column expanded-components" colSpan={showFeatureDev ? "4" : "3"}>
                                      <div className="components-list-wrapper" style={showFeatureDev ? {} : { marginLeft: "120px" }}>
                                        <ul className="components-list">
                                          {item.components.map((component, index) => 
                                            <li className="component-wrapper" key={index}>
                                              <div className="component-description">{component.description}</div>
                                            </li>)
                                          }
                                        </ul>
                                      </div>
                                    </td>
                                  </tr>
                                }
                              </Fragment>)
                            }
                          </tbody>
                        </table>
                      }
                      {spareParts.length === 0 && !showSpinner && !isFirstSparePartsLoad &&
                        <EmptyPageResults
                          title={t('spareparts.no-spareparts')}
                          subtitle={t('cannot_find_matching_search')}
                        />
                      }
                      {canLoadMore && (
                        <div className="buttons-wrapper">
                          <SimpleButton className="load-more-button" onClick={() => handleLoadMore()}>
                            {t('load_more')}
                          </SimpleButton>
                        </div>)
                      }
                    </div>
                  }
                  {!selectedModel && 
                    <EmptyPageResults 
                      title={t('spareparts.no-model-selected')}
                      subtitle={t('spareparts.select-model')}
                    />
                  }
                </div>
              :
                <DataPlaceholder
                  titlePlaceholder={t('spareparts.no-product-type-selected')}
                  subtitlePlaceholder={t('spareparts.select-product-type')}
                /> 
              }
            </>
          }
        </div>
      :
        <div className="content">
          {productTypes.filter(productType => !["BIOSONIC", "STATCLAVE"].includes(productType.name)).map((item, index) => 
            <div
              key={index}
              className="card d-flex flex-align-center"
              onClick={() => selectProductType(item)}
            >
              <div className="image-wrapper d-flex flex-align-center">
                <img
                  src={getImageByModel(item.models?.length > 0 ? item.models[0].name : "")}
                  className="img"
                  alt="product"
                />
              </div>
              <p className="product-type-name">{item.name}</p>
            </div>)
          }
        </div>
      }
      {isMobile && selectedProductType && (
        <SparepartsDetailsModal
          selectedProductType={selectedProductType}
          selectedModel={selectedModel}
          onSelectModel={(model, toggleVisibility) => selectModel(model, toggleVisibility)}
          spareParts={spareParts}
          isFirstSparePartsLoad={isFirstSparePartsLoad}
          totalResults={totalResults}
          filterQuery={filterQuery}
          onSearchSpareParts={(e) => handleSearchSpareParts(e)}
          expandedSparePart={expandedSparePart}
          onExpandSparePart={(componentsLength, itemId) => handleExpandComponent(componentsLength, itemId)}
          canLoadMore={canLoadMore}
          onLoadMore={() => handleLoadMore()}
          showSpinner={showSpinner}
          closeModal={() => setSelectedProductType(null)}
        />)
      }
      {showSpinner &&
        <div className="spinner-wrapper">
          <LoadingSpinner/>
        </div>
      }
    </div>
  )
}

function mapStateToProps({ productTypes }) {
  return {
    productTypes: productTypes || {}
  }
}

export default connect(mapStateToProps)(SparepartsModern)
